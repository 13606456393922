import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { deletepopup } from '../mydevices/mydevices.component';
import { Dataservice } from '../services/dataservice';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../Authentication/authentication.service';
import { Subscription, interval } from 'rxjs';
import { Ng2TelInput } from 'ng2-tel-input';

/**
 * Represents the GeneralComponent of the application.
 */
@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GeneralComponent implements OnInit {
  /**  
  *global variables
  */
  loading: boolean;
  id: any;
  timezoneget: any;
  selectedFile: any;
  data: any;
  user_Id: any;
  org_name: any;
  description: any;
  number: any;
  manager: any;
  timezone: any;
  fileInputLabel: any;
  fileUploadForm: any;
  imagepath: any;
  url: any;
  filtertimezone: any;
  user: any;
  isDisabled: boolean = true;

  config: any = {
    hideSelected: false,
    dropdownPosition: 'auto',
    appearance: 'underline',
    clearOnBackspace: true,
    closeOnSelect: true,
    appendTo: null
  };
  /**
    * Reference to the Ng2TelInput component.
    */
  @ViewChild("telInput") telInput!: Ng2TelInput

  /**
    * Form group for organization details.
    */
  organization = this.formbuilders.group({
    name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
    description: ['', Validators.maxLength(100)],
    number: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(15), Validators.pattern(/^\d+$/)]],
    // timezone: new FormControl(''),
    org_logo: [''],
    language: ['English [EN]'],
    encryptkey: ['Devsbot Key']
  });
  org_logo: any;
  validatetimezone: boolean = false;
  logo: any;
  language: any;
  hide = true;
  SelectConfig: any;
  app_mode_force: any;
  app_mode: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  countrycode: any;
  dialcode: any;
  imageloader!: boolean
  imagedelete!: boolean;
  interval: any;
  newChanges: any = false;
  formControlSubscription!: Subscription;

  /**
   * Represents the constructor of the GeneralComponent class.
   * @param formbuilders - Instance of the UntypedFormBuilder for creating form groups.
   * @param translate - Instance of the TranslateService for language translation.
   * @param matdialog - Instance of the MatDialog for displaying dialogs.
   * @param router - Instance of the Router for navigation.
   * @param dataservice - Service for interacting with API data.
   *                     Provides functions for getting and posting data.
   * @param toastr - Instance of the ToastrService for displaying toast messages.
   * @param authentication - Instance of the AuthenticationService for user authentication.
   */
  constructor(private formbuilders: UntypedFormBuilder, public translate: TranslateService,
    private matdialog: MatDialog,
    private router: Router,
    private dataservice: Dataservice,
    private toastr: ToastrService, private authentication: AuthenticationService) {
    this.loading = true;
    this.imagedelete = false;
    /**
 * Sets up language translation using the provided TranslateService instance.
 * Adds supported languages and sets the default language.
 * @param translate - The instance of TranslateService used for language translation.
 */
    translate.addLangs(['English [EN]', 'Russian [RU]']);
    // Set the default language to English [EN]
    translate.setDefaultLang('English [EN]');
    // Set the default language again (redundant but consistent)
    translate.setDefaultLang('English [EN]');

    this.SelectConfig = {
      dropdownPosition: 'top',
      appearance: 'underline',
      clearOnBackspace: true,
      closeOnSelect: true,
      appendTo: 'app-root'
    };
    /**
 * Stores the user's roles, providing details about the specific role of the user.
 */
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[1];
    /**
    * Checks the roles and permissions of the current user.
    * Sets flags indicating whether the user has permission for create, read, and delete actions.
    */
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })

  }
  toggleText: string = 'You have access to Developer features of Devsbot.';
  toggleChecked: any;
  /**
      * Initializes the component.
      * @param dataservice
 Service for interacting with API data. Provides functions for getting and posting data.
      */

  /**
   * Initializes the component and its properties when the component is created.
   */
  ngOnInit(): void {
    console.log(this.telInput)
     this.dataservice.setPaginationState(null,'all');
    // This can be used to control API calls or data updates when switching components.
    // These are used in MyDeviceComponent in the ngOnDestroy method.
    // Update MyDevicestatus observable
    this.dataservice.MyDevicestatus.next();

    // Update AllDevicestatus observable
    this.dataservice.AllDevicestatus.next();

    // Update singleDevicestatus observable
    this.dataservice.singleDevicestatus.next();
    // Set the loading flag to true
    this.loading = true;

    /**
     * Stores the login details of the user.
     * @param authentication - Instance of the AuthenticationService for user authentication.
     */
    const data = this.authentication.getUserData();

    // Retrieve organization data using user login details
    this.getoraganizationdata(data);

    /**
     * Subscribes to Refreshrequired observable to refresh the response data.
     */
    this.dataservice.Generalrefresh.subscribe((response: any) => {
      this.getoraganizationdata(data);
    });

    // Create form group for file upload
    this.fileUploadForm = this.formbuilders.group({
      Image: ['']
    });

    // Subscribe to form control changes
    this.formControlSubscription = this.organization.valueChanges.subscribe((res: any) => {
      this.newChanges = true;
    });
  }
  /**
   * Method to fetch and update the user's organization data.
   * @param data - The user's login data used to fetch organization details.
   */
  getoraganizationdata(data: any) {
  //  this.organization.get('encryptkey')?.disable()

    // Fetch organization data using the dataservice's getorganization method
    this.dataservice.getorganization(data).subscribe((res: any) => {
      // Set loading to false once data is received
      this.loading = false;

      // Check the response status
      if (res.status == "200") {
        
        // Reset imagedelete flag
        this.imagedelete = false;
        // Update properties with received data
        this.data = res.data;
        this.org_name = this.data?.org_Name;
        this.description = this.data?.description;
        // Check and handle undefined description
        if (this.description == 'undefined') {

          this.description = ''
        }
        // Update country code and dial code

        this.countrycode = this.data?.country_code;
        this.dialcode = this.data?.dial_code;
        // Set imageloader flag
        this.imageloader = true;
        // Update org_logo, language, app_mode, and other properties
        this.org_logo = this.data?.org_logo;
        this.language = this.data?.language;
        this.app_mode = this.data?.app_mode;
                this.timezone = this.data?.org_timezone;
        
  
        if (!this.timezone || this.timezone ==undefined || this.timezone=="undefined") {
          this.validatetimezone = true;
        }

        this.number = String(this.data?.org_contact).replace(this.dialcode, "").trim();
        this.manager = this.data?.org_Manager;
        this.app_mode_force = this.data?.app_mode_force;
        // Set values in the organization form

        this.organization.get('name')?.setValue(this.org_name);
        this.organization.get('number')?.setValue(this.number);

        this.organization.get('description')?.setValue(this.description);
        // Switch language based on received data
        this.switchLang(this.language)
       
       
      }
      else {
        // Display error message using toastr service
        this.toastr.error("Error occurred");
      }

    });
  }

  /**
   * Handles the event when the selected country changes in the tel input.
   * Updates the dial code and country code accordingly.
   * @param event - The event object containing country information.
   */
  onCountryChange(event: any) {
    // console.log(event)
    this.dialcode = '+' + event.dialCode;
    this.countrycode = event.iso2
  };


  telInputObject(event: any) {
    // console.log(event);
  }

  /**
   * Switches the language for translation using the provided lang parameter.
   * @param lang - The language code to switch to.
   */

  switchLang(lang: string) {
    this.translate.use(lang);
  }


  /**
 * Handles the event when a file is selected for image upload.
 * Checks permission and validates the file size.
 * @param event - The event object containing the selected file.
 */
  onFileChanged(event: any) {
   
    if (this.iscreate == true) {
      this.selectedFile = event.target.files[0];

      if (this.selectedFile.size > 2 * 1024 * 1024) {

        this.toastr.info('File size must be less than 2MB');
        // Clear the input field (if needed)
        event.target.value = '';
        this.selectedFile = "";
        let oldinput: any = document.querySelector('#file') as HTMLElement;

        return;
      } else if (this.selectedFile.size <= 2 * 1024 * 1024) {
        this.fileInputLabel = this.selectedFile.name;
        this.fileUploadForm.get('Image').setValue(this.selectedFile);
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
          this.url = reader.result;
        }
      }

    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }



  /**
   * Handles the organization update validation and calls the update function.
   */
  EditOrg() {
    if (this.iscreate == true) {
      if (this.organization.invalid) {
        return;
      }
      else {

        // if(!this.newChanges){
        this.updateOrg();
        // }else{
        //   this.toastr.info("No Changes Detected")
        // }

      }
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted");
    }



  }

  gettimezone() {
    var timezonevalue = document.getElementsByClassName('ng-value-label')
    console.log(timezonevalue)
    // timezonevalue['0']?.innerHTML = + this.timezone
    this.timezone = timezonevalue['0']?.innerHTML;

    var zone = document.getElementsByClassName('ng-placeholder')['0']
    zone.innerHTML = '';
  }


  /**
   * Updates the organization with the provided data.
   * Sends a request to update the organization settings.
   */
  updateOrg() {
    const user = this.authentication.getUserData()
    const org_id = user.org_Id;
    const organization = this.organization.value;
    this.organization.value.timezone = this.timezone;
    this.organization.value.org_logo = this.org_logo;
    const formData = new FormData();
    formData.append('Image', this.fileUploadForm.get('Image').value);
    formData.append('name', this.organization.value.name?.replace(/[^\S\r\n]{2,}/g, ' '));
    formData.append('description', this.organization.value.description?.replace(/[^\S\r\n]{2,}/g, ' '));
    formData.append('number', this.organization.value.number);
    formData.append('timezone', this.organization.value.timezone);
    formData.append('language', this.organization.value.language);
    formData.append('org_logo', this.organization.value.org_logo);
    formData.append('country_code', this.countrycode);
    formData.append('dial_code', this.dialcode);
    formData.append('imagedelete', String(this.imagedelete));

    if (this.app_mode == "0") {
      this.app_mode_force = "0";
    }
    formData.append('app_mode', this.app_mode);
    formData.append('app_mode_force', this.app_mode_force);

    this.dataservice.updateOrgImage(formData, org_id).subscribe((res: any) => {

      if (res.status == "201") {
        this.toastr.success("Organization Setting Successfully Changed");
      } else if (res.status == "0") {
        this.toastr.info("Organization Setting Updated Failed!");
        this.organization.value.number = this.number;
        this.countrycode = this.countrycode;
        this.dialcode = this.dialcode;
      }
      else {
        this.toastr.error("Error occured");
        this.organization.value.number = this.number;
        this.countrycode = this.countrycode;
        this.dialcode = this.dialcode;

      }
    })

  }
  /**
   * Prevents the default action when Enter key is pressed.
   * @param event - The event object representing the key press event.
   */
  onEnter(event: any) {
    event.preventDefault();

  }




  /**
   * Resets the uploaded image.
   * Only permitted when iscreate is true.
   */
  reset() {
    if (this.iscreate == true) {
      this.url = ''
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }
  /**
   * Opens the file upload dialog when the choose file button is clicked.
   * Only permitted when iscreate is true.
   */
  choosefile() {

    if (this.iscreate == true) {
      var file = document.getElementById('file')
      file?.click()
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }

  /**
   * Handles the toggle change event.
   * Updates the toggle text and app mode based on the toggle state, enable or disable developer mode.
   * 
   */
  onToggleChange(event: any) {
    if (this.iscreate == true) {
      this.toggleChecked = event.checked;

      if (this.toggleChecked) {
        this.toggleText = 'You have access to Developer features of Devsbot.';
        // this.toastr.info("Developer Mode")
        this.app_mode = '0'
        this.app_mode_force = '0'
      } else {
        this.toggleText = 'Want to create your own IoT templates with Devsbot? Turn it on.';
        this.app_mode = '1';
        // this.toastr.info("Production Mode")
      }
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }



  }
  /**
   * Resets the form to the original values.
   * Prompts the user before resetting.
   */
  resetcancel() {
    if (window.confirm('Leave this page without saving your changes?')) {

      this.org_name = this.data?.org_Name;
      this.description = this.data?.description;
      if (this.description == 'undefined') {

        this.description = ''
      }
      this.manager = this.data?.org_Manager;
      var timezonevalue = document.getElementsByClassName('ng-value-label')['0']
      if (timezonevalue) {
        timezonevalue.innerHTML = ''
      }
      var zone = document.getElementsByClassName('ng-placeholder')['0']
      zone.innerHTML = this.data?.org_timezone;
      this.timezone = this.data?.org_timezone;
      this.org_logo = this.data?.org_logo;

      this.dialcode = this.data?.dial_code;

      this.number = String(this.data?.org_contact).replace(this.dialcode, "").trim();

      this.imagedelete = false;
      this.language = this.data?.language;

      this.organization.get('name')?.setValue(this.org_name);
      this.organization.get('number')?.setValue(this.number);
      this.organization.get('description')?.setValue(this.description);
      this.organization.get('language')?.setValue(this.language)
      // this.timezone = this.timezone;
      this.switchLang(this.language)
      this.app_mode = this.data?.app_mode;
      this.app_mode_force = this.data?.app_mode_force;
      this.logo = '';
      let flag: any = document.getElementsByClassName("iti__flag")
      let flagdiv = flag[0] as HTMLElement;
      flagdiv.classList.remove(String('iti__' + this.countrycode));
      this.countrycode = String(this.data?.country_code);
      flagdiv.classList.add(String('iti__' + this.countrycode))

      let dialcode: any = document.getElementsByClassName("iti__selected-dial-code")[0] as HTMLElement
      dialcode.innerText = this.dialcode

    }
  }
  /**
   * Removes the organization image.
   * Opens a confirmation dialog before removing the image.
   */
  removeOrgImag() {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, { disableClose: true, data: "delete" });
      MatDialogConfig.afterClosed().subscribe((result) => {
        if (result.confirmation == true) {
          this.imagedelete = true;
          this.logo = this.org_logo;
          this.url = "";
          this.org_logo = "";
          this.fileUploadForm.get('Image').setValue('');
          this.org_logo = "";
        }
      })
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }


  }


  /**
   * Handles the checkbox change event for app mode force.
   */

  onCheckboxChange(event: any) {
    if (this.iscreate == true) {
      if (event.checked == true) {
        this.app_mode_force = '1'
      } else {
        this.app_mode_force = '0'
      }
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }


}

