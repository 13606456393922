import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';



@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

/**
 * Creates an instance of the constructor.
 * @param {Router} router - The router service for navigation.
 * @param {AuthenticationService} authentication - The authentication service for user authentication-related operations.
 */
constructor(private router: Router, private authentication: AuthenticationService) { }
 /**
 * Determines if a route can be activated based on the user's authentication status.
 * @param {ActivatedRouteSnapshot} route - The route to be activated.
 * @param {RouterStateSnapshot} state - The current state of the router.
 * @returns {boolean} - `true` if the route can be activated, `false` otherwise.
 */
canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  /**
   * Check if the user is logged in.
   */
  if (this.isloggedIn()) {
    /**
     * Refresh the user data to ensure up-to-date information.
     */
    this.authentication.RefreshUserdata();
    return true;
  }
  /**
   * Clear any authentication-related intervals.
   */
  this.authentication.clearInterval();
  /**
   * Navigate to the login page.
   */
  this.router.navigate(['/app/signin']);
  /**
   * Return `false` to prevent route activation.
   */
  return false;
}

  /**
 * Checks whether the user is currently logged in.
 * @returns {boolean} - `true` if the user is logged in, `false` otherwise.
 */
public isloggedIn(): boolean {
  let status = false;
  if (localStorage.getItem('isLoggedIn')) {
    status = true;
  } else {
    status = false;
  }
  return status;
}

}
