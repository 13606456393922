import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { changepassword } from '../account/account.component';
import { Dataservice } from '../services/dataservice';
import { Location } from '@angular/common';
import { logout } from '../menu/menu.component';
import { AuthenticationService } from '../Authentication/authentication.service';
import { deletepopup } from '../mydevices/mydevices.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit {
  isAdmin = true;
  isUser = true;
  isStaff = true;
  user_id: any;
  userdata: any;
  loading: boolean;
  id: any;
  user_Id: any;
  isAuthenticated: boolean;
  activatedRoute = '';
  data: any;
  config: any;
  selectedtab: number = 0;
  filterTerm!: string;
  /**
   * Creates an instance of the component.
   *
   * @param matdialog - The MatDialog service for opening dialog windows.
   * @param router - The Router service for navigating between views.
   * @param dataservice - The Dataservice for handling data operations.
   * @param toastr - The ToastrService for displaying toast notifications.
   * @param authentication - The AuthenticationService for managing user authentication.
   */
  constructor(
    public matdialog: MatDialog,
    private router: Router,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private authentication: AuthenticationService
  ) {
    /**
     * Initializes the loading state to false.
     */
    this.loading = false;
    /**
     * Initializes the authentication state to false.
     */
    this.isAuthenticated = false;
    /**
     * Initializes the pagination configuration with default values.
     */
    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
    };
  }

  /**
   * Lifecycle hook that runs when the component is initialized.
   * Initializes data and performs necessary operations upon component initialization.
   */
  ngOnInit(): void {
    /**
     * Emits a new value to notify subscribers of device status changes.
     */
    this.dataservice.MyDevicestatus.next();

    /**
     * Emits a new value to notify subscribers of all device status changes.
     */
    this.dataservice.AllDevicestatus.next();
    /**
     * Emits a new value to notify subscribers of changes to a single device's status.
     */
    this.dataservice.singleDevicestatus.next();
    /**
     * Sets the loading state to true to indicate that data is being fetched.
     */
    this.loading = true;
    /**
     * Retrieves user data from the authentication service.
     */
    const data = this.authentication.getUserData();
    /**
     * Retrieves user data using the Dataservice.
     */
    this.dataservice.getuser(data).subscribe((res: any) => {
      /**
       * Sets the loading state to false to indicate that data retrieval is complete.
       */
      this.loading = false;
      /**
       * Checks the response status to determine further actions.
       */
      if (res.status == '200') {
        /**
         * Assigns the fetched user data to the component's 'data' property.
         */
        this.data = res.data;
      } else {
        /**
         * Displays an error notification if data retrieval fails.
         */
        this.toastr.error('Error occurred');
      }
    });
  }
  /**
   * Updates the current page number in the configuration object when the pagination page is changed.
   *
   * @param event - The event object containing the new page number.
   */
  pageChanged(event: any) {
    /**
     * Updates the current page number in the 'config' object with the newly selected page number.
     */
    this.config.currentPage = event;
  }
  /**
   * Initiates the user logout process by opening a confirmation dialog.
   * If the user confirms the logout, clears local storage and navigates to the sign-in page.
   */
  logout() {
    /**
     * Configures the MatDialog to open a confirmation dialog with the 'logout' component.
     * The dialog is set to be non-closable by the user.
     */
    const MatDialogConfig = this.matdialog.open(logout, { disableClose: true });
    /**
     * Subscribes to the dialog's 'afterClosed' event to listen for the user's choice.
     * If the user confirms the logout, clears the local storage and navigates to the sign-in page.
     *
     * @param result - The result object returned after the dialog is closed.
     */
    MatDialogConfig.afterClosed().subscribe((result: any) => {
      if (result.event == 'confirm') {
        /**
         * Clears local storage, effectively logging out the user.
         */
        localStorage.clear();
        /**
         * Navigates to the sign-in page.
         */
        this.router.navigate(['/app/signin']);
      }
    });
  }

  /**
   * Opens the change password dialog for the user to change their password.
   * The dialog is configured to be non-closable by the user.
   */
  changeopen() {
    /**
     * Configures the MatDialog to open the 'changepassword' component dialog.
     * The dialog is set to be non-closable by the user.
     */
    const MatDialogConfig = this.matdialog.open(changepassword, {
      disableClose: true,
    });
  }
  /**
   * Opens the create user dialog to allow the user to create a new user.
   * The dialog is configured to be non-closable by the user.
   */
  createuser() {
    /**
     * Configures the MatDialog to open the 'createuser' component dialog.
     * The dialog is set to be non-closable by the user.
     */
    const MatDialogConfig = this.matdialog.open(createuser, {
      disableClose: true,
    });
  }
  /**
   * Retrieves user data based on the provided user ID and updates the displayed user information.
   *
   * @param id - The user ID for which to retrieve information.
   */
  info(id: any) {
    /**
     * Sets the user ID to the provided ID.
     * Retrieves user data from the 'dataservice' using the user ID and subscribes to the result.
     * If the status is '200', updates the 'userdata' with the retrieved data.
     * If the status is not '200', displays an error message.
     */
    this.user_id = id;
    this.dataservice.getuserdata(this.user_id).subscribe((res: any) => {
      if (res.status == '200') {
        this.userdata = res.data;
      } else {
        this.toastr.error('Error occurred');
      }
    });
  }

  /**
   * Opens the user edit dialog with pre-filled user data if the user has the necessary permissions.
   */
  editopen() {
    /**
     * Configures the MatDialog to open the 'useredit' component dialog.
     * The dialog is set to be non-closable by the user and is passed the pre-filled 'userdata'.
     */
    const MatDialogConfig = this.matdialog.open(useredit, {
      disableClose: true,
      data: this.userdata,
    });
  }

  /**
   * Activates a user based on the provided user ID if the current user has the necessary permissions.
   *
   * @param user_Id - The user ID for which to activate the user.
   */
  activeuser(user_Id: any) {
    /**
     * Creates a data object with the provided user ID.
     * Calls the 'activeuser' method from the 'dataservice' using the data object and subscribes to the result.
     * If the status is '200', displays an info message indicating the lack of permission.
     * If the status is '201', displays a success message and may reload the page.
     * If the status is not '200' or '201', displays an error message.
     */
    const data = { user_Id };
    this.dataservice.activeuser(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.toastr.info('User not a permission');
      }
      if (res.status == '201') {
        this.toastr.success('User Active');
      } else {
        this.toastr.error('Error occurred');
      }
    });
  }

  /**
   * Suspends a user based on the provided user ID if the current user has the necessary permissions.
   *
   * @param user_Id - The user ID for which to suspend the user.
   */
  suspendeduser(user_Id: any) {
    /**
     * Creates a data object with the provided user ID.
     * Calls the 'suspendeduser' method from the 'dataservice' using the data object and subscribes to the result.
     * If the status is '200', displays an info message indicating the lack of permission.
     * If the status is '201', displays a success message and may reload the page.
     * If the status is not '200' or '201', displays an error message.
     */
    const data = { user_Id };
    this.dataservice.suspendeduser(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.toastr.info('User not a permission');
      }
      if (res.status == '201') {
        this.toastr.success('User Suspended');
      } else {
        this.toastr.error('Error occurred');
      }
    });
  }
}

@Component({
  selector: 'createuser',
  templateUrl: 'createuser.html',
  styleUrls: ['popupstyles.css'],
})
export class createuser implements OnInit {
  // separateDialCode = true;
  // SearchCountryField = SearchCountryField;
  // CountryISO = CountryISO;
  // PhoneNumberFormat = PhoneNumberFormat;
  // preferredCountries: CountryISO[] = [CountryISO.UnitedStates,
  // CountryISO.UnitedKingdom];
  errorMessage = '';
  loading: boolean;
  hide = true;
  id: any;
  user_Id: any;
  data: any;
  verticalvalue: any[] = [];
  usergroup: any[] = [];
  countrycode: any = 'in';
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  dialcode: any = '+91';
  noSpaceAsSecondCharacter = (control: AbstractControl): { [key: string]: boolean } | null => {
    const value: string = control.value;
    
    if (value && value.length >= 2) {
      // Check if the second character is a space
      if (value.charAt(1) === ' ') {
        // Check if there are two or more spaces after the first character
        if (value.substring(2).includes(' ')) {
          return { 'noSpaceAsSecondCharacter': true };
        }
      }
    }
    
    return null;
  };
  
  
  /**
   * Represents the user registration form using Angular's reactive forms approach.
   */
  userform = this.formbuilders.group({
    /**
     * Represents the user's name.
     */
    name: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(15),
        Validators.pattern('^[A-Za-z_][A-Za-z0-9_ .\\-]*$'),
        this.noSpaceAsSecondCharacter // Custom validator
      ],
    ],
    /**
     * Represents the user's email address.
     */
    email: ['', [Validators.required, Validators.email]],
    /**
     * Represents the user's role.
     */
    role: ['', Validators.required],
    /**
     * Represents the user's vertical name.
     */
    verticalname: ['', Validators.required],
    /**
     * Represents the user's phone number.
     */

    number: [
      '',
      [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern(/^\d+$/),
      ],
    ],
    /**
     * Represents the user's password.
     */
    password: [
      '',
      [Validators.required, Validators.minLength(6), Validators.maxLength(20)],
    ],
    /**
     * Represents the country code of the user's phone number.
     */
    country_code: [''],
    /**
     * Represents the dial code for the user's country.
     */
    dial_code: [''],
    user_type:[0],
    gateway:[null],
  });

  submitted = false;
  showSelectField!: boolean;
  /**
   * Represents the constructor of a component or service.
   * @param formbuilders - An instance of the form builder (may be a custom implementation).
   * @param dataservice - The data service used for retrieving and manipulating data.
   * @param router - The Angular router for navigating between routes.
   * @param matdialog - The Angular Material dialog service for opening dialogs.
   * @param toastr - The ToastrService for displaying toast notifications.
   * @param authentication - The authentication service for user authentication.
   */

  constructor(
    public formbuilders: UntypedFormBuilder,
    private dataservice: Dataservice,
    private router: Router,
    private matdialog: MatDialog,
    private toastr: ToastrService,
    private authentication: AuthenticationService
  ) {
    this.loading = false;
  }

  /**
   * Lifecycle hook called when the component is initialized.
   * Initializes component properties and fetches initial data.
   */
  ngOnInit(): void {
    // Initialize device status observables
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();
    // Delayed setting of showSelectField to true
    setTimeout(() => {
      this.showSelectField = true;
    }, 200);
   
   
    this.loading = true; // Set loading state
    const data = this.authentication.getUserData(); // Get user data
    // Fetch vertical values from the data service
    this.dataservice.getverticalvalue(data).subscribe((res: any) => {
      this.loading = false; // Loading finished
      if (res.status == '200') {
        this.data = res.data;
        this.verticalvalue = [];
        // Process vertical names and IDs
        this.data.map((x: any) => {
          x.vertical_name = x.vertical_name?.split(',');
          x.vertical_Id = x.vertical_Id?.split(',');
          x.vertical_name?.map((y: any, i: number) => {
            this.verticalvalue?.push({
              vertical_name: y,
              vertical_Id: x.vertical_Id[i],
            });
          });
        });
      } else {
        this.toastr.error('Error occurred'); // Show error message
      }
    });
    // Fetch user group data from the data service
    this.dataservice.getroleGroupdata(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.usergroup = this.data;
      } else {
        this.toastr.error('Error occurred'); // Show error message
      }
    });

    this.userform.controls['email'].setValue('');
    this.userform.controls['password'].setValue('');
  }

  /**
   * Navigates to the "vertical" route to create a new vertical and closes any open dialog windows.
   */
  createVertical() {
    this.router.navigate(['/app/vertical']); // Navigate to the "vertical" route
    this.matdialog.closeAll(); // Close all open dialog windows
  }

  /**
   * Getter function that returns a dictionary-like object containing the form controls of the userform FormGroup.
   * The controls can be accessed using their names as keys.
   * Example usage: `this.f.email` to access the email form control.
   */
  get f(): { [key: string]: AbstractControl } {
    return this.userform.controls;
  }

  // onSubmit(){
  //   this.submitted = true;
  //   if (this.userform.invalid) {
  //     return;
  //   } else {
  //     this.usercreate();
  //   }
  // }

  /**
   * Event handler for when a country is selected or its data changes.
   * @param event - The event object containing information about the selected country.
   */
  onCountryChange(event: any) {
    // Extract the dial code and set it with a "+" prefix
    this.dialcode = '+' + event.dialCode;
    // Store the ISO2 country code
    this.countrycode = event.iso2;
  }

  /**
   * Handles the form submission for creating a new user.
   */
  onSubmit() {
    this.loading = true;
    // Get the user's data from authentication service
    const user = this.authentication.getUserData();
    const org_Id = user.org_Id;
    // Modify the user's name to replace multiple spaces with a single space
    this.userform.value.name = this.userform.value.name.replace(
      /[^\S\r\n]{2,}/g,
      ' '
    );
    // Set the country code and number based on the selected country
    this.userform.value.country_code = this.countrycode;
    let number = this.userform.value.number;
    this.userform.value.number = this.dialcode + this.userform.value.number;
    this.userform.value.dial_code = this.dialcode;
    // Extract form values
    const userform = this.userform.value;
    // Prepare data for creating the user
    const data = { userform, org_Id };
    // Send the request to create the user
    this.dataservice.createuser(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == '200') {
        this.toastr.error('Email Already Exist');
        this.userform.value.number = number;
        this.dialcode = this.dialcode;
      } else if (res.status == '202') {
        this.toastr.error('user not a permission');
        this.userform.value.number = number;
        this.dialcode = this.dialcode;
      } else if (res.status == '201') {
        this.toastr.success('User Created Successfully');
        this.matdialog.closeAll();
      } else {
        this.toastr.error('error occured');
        this.userform.value.number = number;
        this.dialcode = this.dialcode;
      }
    });
  }
}

@Component({
  selector: 'useredit',
  templateUrl: 'useredit.html',
  styleUrls: ['edituserpopup.css'],
})
export class useredit implements OnInit {
  errorMessage = '';
  loading: boolean;
  location: any;
  location_id: any;
  id: any;
  user_Id: any;
  selectedFile: any;
  fileInputLabel: any;
  fileUploadForm: any;
  url: any;
  role: any;
  countrycode: any = 'in';
  dialcode: any = '+91';
  Roles: any[] = [];
  reactivate!: boolean;
  /**
   * Creates an instance of the UserComponent.
   *
   * @param dataservice - An instance of the Dataservice used for making data service requests.
   * @param formbuilder - An instance of the FormBuilder used for creating and managing forms.
   * @param dialogRef - A reference to the MatDialogRef for the current dialog.
   * @param data - Data passed to the dialog component when it was opened.
   * @param toastr - An instance of the ToastrService for displaying toast messages.
   * @param authentication - An instance of the AuthenticationService for user authentication tasks.
   */
  constructor(
    private dataservice: Dataservice,
    public formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<UserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private authentication: AuthenticationService
  ) {
    this.loading = false;
  }
  noSpaceAsSecondCharacter = (control: AbstractControl): { [key: string]: boolean } | null => {
    const value: string = control.value;
  
    if (value && value.length >= 2 && value.charAt(1) === ' ') {
      return { 'noSpaceAsSecondCharacter': true };
    }
  
    return null;
  };
  user = this.data;
  /**
   * Represents the FormGroup used for editing user information.
   *
   * It contains form controls for fields such as name, email, role, number, country code, and dial code.
   * This form is used to gather and validate user input when editing user details.
   */
  edituserform = this.formbuilder.group({
    /**
     * The user's name.
     */
    name: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
        Validators.pattern('^[A-Za-z_][A-Za-z0-9_ .\\-]*$'),
        this.noSpaceAsSecondCharacter // Custom validator
      ],
    ],
    /**
     * The user's email address.
     */
    email: ['', [Validators.required, Validators.email]],
    /**
     * The role of the user.
     */
    role: ['', Validators.required],
    /**
     * The user's phone number.
     */
    number: [
      '',
      [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern(/^\d+$/),
      ],
    ],
    /**
     * The selected country code for the user's phone number.
     */
    country_code: [''],
    /**
     * The selected dial code for the user's phone number.
     */
    dial_code: [''],
  });
  /**
   * Getter method that provides convenient access to the form controls of the edituserform FormGroup.
   *
   * This getter allows accessing individual form controls within the FormGroup using dot notation.
   * For example, to access the 'name' control, you can use: `f.name`.
   *
   * @returns An object where keys are control names and values are corresponding AbstractControl instances.
   */
  get f(): { [key: string]: AbstractControl } {
    return this.edituserform.controls;
  }

  /**
   * Lifecycle hook called after the component is initialized.
   *
   * This method is used to initialize the component's properties and retrieve necessary data from services.
   */
  ngOnInit(): void {
    // Trigger device status updates
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();
    // Get user data from authentication service
    const data = this.authentication.getUserData();
    this.role = data.role;
    // Retrieve and process role group data
    this.dataservice.getroleGroupdata(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.Roles = res.data;
        this.Roles.map((x: any) => {
          x.group_permission = JSON.parse(x.group_permission);
        });
      } else {
        this.toastr.error('error occured');
      }
    });

    // Set initial form values based on user data
    this.edituserform.get('name')?.setValue(this.user?.firstname);
    this.edituserform.get('email')?.setValue(this.user?.email_id);
    this.dialcode = this.user?.dial_code;
    this.countrycode = this.user?.country_code;
    let number = String(this.user?.phone_num).replace(this.dialcode, '').trim();

    this.edituserform.get('number')?.setValue(number);
    this.edituserform.get('role')?.setValue(this.user?.group_Id);
    // Update 'reactivate' flag based on group_Id presence
    if (!this.user?.group_Id) {
      this.reactivate = true;
    }

    // this.fileUploadForm = this.formbuilder.group({
    //   Image: ['']
    // });
  }

  onCountryChange(event: any) {
    this.dialcode = '+' + event.dialCode; // Update dial code with selected country's dial code
    this.countrycode = event.iso2; // Update country code with selected country's ISO2 code
  }

  // changeprofile(){
  //   document.getElementById("file")?.click();
  // }
  // onFileChanged(event: any) {
  //   this.selectedFile = event.target.files[0]
  //   this.fileInputLabel = this.selectedFile.name;
  //   this.fileUploadForm.get('Image').setValue(this.selectedFile);
  //   var reader = new FileReader();
  //   reader.readAsDataURL(event.target.files[0]);
  //   reader.onload = (_event) => {
  //     this.url = reader.result;
  //   }
  // }

  // reset(){
  //   this.url = ''
  // }
  // \

  /**
   * Handles the process of updating user information.
   */
  userupdate() {
    this.loading = true;
    // Remove excess white spaces from the user's name
    this.edituserform.value.name = this.edituserform.value.name?.replace(
      /[^\S\r\n]{2,}/g,
      ' '
    );
    // Store the user's original number before formatting
    let number = this.edituserform.value.number;
    // Format the user's number with dial code
    this.edituserform.value.number =
      this.dialcode + this.edituserform.value.number;
    // Update user's country code and dial code
    this.edituserform.value.country_code = this.countrycode;
    this.edituserform.value.dial_code = this.dialcode;
    // Get updated user data from the form
    const userdata = this.edituserform.value;
    // Get the user ID of the user being updated
    const user_Id = this.user.user_Id;
    // Prepare data for the update request
    let data = { userdata, user_Id };
    // Send the update request to the data service
    this.dataservice.updateuser(data).subscribe((res: any) => {
      if (res.status == '201') {
        this.toastr.success('Updated Successfully');
        // If the user was previously inactive, activate them
        if (!this.user?.group_Id) {
          this.dataservice.activeuser(data).subscribe((res: any) => {
            if (res.status == '200') {
              this.toastr.info('User not a permission');
            }
            if (res.status == '201') {
              // User activated
            } else {
              this.toastr.error('Error occurred');
            }
          });
        }

        this.dialogRef.close();
      } else {
        this.toastr.error('error occured');
        this.edituserform.value.number = number;
      }
    });
  }

  //     removeprofile(){
  //        const user_Id=this.user.user_Id;
  //        const user_profile=this.user.user_profile;
  //        let data={user_Id,user_profile};
  // this.dataservice.removeprofile(data).subscribe((res:any)=>{
  //   if (res.status == "0") {
  //     this.toastr.error("Process Failed")
  //   } else {
  //     this.toastr.success("Update Sucessfully")
  //     window.location.reload();
  //   }
  // })
}

@Component({
  selector: 'userinfo',
  templateUrl: 'userinfo.html',
  styleUrls: ['../mydevices/mydevices.component.css'],
})
export class userinfo implements OnInit {
  user_Id: any;
  loading: boolean;
  selectedTabIndex = 0;
  userdata: any;
  item: any;
  filterTerm!: string;
  currenttime = new Date();
  usertableconfig: { id: string; itemsPerPage: number; currentPage: number };
  data: any;
  filterdevicevalue: any;
  devicevalue: any;
  statusloading: boolean;
  last_online: string[] = [];
  deviceversion: any[] = [];
  status_Id: any[] = [];
  wifi_status:any[]=[];
  statusinterval: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;

  /**
   * Constructs the UserComponent.
   *
   * @param matdialog - The MatDialog service for opening dialog windows.
   * @param dataservice - The Dataservice for retrieving and managing data.
   * @param toastr - The ToastrService for displaying toast notifications.
   * @param formbuilder - The FormBuilder for creating and managing forms.
   * @param route - The ActivatedRoute for retrieving route parameters.
   * @param router - The Router for navigation.
   * @param _location - The Location service for accessing browser's location.
   * @param authentication - The AuthenticationService for user authentication.
   */
  constructor(
    private matdialog: MatDialog,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private authentication: AuthenticationService
  ) {
    // Initialize properties
    this.loading = false;
    this.usertableconfig = {
      id: 'user-device',
      itemsPerPage: 6,
      currentPage: 1,
    };
    this.statusloading = true;
    // Determine user role permissions
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[3];
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });
  }

  /**
   * Lifecycle hook that is called after the component's view has been initialized.
   * Initializes component properties and subscribes to data service events.
   */
  ngOnInit(): void {
    // Initialize device status observables
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();
    // Set loading state
    this.loading = true;
    // Get user ID from route parameter
    this.user_Id = this.route.snapshot.paramMap.get('id');
    // Load user information using the user ID
    this.info(this.user_Id);
    // Subscribe to Userrefresh event to reload user information
    this.dataservice.Userrefresh.subscribe((res: any) => {
      this.info(this.user_Id);
    });
  }

  /**
   * Changes the selected tab index to the specified index.
   *
   * @param index - The index of the tab to be selected.
   */
  selectTab(index: number): void {
    this.selectedTabIndex = index;
  }

  /**
   * Retrieves user information based on the provided user ID and updates the displayed user data.
   *
   * @param user_Id - The user ID for which information will be retrieved.
   * @returns A Promise that resolves once user data has been retrieved and updated.
   */
  async info(user_Id: any) {
    // Set loading state to indicate data retrieval
    this.loading = true;
    // Switch to the first tab
    this.selectTab(0);
    // Prepare data for API request
    const data = { user_Id };
    // Make an API request to get user data
    await this.dataservice.getuserdata(data).subscribe((res: any) => {
      // Clear loading state
      this.loading = false;
      // Check if API response status is successful
      if (res.status == '200') {
        // Update the data with retrieved user information
        this.data = res.data;
        this.userdata = this.data;
      } else {
        // Display an error message if API response status is not successful
        this.toastr.error('Error occurred');
      }
    });
  }

  /**
   * Handles the tab click event and performs specific actions when a particular tab is clicked.
   *
   * @param event - The tab click event object.
   */
  tabClick(event: any) {
    // Check if the clicked tab is the second tab (index 2)
    if (event.index == 2) {
      // Prepare data for API request
      const data = { user_Id: this.user_Id };
      // Make an API request to get device data
      this.dataservice.getmydevicedata(data).subscribe((res: any) => {
        this.currenttime = new Date();
        if (res.status == '200') {
          this.data = res.data;
          this.devicevalue = this.data;

          this.devicevalue.map((x: any) => {
            x.last_online = new Date(x.last_online);
            x.heartbeat = +Number(x.heartbeat) + 5
            x.heartbeat = Number(x.heartbeat) * 12.5
          });
          // Update device data periodically
          setInterval(() => {
            let i;
            for (i = 0; i < this.devicevalue?.length; i++) {
              this.devicevalue[i].last_online = this.last_online[i];
              this.devicevalue[i].status_Id = this.status_Id[i];
              this.devicevalue[i].device_version = this.deviceversion[i];
              this.devicevalue[i].wifi_status = this.wifi_status[i]

            }
          }, 0.5); // Update interval in milliseconds
        } else {
          this.toastr.error('error occurred');
        }
      });

      // Get my device status data and update it periodically
      this.statusinterval = setInterval(() => {
        this.currenttime = new Date();
        this.dataservice.getmydevicestatusdata(data).subscribe((res: any) => {
          this.statusloading = false;
          if (res.status == '200') {
            let timedata = res.data;
            const devicedata = timedata;
            while (this.last_online?.length > 0) {
              this.last_online.pop();
            }
            while (this.status_Id?.length > 0) {
              this.status_Id.pop();
            }
            while (this.deviceversion?.length > 0) {
              this.deviceversion.pop();
            }
            while (this.wifi_status?.length > 0) {
              this.wifi_status.pop();
            }
            devicedata?.find((x: any) => {
              x.last_online = new Date(x.last_online);
              this.last_online.push(x.last_online);
              this.status_Id.push(x.status_Id);
              this.deviceversion.push(x.device_version);
              this.wifi_status.push(x.wifi_status);
            });
          } else {
            this.toastr.error('Error occurred');
          }
        });
      }, 2000); // Status update interval in milliseconds
    } // Clear the status update interval if a different tab is clicked
    else {
      clearInterval(this.statusinterval);
    }
  }

  /**
   * Navigates to the device information page with the provided device and product IDs.
   *
   * @param device_Id - The ID of the device to view information for.
   * @param product_Id - The ID of the product associated with the device.
   */
  deviceinfo(device_Id: any, product_Id: any) {
    this.router.navigate(['/app/dashboard', device_Id, product_Id]);
  }

  /**
   * Initiates the logout process by opening a dialog for confirmation.
   * Clears local storage and navigates to the signin page upon confirmation.
   */
  logout() {
    const MatDialogConfig = this.matdialog.open(logout, { disableClose: true });
    MatDialogConfig.afterClosed().subscribe((result: any) => {
      if (result.event == 'confirm') {
        localStorage.clear();
        this.router.navigate(['/app/signin']);
      }
    });
  }

  /**
   * Opens a user edit dialog for editing user data if the user has the necessary permissions.
   * If the user doesn't have permissions, displays a message indicating the lack of permission.
   */
  useredit() {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(useredit, {
        disableClose: true,
        data: this.userdata,
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Updates the current page of the user device table based on the provided event.
   *
   * @param event - The event object representing the page change.
   */
  userdevicetable(event: any) {
    this.usertableconfig.currentPage = event;
  }

  /**
   * Opens a dialog for changing the user's password.
   */
  changepassword() {
    const MatDialogConfig = this.matdialog.open(changepassword, {
      disableClose: true,
    });
  }

  /**
   * Navigates the user back to the previous location in the browser's history.
   */
  backClicked() {
    this._location.back();
  }

  /**
   * Initiates a forced logout for a specific user if the current user has the necessary permissions.
   * Opens a confirmation dialog for the action. Displays a success message on successful logout or an error message on failure.
   * If the current user doesn't have permissions, displays a message indicating the lack of permission.
   *
   * @param user_Id - The user ID for which to initiate a forced logout.
   */
  forcelogout(user_Id: any) {
    /**
     * Check if the current user has the necessary permissions for forced logout.
     */
    if (this.iscreate == true) {
      /**
       * Open a confirmation dialog for the forced logout action.
       */
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'logout',
      });
      /**
       * Subscribe to the afterClosed event of the confirmation dialog.
       * If the user confirms the action, proceed with forced logout.
       */
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          /**
           * Prepare data for the forced logout operation.
           */
          const data = { user_Id: user_Id };
          /**
           * Call the dataservice's forcelogout method to initiate forced logout.
           */
          this.dataservice.forcelogout(data).subscribe((res: any) => {
            /**
             * Display success message on successful forced logout.
             * Display error message on failure.
             */
            if (res.status == '201') {
              this.toastr.success('Force Logout Successfully!');
            } else {
              this.toastr.error('Error occurred');
            }
          });
        }
      });
    } else if (this.iscreate == false) {
      /**
       * Display info message if the current user doesn't have permissions.
       */
      this.toastr.info('User not permitted');
    }
  }
}

@Component({
  selector: 'AllUser',
  templateUrl: 'AllUsers.html',
  styleUrls: ['../verticalmembers/verticalmembers.component.css'],
})
export class AllUser implements OnInit {
  config: any;
  filterTerm!: string;
  isAuthenticated!: boolean;
  loading!: boolean;
  data: any;
  currentuserid: any;
  /**
   * Creates an instance of the component.
   *
   * @param matdialog - The MatDialog service for opening dialog windows.
   * @param router - The Router service for navigating between views.
   * @param dataservice - The Dataservice for handling data operations.
   * @param toastr - The ToastrService for displaying toast notifications.
   * @param authentication - The AuthenticationService for managing user authentication.
   */
  constructor(
    public matdialog: MatDialog,
    private router: Router,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private authentication: AuthenticationService
  ) {
    /**
     * Initializes the loading state to false.
     */
    this.loading = false;
    /**
     * Initializes the authentication state to false.
     */
    this.isAuthenticated = false;
    /**
     * Initializes the pagination configuration with default values.
     */
    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
    };
  }

  ngOnInit(): void {
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();
    this.loading = true;
    const userData = this.authentication.getUserData();

    this.currentuserid = userData.user_Id
    console.log(this.currentuserid);


    this.dataservice.getAlluser(userData).subscribe((res: any) => {


      this.loading = false;
      if (res.status == '200') {
        this.data = res.data;

      } else if (res.status == '400') {
        this.toastr.error('Error occurred');
      }
    });
    this.dataservice.Userrefresh.subscribe((res) => {
      const userData = this.authentication.getUserData();
      console.log(userData);
      this.dataservice.getAlluser(userData).subscribe((res: any) => {

        this.loading = false;
        if (res.status == '200') {
          this.data = res.data;
          console.log(this.data);
        } else if (res.status == '400') {
          this.toastr.error('Error occurred');
        }
      });
    });
  }
  pageChanged(event: any) {
    /**
     * Updates the current page number in the 'config' object with the newly selected page number.
     */
    this.config.currentPage = event;
  }

  activeuser(user_Id: any) {
    /**
     * Creates a data object with the provided user ID.
     * Calls the 'activeuser' method from the 'dataservice' using the data object and subscribes to the result.
     * If the status is '200', displays an info message indicating the lack of permission.
     * If the status is '201', displays a success message and may reload the page.
     * If the status is not '200' or '201', displays an error message.
     */

    const MatDialogConfig = this.matdialog.open(deletepopup, {
      disableClose: true,
      data: 'activeuser',
    });

    MatDialogConfig.afterClosed().subscribe((result: any) => {
      if (result.confirmation == true) {
        const data = { user_Id };
        this.dataservice.activeuser(data).subscribe((res: any) => {
          if (res.status == '200') {
            this.toastr.info('User not a permission');
          }
          if (res.status == '201') {
            this.toastr.success('User Active');
          } else {
            this.toastr.error('Error occurred');
          }
        });
      }
    });
  }

  /**
   * Suspends a user based on the provided user ID if the current user has the necessary permissions.
   *
   * @param user_Id - The user ID for which to suspend the user.
   */
  suspendeduser(user_Id: any) {
    /**
     * Creates a data object with the provided user ID.
     * Calls the 'suspendeduser' method from the 'dataservice' using the data object and subscribes to the result.
     * If the status is '200', displays an info message indicating the lack of permission.
     * If the status is '201', displays a success message and may reload the page.
     * If the status is not '200' or '201', displays an error message.
     */

    const MatDialogConfig = this.matdialog.open(deletepopup, {
      disableClose: true,
      data: 'suspendeduser',
    });

    MatDialogConfig.afterClosed().subscribe((result: any) => {
      if (result.confirmation == true) {
        const data = { user_Id };
        this.dataservice.suspendeduser(data).subscribe((res: any) => {
          if (res.status == '200') {
            this.toastr.info('User not a permission');
          }
          if (res.status == '201') {
            this.toastr.success('User Suspended');
          } else {
            this.toastr.error('Error occurred');
          }
        });
      }
    });
  }
}
