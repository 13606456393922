import { Component, OnInit } from '@angular/core';
import { Dataservice } from '../services/dataservice';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {


  constructor(private dataservice: Dataservice) {

  }
  ngOnInit(): void {
    this.dataservice.MyDevicestatus.next()
    this.dataservice.AllDevicestatus.next()
    this.dataservice.singleDevicestatus.next()
  }
}
