<div class="content-matdialog-head">
  <div class="matdialog-head">
    <div>
      <h1>Create New vertical</h1>
    </div>
    <div>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>

  <div class="matdialog-content">
    <form [formGroup]="verticalform">
      <div class="form-field">
        <label class="form-label">Vertical Name </label>
        <input class="form-control" formControlName="name" placeholder="Enter the name" />
        <div *ngIf="verticalform.controls['name'].dirty">
          <span *ngIf="verticalform.controls['name']?.errors?.['required']" style="color: red">
            *Name is required.
          </span>
          <span
            *ngIf="verticalform.controls['name']?.errors?.['minlength']&&!verticalform.controls['name']?.errors?.['pattern']"
            style="color: red">
            *Minimum 2 characters
          </span>
          <span *ngIf="verticalform.controls['name']?.errors?.['maxlength']" style="color: red">
            *Maximum 20 characters
          </span>
          <span *ngIf="verticalform.controls['name']?.errors?.['pattern']" style="color: red">
            *Name is invalid.
          </span>
        </div>
      </div>

      <div class="form-field" style="display: flex;
    flex-direction: column;">

        <label class="form-label">Vertical type</label>
        <!-- <select class="form-select " multiple formControlName="vertical_type">
          <option class="form-option" *ngFor="let type of verticaltype" value="{{type.type_id}}">
            {{type.vertical_type}}
          </option>
        </select> -->

        <mat-form-field appearance="outline">

       
          
          <mat-select  panelClass="myPanelClasss"
            [ngModel]="selectedtype" formControlName="vertical_type" multiple placeholder="Select Vertical type">
            <mat-option *ngFor="let type of verticaltype" [value]="type.type_id"
              >
              {{ type.vertical_type }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="verticalform.get('vertical_type')?.hasError('required')">* Please Select Vertical type
            device</mat-error>
        </mat-form-field>

      </div>
      <!-- <div class="form-field">
        <label for="form-label">Choose Location</label>
        <select class="form-select" formControlName="location">
          <option value="" [selected]="true">Choose location</option>
          <option *ngFor="let item of location" value="{{item.location_id}}">
            {{item.location_name}}
          </option>
        </select>
        <div
          *ngIf="verticalform.controls['location'].invalid && (verticalform.controls['location'].dirty || verticalform.controls['location'].touched)"
        >
          <span
            *ngIf="verticalform.controls['location']?.errors"
            style="color: red"
          >
            *Please Select location
          </span>
        </div>
      </div> -->
      <!-- <div class="form-field">
        <label class="form-label">Vertical Location</label>
        <input
          type="text"
          id="search"
          placeholder="Search Location"
          ngx-google-places-autocomplete
          (onAddressChange)="handleAddressChange($event)"
          class="form-control"
          #placesRef="ngx-places"
          [options]="options"
          required
        />
      </div> -->
      <div class="form-field">
        <label class="form-label">Vertical Location</label>
        <input formControlName="searchValue" type="text" id="search" placeholder="Search Location"
          ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" class="form-control"
          #placesRef="ngx-places" [options]="options" />
        <span style="color: red"
          *ngIf="verticalform.controls?.['searchValue']?.invalid && (verticalform.controls?.['searchValue']?.dirty || verticalform.controls?.['searchValue']?.touched)">
          *Location is required.
        </span>
        <span style="color: red" *ngIf="!this.searchValue">
          *Location is invalid.
        </span>
      </div>
      <div class="form-field">
        <label class="form-label">Contact Person</label>
        <input class="form-control" formControlName="person" placeholder="Enter the name" />
        <div *ngIf="verticalform.controls['person'].dirty">
          <span *ngIf="verticalform.controls['person']?.errors?.['required']" style="color: red">
            *Name is required.
          </span>
          <span
            *ngIf="verticalform.controls['person']?.errors?.['minlength']&&!verticalform.controls['person']?.errors?.['pattern']"
            style="color: red">
            *Minimum 2 characters
          </span>
          <span *ngIf="verticalform.controls['person']?.errors?.['maxlength']" style="color: red">
            *Maximum 20 characters
          </span>
          <span *ngIf="verticalform.controls['person']?.errors?.['pattern']" style="color: red">
            *Name is invalid.
          </span>
        </div>
      </div>
      <div class="form-field">
        <label class="form-label" id="phone-label">Phone number</label>
        <!-- <mat-icon class="icon"> phone</mat-icon> -->
        <!-- <input
          formControlName="number"
          class="form-control"
          placeholder="1234567890"
        /> -->
        <input class="form-control blur" (countryChange)="onCountryChange($event)"
          [ng2TelInputOptions]="{ initialCountry: 'in' }" formControlName="number" ng2TelInput />
      </div>
      <div *ngIf="verticalform.controls['number'].dirty">
        <span *ngIf="verticalform.controls['number']?.errors?.['required']" style="color: red">
          *Number is required.
        </span>
        <span *ngIf="verticalform.controls['number']?.errors?.['pattern']" style="color: red">
          *Number is invalid.
        </span>
        <span
          *ngIf="verticalform.controls['number']?.errors?.['minlength'] && verticalform.controls['number']?.errors?.['minlength'].actualLength !== 0 && !verticalform.controls['number']?.errors?.['pattern']"
          style="color: red">
          *Minimum 7 Digits.
        </span>
        <span *ngIf="verticalform.controls['number']?.errors?.['maxlength']" style="color: red">
          *Maximum 15 Digits.
        </span>
      </div>
      <!-- <div class="form-field"><label class="form-label">contact person</label>
                <input class="form-control" formControlName="person" placeholder="Enter the person name">
                <div *ngIf="verticalform.controls['person'].dirty">
                    <span *ngIf="verticalform.controls['person']?.errors?.['required']" style="color: red;">
                        *Name is required.
                    </span>
                    <span
                        *ngIf="verticalform.controls['person']?.errors?.['minlength']&&!verticalform.controls['person']?.errors?.['pattern']"
                        style="color: red;">
                        *Minimum 2 characters
                    </span>
                    <span *ngIf="verticalform.controls['person']?.errors?.['maxlength']" style="color: red;">
                        *Maximum 20 characters
                    </span>
                    <span *ngIf="verticalform.controls['person']?.errors?.['pattern']" style="color: red;">
                        Special character not allowed
                    </span>
                </div>
            </div> -->
      <div class="form-field">
        <label class="form-label">description</label>
        <!-- <input class="form-control" id="description" formControlName="description"> -->
        <textarea formControlName="description" id="description" class="form-control" rows="3" cols="50"
          placeholder="This is my vertical"></textarea>
        <div *ngIf="
              
                    verticalform.controls['description'].dirty 
                
            ">
          <span *ngIf="verticalform.controls['description']?.errors?.['maxlength']" style="color: red">
            *Maximum 75 characters only
          </span>
        </div>
      </div>
    </form>
  </div>
  <div class="btn-div">
    <button class="btn btn-danger" mat-dialog-close>Cancel</button>
    <button class="btn btn-primary" [disabled]="this.verticalform.invalid||!this.searchValue" type="submit"
      mat-dialog-close (click)="onSubmit()">
      Create
    </button>
  </div>
</div>