<div class="header">
  <h1 style="display: flex; align-items: center">
    <mat-icon class="close-icon" (click)="back()">arrow_back</mat-icon>
    {{this.ClusterName}} Dashboard
  </h1>
  <div class="add" *ngIf="!this.default_cluster&&!this.loading">
    <button
      (click)="toggleParams()"
      *ngIf="!showAllParams&&this.vertical_type!=2"
      mat-raised-button
    >
      Table
    </button>
    <button (click)="toggleParams()" *ngIf="showAllParams" mat-raised-button>
      Dashboard
    </button>
    <button class="btn btn-primary" (click)="addDevices()">Add Devices</button>
    <button class="btn btn-danger" (click)="deletecluster()">
      Delete Cluster
    </button>
    <!-- <button class="btn btn-danger"" >Other Parameters</button> -->
  </div>
</div>

<mat-spinner
  role="progressbar"
  diameter="50"
  aria-hidden="true"
  *ngIf="loading"
  id="spinner"
></mat-spinner>

<div *ngIf="!loading" class="cluster_dashboard">
  <div class="cards">
    <div
      *ngIf="dbdata.length == 0 && getLocationKeys().length == 0"
      class="nodevice"
    >
      <i class="fas fa-exclamation-circle"></i>
      <h3>No Devices Data Found</h3>
    </div>
    <div class="cardss" *ngIf="!this.location && !showAllParams">
      <div class="card" *ngFor="let item of dbdata">
        <iconify-icon
          style="z-index: 999; cursor: pointer"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          icon="mi:options-vertical"
        ></iconify-icon>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a
            class="dropdown-item"
            [class.disabled]="default_cluster"
            [attr.aria-disabled]="default_cluster ? true : null"
            (click)="default_cluster ? null : DeleteSingleDevice(item.device_Id)"
            >Remove</a
          >
          <!-- <a  class="dropdown-item" (click)="toggleOtherParams(item)">Show Other Parameters</a> -->
        </div>
        <ng-container class="limited_parameters" id="limited_parameters">
          <div class="device_name">
            {{ item.device_name }}
            <span class="online_device" *ngIf="item.status == '1'">Online</span>
          </div>
          <div class="gauge" *ngIf="this.vertical_type!=2">
            <ngx-gauge
              (mouseenter)="onCardHover(true)"
              (mouseleave)="onCardHover(false)"
              (click)="gotodeviceDB(item.device_Id,item.cluster_id)"
              class="kwhgauge"
              id="gauge-text-color"
              [size]="150"
              [readonly]="true"
              [strokeWidth]="5"
              [min]="0"
              [max]="1000"
              [value]="item.Monthkwh"
              cap="round"
              type="full"
              rangeColor="lightgray"
              foregroundColor="#673ab7"
              append="kWh"
              label="This Month"
              [thick]="10"
            >
            </ngx-gauge>
            <ngx-gauge
              matTooltip="Last Online {{
item.last_online | date : 'short'
}}"
              *ngIf="item.deviceonline == '#e74c3c'"
              class="kwhgauge"
              id="gauge-text-color"
              [size]="150"
              [readonly]="true"
              [strokeWidth]="5"
              [min]="0"
              [max]="1000"
              [value]="item.Monthkwh"
              cap="round"
              type="full"
              rangeColor="lightgray"
              foregroundColor="#D1D1D1"
              append="kWh"
              label="This Month"
              [thick]="10"
            >
            </ngx-gauge>
            <hr />
            <div class="hour_kwh">
              <div class="first">
                <h2>{{item?.cost?.toFixed(1) || '0.00'}}</h2>
                <p>This Month Cost</p>
              </div>
              <div class="second">
                <h2>{{item.todaykwh || '0.00'}}</h2>
                <p style="text-transform: none">Today's kWh</p>
              </div>
            </div>
          </div>
          <div class="gauge" *ngIf="this.vertical_type==2">
            <ngx-gauge
              (mouseenter)="onCardHover(true)"
              (mouseleave)="onCardHover(false)"
              (click)="gotodeviceDB(item.device_Id,item.cluster_id)"
              class="kwhgauge"
              id="gauge-text-color"
              [size]="150"
              [readonly]="true"
              [strokeWidth]="5"
              [min]="0"
              [max]="item.Month_Total + 5000"
              [value]="item.Month_Total"
              cap="round"
              type="full"
              rangeColor="lightgray"
              foregroundColor="#673ab7"
              append="Count"
              label="This Month"
              [thick]="10"
            >
            </ngx-gauge>
            <ngx-gauge
              matTooltip="Last Online {{
item.last_online | date : 'short'
}}"
              *ngIf="item.deviceonline == '#e74c3c'"
              class="kwhgauge"
              id="gauge-text-color"
              [size]="150"
              [readonly]="true"
              [strokeWidth]="5"
              [min]="0"
              [max]="item.Month_Total + 5000"
              [value]="item.Month_Total"
              cap="round"
              type="full"
              rangeColor="lightgray"
              foregroundColor="#D1D1D1"
              append="Count"
              label="This Month"
              [thick]="10"
            >
            </ngx-gauge>
            <hr />
            <div class="hour_kwh">
              <div class="first">
                <h2>{{item?.Today_Total || '0'}}</h2>
                <p>Today Total</p>
              </div>
              <!-- <div class="second">
                <h2>{{item?.live || '0'}}</h2>
                <p style="text-transform: none">Live Count</p>
              </div> -->
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="cards" *ngIf="showAllParams && !this.location">
      <ng-container class="all_parameters" id="all_parameters">
        <table class="styled-table">
          <thead>
            <tr>
              <th class="table_head">S. No.</th>
              <th class="table_head">Device Name</th>
              <th class="table_head">Frequency</th>
              <th class="table_head">Voltage</th>
              <th class="table_head">Current</th>
              <th class="table_head">Power Factor</th>
              <th class="table_head">Kilowatts</th>
              <th class="table_head">TCO2</th>
              <th class="table_head">Today Kwh</th>
              <th class="table_head">Month Kwh</th>
              <th class="table_head">Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of devicedata">
              <td>{{ element.serialNumber }}</td>
              <td>{{ element.deviceName }}</td>
              <td>{{ element.frequency ?? 0}}</td>
              <td>{{ element.voltage?? 0 }}</td>
              <td>{{ element.current ?? 0}}</td>
              <td>{{ element.powerFactor ?? 0}}</td>
              <td>{{ element.kilowatts?? 0 }}</td>
              <td>{{ element.tco2 ?? 0}}</td>
              <td>{{ element.todayKwh?? 0 }}</td>
              <td>{{ element.monthKwh?? 0 }}</td>
              <td>{{ element.cost ?? 0 }}</td>
            </tr>
          </tbody>
        </table>

        <div class="grid" *ngIf="!devicedata">
          <h3>No Data Found</h3>
        </div>
      </ng-container>
    </div>

    <div *ngIf="this.location" class="locationwithdevice">
      <ng-container *ngFor="let location of getLocationKeys()">
        <h2 class="location">{{ location }}</h2>
        <div class="cardss" *ngIf="!showAllParams">
          <div class="card" *ngFor="let item of deviceWithLocation[location]">
            <!-- Dropdown menu -->
            <iconify-icon
              style="z-index: 999; cursor: pointer"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              icon="mi:options-vertical"
            ></iconify-icon>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                class="dropdown-item"
                (click)="DeleteSingleDevice(item.device_Id)"
                >Remove</a
              >
              <!-- <a   class="dropdown-item" (click)="toggleOtherParams(item)">Show Other Parameters</a> -->
            </div>
            <!-- Device details -->
            <div class="device_name">
              {{ item.device_name }}<span
                class="online_device"
                *ngIf="item.status == '1'"
                >Online</span
              >
            </div>
            <div class="gauge" *ngIf="!showOtherParamsFor(item)&&this.vertical_type!=2">
              <!-- ngx-gauge for kWh -->
              <ngx-gauge
                (mouseenter)="onCardHover(true)"
                (mouseleave)="onCardHover(false)"
                (click)="gotodeviceDB(item.device_Id, item.cluster_id)"
                class="kwhgauge"
                id="gauge-text-color"
                [size]="150"
                [readonly]="true"
                [strokeWidth]="5"
                [min]="0"
                [max]="1000"
                [value]="item.Monthkwh"
                cap="round"
                type="full"
                rangeColor="lightgray"
                foregroundColor="#673ab7"
                append="kWh"
                label="This Month"
                [thick]="10"
              ></ngx-gauge>
              <!-- ngx-gauge for last online -->
              <ngx-gauge
                matTooltip="Last Online {{ item.last_online | date: 'short' }}"
                *ngIf="item.deviceonline == '#e74c3c'"
                class="kwhgauge"
                id="gauge-text-color"
                [size]="150"
                [readonly]="true"
                [strokeWidth]="5"
                [min]="0"
                [max]="1000"
                [value]="item.Monthkwh"
                cap="round"
                type="full"
                rangeColor="lightgray"
                foregroundColor="#D1D1D1"
                append="kWh"
                label="This Month"
                [thick]="10"
              ></ngx-gauge>
              <hr />
              <div class="hour_kwh">
                <div class="first">
                  <h2>{{ item?.cost?.toFixed(1) || '0.00' }}</h2>
                  <p>This Month Cost</p>
                </div>
                <div class="second">
                  <h2>{{ item.todaykwh || '0.00' }}</h2>
                  <p style="text-transform: none">Today's kWh</p>
                </div>
              </div>
            </div>
            <div class="gauge" *ngIf="!showOtherParamsFor(item)&&this.vertical_type==2">
              <!-- ngx-gauge for kWh -->
              <ngx-gauge
                (mouseenter)="onCardHover(true)"
                (mouseleave)="onCardHover(false)"
                (click)="gotodeviceDB(item.device_Id, item.cluster_id)"
                class="kwhgauge"
                id="gauge-text-color"
                [size]="150"
                [readonly]="true"
                [strokeWidth]="5"
                [min]="0"
                [max]="item.Month_Total+5000"
                [value]="item.Month_Total"
                cap="round"
                type="full"
                rangeColor="lightgray"
                foregroundColor="#673ab7"
                append="count"
                label="This Month"
                [thick]="10"
              ></ngx-gauge>
              <!-- ngx-gauge for last online -->
              <ngx-gauge
                matTooltip="Last Online {{ item.last_online | date: 'short' }}"
                *ngIf="item.deviceonline == '#e74c3c'"
                class="kwhgauge"
                id="gauge-text-color"
                [size]="150"
                [readonly]="true"
                [strokeWidth]="5"
                [min]="0"
                [max]="item.Month_Total+1000"
                [value]="item.Month_Total"
                cap="round"
                type="full"
                rangeColor="lightgray"
                foregroundColor="#D1D1D1"
                append="Count"
                label="This Month"
                [thick]="10"
              ></ngx-gauge>
              <hr />
              <div class="hour_kwh">
                <div class="first">
                  <h2>{{item?.Today_Total || '0'}}</h2>
                  <p>Today Total</p>
                </div>
                <!-- <div class="second">
                  <h2>{{item?.live || '0'}}</h2>
                  <p style="text-transform: none">Live Count</p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="cards" *ngIf="showAllParams">
          <ng-container class="all_parameters" id="all_parameters">
            <table class="styled-table">
              <thead>
                <tr>
                  <th class="table_head">S. No.</th>
                  <th class="table_head">Device Name</th>
                  <th class="table_head">Frequency</th>
                  <th class="table_head">Voltage</th>
                  <th class="table_head">Current</th>
                  <th class="table_head">Power Factor</th>
                  <th class="table_head">Kilowatts</th>
                  <th class="table_head">TCO2</th>
                  <th class="table_head">Today Kwh</th>
                  <th class="table_head">Month Kwh</th>
                  <th class="table_head">Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of deviceWithLocation[location]; let i = index"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.device_name }}</td>
                  <td>{{ item.device_value.V0 ?? 0 }}</td>
                  <td>{{ item.device_value.V3 ?? 0 }}</td>
                  <td>{{ item.device_value.V4 ?? 0 }}</td>
                  <td>{{ item.device_value.V5 ?? 0 }}</td>
                  <td>{{ item.device_value.V6 ?? 0 }}</td>
                  <td>{{ item.device_value.V7 ?? 0 }}</td>
                  <td>{{ item.device_value.todayKwh ?? 0 }}</td>
                  <td>{{ item.device_value.currentMonthKWH ?? 0 }}</td>
                  <td>{{ item.device_value.currentcost ?? 0 }}</td>
                </tr>
              </tbody>
            </table>
            <div class="grid" *ngIf="!deviceWithLocation[location].length">
              <h3>No Data Found</h3>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- <table mat-table [dataSource]="devicedata" class="mat-elevation-z8">

    <ng-container matColumnDef="S.no">
      <th mat-header-cell *matHeaderCellDef> S No </th>
      <td mat-cell *matCellDef="let element; let i = index"> {{ i + 1 }} </td>
    </ng-container>

    <ng-container matColumnDef="This Month kWh">
      <th mat-header-cell *matHeaderCellDef> This Month kWh </th>
      <td mat-cell *matCellDef="let element"> {{element.Monthkwh}} </td>
    </ng-container>

    <ng-container matColumnDef="Today kWh">
      <th mat-header-cell *matHeaderCellDef> Today kWh </th>
      <td mat-cell *matCellDef="let element"> {{element.todaykwh}} </td>
    </ng-container>

    <ng-container matColumnDef="Current">
      <th mat-header-cell *matHeaderCellDef> Average Current </th>
      <td mat-cell *matCellDef="let element"> {{element.device_value.V4}} </td>
    </ng-container>
    <ng-container matColumnDef="Power Factor">
      <th mat-header-cell *matHeaderCellDef> Power Factor </th>
      <td mat-cell *matCellDef="let element"> {{element.device_value.V5}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table> -->
