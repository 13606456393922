<div class="content-matdialog">
  <div class="matdialog-head">
    <div>
      <h1>Create New User</h1>
    </div>
    <div>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <div class="matdialog-content">
    <form autocomplete="off" [formGroup]="userform">
      <div class="form-field">
        <label class="form-label">Name <span style="color:red"> *</span></label>
        <mat-icon class="icon">person</mat-icon>
        <input
          type="text"
          class="form-control"
          formControlName="name"
          placeholder="Enter the name"
          autocomplete="off"
        />
        <div *ngIf="userform.controls['name'].dirty">
          <span
            *ngIf="userform.controls['name']?.errors?.['required']"
            style="color: red"
          >
            *Name is required.
          </span>
          <span
            *ngIf="userform.controls['name']?.errors?.['minlength']&&!userform.controls['name']?.errors?.['pattern']"
            style="color: red"
          >
            *Name must be at least 2 characters
          </span>
          <span
            *ngIf="userform.controls['name']?.errors?.['maxlength']&&!userform.controls['name']?.errors?.['pattern']"
            style="color: red"
          >
            *Maxinum must be lesser than 20 characters
          </span>
          <span
            *ngIf="userform.controls['name']?.errors?.['pattern'] && !userform.controls['name']?.errors?.['noSpaceAsSecondCharacter']"
            style="color: red"
          >
            Special character not allowed
          </span>
          <span
  *ngIf="userform.controls['name']?.errors?.['noSpaceAsSecondCharacter']"
  style="color: red"
>
  *Space not allowed.
</span>
        </div>
      </div>

      <div class="form-field">
        <label class="form-label">Email</label><span style="color:red"> *</span>
        <mat-icon class="icon"> local_post_office</mat-icon>
        <input
          type="email"
          class="form-control"
          formControlName="email"
          placeholder="Enter the email"
          autocomplete="off"
        />
        <div *ngIf="userform.controls['email'].dirty">
          <span
            *ngIf="userform.controls['email']?.errors?.['required']"
            style="color: red"
          >
            *Email is required.
          </span>
          <span
            *ngIf="userform.controls['email']?.invalid && !userform.controls['email']?.errors?.['required']"
            style="color: red"
          >
            *Email is Invalid.
          </span>
        </div>
      </div>
      <div class="form-field">
        <label class="form-label">Password</label><span style="color:red"> *</span>
        <mat-icon class="icon" (click)="password.focus()">key_variant</mat-icon>
        <div class="password-field">  <input
          [type]="hide ? 'password' : 'text'"
          class="form-control"
          autocomplete="new-password"
          formControlName="password"
          [ngClass]="{ error: submitted && f['password'].errors }"
          placeholder="Enter the password"
          #password
        />

        <button
          class="hideeye"
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon style="margin-left: 2px"
            >{{ hide ? "visibility_off" : "visibility" }}</mat-icon
          >
        </button>
      </div>
      
        <div *ngIf="userform.controls['password']?.dirty">
          <span
            *ngIf="userform.controls['password']?.errors?.['required']"
            style="color: red"
          >
            *Password is required.
          </span>
          <span
            *ngIf="userform.controls['password']?.errors?.['minlength']"
            style="color: red"
          >
            *Minimum 6 characters
          </span>
          <span
            *ngIf="userform.controls['password']?.errors?.['maxlength']"
            style="color: red"
          >
            *Maximum 20 characters
          </span>
        </div>
      </div>
      <div class="form-field" >
        <label class="form-label">Select Vertical<span style="color: red"> *</span></label>

        <select *ngIf="verticalvalue.length > 0" class="form-select" formControlName="verticalname">
          <option value="" class="form-option" selected>Select Vertical</option>
          <option class="form-option" value="{{ item.vertical_Id }}" *ngFor="let item of verticalvalue">
            {{ item.vertical_name }}
          </option>
        </select>
        
        <div *ngIf="userform.controls['verticalname'].invalid && (userform.controls['verticalname'].dirty || userform.controls['verticalname'].touched)">
          <span *ngIf="userform.controls['verticalname']?.errors" style="color: red">
            *Please Select Vertical
          </span>
        </div>
        <div style="position: relative;" class="createvertical" *ngIf="showSelectField && verticalvalue.length === 0" >
          <input disabled class="form-control" placeholder="Create Your Vertical">
          <iconify-icon style="position: absolute;
          top: 8px;
          cursor: pointer;
          font-size: 20px;
          right: 10px;" (click)="createVertical()" matTooltip="Goto Verticals" icon="ic:sharp-arrow-outward"></iconify-icon>
         
        </div>
      </div>

     

      <div class="form-field">
        <label class="form-label">Role<span style="color:red"> *</span></label>
        <select class="form-select" formControlName="role">
          <option value="" [selected]="true">Select User Role</option>
          <!-- <option value="Admin">Admin</option>
                    <option value="User">User</option> -->
          <!-- <option value="Staff">Staff</option> -->
          <option *ngFor="let groups of usergroup" [value]="groups.group_Id">
            {{groups.group_name}}
          </option>
        </select>
        <div
          *ngIf="userform.controls['role'].invalid && (userform.controls['role'].dirty || userform.controls['role'].touched)"
        >
          <span *ngIf="userform.controls['role']?.errors" style="color: red">
            *Please Select User Role
          </span>
        </div>
      </div>
      <!-- <div class="form-field">
        <label class="form-label" id="phone-label">Phone number <span style="color:red"> *</span></label>
        <mat-icon class="icon"> phone</mat-icon>
        <input
          type="tel"
          formControlName="number"
          class="form-control"
          placeholder="1234567890"
        />
      </div>
      <div *ngIf="userform.controls['number'].dirty">
        <span
          *ngIf="userform.controls['number']?.errors?.['required']"
          style="color: red"
        >
          *Number is required.
        </span>
        <span
          *ngIf="userform.controls['number']?.errors?.['pattern']"
          style="color: red"
        >
          *Number is invalid.
        </span>
        <span
          *ngIf="userform.controls['number']?.errors?.['minlength'] && userform.controls['number']?.errors?.['minlength'].actualLength !== 0 && !userform.controls['number']?.errors?.['pattern']"
          style="color: red"
        >
          *Minimum 7 Digits.
        </span>
        <span
          *ngIf="userform.controls['number']?.errors?.['maxlength']"
          style="color: red"
        >
          *Maximum 15 Digits.
        </span>
      </div> -->
      <div class="form-field">
        <label class="form-label" id="phone-label">Phone number <span style="color:red"> *</span></label>
      
        <!-- <ngx-intl-tel-input  [cssClass]="'custom'" [preferredCountries]="preferredCountries"
          [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[
          SearchCountryField.Iso2,
          SearchCountryField.Name
        ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
          [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National"
          name="phone" formControlName="number">
        </ngx-intl-tel-input> -->
        <input
   
        class="form-control blur"
        autocomplete="off"
        (countryChange)="onCountryChange($event)"
        [ng2TelInputOptions]="{ initialCountry: 'in' }"
        formControlName="number"
        placeholder="123-456-7890"
        ng2TelInput
      />
        <!-- <div *ngIf="(userform.controls['number'].touched)||(userform.controls['number'].dirty)">

          <div *ngIf="userform.get('number')!.errors?.['required']&&(userform.controls['number'].touched)" style="color: red;">
            *Number is required.
          </div>
  
          <span *ngIf="userform.controls['number']?.invalid&& !userform.controls['number']?.errors?.['required']"
            style="color: red;">
            *Invalid number. Please enter a valid phone number.
          </span>
  
        </div> -->
        <div *ngIf="userform.controls['number'].dirty">
          <span
            *ngIf="userform.controls['number']?.errors?.['required']"
            style="color: red"
          >
            *Number is required.
          </span>
          <span
            *ngIf="userform.controls['number']?.errors?.['pattern']"
            style="color: red"
          >
            *Number is invalid.
          </span>
          <span
            *ngIf="userform.controls['number']?.errors?.['minlength'] && userform.controls['number']?.errors?.['minlength'].actualLength !== 0 && !userform.controls['number']?.errors?.['pattern']"
            style="color: red"
          >
            *Minimum 7 Digits.
          </span>
          <span
            *ngIf="userform.controls['number']?.errors?.['maxlength']"
            style="color: red"
          >
            *Maximum 15 Digits.
          </span>
        </div>
      </div>
    </form>
  </div>
  <mat-error style="margin-left: 2px">{{ errorMessage }}</mat-error>
  <div class="btn-div">
    <div class="btn-div">
      <button class="btn btn-danger" mat-dialog-close>Cancel</button>
      <button
        class="btn btn-primary"
        type="submit"
   
        [disabled]="this.userform.invalid"
        (click)="onSubmit()"
      >
        Create User
      </button>
    </div>
  </div>
</div>
