<div class="content-matdialog-head">
  <div class="matdialog-head">
    <div>
      <h1 *ngIf="!this.edit">Create New Cluster</h1>
      <h1 *ngIf="this.edit">Update Cluster</h1>
    </div>
    <div>
      <div>
        <mat-icon style="cursor: pointer;" mat-dialog-close>close</mat-icon>
      </div>
    </div>
  </div>
  <!-- <div class="matdialog-content">
        <form [formGroup]="newcluster">
            <div class="form-field">
              <mat-label>Name   <span style="font-size: 10px; color: red;"> *</span></mat-label>
            
              <mat-form-field appearance="outline">
                <input matInput formControlName="name" placeholder="Enter the name" />
                <mat-error *ngIf="newcluster.get('name')?.hasError('required')">* Name is required</mat-error>
                <mat-error *ngIf="newcluster.get('name')?.hasError('minlength')">* Minimum 2 characters</mat-error>
                <mat-error *ngIf="newcluster.get('name')?.hasError('maxlength')">* Maximum 20 characters</mat-error>
                <mat-error *ngIf="newcluster.get('name')?.hasError('pattern')">* Special characters not allowed</mat-error>
              </mat-form-field>
            </div>
          <div class="form-field">
            <p><mat-slide-toggle>Slide me!</mat-slide-toggle></p>
          </div>
            <div class="devices">
              <div class="form-field">
                <mat-label>Devices   <span style="font-size: 10px; color: red;"> *</span></mat-label>
           
                <mat-form-field appearance="outline">
                  <mat-select [ngModel]="selecteddevices" 
                            
                              fordevicesmControlName="devices" 
                              multiple 
                              placeholder="Select Devices">
                    <mat-option *ngFor="let type of devices" [value]="type.device_id" [disabled]="isAlreadySelected(type.device_id)">
                      {{ type.device_name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="newcluster.get('devices')?.hasError('required')">* Please select at least one device</mat-error>
                </mat-form-field>
                
              </div>
            </div>
          
            <div class="form-field">
              <mat-label>Description</mat-label>
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="description" placeholder="Enter the description" rows="3" cols="50"></textarea>
                <mat-error *ngIf="newcluster.get('description')?.hasError('maxlength')">* Maximum 75 characters only</mat-error>
              </mat-form-field>
            </div>
          </form>
          
          
    </div> -->


  <div class="matdialog-content">
    <div *ngIf="loading" style="height:25vh">
      <mat-spinner role="progressbar" diameter="50" aria-hidden="true"  id="spinner"></mat-spinner>
    </div>
    <form *ngIf="!loading" [formGroup]="newcluster">
      <div class="form-field">
        <mat-label>Name <span style="font-size: 10px; color: red;">*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="name" placeholder="Enter name" />
          <mat-error *ngIf="newcluster.get('name')?.hasError('required')">* Name is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-field" class="showlocation">
        <mat-slide-toggle color="primary" formControlName="showLocationDevices"
          (change)="toggleLocationDevices($event.checked)">Group
          Devices with Locations</mat-slide-toggle>

        <!-- Add New Row Button -->
        <button *ngIf="newcluster.get('showLocationDevices')?.value"
          [matTooltip]="this.addressArray.invalid ==true ? 'Please enter location and Select the devices':'' "
          [disabled]="this.addressArray.invalid" mat-raised-button (click)="addAddress()">
          Add
        </button>
        <!-- <button *ngIf="newcluster.get('showLocationDevices')?.value"  mat-raised-button (click)="addAddress()">
          Add
        </button> -->
      </div>
      <div class="devices" *ngIf="!newcluster.get('showLocationDevices')?.value">
        <div class="form-field">
          <mat-label>Devices <span style="font-size: 10px; color: red;"> *</span></mat-label>
          <mat-form-field appearance="outline">

            <input [disabled]="true" *ngIf="this.devices.length==this.InitialselectedDevices.length" matInput
              value="All Devices already use" />

            <mat-select *ngIf="this.devices.length!=this.InitialselectedDevices.length" panelClass="myPanelClasss"
              [ngModel]="selecteddevices" formControlName="devices" multiple placeholder="Select Devices">
              <mat-option *ngFor="let type of devices" [value]="type.device_id"
                [disabled]="isAlreadySelected(type.device_id) ||isAlreadySelectedInitial(type.device_id)">
                {{ type.device_name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="newcluster.get('devices')?.hasError('required')">* Please select at least one
              device</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="newcluster.get('showLocationDevices')?.value">
        <div *ngFor="let group of addressArray.controls; let i = index" formArrayName="address">
          <div class="form-row" [formGroupName]="i">
            <mat-form-field appearance="outline">
              <input matInput formControlName="location" placeholder="Enter location">
              <mat-error *ngIf="group.get('location')?.errors?.['required']">* Location is required</mat-error>
              <mat-error *ngIf="group.get('location')?.errors?.['minlength']">* Minimum 2 characters</mat-error>
              <mat-error *ngIf="group.get('location')?.errors?.['maxlength']">* Maximum 20 characters</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <input [disabled]="true" *ngIf="this.devices.length==this.InitialselectedDevices.length" matInput
              value="All Devices already use" />
              <mat-select *ngIf="this.devices.length!=this.InitialselectedDevices.length" panelClass="myPanelClasss" formControlName="device" multiple placeholder="Select Devices">
                <mat-option *ngFor="let device of devices" [value]="device.device_id"
                  [disabled]="isDeviceSelected(device.device_id, i) || isAlreadySelectedInitial(device.device_id)">
                  {{ device.device_name }}
                </mat-option>
              </mat-select>


              <mat-error *ngIf="group.get('device')?.errors?.['required']">* Please select at least one
                device</mat-error>
              <mat-error *ngIf="group.get('device')?.errors?.['minlength']">* Please select at least one
                device</mat-error> </mat-form-field>

            <button *ngIf="i > 0" mat-icon-button (click)="removeAddress(i)"
              aria-label="Delete this location-device mapping">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="form-field" *ngIf="!this.edit">
        <mat-form-field>
          <mat-label>Select a Vertical</mat-label>
          <mat-select panelClass="myPanelClass" formControlName="vertical_Id">
            <mat-option value="{{ item.vertical_Id }}" *ngFor="let item of vertname">{{ item.vertical_name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="newcluster.get('vertical_Id')?.errors?.['required']">* Please select the
            vertical</mat-error>
        </mat-form-field>
      </div>



      <!-- Description Field -->
      <div class="form-field">
        <mat-label>Description</mat-label>
        <mat-form-field appearance="outline">
          <textarea matInput formControlName="description" placeholder="Enter description" rows="3"
            cols="50"></textarea>
        </mat-form-field>
      </div>

    </form>
  </div>
  <div class="btn-div">
    <button class="btn btn-danger" mat-dialog-close>Cancel</button>
    <button class="btn btn-primary" type="submit" mat-dialog-close *ngIf="!this.edit"
      [disabled]="!this.newcluster.valid" (click)="onSubmit()">
      Create
    </button>
    <button class="btn btn-primary" type="submit" mat-dialog-close [disabled]="!this.newcluster.valid" *ngIf="this.edit"
      (click)="onSubmit()">
      Update
    </button>
  </div>
</div>