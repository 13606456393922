<div class="content-matdialog">
  <div class="matdialog-head">
    <div>
      <h1>Edit User</h1>
    </div>
    <div>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>

  <div class="matdialog-body">
    <form [formGroup]="edituserform">
      <div class="form-field">
        <label class="form-label">Name</label>
        <mat-icon class="icon">person</mat-icon>
        <input
          type="text"
          class="form-control"
          formControlName="name"
          placeholder="Enter the name"
        />
        <div *ngIf="edituserform.controls['name'].dirty">
          <span
            *ngIf="edituserform.controls['name']?.errors?.['required']"
            style="color: red"
          >
            *Name is required.
          </span>
          <span
            *ngIf="edituserform.controls['name']?.errors?.['minlength']&&!edituserform.controls['name']?.errors?.['pattern']"
            style="color: red"
          >
            *Name must be at least 2 characters
          </span>
          <span
            *ngIf="edituserform.controls['name']?.errors?.['maxlength']"
            style="color: red"
          >
            *Name must be at least 15 characters
          </span>
          <span
          *ngIf="edituserform.controls['name']?.errors?.['pattern'] && !edituserform.controls['name']?.errors?.['noSpaceAsSecondCharacter']"
          style="color: red"
        >
          Special character not allowed
        </span>
        <span
*ngIf="edituserform.controls['name']?.errors?.['noSpaceAsSecondCharacter']"
style="color: red"
>
*Space not allowed.
</span>
        </div>
      </div>

      

      <div class="form-field">
        <label class="form-label">Email</label>
        <mat-icon class="icon"> local_post_office</mat-icon>
        <input
          type="email"
          class="form-control"
          readonly
          formControlName="email"
          placeholder="welcome@123"
          [attr.disabled]="true"
        />
        <div *ngIf="edituserform.controls['email'].dirty">
          <span
            *ngIf="edituserform.controls['email']?.errors?.['required']"
            style="color: red"
          >
            *Email is required.
          </span>
          <span
            *ngIf="edituserform.controls['email']?.invalid && !edituserform.controls['email']?.errors?.['required']"
            style="color: red"
          >
            *Email is Invalid.
          </span>
        </div>
      </div>

      <div class="form-field">
        <!-- <mat-form-field>
          <mat-label>Favorite food</mat-label>
          <mat-select  formControlName="role">
            <mat-option  [disabled]="this.role!='Admin'" *ngFor="let item of Roles" [value]="item.group_Id">
              {{item.group_name}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <label class="form-label">Role</label>
        <select
          class="form-select"
          
          formControlName="role"
        >
          <option  [disabled]="this.role!='Admin'" *ngFor="let item of Roles" [value]="item.group_Id">
            {{item.group_name}}
          </option>
        </select>
      </div>
      <div class="form-field">
        <label class="form-label" id="phone-label">Phone number</label>
        <mat-icon class="icon"> phone</mat-icon>
        <input
          class="form-control blur"

          (countryChange)="onCountryChange($event)"
          [ng2TelInputOptions]="{ initialCountry: this.user?.country_code }"
          formControlName="number"
          ng2TelInput
          placeholder="123-456-7890"
        />
      </div>
      <div *ngIf="edituserform.controls['number'].dirty">
        <span
          *ngIf="edituserform.controls['number']?.errors?.['required']"
          style="color: red"
        >
          *Number is required.
        </span>
        <span
          *ngIf="edituserform.controls['number']?.errors?.['pattern']"
          style="color: red"
        >
          *Number is invalid.
        </span>
        <span
          *ngIf="edituserform.controls['number']?.errors?.['minlength'] && edituserform.controls['number']?.errors?.['minlength'].actualLength !== 0 && !edituserform.controls['number']?.errors?.['pattern']"
          style="color: red"
        >
          *Minimum 7 Digits.
        </span>
        <span
          *ngIf="edituserform.controls['number']?.errors?.['maxlength']"
          style="color: red"
        >
          *Maximum 15 Digits.
        </span>
      </div>
    </form>
  </div>
  <div class="btn-div">
    <button class="btn btn-danger" mat-dialog-close>Cancel</button>
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="this.edituserform.invalid"
      (click)="userupdate()"
      *ngIf="!this.reactivate"
    >
      Save
    </button>
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="this.edituserform.invalid"
      (click)="userupdate()"
      *ngIf="this.reactivate"
    >
      Save And Reactivate
    </button>
  </div>
</div>
