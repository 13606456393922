import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { Dataservice } from '../services/dataservice';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { deletepopup } from '../mydevices/mydevices.component';
import { Toast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {



  env = environment;
  api = this.env.api;
  SECRET_KEY = this.env.SECRET_KEY;
  id: any;
  roles: any;
  refresh: any;

  /**
  * Creates an instance of the constructor.
  * @param {HttpClient} http - The HttpClient service for making HTTP requests.
  * @param {Dataservice} dataservice - The Dataservice for handling data-related operations.
  * @param {ToastrService} toastr - The ToastrService for displaying toast notifications.
  * @param {Router} router - The router service for navigation.
  * @param {MatDialog} matdialog - The MatDialog service for opening dialog modals.
  */
  constructor(
    private http: HttpClient,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private router: Router,
    private matdialog: MatDialog
  ) { }

  /**
   * Encrypts user data using AES encryption.
   * @param {any} userData - The user data to be encrypted.
   * @returns {string} - The encrypted user data as a string.
   */
  encryptUserData(userData: any): string {
    /**
     * Encrypt the user data using AES encryption.
     * @type {string}
     */
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userData), this.SECRET_KEY).toString();
    return encryptedData;
  }

  /**
  * Encrypts user roles using AES encryption.
  * @param {any} userRoles - The user roles to be encrypted.
  * @returns {string} - The encrypted user roles as a string.
  */
  encryptUserRoles(userRoles: any): string {
    /**
     * Encrypt the user roles using AES encryption.
     * @type {string}
     */
    const encryptedUserRoles = CryptoJS.AES.encrypt(userRoles, this.SECRET_KEY).toString();
    return encryptedUserRoles;
  }

  /**
  * Saves user data and roles to local storage after encrypting them.
  * @param {any} userData - The user data to be saved.
  * @param {any} userRoles - The user roles to be saved.
  * @returns {void}
  */
  saveUserData(userData: any, userRoles: any): void {
    /**
     * Get the user ID from the user data.
     * @type {string}
     */
    const Id = userData.user_Id;

    /**
     * Encrypt the user data and user roles.
     * @type {string}
     */
    const encryptedData = this.encryptUserData(userData);
    const roles = this.encryptUserRoles(userRoles);

    // Save the encrypted data and roles to local storage
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('Roles', roles);
    localStorage.setItem('token', encryptedData);
  }


  /**
   * Retrieves and decrypts user data from local storage.
   * @returns {any} - The decrypted user data.
   */
  getUserData(): any {
    /**
     * Get the encrypted user data from local storage.
     * @type {string}
     */
    this.id = localStorage.getItem('token');

    /**
     * Decrypt the encrypted data using the secret key.
     * @type {string}
     */
    const encryptedData = this.id;
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, this.SECRET_KEY).toString(CryptoJS.enc.Utf8);

    /**
     * Parse the decrypted data to retrieve user information.
     * @type {any}
     */
    const userdata = JSON.parse(decryptedData);
    return userdata;
  }


  /**
 * Retrieves and decrypts user roles from local storage.
 * @returns {any} - The decrypted user roles.
 */
  getUserRole(): any {
    /**
     * Get the encrypted user roles from local storage.
     * @type {string}
     */
    this.roles = localStorage.getItem('Roles');

    /**
     * Decrypt the encrypted roles using the secret key.
     * @type {string}
     */
    const encryptuserRoles = this.roles;
    const decrypteduserRoles = CryptoJS.AES.decrypt(encryptuserRoles, this.SECRET_KEY).toString(CryptoJS.enc.Utf8);

    /**
     * Parse the decrypted roles to retrieve user role information.
     * @type {any}
     */
    const userRoles = JSON.parse(decrypteduserRoles);
    return userRoles;
  }
  UpdateVertical(userData: any): void {

    const encryptedData = this.encryptUserData(userData);
    localStorage.setItem('token', encryptedData);
    console.log("getafterset",localStorage.getItem('token'))
}

  /**
  * Refreshes the user data periodically and handles user authentication status.
  */
  RefreshUserdata() {
    let data: any;

    /**
     * Set an interval to periodically refresh user data and handle authentication status.
     */
    this.refresh = setInterval(() => {
      data = this.getUserData();

      /**
       * Fetch user data from the server using the retrieved user data.
       * @param {any} res - The response containing user data.
       */
      this.dataservice.fetchUserdata(data).subscribe((res: any) => {
        if (res.status == "200") {
          // Extract user data from the response
          let user_Id = res.data?.user_Id;
          let role = res.data?.role_name;
          let username = res.data?.firstname;
          let isactive = res.data?.isactive;
          let org_Id = res.data?.org_Id;
          let vertical_Id = res.data?.vertical_Id;
          let any_changes = res.data?.any_changes;
          let f_logout = res.data?.f_logout;
          let gateway = res.data?.gateway_id;
          let user_type = res.data?.user_type;
          let vertical_type=res.data?.vertical_type;

          if (Number(isactive) == Number(0)) {
            // Handle case when user is inactive
            if (Number(data.any_changes) == Number(any_changes)) {
              if (Number(data.f_logout) == Number(f_logout)) {
                let superadmin = false
                if(vertical_Id == null){
                  superadmin = true
                }
                vertical_Id = data.vertical_Id

                // Update and save user data if no significant changes
                let newdata = { user_Id, role, org_Id, vertical_Id,superadmin, username, isactive, any_changes, f_logout,gateway,user_type,vertical_type}
                const roles = res.data?.group_permission;
                this.saveUserData(newdata, roles);
              } else if (Number(data.f_logout) != Number(f_logout)) {
                // Handle logout due to force logout change,
                // Show confirmation dialog and clear local storage
                // Navigate to signin page
                const MatDialogConfig = this.matdialog.open(deletepopup, {
                  disableClose: true, data: "InValidUser"
                });
                MatDialogConfig.afterClosed().subscribe((result: any) => {
                  if (result.confirmation == true) {
                    localStorage.clear();
                    this.matdialog.closeAll();
                    this.router.navigate(['/app/signin']);
                  }
                });
                localStorage.clear();
                this.clearInterval();
              }
            } else if (Number(data.any_changes) != Number(any_changes)) {
              // Handle logout due to significant user data changes
              // Show confirmation dialog and clear local storage
              // Navigate to signin page
              const MatDialogConfig = this.matdialog.open(deletepopup, {
                disableClose: true, data: "InValidUser"
              });
              MatDialogConfig.afterClosed().subscribe((result: any) => {
                if (result.confirmation == true) {
                  localStorage.clear();
                  this.matdialog.closeAll();
                  this.router.navigate(['/app/signin']);
                }
              });
              localStorage.clear();
              this.clearInterval();
            }
          } else if (Number(isactive) != Number(0)) {
            // Handle logout due to user being marked as inactive
            // Show confirmation dialog and clear local storage
            // Navigate to signin page
            const MatDialogConfig = this.matdialog.open(deletepopup, {
              disableClose: true, data: "InValidUser"
            });
            MatDialogConfig.afterClosed().subscribe((result: any) => {
              if (result.confirmation == true) {
                localStorage.clear();
                this.matdialog.closeAll();
                this.router.navigate(['/app/signin']);
              }
            });
            localStorage.clear();
            this.clearInterval();
          }
        } else {
          this.toastr.error("Error Occured");
        }
      });
    }, 30000);
  }
  /**
  * Clears the interval for periodically refreshing user data and handling authentication status.
  */
  clearInterval(): void {
    clearInterval(this.refresh);
  }

}
