<!--SIDENAV MENU START  -->
<div class="menues">
  <!-- FIRST PART MENUS LIST START -->
  <div class="search">
    <!-- MENU HEAD TITLE -->
    <!-- <div class="menu-style1">
      <iconify-icon class="head-icon" icon="material-symbols:category" width="27"></iconify-icon>
      <h2 class="heading">{{ "MANAGE" | translate }}</h2>
    </div> -->
    <div *ngIf="this.dashboard" ngbTooltip="Dashboard" placement="right" tooltipClass="tooltips" class="active"
      (click)="Confirm('/app/Verticaldash')" routerLink="/app/Verticaldash" class="style" routerLinkActive="active"
      (click)="default()">
      <div class="menu-style">
        <iconify-icon class="sidenav-icon" icon="ic:baseline-dashboard"></iconify-icon>
        <!-- <mat-icon class="sidenav-icon">memory</mat-icon> -->
        <h3 class="sidenav-menu">{{ "Dashboard" | translate }}</h3>
      </div>
    </div>
    <!-- DEVICE MENU START -->
    <div *ngIf="this.Devices && !this.Vertical_Id" ngbTooltip="Devices" placement="right" tooltipClass="tooltips"
      class="active" [ngClass]="{ activelink: this.deviceurl }" (click)="Confirm('/app/mydevices')"
      routerLink="/app/mydevices" class="style" routerLinkActive="active" (click)="default()">
      <div class="menu-style">
        <iconify-icon class="sidenav-icon" icon="heroicons:cpu-chip"></iconify-icon>
        <!-- <mat-icon class="sidenav-icon">memory</mat-icon> -->
        <h3 class="sidenav-menu">{{ "Devices" | translate }}</h3>
      </div>
    </div>
    <div *ngIf="this.Devices && this.Vertical_Id > 0" ngbTooltip="Devices" placement="right" tooltipClass="tooltips"
      class="active" [ngClass]="{ activelink: this.deviceurl }" (click)="Confirm('/app/devices')"
      routerLink="/app/devices" class="style" routerLinkActive="active" (click)="default()">
      <div class="menu-style">
        <iconify-icon class="sidenav-icon" icon="heroicons:cpu-chip"></iconify-icon>
        <!-- <mat-icon class="sidenav-icon">memory</mat-icon> -->
        <h3 class="sidenav-menu">{{ "Devices" | translate }}</h3>
      </div>
    </div>
    <!-- DEVICE MENU END -->

    <!-- CLUSTER MENU START-->
    <div *ngIf="this.Cluster" class="style" ngbTooltip="Gateways" placement="right" tooltipClass="tooltips"
      [ngClass]="{ activelink: this.gatewayurl }" (click)="Confirm('/app/cluster')" id="product"
      routerLink="/app/cluster" routerLinkActive="active" (click)="onToggleSidenav()" (click)="default()">
      <div class="menu-style">
        <!-- <mat-icon class="sidenav-icon">grid_on</mat-icon> -->
        <iconify-icon class="sidenav-icon" icon="bxs:microchip"></iconify-icon>
        <h3 class="sidenav-menu">{{ "Gateways" | translate }}</h3>
      </div>
    </div>


    <div *ngIf="this.Cluster" class="style" ngbTooltip="Clusters" placement="right" tooltipClass="tooltips"
      [ngClass]="{'activelink':this.clusterurl}" (click)="Confirm('/app/group')" id="product" routerLink="/app/group"
      routerLinkActive="active" (click)="onToggleSidenav()" (click)="default()">
      <div class="menu-style">
        <!-- <mat-icon class="sidenav-icon">grid_on</mat-icon> -->
        <iconify-icon class="sidenav-icon" icon="carbon:assembly-cluster"></iconify-icon>
        <h3 class="sidenav-menu">{{ "Clusters" | translate }}</h3>
      </div>
    </div>
    <!-- CLUSTER MENU END-->
    <!-- Routes MENU START-->
    <div *ngIf="Routes" class="style" ngbTooltip="Routes" placement="right" tooltipClass="tooltips" id="routes"
      routerLink="/app/routes" routerLinkActive="active" (click)="route()">
      <div class="menu-style">
        <!-- <mat-icon>device_hub</mat-icon> -->
        <iconify-icon class="sidenav-icon" icon="tabler:route"></iconify-icon>
        <h3 class="sidenav-menu">{{ "Routes" | translate }}</h3>
      </div>
    </div>
    <!-- Routes MENU END-->

    <!-- WEBZONE MENU START -->
    <div *ngIf="WebZone" class="style" ngbTooltip="Web zones" placement="right" tooltipClass="tooltips"
      (click)="Confirm('/app/web-zone')" id="product" routerLink="/app/web-zone" routerLinkActive="active"
      (click)="onToggleSidenav()" (click)="default()">
      <div class="menu-style">
        <iconify-icon class="sidenav-icon" icon="ion:wifi"></iconify-icon>
        <!-- <mat-icon>wifi_tethering</mat-icon> -->
        <h3 class="sidenav-menu">{{ "Web zones" | translate }}</h3>
      </div>
    </div>
    <!-- WEBZONE MENU END -->

    <!-- OTA MENU START -->
    <div *ngIf="this.OTA" class="style" ngbTooltip="OTA" placement="right" tooltipClass="tooltips"
      (click)="Confirm('/app/ota')" id="ota" routerLink="/app/ota" routerLinkActive="active" (click)="onToggleSidenav()"
      (click)="default()">
      <div class="menu-style">
        <iconify-icon class="sidenav-icon" icon="carbon:update-now"></iconify-icon>
        <!-- <iconify-icon  class="sidenav-icon" icon="radix-icons:update"></iconify-icon> -->
        <!-- <mat-icon>autorenew</mat-icon> -->
        <h3 class="sidenav-menu">{{ "OTA" | translate }}</h3>
      </div>
    </div>

    <!-- OTA MENU END -->

    <!-- USERS MENU START -->
    <div *ngIf="this.User" class="menu-head style" ngbTooltip="Users" [ngClass]="{ activelink: this.userurl }"
      placement="right" tooltipClass="tooltips" (click)="Confirm('/app/myverticalmembers')"
      routerLink="/app/myverticalmembers" routerLinkActive="active" (click)="onToggleSidenav()" (click)="default()">
      <div class="menu-style">
        <iconify-icon class="sidenav-icon" icon="fe:user"></iconify-icon>
        <!-- <mat-icon class="sidenav-icon">person</mat-icon> -->
        <h3 class="sidenav-menu">{{ "Users" | translate }}</h3>
      </div>
    </div>
    <!-- USERS MENU END -->
  </div>
  <!-- FIRST PART MENUS LIST END -->

  <!-- CLOUDS MENU START -->
  <div *ngIf="this.Clouds" class="style" id="ota" ngbTooltip="Clouds" placement="right" tooltipClass="tooltips"
    (click)="Confirm('/app/clouds')" routerLink="/app/clouds" routerLinkActive="active" (click)="onToggleSidenav()"
    (click)="default()">
    <div class="menu-style">
      <iconify-icon class="sidenav-icon" icon="pepicons-pencil:cloud-up"></iconify-icon>
      <!-- <mat-icon class="sidenav-icon">cloud</mat-icon> -->
      <h3 class="sidenav-menu">{{ "Clouds" | translate }}</h3>
    </div>
  </div>
  <!-- CLOUDS MENU END -->
  <!-- DOWNLOADS MENU START -->
  <div *ngIf="this.Downloads" class="style" id="ota" ngbTooltip="Downloads" placement="right" tooltipClass="tooltips"
    routerLink="/app/downloads" (click)="Confirm('/app/downloads')" (click)="onToggleSidenav()" (click)="default()"
    routerLinkActive="active">
    <div class="menu-style">
      <iconify-icon class="sidenav-icon" icon="line-md:download-loop"></iconify-icon>
      <!-- <mat-icon class="sidenav-icon"> save_alt</mat-icon>/ -->
      <h3 class="sidenav-menu">{{ "Downloads" | translate }}</h3>
    </div>
  </div>
  <!-- DOWNLOADS MENU END -->

  <!-- SECOND PART MENUS LIST START -->
  <!-- MENU HEAD TITLE -->
  <!-- <div class="menu-style1">
    <div class="menu-style1">
      <iconify-icon  class="head-icon" width="27" icon="material-symbols:settings-b-roll"></iconify-icon>
 
      <h2 class="heading">{{ "GENERAL" | translate }}</h2>
    </div>
  </div> -->

  <div *ngIf="this.General" mat-button class="active" class="navbar-toggler style sett-menu" ngbTooltip="Settings"
    placement="right" tooltipClass="tooltips" [ngClass]="{ activelink: this.settingurl }" (click)="setting()" id="setting" routerLinkActive="active">
    <div class="menu-style">
      <iconify-icon class="sidenav-icon" icon="fluent:person-settings-20-filled"></iconify-icon>
      <!-- <mat-icon class="sidenav-icon">settings</mat-icon> -->
      <h3 class="sidenav-menu">{{ "Settings" | translate }}</h3>
    </div>
    <iconify-icon class="sidenav-icon" *ngIf="settingicon" icon="iconamoon:arrow-up-2"></iconify-icon>
    <iconify-icon class="sidenav-icon" *ngIf="!settingicon" icon="iconamoon:arrow-down-2"></iconify-icon>
    <!-- <mat-icon>{{
      settingicon ? "keyboard_arrow_up" : "keyboard_arrow_down"
      }}</mat-icon> -->
  </div>
  <!-- SUBMENU START -->
  <div *ngIf="this.General" class="collapse navbar-collapse" [ngbCollapse]="isSetting || this.collapse">
    <ul>
      <!-- GENERAL MENU START -->
      <div ngbTooltip="General" placement="right" tooltipClass="tooltips" (click)="Confirm('/app/general')" class="list"
        routerLink="/app/general" routerLinkActive="active" (click)="onToggleSidenav()">
        <li><i class="fa-solid fa-minus"></i> {{ "general" | translate }}</li>
      </div>
      <!-- GENERAL MENU END -->

      <!-- VERTICAL MENU START -->
      <div ngbTooltip="Verticals" placement="right" tooltipClass="tooltips" (click)="Confirm('/app/vertical')"
        class="list" routerLink="/app/vertical" routerLinkActive="active" (click)="onToggleSidenav()">
        <li><i class="fa-solid fa-minus"></i> {{ "Verticals" | translate }}</li>
      </div>
      <!-- VERTICAL MENU END -->

      <!-- USER MENU START -->
      <!-- <div
        ngbTooltip="Users"
        placement="right"
        tooltipClass="tooltips"
        (click)="Confirm('/app/myverticalmembers')"
        class="list"
        routerLink="/app/myverticalmembers"
        routerLinkActive="active"
        (click)="onToggleSidenav()"
      >
        <li><i class="fa-solid fa-minus"></i> {{ "Users" | translate }}</li>
      </div> -->

      <!-- <div
        *ngIf="inputFromParent2 == 'managers@niraltek.com'"
        ngbTooltip="Users"
        placement="right"
        tooltipClass="tooltips"
        (click)="Confirm('/app/Alluser')"
        class="list"
        routerLink="/app/AllUser"
        routerLinkActive="active"
        (click)="onToggleSidenav()"
      >
        <li><i class="fa-solid fa-minus"></i> {{ "All Users" | translate }}</li>
      </div> -->

      <!-- USER MENU END -->

      <!--ROLES AND PERMISSION  MENU START -->
      <div ngbTooltip="Roles & Permissions" placement="right" tooltipClass="tooltips"
        (click)="Confirm('/app/roles-permissions')" class="list" routerLink="/app/roles-permissions"
        routerLinkActive="active" (click)="onToggleSidenav()">
        <li><i class="fa-solid fa-minus"></i>Roles & Permissions</li>
      </div>
      <!--ROLES AND PERMISSION  MENU END -->

      <div ngbTooltip="Jobs" placement="right" tooltipClass="tooltips" (click)="Confirm('/app/cronjobs')" class="list"
        routerLink="/app/cronjobs" routerLinkActive="active" (click)="onToggleSidenav()">
        <li><i class="fa-solid fa-minus"></i>Jobs</li>
      </div>
    </ul>
  </div>
  <!-- SUBMENU END -->

  <!-- ANALYTICS MENU START -->
  <div *ngIf="Analytics" ngbTooltip="Analytics" placement="right" tooltipClass="tooltips"
     class=" navbar-toggler style"
    (click)="onToggleSidenav()" 
    routerLinkActive="active"
    (click)="search()">
    <div class="menu-style">
      <iconify-icon class="sidenav-icon" icon="clarity:analytics-solid"></iconify-icon>
      <!-- <mat-icon class="sidenav-icon">file_copy</mat-icon> -->
      <h3 *ngIf="this.show" class="sidenav-menu">
        {{ "Analytics" | translate }}
      </h3>
    </div>
    <iconify-icon class="sidenav-icon" *ngIf="searchicon" icon="iconamoon:arrow-up-2"></iconify-icon>
    <iconify-icon class="sidenav-icon" *ngIf="!searchicon" icon="iconamoon:arrow-down-2"></iconify-icon>
  </div>
  <!-- ANALYTICS MENU END -->

  <!--ANALYTICS SUBMENU START  -->
  <div *ngIf="this.Analytics" class="collapse navbar-collapse" [ngbCollapse]="isSearch || this.collapse">
    <ul>
      <!-- Energy Meter MENU START -->
      <div ngbTooltip="Smart Energy Meter" placement="right" *ngIf="this.vertical_type==1||!this.vertical_type"
        tooltipClass="tooltips" (click)="Confirm('/app/analytics/'+(this.vertical_type==null?'1':this.vertical_type))"
        class="list" [routerLink]="'/app/analytics/'+(this.vertical_type ==null ?'1':this.vertical_type)"
        routerLinkActive="active" (click)="onToggleSidenav()">
        <li><i class="fa-solid fa-minus"></i>Energy Meter</li>
      </div>
      <!-- Energy Meter MENU END -->

      <!-- Production MENU START -->
      <div ngbTooltip="Production" placement="right" *ngIf="this.vertical_type==2||!this.vertical_type"
        tooltipClass="tooltips" (click)="Confirm('/app/analytics/'+(this.vertical_type==null?'2':this.vertical_type))"
        class="list" [routerLink]="'/app/analytics/'+(this.vertical_type ==null ?'2':this.vertical_type)"
        routerLinkActive="active" (click)="onToggleSidenav()">
        <li><i class="fa-solid fa-minus"></i>Production</li>
      </div>
      <!-- Production MENU END -->

      <!--Solar  MENU START -->
      <div ngbTooltip="Solar" *ngIf="this.vertical_type==3 ||!this.vertical_type" placement="right "
        tooltipClass="tooltips" (click)="Confirm('/app/analytics/'+(this.vertical_type==null?'3':this.vertical_type))"
        class="list" [routerLink]="'/app/analytics/'+(this.vertical_type ==null ?'3':this.vertical_type)"
        routerLinkActive="active" (click)="onToggleSidenav()">
        <li><i class="fa-solid fa-minus"></i>Solar</li>
      </div>
      <!--Solar  MENU END -->

    </ul>
  </div>
  <!--ANALYTICS SUBMENU END  -->

  <!-- ACCOUND MENU START -->
  <div ngbTooltip="Account" placement="right" tooltipClass="tooltips" (click)="Confirm('/app/account')" class="style"
    id="account" routerLink="/app/account" routerLinkActive="active" (click)="onToggleSidenav()" (click)="default()">
    <div class="menu-style">
      <iconify-icon class="sidenav-icon" icon="ic:baseline-account-circle"></iconify-icon>
      <!-- <mat-icon class="sidenav-icon">account_circle</mat-icon> -->
      <h3 *ngIf="this.show" class="sidenav-menu">
        {{ "Account" | translate }}
      </h3>
    </div>
  </div>
  <!-- ACCOUND MENU END -->

  <!-- ADS MENU START -->
  <div *ngIf="Ads" ngbTooltip="Ads" placement="right" tooltipClass="tooltips" (click)="Confirm('/app/ads')"
    class="style" id="account" routerLink="/app/ads" routerLinkActive="active" (click)="onToggleSidenav()"
    (click)="default()">
    <div class="menu-style">
      <iconify-icon class="sidenav-icon" icon="mdi:ads"></iconify-icon>
      <!-- <mat-icon class="sidenav-icon">account_circle</mat-icon> -->
      <h3 *ngIf="this.show" class="sidenav-menu">
        {{ "Ads" | translate }}
      </h3>
    </div>
  </div>
  <!-- ADS MENU END -->

  <!-- LOGOUT BUTTON -->
  <div ngbTooltip="Logout" placement="right" tooltipClass="tooltips" [ngClass]="{ disabled: this.disabled }"
    class="style" (click)="onToggleSidenav()" (click)="userlogout()">
    <div class="menu-style">
      <iconify-icon class="sidenav-icon" icon="tabler:logout"></iconify-icon>
      <!-- <mat-icon class="sidenav-icon">logout</mat-icon> -->
      <h3 *ngIf="this.show" class="sidenav-menu">{{ "Logout" | translate }}</h3>
    </div>
  </div>
  <!-- LOGOUT BUTTON -->
</div>
<!--SIDENAV MENU END  -->