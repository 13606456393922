<!-- LOADING SPINNER -->
<mat-spinner
  role="progressbar"
  diameter="50"
  aria-hidden="true"
  *ngIf="loading"
  id="spinner"
></mat-spinner>
<div class="content" *ngIf="isread == false">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p style="font-weight: 500">
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>

<!-- NO PRODUCT PAGE START -->
<div class="content" *ngIf="productvalue?.length == 0 && isread == true">
  <div>
    <h1 style="font-weight: 500">Start by creating your first Gateway</h1>
    <p style="font-weight: 500">
      Gateway is a digital model of a physical object. It is used in Devsbot
      platform as a Gateway to be assigned to devices.
    </p>
  </div>
  <div>
    <button class="btn btn-primary" (click)="open()">+ New Gateway</button>
  </div>
</div>
<!-- NO PRODUCT PAGE END -->

<!-- PRODUCT START VIEW PAGE START -->
<div style="padding: 10px" *ngIf="!loading && productvalue?.length >= 1 && isread == true">
  <div class="col-md-12 d-flex">
    <div class="col-md-6">
      <div class="main-head">
        <div class="d-flex" style="align-items: center; gap:5px">
          <!-- <h1>Gateways  <mat-icon
            class="help"
            matTooltipClass="my-tooltip"
            matTooltip="Help"
            matTooltipPosition="right"
            >info
          </mat-icon></h1> -->
          <h1>Gateways</h1>
        </div>
      </div>
      <div class="count-product">
        <div>
          <span class="count-product-font">
            <h4>No Of Gateways : {{ productvalue?.length }}</h4>
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-flex new-dev-sec">
      <div class="buttons" *ngIf="this.iscreate">
        <button class="btn btn-primary new-dev" (click)="open()">
          New Gateways <i class="fa-solid fa-plus"></i>
        </button>
      </div>
      <div class="search-div" *ngIf="productvalue?.length >= 1">
        <input
          type="search"
          #search
          class="form-control"
          placeholder="Search Gateways"
          [(ngModel)]="filterTerm"
        />
        <mat-icon class="search-icon" (click)="search.focus()">search</mat-icon>
      </div>
    </div>
  </div>
</div>

<div class="product-body" *ngIf="!loading && productvalue?.length >= 1 && isread == true">
  <div
    class="product"
    (click)="info(cluster.cluster_id)"
    
    *ngFor="let cluster of productvalue | filter : filterTerm"
  >
    <div class="product-info">
      <h6>{{ cluster.regdate | date : "medium" }}</h6>

      <div class="productlogo">
        <!-- profilelogo -->
        {{ cluster.cluster_name.slice(0, 1) | uppercase }}
      </div>
    </div>
    <div class="product-preview">
      <h6 *ngIf="cluster.app_mode == '0'">Developer Mode</h6>
      <h6 *ngIf="cluster.app_mode == '1'">&nbsp;</h6>
      <h2>{{ cluster.cluster_name }}</h2>
      <div *ngIf="cluster.device_count == 0">No Devices</div>
      <div *ngIf="cluster.device_count >= 1">
        No Of Devices : {{ cluster.device_count }}
      </div>
      <div *ngIf="cluster.iscopy == 1">-Copy</div>
      <p >Created By : <span>{{cluster.firstname}}</span></p>
    </div>
  </div>
</div>
<!-- PRODUCT START VIEW  PAGE END -->
<!-- instruction  -->
<!-- <mat-sidenav class="sidenav-content" #sidenav mode="over" position="end" style="position: fixed; right: 0;">
	
	<div class="instruction">
		<mat-icon class="close" (click)="sidenav.close()">close</mat-icon>
    <h2>Create a Cluster</h2>
    <p>Here are the instructions to create a cluster.</p>
    <ol class="device-instructions">
      <li>Click on New Device and a pop-up window will be displayed.</li>
      <li>By Default the Single Device tab will be selected.</li>
      <li>Select a Cluster from the dropdown.</li>
      <li>Enter the Device Name and click on create button to create the device without mac id.</li>
      <li>The Toggle button can be enabled for advanced option, Where you can enter and verify the device mac-ID.</li>
      <li>Enter the Device Mac ID and select the check box to verify.</li>
      <li>Select the Web Zone from the dropdown.</li>
      <li>Enter the Qr Code ID of the Device.</li>
      <li>Click Create button to create the device.</li>
    </ol>
	</div>
  </mat-sidenav> -->
  
 