
<div class="container" *ngIf="!showGoogleSignup || !newusersignin">
  <div class="left-side">
   
  </div>

  <div class="right-side">

    <form [formGroup]="signin" (ngSubmit)="onSubmit()">
      <img
        class="logo"
        src="../../assets/organization/devsbot logo-1-12.png"
        alt=""
      />
      <p></p>


      <mat-form-field appearance="fill">
        <mat-label class="input-field">Email</mat-label>
        <input
          matInput
          type="email"
          id="email"
          formControlName="email"
          [ngClass]="{ error: submitted && f['email'].errors }"
        />
        <mat-icon matSuffix>email</mat-icon>

        <mat-error *ngIf=" f['email'].errors?.['required']"
          >Email is required</mat-error
        >
        <mat-error *ngIf=" f['email'].errors?.['email']">
          Email is invalid</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label class="input-field">Password</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          id="password"
          formControlName="password"
          [ngClass]="{
            error: submitted && f['password'].errors
          }"
        />

        <mat-icon
          style="margin-left: 30px; height: 10px"
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
          >{{ hide ? "visibility_off" : "visibility" }}</mat-icon
        >


        <mat-error *ngIf="f['password'].errors?.['required']"
          >Password is required</mat-error
        >
      </mat-form-field>

      <mat-error style="text-align: center" *ngIf="!loading">{{
        errorMessage
      }}</mat-error>

      <span class="Forgot" routerLink="/app/forgotpassword">Forgot Password?</span>
  
      <!-- <div class="login d-flex"> -->
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="loading"
          class="button"
        >
          <span>Login</span>
        </button>
        <!-- <button
          type="submit"
          mat-raised-button
          color="primary"
          routerLink="/app/signup"
          [disabled]="loading"
          class="button signup"
        >
          <span>Sign Up</span>
        </button> -->
      <!-- </div> -->
      <!-- <div class="line"></div>
  

      <div class="google-signup-div">
        <asl-google-signin-button
          type="standard"
          size="large"
          class="google-login-btn"
        >
        </asl-google-signin-button>

      </div> -->
    </form>

    <div class="copyright">
      <span>©2023 DevsBot. All Rights Reserved.</span>
    </div>
  </div>
</div>






<!-- new user signin -->

<ng-container>
  <app-newusersignin ></app-newusersignin>
</ng-container>
