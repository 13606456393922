<!-- manual matdialog page view  -->
<div class="matdialog-main">
    <div class="matdialog-head">

        <!-- heading  -->
        <div>
            <h1 style="padding:0px 0px 10px 10px;margin:0 !important;">Manual entry</h1>
            <p style="    padding: 0px 0px 0px 10px;
    margin: 0 !important;
    font-weight: 500;">Enter the code provided with the device.<br>
                (it's usually placed below QR code)</p>
        </div>
        <div>
            <mat-icon mat-dialog-close (click)="open()">close</mat-icon>
        </div>
    
        <!-- manual create device form  -->
    </div>
    <div class="matdialog-content">
        <form [formGroup]="manual"><label class="form-label">code</label>
            <input class="form-control" formControlName="code" placeholder="Enter the valid code">
        </form>
    </div>
    <!-- buttons  -->
    <div class="btn-divall"><button class="btn btn-danger" mat-dialog-close>Cancel</button><button
            class="btn btn-primary" [disabled]="!this.manual.valid">Next</button></div>
</div>