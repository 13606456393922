import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Dataservice } from '../services/dataservice';
import { AuthenticationService } from '../Authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { deletepopup } from '../mydevices/mydevices.component';
import { logout } from '../menu/menu.component';
import { CustomSelectOverlay } from '../analytics/custom-select-overlay';
import { Overlay } from '@angular/cdk/overlay';
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent {

  verticaldata: any[] = [];
  vertical_Id: any;
  roles: any;
  iscreate!: boolean;
  isread!: boolean;
  isdelete!: boolean;
  loading!: boolean;
  filterTerm: any;
  clusterdata: any[] = [];
  superadmin: boolean = false;
  cluster_id: any;
  vertical_type:any;

  constructor(private dataservice: Dataservice,
    private authentication: AuthenticationService,
    private toastr: ToastrService,
    private router: Router, private matdialog: MatDialog,

  ) {
    this.loading = false
    this.roles = this.authentication.getUserRole();
    this.vertical_type = this.authentication.getUserData().vertical_type;
    /**
     * Role for create permission.
     * @type {number}
     */



    this.roles = this.roles[13];

    /**
     * Iterate through roles and set permission flags accordingly.
     */
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          /**
           * Indicates whether user has create permission.
           * @type {boolean}
           */
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          /**
           * Indicates whether user has read permission.
           * @type {boolean}
           */
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          /**
           * Indicates whether user has delete permission.
           * @type {boolean}
           */
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });
  }

  ngOnInit() {
    this.loading = true
    this.dataservice.refreshcluster.subscribe(() => {
      this.opencluster(this.vertical_Id)
    })

    let data = this.authentication.getUserData();


    this.vertical_Id = data.vertical_Id
    this.opencluster(this.vertical_Id)
    // if(this.vertical_Id == null){
    //   this.superadmin=true
    //   this.dataservice.getverticaldata(data).subscribe((res:any)=>{
    //     if(res.status = 200){
    //       this.loading=false
    //       this.verticaldata = res.data
    //     }else{
    //       this.toastr.error('Vertical data not found')
    //     }
    //   })
    // }else{
    //   this.superadmin=false
    //   this.opencluster(this.vertical_Id)
    // }
  }
  opencluster(vertical_Id: any) {
    this.dataservice.getclusterdata(vertical_Id).subscribe((res: any) => {
      if (res.status = 200) {
        this.vertical_Id = vertical_Id
        this.clusterdata = res.data
        this.loading = false
        this.clusterdata.map((x: any) => {

          if (x.device_id == null || x.device_id == "") {
            x['device_count'] = 0
          } else {
            x['device_count'] = x.device_id.split(",").length
          }

        })


      } else {
        this.toastr.error('Cluster not found')
      }

    })
  }
  back() {
    this.vertical_Id = null
  }
  Clusterdata(id: any) {
    this.cluster_id = id;


    this.router.navigate(['/app/groupdb', this.cluster_id])

  }
  CreateCluster() {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(creategroup, {
        width: '600px',
        disableClose: true,
        data: {
          verticalId: this.vertical_Id // Pass vertical_id to createcluster
        }
      });
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }
}
@Component({
  selector: 'groupdb',
  templateUrl: 'groupdashboard.html',
  styleUrls: ['./group.component.css'],
})
export class groupdb implements OnInit {
  loading: boolean;
  roles: any;
  cluster_id: any;
  singleClusterData: any;
  ClusterName: any;
  expandedItems: Set<any> = new Set(); // Set to track expanded items
  displayedColumnss: string[] = ['S.no', 'This Month kWh', 'Today kWh', 'Current', 'Power Factor',];
  devicedata!: any;
  dbdata: any[] = [];
  iscreate!: boolean;
  isread!: boolean;
  isdelete!: boolean;
  deviceWithLocation: any;
  vertical_type:any;
  location: boolean = false;
  showLimitedParams: boolean = true;
  default_cluster: boolean = false;
  public displayedColumns: string[] = ['frequency', 'voltage', 'current', 'powerFactor', 'kilowatts', 'tco2', 'todayKwh', 'monthKwh', 'cost'];
  devicedatawithloc!: { serialNumber: number; deviceName: any; frequency: any; voltage: any; current: any; powerFactor: any; kilowatts: any; tco2: any; todayKwh: any; monthKwh: any; cost: any; }[];
  constructor(private route: ActivatedRoute, private matdialog: MatDialog, private router: Router, private authentication: AuthenticationService, private dataservice: Dataservice, private toastr: ToastrService,) {
    this.vertical_type = this.authentication.getUserData().vertical_type;
    this.loading = true;
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[13];
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })
  }


  ngOnInit(): void {

    this.cluster_id = this.route.snapshot.paramMap.get('id')
    this.getsingleclusterdata()
    this.dataservice.refreshcluster.subscribe(() => {
      this.getsingleclusterdata()
    })
  }
  toggleOtherParams(item: any) {
    if (this.expandedItems.has(item)) {
      this.expandedItems.delete(item);
    } else {
      this.expandedItems.add(item);
      // Ensure showAllParams is false when expanding a specific item
      this.showAllParams = false;
    }
  }

  showOtherParamsFor(item: any): boolean {
    return this.expandedItems.has(item) || this.showAllParams;
  }

  getsingleclusterdata() {
    this.dataservice.getsingleclusterdata(this.cluster_id).subscribe((res: any) => {
      if (res.status == "0") {
        this.router.navigate(['/app/group'])
      }
      else if (res.status == '200') {
        this.singleClusterData = res.data;
        this.ClusterName = this.singleClusterData.group_name;
        if (this.singleClusterData.default_cluster == 1) {
          this.default_cluster = true
        }


        if (this.singleClusterData.device_id && this.singleClusterData.device_id != null) {
          this.singleClusterData['devices'] = this.singleClusterData.device_id.split(",");
          let data = { device_id: this.singleClusterData.device_id, device_location: this.singleClusterData.device_location, location: this.singleClusterData.location }
          this.getdevicedbdata(data)
        } else {
          this.loading = false;
        }
      }
    });
  }

  getdevicedbdata(data: any) {
    if(this.vertical_type!=2){
      this.dataservice.getdevicedata(data).subscribe((res: any) => {
        if (res.status == "400") {
          this.toastr.error('No device data found');
        } else if (res.status == '200') {
          this.loading = false;
          if (res.device_location == 1) {
            this.location = true;
            this.deviceWithLocation = res.data;
            console.log("locccccc", this.deviceWithLocation);
  
  
          } else {
            this.location = false;
            this.dbdata = res.data;
            console.log(this.dbdata);
            this.devicedata = this.transformDeviceData(res.data);
          }
        }
      });
    }else if(this.vertical_type==2){
      this.dataservice.GetProductiondevicedata(data).subscribe((res: any) => {
        if (res.status == "400") {
          this.toastr.error('No device data found');
        } else if (res.status == '200') {
          this.loading = false;
          if (res.device_location == 1) {
            this.location = true;
            this.deviceWithLocation = res.data;
          } else {
            this.location = false;
            this.dbdata = res.data;
            this.devicedata = this.transformDeviceData(res.data);
          }
        }
      });
    }
 
  }

  transformDeviceData(data: any[]): any[] {
    return data.map((item, index) => ({
      serialNumber: index + 1,
      deviceName: item.device_name,
      frequency: item.device_value?.V0 ?? 0,
      voltage: item.device_value?.V3 ?? 0,
      current: item.device_value?.V4 ?? 0,
      powerFactor: item.device_value?.V5 ?? 0,
      kilowatts: item.device_value?.V6 ?? 0,
      tco2: item.device_value?.V7 ?? 0,
      todayKwh: item.device_value?.todayKwh ?? 0,
      monthKwh: item.device_value?.currentMonthKWH ?? 0,
      cost: item.device_value?.currentcost ?? 0,
    }));
  }

  getLocationKeys(): string[] {
    if (!this.deviceWithLocation) {
      return [];
    }
    return Object.keys(this.deviceWithLocation).sort();
  }

  DeleteSingleDevice(device_id: any) {
    if (this.isdelete == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "Remove"
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          this.dataservice.deletesingleDevice(device_id, this.cluster_id).subscribe((res: any) => {
            if (res.status == '200') {
              this.toastr.success('Device Deleted');
              this.router.navigateByUrl(this.router.url)
            } else if (res.status == 202) {
              this.router.navigate(['/app/group'])
              this.toastr.success('Device Deleted');
            } else {
              this.toastr.error('error occured');
            }
          });
        }
      });
    }
  }
  addDevices() {
    if (this.iscreate == true) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '600px';
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        edit: true,
        clusterData: this.singleClusterData // Pass 'singleClusterData' as custom data
      };
      const dialogRef = this.matdialog.open(creategroup, dialogConfig);
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }



  back() {
    this.router.navigate(['/app/group'])
  }
  deletecluster() {
    if (this.isdelete == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "Cluster"
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          this.dataservice.deletecluster(this.cluster_id).subscribe((res: any) => {
            if (res.status == '200') {
              this.toastr.success('Cluster Deleted');
              this.router.navigate(["/app/group"])
            } else {
              this.toastr.error('error occured');
            }
          });
        }
      });
    }
    else if (this.isdelete == false) {
      this.toastr.info("User not permitted")
    }
  }
  showAllParams: boolean = false;
  toggleParams() {
    this.showAllParams = !this.showAllParams;
    console.log(this.showAllParams);

    // Clear expandedItems set when toggling all parameters
    // if (this.showAllParams) {
    //   this.expandedItems.clear();
    // }
  }
  gotodeviceDB(item: any, clusterid: any) {
    this.router.navigate(['/app/dashboard', item, clusterid]);
  }
  isCardHovered: boolean = false;
  onCardHover(isHovered: boolean) {
    this.isCardHovered = isHovered;
  }



}
@Component({
  selector: 'creategroup',
  templateUrl: 'creategroup.html',
  styleUrls: ['./group.component.css'],
  // viewProviders: [{
  //   provide: Overlay,
  //   useClass: CustomSelectOverlay
  // }],
  // encapsulation:ViewEncapsulation.None
})
export class creategroup implements OnInit {

  loading: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  devices: any;
  edit: boolean = false;
  Cluster_data: any;
  selecteddevices!: any;
  Cluster_id: any;
  newcluster: any;
  public userForm!: FormGroup;
  public device_location: boolean = false;
  InitialselectedDevices: any;
  showLocationDevices: boolean = false;
  Disableadd!: boolean;
  vertname: any[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public vertical_id: any, @Inject(MAT_DIALOG_DATA) public data: any, private formbuilders: FormBuilder,
    private dataservice: Dataservice,
    private toastr: ToastrService, private router: Router,
    private authentication: AuthenticationService) {

    this.Cluster_data = data.clusterData


    if (data.clusterData) {
      this.Cluster_id = data.clusterData.group_id


    }

    this.newcluster = this.formbuilders.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern('^[A-Za-z_][A-Za-z0-9_ .\\-]*$')]],
      showLocationDevices: [false],
      devices: [[], Validators.required],
      address: this.formbuilders.array([this.addLocationDeviceRow()]),
      vertical_Id: ['', Validators.required],
      description: ['', Validators.maxLength(75)]
    });
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[13];
    this.loading = true;
    this.roles.map((x: any, i: any) => {
      if (data.edit == true) {
        this.edit = true
      }
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })
  }

  calculateTotalLength(arr: any) {
    let totalLength = 0;
    for (let i = 0; i < arr.length; i++) {
      totalLength += arr[i].length;
    }
    return totalLength;
  }

  addAddress(): void {
    const selectedDevices = this.addressArray.controls.map(control => control.get('device')?.value);

    let totalLength = this.calculateTotalLength(selectedDevices);

    totalLength = totalLength + this.InitialselectedDevices.length

    if (totalLength != this.devices.length) {
      this.addressArray.push(this.addLocationDeviceRow());
      this.addressArray.controls.forEach(control => {
        control.get('location')!.setValidators(Validators.required);
        control.get('device')!.setValidators([Validators.required, Validators.minLength(1)]);
      });
    } else {
      this.toastr.info("There are no devices left to select")
    }


  }

  //Remove Fields
  removeAddress(index: number): void {
    this.addressArray.removeAt(index);
  }
  //Fields Array
  get addressArray(): FormArray {
    return <FormArray>this.newcluster.get('address');
  }
  private addLocationDeviceRow(): FormGroup {
    return this.formbuilders.group({
      location: [''],
      device: [[]]
    });

  }

  // devicesControl = new FormControl(); // Create form control
  async ngOnInit() {
    this.loading = true
    if (this.edit) {
      this.loading = false;
      this.newcluster.get('name')?.setValue(this.Cluster_data.group_name);
      this.selecteddevices = this.Cluster_data.devices.map((id: string) => parseInt(id, 10));
      this.newcluster.get('showLocationDevices')?.disable();
      if (this.Cluster_data.device_location == 1) {
        this.newcluster.get('devices')!.disable();
        this.newcluster.get('showLocationDevices')?.setValue(true);
        this.showLocationDevices = true
        let location = JSON.parse(this.Cluster_data.location)

        // Assuming 'addressArray' is your form array
        const addressArray = this.newcluster.get('address') as FormArray;

        // Clear any existing form controls
        while (addressArray.length) {
          addressArray.removeAt(0);
        }

        // Loop through each location object and create a form group for it
        location.forEach((locationObj: { location: any; device: any; }) => {
          const locationGroup = this.formbuilders.group({
            location: [locationObj.location, Validators.required, Validators.minLength(2), Validators.maxLength(20)], // Assuming location is required
            device: [locationObj.device, Validators.required] // Assuming device selection is required
          });
          addressArray.push(locationGroup);
        });
        this.device_location = true
      }

    }
    await this.loadData();

  }

  async loadData() {
    const data = this.authentication.getUserData();

    this.dataservice.getalldevicedata(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.devices = res.data;
      } else {
        this.toastr.error('Error Occured');
      }
    });
    this.dataservice.selectedDevices().subscribe((res: any) => {
      if (res.status == '200') {
        this.InitialselectedDevices = res.data;
      } else {
        this.toastr.error('Error Occured');
      }
    });
    this.dataservice.getverticalvalue(data).subscribe(async (res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        await Promise.all(this.data.map((x: any) => {
          x.vertical_name = x.vertical_name?.split(',')
          x.vertical_Id = x.vertical_Id?.split(',')
          x.vertical_name?.map((y: any, i: number) => {
            this.vertname?.push({ "vertical_name": y, "vertical_Id": x.vertical_Id[i] })
          })
        }))
        if (data.vertical_Id) {
          this.newcluster.controls.vertical_Id.setValue(String(data.vertical_Id))
        } else {
          this.newcluster.controls.vertical_Id.setValue(String(this.vertname[0].vertical_Id))
        }
        this.loading = false;
      } else {
        this.toastr.error('Error Occured');
      }
    })

  }




  isDeviceSelected(deviceId: string, currentIndex: number): boolean {
    const selectedDevices = this.addressArray.controls.map(control => control.get('device')?.value);
    for (let i = 0; i < selectedDevices.length; i++) {
      if (i !== currentIndex && selectedDevices[i].includes(deviceId)) {
        return true;
      }
    }
    return false;
  }



  // addLocationDeviceRow(): void {
  //   const row = this.formbuilders.group({
  //     location: ['', Validators.required],
  //     devices: [[], [Validators.required, Validators.minLength(1)]]
  //   });
  //   this.locationAndDevices.push(row);
  //   const index = this.locationAndDevices.length - 1;
  //   this.newcluster.addControl(`location - ${index}`, this.formbuilders.control('', Validators.required));
  //   this.newcluster.addControl(`devices - ${index}`, this.formbuilders.control([], [Validators.required, Validators.minLength(1)]));
  // }


  // removeLocationDevice(index: number): void {
  //   (this.newcluster.get('locationAndDevices') as FormArray).removeAt(index);
  // }

  // get locationAndDevices(): FormArray {
  //   return this.newcluster.get('locationAndDevices') as FormArray;
  // }

  toggleLocationDevices(checked: boolean) {
    if (checked) {
      this.newcluster.get('devices')!.disable();
      this.addressArray.controls.forEach(control => {
        control.get('location')!.setValidators(Validators.required);
        control.get('device')!.setValidators([Validators.required, Validators.minLength(1)]);
      });
      // this.addLocationDeviceRow(); // Add a location-device row by default when toggle is checked
    } else {
      this.newcluster.get('devices')!.enable();
      this.addressArray.controls.forEach(control => {
        control.get('location')!.clearValidators();
        control.get('device')!.clearValidators();
        control.get('location')!.setValue('');
        control.get('device')!.setValue('');
      });
      // this.addressArray.controls.forEach(control => {
      //   control.get('location')!.clearValidators();
      //   control.get('device')!.clearValidators();
      // });
    }
  }

  isAlreadySelected(deviceId: any): boolean {
    // Check if all device IDs in the array are already selected

    // For a single device ID
    return this.selecteddevices && this.selecteddevices.includes(deviceId);
  }

  isAlreadySelectedInitial(deviceId: any): boolean {
    // Check if all device IDs in the array are initially selected

    // For a single device ID
    return this.InitialselectedDevices && this.InitialselectedDevices.includes(deviceId);
  }

  // Function to set Disableadd based on the presence of all devices in both arrays
  checkAllDevicesSelected(deviceIds: any): boolean {
    const allSelected = this.isAlreadySelected(deviceIds) && this.isAlreadySelectedInitial(deviceIds);
    this.Disableadd = allSelected;
    return allSelected;
  }

  onSubmit() {

    if (this.iscreate == true) {
      const user = this.authentication.getUserData()
      const user_Id = user.user_Id;
      let vertical_Id
      // if (user.vertical_Id) {
      //   vertical_Id = this.newcluster.value.vertical_Id;
      // }

      vertical_Id = this.newcluster.value.vertical_Id;

      if (vertical_Id) {
        if (!this.edit) {

          // Replace multiple spaces with single space in name and description
          this.newcluster.controls['name'].setValue(this.newcluster.value.name.replace(/[^\S\r\n]{2,}/g, ' '))
          this.newcluster.controls['description'].setValue(this.newcluster.value.description.replace(/[^\S\r\n]{2,}/g, ' '))


          const newclusterData = this.newcluster.value;
          const data = { newclusterData, user_Id, vertical_Id };

          this.dataservice.createCluster(data).subscribe((res: any) => {
            if (res.status == '201') {
              this.toastr.success('Cluster Created Successfully');
              // Navigate to the current URL after successful cluster creation
              this.router.navigateByUrl(this.router.url);
            } else {
              this.toastr.error('Error occurred');
            }
          });
        }

        else {

          const newclusterData = this.newcluster.value;
          console.log(newclusterData);

          const data = { newclusterData, cluster_id: this.Cluster_id, showlocation: this.showLocationDevices };
          console.log(data);

          this.dataservice.updateCluster(data).subscribe((res: any) => {
            if (res.status == '201') {
              this.toastr.success('Cluster updated Successfully');
              // Navigate to the current URL after successful cluster creation
              this.router.navigateByUrl(this.router.url);
            } else {
              this.toastr.error('Error occurred');
            }
          });
        }
      }
      else {
        this.toastr.error('Please Select a Vertical');
      }
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }


}


