import { Component, OnInit, Inject, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Dataservice } from '../services/dataservice';
import { Clipboard } from '@angular/cdk/clipboard';
import { deletepopup } from '../mydevices/mydevices.component';
import {
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { SocketService } from '../socket.io/socket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenticationService } from '../Authentication/authentication.service';
import { GridsterComponent, GridsterConfig, GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { __assign } from 'tslib';
import { Options } from '@angular-slider/ngx-slider';
import { Location } from '@angular/common';



@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit {
  data: any;
  loading: boolean;
  isconfirm: boolean;
  product_id: any;
  productdata: any;
  devicedata: Object | undefined;
  id: any;
  productvalue: any;
  user_Id: any;
  productname: any;
  hardware: any;
  connection: any;
  description: any;
  product_api_id: any;
  manufacturer: any;
  heartbeat: any;
  selectedFile: any;
  fileInputLabel: any;
  fileUploadForm: any;
  url: any;
  product_logo: any;
  filterTerm: any;
  datastreamfilter: any;
  value: number = 80;
  basicData: any;
  btn: any;
  datastreamconfig: any;
  deviceconfig: any;
  eventconfig: any;
  eventfilter: any;

  singledatastream: any;
  textcolor = '#ffffff';
  screencolor = '#000000';
  selectedTabIndex = 0;
  clusterinfo: boolean = false;

  //product event
  event: object[] = [];

  // new datastream switch
  datastreamdata: object[] = [];
  datastreamarr = ['Digital', 'Analog', 'Virtual pin'];
  datastreamall: object[] = [];
  delete: number[] = [];
  deletedatastream_Id: number[] = [];
  // units = [];

  // widget
  widgetbox: any[] = [];
  dashboardwidget: any[] = [];
  dashboardwidgetbox: any[] = [];
  widgetdatastreamdata: any[] = [];
  widget_Id: any[] = [];
  devicedata_Id: any[] = []
  username: any;
  deletewidget: any[] = [];
  deletewidget_Id: any[] = [];
  dashboard: any[] = [];
  verticalname: any;
  changesSaved!: boolean;
  deviceslist: any;
  devicefilter: any;
  deviceupdatemode: any[] = [];
  metadatalist: any;

  provision: any;
  deviceprefix: any;
  devicedataconfig: any;
  devicedatafilter: any;
  metadatalength: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  my_id: any;
  user: any;
  /**
   * Constructor of the component.
   * Initializes member variables, services, and configurations.
   *
   * @param matdialog - MatDialog service for opening dialog windows.
   * @param dataservice - Dataservice for handling data operations.
   * @param toastr - ToastrService for displaying notifications.
   * @param formbuilder - FormBuilder for creating and managing form groups.
   * @param clipboard - Clipboard service for copying content to the clipboard.
   * @param socket - SocketService for handling socket communication.
   * @param route - ActivatedRoute for accessing route parameters.
   * @param router - Router for navigation between routes.
   * @param authentication - AuthenticationService for handling user authentication.
   */
  constructor(
    private matdialog: MatDialog,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private formbuilder: FormBuilder,
    private clipboard: Clipboard,
    private socket: SocketService,
    private route: ActivatedRoute,
    private router: Router,
    private authentication: AuthenticationService
  ) {
    this.isconfirm = false;
    this.loading = false;
    // Get user roles from authentication service
    this.roles = this.authentication.getUserRole();


    this.roles = this.roles[5];
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })

  }
  // Form group for product information
  productform = this.formbuilder.group({
    productname: ['', Validators.required],
    hardware: [''],
    connection: [''],
    description: [''],
    product_api_id: [''],
    manufacturer: [''],
    vertical: [''],
    heartbeat: ['', Validators.required],
    preprovision: [''],
    deviceprefix: [''],
    Webzone_Id: [''],
    // hotspot_prefix: [''],
    // ingnore_period: [''],
  });
  // Form group for device provisioning settings
  provisionform: FormGroup = this.formbuilder.group({
    deviceprefix: ['', [Validators.required, Validators.maxLength(10)]]
  })
  /**
   * Lifecycle hook called when the component is initialized.
   * Handles initial data retrieval and subscription to observables.
   */
  ngOnInit(): void {
    this.dataservice.setPaginationState(null, 'all');
    this.loading = true;
    const data = this.authentication.getUserData();
    this.user = data.role
    this.dataservice.MyDevicestatus.next()
    this.dataservice.AllDevicestatus.next()
    this.dataservice.singleDevicestatus.next()
    // Retrieve and manage product data
    this.getproductdata(data)
    // Subscribe to ClusterCreate event to update product data
    this.dataservice.ClusterCreate.subscribe((response: any) => {
      this.getproductdata(data)
    })



  }
  /**
   * Fetches product data for the current user.
   *
   * @param data - User data to identify the user.
   */
  getproductdata(data: any) {
    // Call the Dataservice to get product data for the user
    this.dataservice.getproduct(data).subscribe((res: any) => {
      this.my_id = data.user_Id
      this.loading = false;
      if (res.status == '200') {
        this.data = res.data;
        this.productvalue = this.data;
      } else {
        this.toastr.error('error occured');
      }
    });
  }

  /**
   * Opens a dialog window to create a new product.
   * Only users with create permission can open the dialog.
   */
  open() {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(newproduct, {
        width: '600px',
        disableClose: true,
      });
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }

  /**
   * Navigates to the detailed information page of a product.
   *
   * @param id - ID of the selected product.
   */

  info(id: any) {
    // this.selectTab(0)
    this.product_id = id;

    this.router.navigate(['/app/clusterinfo', this.product_id])
  }


  clusterdashboard(id: any) {
    // this.selectTab(0)
    this.product_id = id;
    const product_Id = id;
    this.router.navigate(['/app/clusterdashboard', this.product_id])
  }





}


// product info 
@Component({
  selector: 'productinfo',
  templateUrl: 'productinfo.html',
  styleUrls: ['./product.component.css'],
})
export class productinfo implements OnInit {
  data: any;
  loading: boolean;
  isconfirm: boolean;
  product_id: any;
  productdata: any;
  devicedata: Object | undefined;
  id: any;
  productvalue: any;
  user_Id: any;
  productname: any;
  hardware: any;
  connection: any;
  description: any;
  product_api_id: any;
  manufacturer: any;
  heartbeat: any;
  selectedFile: any;
  fileInputLabel: any;
  fileUploadForm: any;
  url: any;
  product_logo: any;
  filterTerm: any;
  datastreamfilter: any;
  addresslist: any[] = [];
  value: number = 80;
  basicData: any;
  btn: any;
  datastreamconfig: any;
  deviceconfig: any;
  eventconfig: any;
  eventfilter: any;
  edit: boolean;
  device_Id: any[] = [];
  checked: boolean;
  singledatastream: any;
  textcolor = '#ffffff';
  screencolor = '#000000';
  selectedTabIndex = 0;
  clusterinfo: boolean = false;

  //product event
  event: object[] = [];

  // new datastream switch
  datastreamdata: object[] = [];
  datastreamarr = ['Digital', 'Analog', 'Virtual pin'];
  datastreamall: object[] = [];
  delete: number[] = [];
  deletedatastream_Id: number[] = [];
  // units = [];

  // widget
  widgetbox: any[] = [];
  dashboardwidget: any[] = [];
  dashboardwidgetbox: any[] = [];
  widgetdatastreamdata: any[] = [];
  widget_Id: any[] = [];
  devicedata_Id: any[] = []
  username: any;
  deletewidget: any[] = [];
  deletewidget_Id: any[] = [];
  deleteevent_Id: any[] = [];
  dashboard: any[] = [];
  verticalname: any;
  changesSaved!: boolean;
  deviceslist: any;
  devicefilter: any;
  deviceupdatemode: any[] = [];
  metadatalist: any;
  provision: any;
  deviceprefix: any;
  devicedataconfig: any;
  devicedatafilter: any;
  addressdatafilter: any;
  addressdataconfig: any;
  metadatalength: any;
  ssid: boolean;
  allselect: boolean;
  app_mode: any;
  app_mode_force: any;
  toggleChecked: any;
  zoneimport: boolean;
  devicemode: boolean;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  chartoptions: any;
  data_interval: any;
  live_data: any;
  parity: any;
  baudrate: any;
  databits: any;
  stopbits:any;
  // Array of initial chart labels with current time formatted as locale time strings
  chartlabel: any[] = [new Date().toLocaleTimeString(), new Date().toLocaleTimeString(), new Date().toLocaleTimeString(), new Date().toLocaleTimeString(), new Date().toLocaleTimeString()]
  // ViewChild decorators to access the GridsterComponent instances in the template
  @ViewChild('gridster', { static: false }) gridster!: GridsterComponent;
  @ViewChild('gridsteredit', { static: false }) gridsteredit!: GridsterComponent;
  // Gridster options configuration object
  options: any = {
    draggable: true,
    resizable: true,
    // Other gridster options
  };
  webzonevalue: any;
  Webzone_Id: any;
  mac_id: any
  user: any;


  /**
   * Constructor of the component.
   *
   * @param cdRef - ChangeDetectorRef for managing change detection.
   * @param matdialog - MatDialog service for opening dialog windows.
   * @param dataservice - Dataservice for handling data operations.
   * @param toastr - ToastrService for displaying notifications.
   * @param formbuilder - FormBuilder for creating and managing form groups.
   * @param clipboard - Clipboard service for copying content to the clipboard.
   * @param socket - SocketService for handling socket communication.
   * @param route - ActivatedRoute for accessing route parameters.
   * @param router - Router for navigation between routes.
   * @param authentication - AuthenticationService for handling user authentication.
   */
  constructor(
    private cdRef: ChangeDetectorRef,
    private matdialog: MatDialog,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private formbuilder: FormBuilder,
    private clipboard: Clipboard,
    private socket: SocketService,
    private route: ActivatedRoute,
    private router: Router,
    private authentication: AuthenticationService,
    private location: Location
  ) {
    this.updateGridsterOptions();
    window.addEventListener('resize', () => {
      this.updateGridsterOptions();
    });
    this.isconfirm = false;
    this.loading = false;
    this.edit = false;
    this.checked = false;
    this.allselect = false;
    this.clusterinfo = false;
    this.devicemode = false;
    this.ssid = false;
    this.zoneimport = false;
    this.datastreamconfig = {
      id: 'datastream',
      itemsPerPage: 5,
      currentPage: 1,
    };
    this.eventconfig = {
      id: 'event',
      itemsPerPage: 5,
      currentPage: 1,
    };
    this.deviceconfig = {
      id: 'devices',
      itemsPerPage: 5,
      currentPage: 1,
    };
    this.devicedataconfig = {
      id: 'devicedata',
      itemsPerPage: 5,
      currentPage: 1,
    };
    this.addressdataconfig = {
      id: 'address',
      itemsPerPage: 5,
      currentPage: 1,
    };
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[5];
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })



  }

  gaugeType = "arch";
  gaugeLabel = "Speed"; Type = "full";
  /**
   * GRIDSTER CONFIGURATION of the component.
   
   */
  displaygrid: GridsterConfig = {
    gridType: 'fixed',
    fixedColWidth: 110,
    fixedRowHeight: 100,
    compactType: 'none',
    margin: 10,
    outerMargin: false,
    minCols: 10,
    maxCols: 10,
    minRows: 5,
    maxRows: 100,
    displayGrid: 'onDrag&Resize',
    // defaultItemCols: 2,
    // defaultItemRows: 1,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,

    draggable: {
      enabled: false
    },
    resizable: {
      enabled: false
    },
    pushItems: true,
    pushResizeItems: false,
    swap: true,
  }
  // ngx slider 
  Options: Options = {
    floor: 0,
    ceil: 1000,
    step: 30,
    disabled: true,
    // showTicks: true,
    // draggableRange: true
  };
  gridsterOptions: GridsterConfig = {
    gridType: 'fixed',
    fixedColWidth: 80,
    fixedRowHeight: 100,
    compactType: 'none',
    margin: 10,
    outerMargin: false,
    minCols: 10,
    maxCols: 10,
    minRows: 5,
    maxRows: 100,
    displayGrid: 'onDrag&Resize',
    // defaultItemCols: 2,
    // defaultItemRows: 1,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,

    draggable: {
      enabled: true
    },
    resizable: {
      enabled: false
    },
    pushItems: true,
    pushResizeItems: false,
    swap: true,
    itemResizeCallback: (widget, itemComponent) =>
      this.itemResize(widget, itemComponent),
    itemChangeCallback: (widget, itemComponent) =>
      this.itemChange(widget, itemComponent),
  };
  /**
   * Fetches product data for the current user.
   *
   * @param data - User data to identify the user.
   */
  private updateGridsterOptions() {
    console.log('updateGridsterOptions() called');
    console.log('window.innerWidth:', window.innerWidth);
    if (window.innerWidth >= 2560 && window.innerWidth <= 3840) {
      this.gridsterOptions.fixedColWidth = 150;
      this.gridsterOptions.fixedRowHeight = 140;
      this.displaygrid.fixedColWidth = 170;
      this.displaygrid.fixedRowHeight = 140;
      console.log('fixedColWidth set to 120');
    } else if (window.innerWidth >= 1920 && window.innerWidth < 2560) {
      this.gridsterOptions.fixedColWidth = 110;
      this.gridsterOptions.fixedRowHeight = 120;
      this.displaygrid.fixedColWidth = 140;
      this.displaygrid.fixedRowHeight = 120;
      console.log('fixedColWidth set to 200');
    } else {
      this.gridsterOptions.fixedColWidth = 80;
      this.displaygrid.fixedColWidth = 110;
      console.log('fixedColWidth set to 100');
    }
  }
  /**
 * Handles changes in widget position.
 *
 * @param widget - The widget being changed.
 * @param itemComponent - The Gridster item component.
 */
  itemChange(widget: any, itemComponent: any) {


    const gaugeWidget = this.dashboardwidget.find(
      (w) => w.widget_name === 'Gauge'
    );
    if (gaugeWidget && widget === gaugeWidget) {
      gaugeWidget.maxItemCols = 3;
      gaugeWidget.maxItemRows = 2;
      gaugeWidget.minItemCols = 3;
      gaugeWidget.minItemRows = 2;


      this.cdRef.detectChanges();

      console.log(widget);
      if (gaugeWidget && widget === gaugeWidget) {
        if (gaugeWidget['cols'] >= 3) {
          gaugeWidget['cols'] = 3;
        }
        if (gaugeWidget['rows'] >= 2) {
          gaugeWidget['rows'] = 2;
        }
        if (gaugeWidget['cols'] <= 1) {
          gaugeWidget['cols'] = 3;
        }
        if (gaugeWidget['rows'] <= 1) {
          gaugeWidget['rows'] = 2;
        }
      }

    }
  }
  /**
 * Handles resizing of widgets.
 *
 * @param widget - The widget being resized.
 * @param itemComponent - The Gridster item component interface.
 */
  itemResize(widget: GridsterItem, itemComponent: GridsterItemComponentInterface) {


    const gaugeWidget = this.dashboardwidget.find(
      (w) => w.widget_name === 'Gauge'
    );
    if (gaugeWidget && widget === gaugeWidget) {
      gaugeWidget.maxItemCols = 3;
      gaugeWidget.maxItemRows = 2;
      gaugeWidget.minItemCols = 3;
      gaugeWidget.minItemRows = 2;


      this.cdRef.detectChanges();


      if (gaugeWidget && widget === gaugeWidget) {
        if (gaugeWidget['cols'] >= 3) {
          gaugeWidget['cols'] = 3;
        }
        if (gaugeWidget['rows'] >= 2) {
          gaugeWidget['rows'] = 2;
        }
        if (gaugeWidget['cols'] <= 1) {
          gaugeWidget['cols'] = 3;
        }
        if (gaugeWidget['rows'] <= 1) {
          gaugeWidget['rows'] = 2;
        }
      }

    }
  }

  /**
   * Enables drag-and-drop and resizing for Gridster.
   */
  private enableLock() {
    __assign(this.gridsterOptions, {
      draggable: {
        enabled: true
      },
      resizable: {
        enabled: true
      }
    } as GridsterConfig);
  }
  /**
   * Disables drag-and-drop and resizing for Gridster.
   */
  private disableLock() {
    __assign(this.gridsterOptions, {
      draggable: {
        enabled: false
      },
      resizable: {
        enabled: false
      }
    } as GridsterConfig);
  }
  noSpaceInFirstTwoCharactersValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
    const value: string = control.value;


    if (value && value.length >= 2) {
      // Check if the second character is a space
      if (value.charAt(1) === ' ') {

        // Check if there are two or more spaces after the first character
        if (value.substring(2).includes(' ')) {
          return { 'noSpaceInFirstTwoCharacters': true };
        }
      }
    }


    return null; // Validation passed
  };
  // Form group for creating a product.
  productform = this.formbuilder.group({
    productname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern('^[A-Za-z_][A-Za-z0-9_ .\\-]*$'),
    this.noSpaceInFirstTwoCharactersValidator]],
    hardware: ['', Validators.required],
    connection: ['', Validators.required],
    description: ['', Validators.maxLength(75)],
    product_api_id: [''],
    manufacturer: [''],
    vertical: [''],
    heartbeat: ['', [Validators.required, Validators.min(25)]],
    data_interval: ['', [Validators.required, Validators.min(60)]],
    live_data: [],
    Webzone_Id: [''],
    mac_id: [''],
    parity: [''],
    baudrate: [''],
    databits: [''],
    stopbits:['']

    // hotspot_prefix: [''],
    // ingnore_period: [''],
  });

  // Form group for provisioning.
  provisionform: FormGroup = this.formbuilder.group({
    deviceprefix: ['', [Validators.required, Validators.maxLength(10)]]
  })

  /**
   * Lifecycle hook called when the component is initialized.
   * Initializes chart data, options, and subscribes to data services.
   */
  ngOnInit(): void {
    this.dataservice.MyDevicestatus.next()
    this.dataservice.AllDevicestatus.next()
    this.dataservice.singleDevicestatus.next()
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    const data = this.authentication.getUserData()
    this.user = data.role
    this.dataservice.getwebzonedata(data).subscribe((res: any) => {

      if (res.status == '200') {
        this.data = res.data;
        this.webzonevalue = this.data;
      } else {
        this.toastr.error('Error occurred');
      }
    });
    // Initialize basic data for the chart.
    this.basicData = {
      labels: this.chartlabel,
      datasets: [
        {
          label: 'Label',
          data: [0, 1],
          fill: false,
          tension: 0.4,
          borderColor: documentStyle.getPropertyValue('--blue-500')
        }
      ]
    };
    // Set chart options for responsive display and scales.
    this.chartoptions = {
      responsive: true,

      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'linear',
          display: true,

          time: {
            displayFormats: {
              'millisecond': 'h:mm:ss.SSS a',
              'second': 'h:mm:ss a',
              'minute': 'h:mm:ss a',
              'hour': 'h:mm:ss a',
              'day': 'MMM D',
              'week': 'll',
              'month': 'MMM YYYY',
              'quarter': '[Q]Q - YYYY',
              'year': 'YYYY'
            },
            unit: 'hour',
            tooltipFormat: 'h:mm:ss a',
          },
          ticks: {
            source: 'auto',
            autoSkip: true,
            maxTicksLimit: 12
          }
        }],
        yAxes: [{
          type: 'linear',
          display: true,
          position: 'left'
        }]
      }
    };
    // Get the 'id' parameter from the route snapshot.
    this.id = this.route.snapshot.paramMap.get('id');
    this.info(this.id);
    // Subscribe to data refresh requirement.

    this.dataservice.Clusterrefresh.subscribe((response: any) => {
      if (this.id) {
        this.info(this.id)
      }

    })
    // Fetch widget box data from the data service.
    this.dataservice.Getwidgetbox().subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.widgetbox = this.data;
      } else {
        this.toastr.error('error occured');
      }
    });

  }
  downloadzip() {
    this.dataservice.downloadzip();
  }

  copyTextt(product_api_Id: any) {


    const value =
      '#include <Devsbot.h>' +
      '"' +
      '\n' +
      '#define DBOT_GATEWAY_ID' +
      ' ' +
      '"' +
      product_api_Id +
      '"' +
      '\n' +
      '#define WIFI_SSID' +
      ' ' +
      '"' +
      '' +
      '"' +
      ' //Replace Your WIFI_SSID' +
      '\n' +
      '#define WIFI_PASSWORD' +
      ' ' +
      '"' +
      '' +
      '"' +
      ' //Replace Your WIFI_PASSWORD' +
      '\n' +
      '\n' +
      'void setup()' +
      '\n' +
      '{' +
      '\n' +
      'Serial.begin(115200);' +
      '\n' +
      'dBot.begin(DBOT_GATEWAY_ID,WIFI_SSID,WIFI_PASSWORD);' +
      '\n' +
      '}' +
      '\n' +
      '\n' +
      'void loop()' +
      '\n' +
      '{' +
      '\n' +
      '// put your main code here, to run repeatedly' +
      '\n' +
      'dBot.Loop();' +
      '\n' +
      '}';

    // const value = '#include "devsbot.h"' + '\n' + '\n' + '#define DBOT_AUTH_TOKEN' + ' ' + '"' + authtoken + '"' + '\n' + '#define DBOT_CLUSTER_ID' + ' ' + '"' + product_api_Id + '"' + '\n' + '\n' + 'cls_devsbot iot;' + '\n' + '\n' + 'void setup()' + '\n' + '{' + '\n' + '// put your setup code here, to run once:' + '\n' + 'Serial.begin(115200);' + '\n' + 'iot.devsbotBegin(DBOT_CLUSTER_ID, "", "",DBOT_AUTH_TOKEN);' + '\n' + '}' + '\n' + '\n' + 'void loop()' + '\n' + '{' + '\n' + '// put your main code here, to run repeatedly:' + '\n' + 'iot.devsbotRun();' + '\n' + '}'
    // const token =
    //   '#define NTS_device_auth_token' + ' ' + '"' + authtoken + '"' + '\n';
    // const Id = '#define NTS_cluster_id' + ' ' + '"' + product_api_Id + '"';
    // const value: any = [token + Id];
    this.clipboard.copy(value);
    this.toastr.success('Copied to Clipboard');
  }

  /**
   * Handles tab click event.
   *
   * @param event - The event object containing tab information.
   */
  tabClick(event: any) {

    if (event.index == 5) {
      this.options.draggable = !this.options.draggable;
      this.options.resizable = !this.options.resizable;
      // Apply the updated options to the grid.
      this.gridster.optionsChanged();
    }

  }

  ngOnDestroy() {
    this.id = ''
  }

  /**
   * Fetches and populates information related to a specific product.
   *
   * @param id - The ID of the product to retrieve information for.
   */
  info(id: any) {

    // Set the product ID based on the provided ID.
    this.product_id = id;
    const product_Id = id;
    // Fetch PRODUCT DATA using api
    this.dataservice.getproductdata(this.product_id).subscribe((res: any) => {

      if (res.status == "0") {

        this.router.navigate(['/app/cluster'])
      }
      else if (res.status == '200') {
        this.data = res.data;
        this.productdata = this.data;
        console.log(this.productdata)


        this.productname = this.productdata.cluster_name;
        this.connection = this.productdata.connection_type;
        this.hardware = this.productdata.hardware_name;
        this.description = this.productdata.description;
        this.manufacturer = this.productdata.org_Name;

        this.product_api_id = this.productdata.cluster_api_Id;
        this.product_id = this.productdata.cluster_id;
        this.product_logo = this.productdata.product_logo;
        this.username = this.productdata.firstname;
        this.verticalname = this.productdata.vertical_name;
        this.heartbeat = this.productdata.heartbeat;
        this.provision = this.productdata.provision;
        this.deviceprefix = this.productdata.deviceprefix;
        this.app_mode = this.productdata.app_mode;
        this.app_mode_force = this.productdata.app_mode_force;
        this.data_interval = this.productdata.data_interval;
        this.live_data = this.productdata.live_data;
        this.Webzone_Id = this.productdata.zonename;
        this.mac_id = this.productdata.mac_id;
        this.parity = this.productdata.parity;
        this.baudrate = this.productdata.baudrate;
        this.databits = this.productdata.data_bits;
        this.stopbits=this.productdata.stop_bits;

 
        // Update form controls with retrieved values.
        this.productform.get('productname')?.setValue(this.productname);
        this.productform.get('description')?.setValue(this.description);
        this.productform.get('hardware')?.setValue(this.hardware);
        this.productform.get('connection')?.setValue(this.connection);
        this.productform.get('product_api_id')?.setValue(this.product_api_id);
        this.productform.get('manufacturer')?.setValue(this.manufacturer);
        this.productform.get('vertical')?.setValue(this.verticalname);
        this.productform.get('heartbeat')?.setValue(this.heartbeat);
        this.provisionform.get('deviceprefix')?.setValue(this.deviceprefix);
        this.productform.get('data_interval')?.setValue(this.data_interval);
        this.productform.get('live_data')?.setValue(this.live_data)
        this.productform.get('Webzone_Id')?.setValue(this.Webzone_Id)
        this.productform.get('mac_id')?.setValue(this.mac_id)
        this.productform.get('parity')?.setValue(this.parity)
        this.productform.get('baudrate')?.setValue(this.baudrate)
        this.productform.get('databits')?.setValue(this.databits)
        this.productform.get('stopbits')?.setValue(this.stopbits)


        this.productform.controls.manufacturer.disable();
        this.productform.controls.product_api_id.disable();
        this.productform.controls.vertical.disable();
      }


      // Fetch PRODUCT DATASTREAM DATA
      // this.dataservice
      //   .getdatastreamdata(this.product_id)
      //   .subscribe((res: any) => {
      //     if (res.status == '200') {
      //       this.data = res.data;
      //       let datastream = this.data;
      //       this.datastreamdata = datastream;
      //     } else {
      //       this.toastr.error('error occurred');
      //     }
      //   });

      /**  Fetch event box data from the data service using Getproductevent method.*/

      // this.dataservice
      //   .Getproductevent(this.product_id)
      //   .subscribe((res: any) => {
      //     if (res.status == '200') {
      //       this.data = res.data;
      //       this.event = this.data;
      //     } else {
      //       this.toastr.error('error occurred');
      //     }
      //   });

      // // PRODUCT DASHBOARD WIDGET
      // this.dataservice
      //   .GetDashboardWidget(this.product_id)
      //   .subscribe((res: any) => {
      //     if (res.status == '200') {
      //       this.data = res.data;
      //       this.dashboard = this.data
      //       this.dashboard.map((row: any) => {
      //         row.x = row.X;
      //         row.y = row.Y;
      //         row.cols = row.cols;
      //         row['rows'] = row.line;
      //       })
      //       this.dashboardwidget = this.data;
      //       this.dashboardwidget.map((row: any) => {
      //         row.x = row.X;
      //         row.y = row.Y;
      //         row.cols = row.cols;
      //         row['rows'] = row.line;
      //       })


      //     } else {
      //       this.toastr.error('error occurred');
      //     }
      //   });
    });


    /**  Fetch deviceslist box data from the data service using getproductdevices method.*/

    this.dataservice.getproductdevices(product_Id).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.deviceslist = this.data.map((x: any) => {
          return {
            ...x,
            isSelected: false
          }
        });
        this.deviceslist.map((x: any) => {
          let device_Id = x.device_id;
          let updatemode = x.update_mode;
          let value = { device_Id, updatemode };

          this.deviceupdatemode.push(value);
        });
      } else {
        this.toastr.error('Error occurred');
      }
    });


    /**  Fetch metadatalist box data from the data service using getproductmetadata method.*/
    // this.dataservice.getproductmetadata(product_Id).subscribe((res: any) => {
    //   if (res.status == '200') {
    //     this.data = res.data;
    //     this.metadatalist = this.data;
    //     this.metadatalist.map((x: any) => {
    //       if (x.meta_key == "SSID") {
    //         this.ssid = true
    //       }
    //     })

    //   } else {
    //     this.toastr.error('Error occurred');
    //   }
    // });

    // Get Address Config
    this.dataservice.GetGatewayaddress(product_Id).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.addresslist = this.data;


      } else {
        this.toastr.error('Error occurred');
      }
    });


  }


  /**
   * Enable or disable preprovision using checkbox.
   * @param event - The checkbox event object.
   */
  preprovision(event: any) {
    if (event.checked == true) {
      this.provision = 1
    } else if (event.checked == false) {
      this.provision = 0
    }
  }

  /**
   * Method for deleting a cluster.
   * @param product_Id - The ID of the cluster to be deleted.
   */
  productdelete(product_Id: any) {
    if (this.isdelete == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "Gateway"
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          const data = { product_Id };
          this.dataservice.deleteproduct(data).subscribe((res: any) => {
            if (res.status == '201') {
              this.toastr.success('Gateway Deleted');
              this.router.navigate(["/app/cluster"])
            } else {
              this.toastr.error('error occured');
            }
          });
        }
      });
    }
    else if (this.isdelete == false) {
      this.toastr.info("User not permitted")
    }

  }
  /**
   * Device update mode change.
   * @param event - The checkbox event object.
   * @param device_Id - The ID of the device for which the update mode is being changed.
   */
  updatemode(event: any, device_Id: any) {
    if (this.iscreate == true) {
      if (event.checked == true) {
        this.devicemode = true
        this.deviceupdatemode.map((x: any) => {
          if (x.device_Id == device_Id) {
            x.updatemode = 0;
          }
        });
      } else if (event.checked == false) {
        this.devicemode = true
        this.deviceupdatemode.map((x: any) => {
          if (x.device_Id == device_Id) {
            x.updatemode = 1;
          }
        });
      }
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }

  }



  // edit product validation
  Editproduct() {
    if (this.iscreate == true) {
      if (this.productform.invalid) {
        this.toastr.error('Invalid input. Please check your information and try again.');
        return;
      } else {
        this.Updateproduct();
        this.changesSaved = true;
      }
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }

  }

  /**
  * Method for updating the product.
  */
  Updateproduct() {
    this.loading = true;
    // console.log(this.productform.value);
    this.productform.value.heartbeat = String(this.productform.value.heartbeat);

    this.productform.controls.productname.setValue(String(this.productform.value.productname).replace(/[^\S\r\n]{2,}/g, ' '))
    this.productform.controls.description.setValue(String(this.productform.value.description).replace(/[^\S\r\n]{2,}/g, ' '))
    const productform = this.productform.value;
    const product_id = this.product_id;
    const data = { productform, product_id, "app_mode": this.app_mode };



    // info tab
    if (this.selectedTabIndex == 0) {
      this.dataservice.Updateproduct(data).subscribe((res: any) => {
        this.loading = false;
        if (res.status == '201') {
          this.toastr.success('Cluster Successfully Changed');
          this.clusterinfo = false;
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1500);

        } else {
          this.toastr.error('error occured');
        }
      });
    }

    // device tab
    // if (this.selectedTabIndex == 1) {
    //   // devicedata create
    //   const devicedata = this.metadatalist;
    //   const devicedatavalue = { devicedata, product_id }

    //   if (this.metadatalist.length >= 1) {
    //     // api call to create device data using dataservice

    //     this.dataservice
    //       .createdevicedata(devicedatavalue)
    //       .subscribe((res: any) => {
    //         if (res.status == '201') {
    //           // this.toastr.success('Device Data Created ');
    //           this.clusterinfo = false;
    //           while (this.metadatalist?.length > 0) {
    //             this.metadatalist.pop()
    //           }
    //           // setTimeout(() => {
    //           //   window.location.reload();
    //           // }, 1500);
    //         } else {
    //           this.toastr.error('error occured');
    //         }
    //       });
    //   }

    //   // api call to delete device data using dataservice
    //   if (this.devicedata_Id?.length > 0) {
    //     this.dataservice.deletedevicedata(this.devicedata_Id).subscribe((res: any) => {
    //       if (res.status == '201') {
    //         this.toastr.success('Saved successfully');
    //         this.clusterinfo = false;
    //         while (this.devicedata_Id?.length > 0) {
    //           this.devicedata_Id.pop()
    //         }
    //         // setTimeout(() => {
    //         //   window.location.reload();
    //         // }, 1500);=
    //         // this.devicedata_Id.splice(0, this.devicedata_Id?.length)
    //       } else {
    //         this.toastr.error('Device data delete failed!')
    //       }
    //     })

    //   }
    // }



    // devices tab


    if (this.selectedTabIndex == 1) {

      if (this.devicemode == true) {
        //device update mode
        this.dataservice
          .deviceupdatemodechange(this.deviceupdatemode)
          .subscribe((res: any) => {
            if (res.status == '201') {
              this.toastr.success('Device Update Mode Change successfully');
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1500);
              this.clusterinfo = false;
              this.devicemode = false;
              while (this.deviceupdatemode?.length > 0) {
                this.deviceupdatemode.pop()
              }

            } else {
              this.toastr.error('Device Update Mode Change Failed!');
            }
          })
      }


      if (this.zoneimport == true) {
        const devicelist = this.deviceslist;

        // api call to import webzone data using dataservice
        this.dataservice.deivcewebzoneimport(devicelist).subscribe((res: any) => {

          if (res.status == '201') {
            this.toastr.success('Import completed');
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1500);
            this.clusterinfo = false;
            this.zoneimport = false;
          }
          else {
            this.toastr.error('Web zone import Failed!')
          }
        })
      }
    }

    // dashboard tab

    // widget create and update
    // if (this.selectedTabIndex == 5) {
    //   // widget datastream create
    //   if (
    //     this.dashboardwidget?.length > 0 ||
    //     this.widgetdatastreamdata?.length > 0
    //   ) {

    //     let widgetall = this.dashboardwidget;
    //     let widgetdatastreamall = this.widgetdatastreamdata;
    //     let widgetdata = { widgetdatastreamall, widgetall };


    //     this.dataservice
    //       .widgetdatastreamcreate(widgetdata, this.product_id)
    //       .subscribe((res: any) => {
    //         if (res.status == '201') {
    //           this.toastr.success('Widget Created Successfully');
    //           // setTimeout(() => {
    //           //   window.location.reload();
    //           // }, 1500);
    //           this.clusterinfo = false;
    //           while (this.dashboardwidget?.length > 0) {
    //             this.dashboardwidget.pop()
    //           }
    //           while (this.widgetdatastreamdata?.length > 0) {
    //             this.widgetdatastreamdata.pop()
    //           }
    //         } else {
    //           this.toastr.error('Widget created failed');
    //         }
    //       });
    //   }

    //   // delete widget
    //   if (this.deletewidget?.length > 0) {
    //     this.dataservice
    //       .DeletedashboardWidget(this.deletewidget)
    //       .subscribe((res: any) => {
    //         if (res.status == '201') {
    //           this.toastr.success('Widget Deleted successfully');
    //           // setTimeout(() => {
    //           //   window.location.reload();
    //           // }, 1500);
    //           this.clusterinfo = false;

    //           while (this.deletewidget?.length > 0) {
    //             this.deletewidget.pop()
    //           }
    //           // this.deletewidget.splice(0, this.deletewidget?.length);
    //         } else {
    //           this.toastr.error('widget Delete failed!');
    //         }
    //       });
    //   }


    // }

    // device pre provision
    // if (this.selectedTabIndex == 6) {
    //   console.log(this.provisionform.value.deviceprefix)
    //   if (this.provisionform.valid) {
    //     const data = { "preprovision": this.provision, "deviceprefix": this.provisionform.value.deviceprefix, "product_Id": product_id }

    //     this.dataservice.preprovisionupdate(data).subscribe((res: any) => {
    //       if (res.status == '201') {
    //         this.toastr.success("Pre-provision Changed Successfully");
    //         setTimeout(() => {
    //           window.location.reload();
    //         }, 1500);

    //       } else {
    //         this.toastr.error("error occurred")
    //       }
    //     })

    //   } else {
    //     this.toastr.error("Device Prefix Name Required")

    //   }

    // }



  }

  /**
   * Opens the cluster edit page, toggling draggable and resizable options if permitted.
   */
  open() {
    if (this.isdelete == true || this.iscreate == true) {
      this.clusterinfo = true;
      this.options.draggable = !this.options.draggable;
      this.options.resizable = !this.options.resizable;
      this.gridsteredit.optionsChanged();

    } else {
      this.toastr.info("User not permitted")
    }


  }



  /**
   * Handles the creation or updating of various components based on user permissions.
   * @param btn - The button clicked to trigger the creation/update action.
   * @param singledatastream - The single datastream to be updated.
   * @param i - Index of the datastream being updated.
   */
  Create(btn: any, singledatastream: any, i: any) {
    if (this.iscreate == true) {
      this.btn = btn;
      const MatDialogConfig = this.matdialog.open(datastreampopup, {
        disableClose: true,
        data: {
          btn: this.btn,
          singledatastream: singledatastream,
          datastream: this.datastreamdata,
          hardware: this.hardware
        },
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result?.event == 'Createdatastream') {

          this.datastreamdata.push(result.data);
        }
        if (result?.event == 'Updatedatastream') {

          this.datastreamdata.splice(i, 1, result.data);
        }
        if (result?.event == "CreateEvent") {

          this.event.push(result.data)
        }
        if (result?.event == 'CreateWidgetDatastream') {


          const datastream_Id = result.data.datastream_Id;

          this.dataservice
            .getdatastreampin(datastream_Id)
            .subscribe((res: any) => {
              if (res.status == '200') {
                result.data.pin = res.data;
                this.widgetdatastreamdata.push(result.data);
                this.widget_Id.push(result.Id);
                result.data['widget_name'] = result.data['widgetname'];
                result.data['x'] = this.btn.x;
                result.data['y'] = this.btn.y;
                result.data['rows'] = this.btn?.rows;
                result.data['cols'] = this.btn?.cols;
                this.dashboardwidget[i] = result.data;

                // this.dashboardwidget[this.dashboardwidget.length - 1].title=result.data.title
                //  this.dashboardwidget=this.dashboardwidget.map((x: any) => {
                //     return {
                //       ...x,
                //       ...result.data,
                //     }
                //   })

              } else {
                this.toastr.error('error occured');
              }
            });
        }
        if (result?.event == 'Createdevicedata') {
          this.metadatalist.push(result.data);

        }
        if (result?.event == 'Updatedevicedata') {
          this.metadatalist.splice(i, 1, result.data)
        }
        if (result?.event == 'Createhotspot') {
          const ssid = result?.data.meta_key;
          const password = result?.data.meta_value;
          const description = result?.data.description;
          result.data.meta_value = ssid;
          result.data.meta_key = "SSID";
          this.metadatalist.push(result.data);
          this.ssid = true
          let object = { "meta_key": "Password", "meta_value": password, "description": description, "meta_Id": 0 }
          this.metadatalist.push(object)
        } if (result?.event == 'UpdateEvent') {
          this.event.splice(i, 1, result.data)
        }
        if (result?.event == 'Close') {
          MatDialogConfig.close();
        }
      });
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }

  }
  /**
   * Opens the edit dialog for a specific datastream.
   * @param datastream_Id - The ID of the datastream to be edited.
   * @param i - Index of the datastream being edited.
   * @param datastream_name - The name of the datastream.
   */
  editdatastream(datastream_Id: any, i: any, datastream_name: any) {
    if (this.iscreate == true) {
      let index = (this.datastreamconfig.currentPage - 1) * 5 + i;

      if (datastream_Id != 0) {
        this.dataservice
          .getsingledatastreamdata(datastream_Id)
          .subscribe((res: any) => {
            if (res.status == "200") {
              this.data = res.data;
              this.singledatastream = this.data;
              this.Create(datastream_name, this.singledatastream, index);
            } else {
              this.toastr.error('error occured');
            }
          });
      } else {
        let data = [this.datastreamdata.at(index)];

        this.Create(datastream_name, data, index);
      }
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }

  }
  /**
   * Opens the edit dialog for a specific event.
   * @param event_id - The ID of the event to be edited.
   * @param i - Index of the event being edited.
   * @param event_name - The name of the event.
   */
  editevent(event_id: any, i: any, event_name: any) {
    if (this.iscreate == true) {
      let index = (this.eventconfig.currentPage - 1) * 5 + i;
      let data = [this.event.at(index)];
      this.Create('Event', data, index)
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }


  }



  /**
   * Deletes a datastream.
   * @param i - Index of the datastream being deleted.
   * @param datastream_Id - The ID of the datastream to be deleted.
   */
  deletedatastream(i: any, datastream_Id: number) {
    if (this.isdelete == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "delete"
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          this.deletedatastream_Id.push(datastream_Id);
          this.toastr.success('Datastream Deleted Successfully')
          let index = (this.datastreamconfig.currentPage - 1) * 5 + i;
          this.datastreamdata.splice(index, 1);
        }
      });
    } else if (this.isdelete == false) {
      this.toastr.info("User not permitted")
    }

  }

  /**
   * Opens the edit dialog for a specific device data.
   * @param meta_Id - The ID of the metadata to be edited.
   * @param i - Index of the device data being edited.
   */
  editdevicedata(meta_Id: any, i: any) {
    if (this.iscreate == true) {
      let index = (this.devicedataconfig.currentPage - 1) * 5 + i;

      if (meta_Id != 0) {
        this.dataservice.getsingledevicedata(meta_Id).subscribe((res: any) => {
          if (res.status == "200") {
            this.data = res.data;
            this.Create("devicedata", this.data, index)
          } else {
            this.toastr.error('error occured')
          }
        })
      } else {
        let data = [this.metadatalist.at(index)];
        this.Create("devicedata", data, index)
      }
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }

  }

  /**
   *  Deletes a devicedata
   * @param meta_Id - The ID of the metadata to be edited.
   * @param i - Index of the device data being edited.
   */
  /**
   * Deletes an event.
   * @param i - Index of the event being deleted.
   * @param event_Id - The ID of the event to be deleted.
   */
  deletedevicedata(i: any, meta_Id: number) {
    if (this.isdelete == true) {
      this.matdialog.open(deletepopup, { disableClose: true, data: "delete" })
        .afterClosed()
        .subscribe((result: any) => {
          if (result.confirmation == true) {
            this.devicedata_Id.push(meta_Id);
            this.toastr.success('Device Data deleted successfully')

            this.metadatalist.splice((this.devicedataconfig.currentPage - 1) * this.devicedataconfig.itemsPerPage + i, 1);

            if (this.metadatalist.slice((this.devicedataconfig.currentPage - 1) * this.devicedataconfig.itemsPerPage, this.devicedataconfig.currentPage * this.devicedataconfig.itemsPerPage).length === 0 && this.devicedataconfig.currentPage > 1) {
              this.devicedataconfig.currentPage--;
            }
          }
        });
    }
    else if (this.isdelete == false) {
      this.toastr.info("User not permitted")
    }
  }

  deleteevent(i: any, event_Id: any) {
    if (this.isdelete == true) {
      this.matdialog.open(deletepopup, { disableClose: true, data: "delete" })
        .afterClosed()
        .subscribe((result: any) => {
          if (result.confirmation == true) {
            this.deleteevent_Id.push(event_Id);
            this.toastr.success('Event Deleted successfully')

            this.event.splice((this.eventconfig.currentPage - 1) * this.eventconfig.itemsPerPage + i, 1);

            if (this.event.slice((this.eventconfig.currentPage - 1) * this.eventconfig.itemsPerPage, this.eventconfig.currentPage * this.eventconfig.itemsPerPage).length === 0 && this.eventconfig.currentPage > 1) {
              this.eventconfig.currentPage--;
            }
          }
        });
    }
    else if (this.isdelete == false) {
      this.toastr.info("User not permitted")
    }

  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      this.widgetbox.splice(
        event.previousIndex,
        0,
        event.item.element.nativeElement.innerText
      );
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      event.item.data.widget_Id = 0;
      let cols;
      let rows;
      if (event.item.data.widget_name == "Gauge") {
        event.item.data.cols = 3;
        event.item.data.rows = 2;
        event.item.data.x = 0;
        event.item.data.y = 0;


      }
      if (event.item.data.widget_name == "Slider") {
        event.item.data.cols = 4;
        event.item.data.rows = 1;
        event.item.data.x = 0;
        event.item.data.y = 0;
      }
      if (event.item.data.widget_name == "Switch") {
        event.item.data.cols = 2;
        event.item.data.rows = 1;
        event.item.data.x = 0;
        event.item.data.y = 0;
      }
      if (event.item.data.widget_name == "Terminal") {
        event.item.data.cols = 4;
        event.item.data.rows = 3;
        event.item.data.x = 0;
        event.item.data.y = 0;
      }
      if (event.item.data.widget_name == "LED") {
        event.item.data.cols = 2;
        event.item.data.rows = 1;
        event.item.data.x = 0;
        event.item.data.y = 0;
      }
      if (event.item.data.widget_name == "Label") {
        event.item.data.cols = 2;
        event.item.data.rows = 1;
        event.item.data.x = 0;
        event.item.data.y = 0;
      }
      if (event.item.data.widget_name == "Chart") {
        event.item.data.cols = 6;
        event.item.data.rows = 3;
        event.item.data.x = 0;
        event.item.data.y = 0;
      }


      this.dashboardwidget.splice(event.currentIndex, 1, event.item.data);
    }
  }
  sortPredicate(index: number, item: CdkDrag<string[]>) {
    return index === index;
  }
  /** Predicate function that doesn't allow items to be dropped into a list. */
  noReturnPredicate() {
    return false;
  }

  copywidget(value: any) {
    let copywidget = { widget_Id: 0, widget_name: value };
    this.dashboardwidget.push(copywidget);
    this.toastr.success("Widget copied successfully")
  }


  // add widget 
  addwidget(widget_name: any) {
    let cols;
    let rows;
    if (widget_name == "Gauge") {
      cols = 3;
      rows = 2
    }
    if (widget_name == "Slider") {
      cols = 4;
      rows = 1
    }
    if (widget_name == "Switch") {
      cols = 2;
      rows = 1
    }
    if (widget_name == "Terminal") {
      cols = 4;
      rows = 3
    }
    if (widget_name == "LED") {
      cols = 2;
      rows = 1
    }
    if (widget_name == "Label") {
      cols = 2;
      rows = 1
    }
    if (widget_name == "Chart") {
      cols = 6;
      rows = 3
    }
    let newwidget = { widget_Id: 0, widget_name: widget_name, "rows": rows, "cols": cols, "x": 0, "y": 0 };
    this.dashboardwidget.push(newwidget)
    this.toastr.success("Widget Added Successfully")

  }

  widgetdelete(index: any, widget_Id: any) {
    if (this.isdelete == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "delete"
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          this.deletewidget.push(widget_Id);
          this.dashboardwidget.splice(index, 1);
          this.toastr.success("Widget deleted successfully")
        }
      });
    }
    else if (this.isdelete == false) {
      this.toastr.info("User not permitted")
    }
  }

  back() {

    this.selectedTabIndex = 0;
    // this.router.navigate(['/app/cluster'])
    this.location.back()
  }


  /**
  * Method to change the selected tab.
  * @param index - The index of the tab to be selected.
  */
  selectTab(index: number): void {
    this.selectedTabIndex = index;
  }
  /**
   * Copies the given text and product name to the clipboard.
   * @param copyText - The text to be copied.
   * @param productname - The product name.
   */
  copyText(copyText: any, productname: any) {

    const name = '#define NTS_cluster_name' + ' ' + '"' + productname + '"';
    const Id = '#define NTS_cluster_id' + ' ' + '"' + copyText + '"' + '\n';
    const value: any = [Id + name];
    this.clipboard.copy(value);
    this.toastr.success('Copied to Clipboard');
  }
  /**
   * Event handler for changing the current page in the event pagination.
   * @param event - The event containing the new page number.
   */
  eventpageChanged(event: any) {
    this.eventconfig.currentPage = event;
  }
  /**
 * Event handler for changing the current page in the device pagination.
 * @param event - The event containing the new page number.
 */
  devicespageChanged(event: any) {
    this.deviceconfig.currentPage = event;
  }
  /**
   * Event handler for changing the current page in the datastream pagination.
   * @param event - The event containing the new page number.
   */
  datastreampageChanged(event: any) {
    this.datastreamconfig.currentPage = event;
  }
  /**
 * Event handler for changing the current page in the devicedata pagination.
 * @param event - The event containing the new page number.
 */
  devicedatapageChanged(event: any) {
    this.devicedataconfig.currentPage = event;
  }
  /**
   * Toggles the checked state of checkboxes and updates the UI.
   * @param event - The event containing the checkbox information.
   */
  toggleChanges(event: any) {
    var Id = event.source?.id;
    document.getElementById(Id)?.setAttribute('checked', 'checked');
    this.checked = event.source?.checked;
  }
  /**
   * Exits the cluster edit page, either confirming or cancelling the exit.
   */
  exit() {
    if (window.confirm('Leave this page without saving your changes?')) {
      this.clusterinfo = false;
      this.info(this.product_id);
      this.deleteevent_Id.splice(0, this.deleteevent_Id?.length);
      this.devicedata_Id.splice(0, this.devicedata_Id?.length);
      this.deletedatastream_Id.splice(0, this.deletedatastream_Id?.length)
    } else {
      this.clusterinfo = true;

    }

  }


  /**
   * Handles the selection of multiple devices using checkboxes.
   * @param event - The checkbox change event.
   * @param device_Id - The ID of the device being selected.
   */
  onCheckboxChange(event: any, device_Id: any) {
    if (this.iscreate == true) {
      if (event.checked == true) {
        this.device_Id.push(device_Id);
        this.deviceslist.map((x: any) => {
          if (x.device_id == device_Id) {
            x.isSelected = true
          }

        })
        if ((this.deviceslist.length === this.device_Id.length) || (this.deviceconfig.itemsPerPage === this.device_Id.length)) {
          this.allselect = true
        }
        this.checked = true;
      } else {
        var index = this.device_Id.indexOf(device_Id);
        this.device_Id.splice(index, 1);
        this.deviceslist.map((x: any) => {
          if (x.device_id == device_Id) {
            x.isSelected = false
          }
        })
        if (this.device_Id.length <= 0) {
          this.checked = false;
        }
        if (this.deviceslist.length !== this.device_Id.length) {
          this.allselect = false
        }
      }
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }

  }

  /**
   * Handles the selection of all devices using a master checkbox.
   * @param event - The checkbox change event.
   */
  allCheckboxChange(event: any) {
    if (this.iscreate == true) {
      const itemsPerPage = 5;
      const currentPage = this.deviceconfig.currentPage;
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      if (event.checked == true) {
        this.deviceslist.slice(startIndex, endIndex).forEach((x: any) => {
          x.isSelected = true;
        });
        this.allselect = true;
        this.checked = true;
        this.deviceslist.slice(startIndex, endIndex).forEach((x: any) => {
          if (this.device_Id.indexOf(x.device_id) === -1) {
            this.device_Id.push(x.device_id);
          }
        });
      } else {
        this.deviceslist.slice(startIndex, endIndex).forEach((x: any) => {
          x.isSelected = false;
        });
        this.allselect = false;
        this.checked = false;
        while (this.device_Id.length > 0) {
          this.device_Id.pop();
        }
      }
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }

  /**
   * Imports a web zone for selected devices.
   */
  importzone() {
    if (this.iscreate == true) {
      const itemsPerPage = 5;
      const currentPage = this.deviceconfig.currentPage;
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const MatDialogConfig = this.matdialog.open(webzonepopup)

      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result?.event == 'importwebzone') {
          this.zoneimport = true;
          this.device_Id.map((x: any) => {
            this.deviceslist.map((y: any) => {
              if (y.device_id == x) {
                y.zonename = result.data
              }
            })
          })

          this.deviceslist.slice(startIndex, endIndex).forEach((x: any) => {
            x.isSelected = false;
          });
          this.allselect = false;
          this.checked = false;
          while (this.device_Id.length > 0) {
            this.device_Id.pop();
          }

        } else if (result?.event == 'Close') {
          MatDialogConfig.close();
        }
      })
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }

  }

  // onToggleChange(event: any) {
  //   if (event.checked == true) {
  //     this.toastr.success("Developer Mode is on")
  //     this.app_mode = '0'
  //   } else {
  //     this.app_mode = '1'
  //   }
  // }
  toggleText: string = 'You have access to Developer features of Devsbot.';
  /**
  * Handles the toggle change event for Developer Mode.
  * @param event - The toggle change event.
  */
  onToggleChange(event: any) {
    if (this.iscreate == true) {
      // Set the value of the toggleChecked flag based on the event.
      this.toggleChecked = event.checked;

      // Update the toggleText based on the toggleChecked state.
      if (this.toggleChecked) {
        this.toggleText = 'You have access to Developer features of Devsbot.';
        this.toastr.success("Developer Mode is on");
        this.app_mode = '0';
      } else {
        this.toggleText = 'Want to create your own IoT templates with Devsbot? Turn it on.';
        this.app_mode = '1';
      }
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted");
    }
  }

  onliveToggle(event: any) {
    if (this.iscreate == true) {
      // Set the value of the toggleChecked flag based on the event.
      this.toggleChecked = event.checked;

      // Update the toggleText based on the toggleChecked state.
      if (this.toggleChecked) {
        this.live_data = 1;
        this.productform.controls.live_data.setValue(this.live_data)
      } else {
        this.live_data = 0;
        this.productform.controls.live_data.setValue(this.live_data)
      }
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted");
    }
  }


}


// PRODUCT NEW CREATE POPUP
@Component({
  selector: 'newproduct',
  templateUrl: 'productcreate.html',
  styleUrls: ['popup.css'],
})
export class newproduct implements OnInit {
  id: any;
  vertname: any[] = [];
  hardwaretype: any;
  app_mode_force: any
  app_mode: any = 0;
  data: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  loading: any;

  /**
   * Constructor of the component.
   * Initializes member variables, services, and configurations.
   *
   * @param formbuilders - UntypedFormBuilder for creating and managing form groups (type assumed).
   * @param dataservice - Dataservice for handling data operations.
   * @param toastr - ToastrService for displaying notifications.
   * @param authentication - AuthenticationService for handling user authentication.
   */
  constructor(
    private formbuilders: UntypedFormBuilder,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private authentication: AuthenticationService
  ) {
    // Retrieve user roles from authentication service
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[5];
    this.loading = true;
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })
  }

  /**
   * Initializes component data and configurations when the component is initialized.
   */
  ngOnInit(): void {
    this.dataservice.MyDevicestatus.next()
    this.dataservice.AllDevicestatus.next()
    this.dataservice.singleDevicestatus.next()
    // Get user data from authentication service
    const data = this.authentication.getUserData()
    // Fetch vertical values from the data service
    this.dataservice.getverticalvalue(data).subscribe((res: any) => {

      if (res.status == '200') {
        this.data = res.data;

        this.data.map((x: any) => {
          x.vertical_name = x.vertical_name?.split(',')
          x.vertical_Id = x.vertical_Id?.split(',')
          x.vertical_name?.map((y: any, i: number) => {
            this.vertname?.push({ "vertical_name": y, "vertical_Id": x.vertical_Id[i] })
          })
          if (data.vertical_Id) {
            this.productform.controls['vertical'].setValue(data.vertical_Id)
          }

          this.app_mode_force = x.app_mode_force;
          this.app_mode = x.app_mode;
          this.loading = false;
        })
      } else {
        this.toastr.error('Error Occured');
      }
    });
    // Fetch hardware types from the data service
    this.dataservice.hardwaretype().subscribe((res: any) => {
      if (res.status == '200') {
        this.hardwaretype = res.data
      } else {
        this.toastr.error("Error Occured")
      }
    })
  }
  productform = this.formbuilders.group({
    name: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern('^[A-Za-z_][A-Za-z0-9_ \\-]*$'),],
    ],
    hardware: ['', Validators.required],
    connection: ['WiFi', Validators.required],
    vertical: ['', Validators.required],
    description: ['', Validators.maxLength(75)],
  });

  get productformcontrol(): {
    [key: string]: AbstractControl;
  } {
    return this.productform.controls;
  }

  onToggleChange(event: any) {
    if (event.checked == true) {
      this.toastr.success("Developer Mode is on")
      this.app_mode = '0'
    } else {
      this.app_mode = '1'
    }
  }

  onSubmit() {
    if (this.iscreate == true) {
      const user = this.authentication.getUserData()
      const user_Id = user.user_Id;
      const org_Id = user.org_Id;

      this.productform.controls['name'].setValue(this.productform.value.name.replace(/[^\S\r\n]{2,}/g, ' '))
      this.productform.controls['description'].setValue(this.productform.value.description.replace(/[^\S\r\n]{2,}/g, ' '))

      const productdata = this.productform.value;

      if (this.app_mode_force == '1') {
        this.app_mode = '1'
      }


      const data = { productdata, user_Id, org_Id, "app_mode": this.app_mode };
      this.dataservice.createproduct(data).subscribe((res: any) => {
        if (res.status == '201') {
          this.toastr.success('Cluster Created Sucessfully');
        } else {
          this.toastr.error('error occured');
        }
      });
    } else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }

  }

}

// create datastream and widget datastream popup
@Component({
  selector: 'datastreampopup',
  templateUrl: 'datastream.html',
  styleUrls: ['popup.css'],
})
export class datastreampopup implements OnInit {
  btn: any;
  icon: any;
  action!: string;
  units: any;
  unit: any;
  label: any;
  id: any;
  singledatastream: any;
  widgetname: any;
  useremail: string[] = []
  onlabel: any;
  offlabel: any;
  checked: boolean;

  pin: any;
  title: any;
  datastreamname: any;
  datastreampin: any;
  show: boolean;
  create: boolean;
  name: any;
  editdatastream: any;
  index: any;
  max: any;
  min: any;
  step: any;
  update: any;
  chartlabelvalue: any[] = [new Date().toLocaleTimeString(), new Date().toLocaleTimeString(), new Date().toLocaleTimeString(), new Date().toLocaleTimeString(), new Date().toLocaleTimeString()]
  enableNotifications: boolean = false;
  triggerInterval!: number;
  inputhint = 'Input here';
  color = '#3633b7';
  textcolor = '#ffffff';
  screencolor = '#000000';
  Digitalpin: any[] = [];
  AnalogpinOut: any[] = [];
  AnalogpinIn: any[] = []
  Virtualpin: any[] = [];
  DigitalpinOut: any[] = [];
  Digitalpinpull: any[] = [];
  pinmode = ['Output', 'Input', 'Input_pullup'];
  datastream_type = ['Integer', 'Double', 'String'];
  decimallist: any[] = [
    '#',
    '#.#',
    '#.##',
    '#.###',
    '#.####',
    '#.#####',
    '#.0',
    '#.00',
    '#.000',
    '#.0000',
    '#.00000',
  ];
  icons: string[] = ['fa', 'bs', 'fa5', 'mat', 'all'];
  datastreamall: object[] = [];
  datastreamdata: any[] = [];
  myFormGroup!: FormGroup;
  iconCss = new FormControl();
  fallbackIcon = 'fas fa-cube';


  form = this.formbuilder.group({
    handlestep: ['', Validators.required]
  });

  // digitaldatastream form
  digitaldatastream = this.formbuilder.group({
    datastream_name: ['Digital'],
    datastream_Id: ['0'],
    datastream_type: ['Integer'],
    icon: [''],
    name: ['Digital 0', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    alias: ['Digital 0', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    color: [''],
    pin: ['', Validators.required],
    pinmode: ['', Validators.required],
    decimal_value: [''],
  });

  // Analog datastream form
  analogdatastream = this.formbuilder.group({
    datastream_name: ['Analog'],
    datastream_Id: ['0'],
    datastream_type: ['Integer'],
    icon: [''],
    name: ['Analog A0', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    alias: ['Analog A0', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    color: [''],
    pin: ['', Validators.required],
    pinmode: ['', Validators.required],
    units: ['', Validators.required],
    min: ['0', Validators.required],
    max: ['1', Validators.required],
    default_value: ['0', Validators.required],
    decimal_value: [''],
  });

  // Virtual pin form
  virtualpindatastream = this.formbuilder.group({
    datastream_name: ['Virtual pin'],
    datastream_Id: ['0'],
    icon: [''],
    name: ['Integer V0', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    alias: ['Integer V0', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    color: [''],
    pin: ['', Validators.required],
    datastream_type: ['', Validators.required],
    units: ['', Validators.required],
    min: ['0', Validators.required],
    pinmode: [''],
    max: ['1', Validators.required],
    decimal_value: ['', Validators.required],
    default_value: ['0', Validators.required],
  });

  // SWITCH DATASTREAM
  switchdatastream = this.formbuilder.group({
    title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    datastream_Id: ['', Validators.required],
    onvalue: ['1'],
    Offvalue: ['0'],
    color: [''],
    onlabel: [''],
    offlabel: [''],
    labelposition: [''],
    widgetnamedisplay: [''],
    widget_Id: [''],
    widgetname: ['Switch'],
  });

  // LED DATASTREAM
  leddatastream = this.formbuilder.group({
    title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    datastream_Id: ['', Validators.required],
    color: [''],
    widget_Id: [''],
    widgetname: ['LED'],
  });

  // LABELDATASTREAM
  labeldatastream = this.formbuilder.group({
    title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    datastream_Id: ['', Validators.required],
    widget_Id: [''],
    color: [''],
    widgetname: ['Label'],
  });

  // SLIDERDATASTREAM
  sliderdatastream = this.formbuilder.group({
    title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    datastream_Id: ['', Validators.required],
    widget_Id: [''],
    color: [''],
    handlestep: ['', Validators.required],
    widgetname: ['Slider'],
  });

  // GAUGEDATASTREAM
  gaugedatastream = this.formbuilder.group({
    title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    datastream_Id: ['', Validators.required],
    widget_Id: [''],
    color: [''],
    widgetname: ['Gauge'],
    // max: [''],
    // min: ['']
  });

  // TERMINAL DATASTREAM
  terminaldatastream = this.formbuilder.group({
    title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    datastream_Id: ['', Validators.required],
    widget_Id: [''],
    inputhint: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    textcolor: [''],
    screencolor: [''],
    widgetname: ['Terminal'],
  });

  // CHART DATASTREAM
  Chartdatastream = this.formbuilder.group({
    title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    datastream_Id: ['', Validators.required],
    widget_Id: [''],
    Min: ['0', Validators.required],
    Max: ['1', Validators.required],
    timeseries: ['Default Timezone'],
    widgetname: ['Chart'],
    label: ['label', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]]
  });

  // DEVICEDATA
  devicedata = this.formbuilder.group({
    meta_key: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    meta_value: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    description: ['', Validators.maxLength(50)],
    meta_Id: ['0']
  });
  // Address
  Address = this.formbuilder.group({
    address: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    address_tag: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    address_tag_pin: ['', []],
    description: ['', Validators.maxLength(50)],
    address_Id: ['0']
  });

  hardware: any;


  // EVENTS
  event = this.formbuilder.group({
    event_Id: ['0'],
    name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    event_type: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
    color: [''],
    description: ['', Validators.maxLength(50)],
    message_count: ['0', Validators.min(0)],
    eventtime_delay: ['0'],
    email_to: [''],
  })

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, 188];
  fruitCtrl = new FormControl('', [Validators.email]);

  filteredFruits!: Observable<string[]>;
  fruits: string[] = [];
  allFruits: string[] = ["Apple", "Lemon", "Lime", "Orange", "Strawberry"];


  @ViewChild("fruitInput") fruitInput!: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete!: MatAutocomplete;
  @ViewChild('pinmodeSelect') pinmodeSelect!: ElementRef;
  @ViewChild('selectpin') selectpin!: ElementRef;
  basicData: any;
  chartoptions: any;
  chartlabel: any = "Label";



  ngAfterViewInit() {
    if (this.editdatastream) {
      const selectEl = this.pinmodeSelect?.nativeElement;
      const selectpin = this.selectpin?.nativeElement;

      const selectedOption = selectEl.querySelector(`option[value="${this.editdatastream?.pinmode}"]`);
      const selectedpin = selectpin.querySelector(`option[value="${this.editdatastream?.pin}"]`);

      if (selectedOption) {

        selectEl.selectedIndex = selectedOption.index;
        console.log(selectEl.value);
      }
      if (selectedpin) {
        selectpin.selectedIndex = selectedpin.index;
        console.log(selectpin.value)
      }

    }
  }

  constructor(
    private formbuilder: FormBuilder,
    private dataservice: Dataservice,
    private matdialog: MatDialog,
    private toastr: ToastrService,
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<ProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.label = false;
    this.checked = false;
    this.create = false;
    this.show = true;
    this.update = false;
    this.max = 1;
    this.min = 0;
    this.hardware = this.data.hardware;




    const user = this.authentication.getUserData()
    const user_Id = user.user_Id;
    const org_Id = user.org_Id;
    const vertical_Id = user.vertical_Id;

    const id = { org_Id, vertical_Id };

    this.dataservice.useremail(id).subscribe((res: any) => {
      if (res.status == "200") {
        this.data = res.data;
        this.data.map((x: any) => {
          this.useremail.push(x.email_id)
        })

      }
      else {
        this.toastr.error("error occurred")
      }
    })


    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.useremail.slice()
      )
    );


  }



  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    // Check if the input value is a valid email and exists in the useremail array
    if (this.fruitCtrl.valid && value && this.useremail.includes(value)) {
      // Add the email to the list of fruits
      this.fruits.push(value);
    } else {
      // Display an error message if the email is invalid or not in the useremail array
      // this.toastr.error("Please enter a valid email address");
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = "";
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.useremail.filter(
      fruit => fruit.toLowerCase().indexOf(filterValue) === 0
    );
  }


  ngOnInit(): void {

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.chartoptions = {
      responsive: true,
      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'linear',
          display: true,
          time: {
            displayFormats: {
              'millisecond': 'h:mm:ss.SSS a',
              'second': 'h:mm:ss a',
              'minute': 'h:mm:ss a',
              'hour': 'h:mm:ss a',
              'day': 'MMM D',
              'week': 'll',
              'month': 'MMM YYYY',
              'quarter': '[Q]Q - YYYY',
              'year': 'YYYY'
            },
            unit: 'hour',
            tooltipFormat: 'h:mm:ss a',
          },
          ticks: {
            source: 'auto',
            autoSkip: true,
            maxTicksLimit: 12
          }
        }],
        yAxes: [{
          type: 'linear',
          display: true,
          position: 'left'
        }]
      }
    };

    this.basicData = {
      labels: this.chartlabelvalue,
      datasets: [
        {
          label: this.chartlabel,
          data: [this.Chartdatastream.value.Min, this.Chartdatastream.value.Max],
          fill: false,
          tension: 0.4,
          borderColor: documentStyle.getPropertyValue('--blue-500')
        }
      ]
    };





    this.dataservice.getdatastreamunits().subscribe((res: any) => {
      if (res.status == '200') {
        this.unit = res.data;
        this.units = this.unit;
      } else {
        this.toastr.error('error occured');
      }
    });

    this.btn = this.data.btn;
    this.index = this.data.index;

    this.analogdatastream.controls.pinmode.valueChanges.subscribe((x: any) => {
      if (x == "Output") {
        if (this.hardware == "Esp32") {
          this.analogdatastream.controls.pin.setValue("0")
        }
        else {
          this.analogdatastream.controls.pin.setValue("D1")
        }
      }
      if (x == "Input") {
        if (this.hardware == "Esp32") {
          this.analogdatastream.controls.pin.setValue("32")
        }
        else {
          this.analogdatastream.controls.pin.setValue("A0")
        }
      }

    })


    // if (this.data.singledatastream?.length <= 0 ||
    //   this.data.singledatastream?.datastream_name?.length <= 0) {
    this.dataservice.hardwaredatastreampin(this.hardware).subscribe(async (res: any) => {
      if (res.status == "200") {
        this.data = res.data;
        this.data.map((x: any) => {
          if (x.datastream_name == "Digital") {
            this.Digitalpin = x.input.split(',').map(String)
            this.DigitalpinOut = x.output.split(',').map(String)
            this.Digitalpinpull = x.input_pull_up.split(',').map(String)

          }
          if (x.datastream_name == "Analog") {
            this.AnalogpinIn = x.input.split(',').map(String)
            this.AnalogpinOut = x.output.split(',').map(String)

          } if (x.datastream_name == "Virtual") {
            this.Virtualpin = x.input.split(',').map(String)
          }

        })

      } else {
        this.toastr.error("error occured")
      }
    })
    // }

    if (this.data.singledatastream?.length <= 0) {

      if (this.hardware == "Esp32") {
        this.digitaldatastream.controls.pin.setValue("0");
        this.digitaldatastream.controls.pinmode.setValue('Output');
        this.analogdatastream.controls.pin.setValue("0");
        this.analogdatastream.controls.pinmode.setValue('Output');
        this.analogdatastream.controls.units.setValue('None');
        this.virtualpindatastream.controls.pin.setValue("V0");
        this.virtualpindatastream.controls.units.setValue('None');
        this.virtualpindatastream.controls.datastream_type.setValue('Integer');
        this.virtualpindatastream.controls.decimal_value.setValue('#.00000');
      } else if (this.hardware == "Esp8266") {
        this.digitaldatastream.controls.pin.setValue("D1");
        this.digitaldatastream.controls.pinmode.setValue('Output');
        this.analogdatastream.controls.pin.setValue("D1");
        this.analogdatastream.controls.pinmode.setValue('Output');
        this.analogdatastream.controls.units.setValue('None');
        this.virtualpindatastream.controls.pin.setValue('V0');
        this.virtualpindatastream.controls.units.setValue('None');
        this.virtualpindatastream.controls.datastream_type.setValue('Integer');
        this.virtualpindatastream.controls.decimal_value.setValue('#.00000');
      }

    }

    if (
      this.data.singledatastream?.length >= 1 ||
      this.data.singledatastream?.datastream_name?.length >= 1
    ) {
      this.data.singledatastream?.find((x: any) => {
        this.editdatastream = x;
      });
      if (this.editdatastream?.event_Id >= 0) {
        this.event.controls.event_Id.setValue(this.editdatastream.event_Id);
        this.event.controls.description.setValue(this.editdatastream.description);
        this.event.controls.event_type.setValue(this.editdatastream.event_type);
        console.log(this.editdatastream.eventtime_delay)
        this.color = this.editdatastream.color;
        this.event.controls.message_count.setValue(this.editdatastream.message_count);
        this.event.controls.name.setValue(this.editdatastream.name);
        let data: any[] = this.editdatastream.email_to.split(',')
        data.map((x: any) => {
          this.fruits.push(x)
        })

      }
      if (this.editdatastream?.meta_Id >= 0) {
        this.update = true;

        this.devicedata.controls.meta_Id.setValue(this.editdatastream.meta_Id);
        this.devicedata.controls.description.setValue(this.editdatastream.description);
        this.devicedata.controls.meta_key.setValue(this.editdatastream.meta_key);
        this.devicedata.controls.meta_value.setValue(this.editdatastream.meta_value);
        if (this.editdatastream.meta_key == "SSID" || this.editdatastream.meta_key == "Password") {
          this.devicedata.controls.meta_key.disable()
        }
      }


      if (this.editdatastream?.datastream_name == 'Digital') {

        this.digitaldatastream.controls.datastream_name.setValue(
          this.editdatastream.datastream_name
        );
        this.digitaldatastream.controls.alias.setValue(
          this.editdatastream.alias
        );
        this.digitaldatastream.controls.datastream_Id.setValue(
          this.editdatastream.datastream_Id
        );
        this.digitaldatastream.controls.datastream_type.setValue(
          this.editdatastream.datastream_type
        );
        this.color = this.editdatastream.color;
        this.digitaldatastream.controls.name.setValue(this.editdatastream.name);
        this.digitaldatastream.controls.pin.setValue(this.editdatastream.pin);
        this.digitaldatastream.controls.pinmode.setValue(this.editdatastream.pinmode)

        this.iconCss.setValue(this.editdatastream.icon);
      }
      if (this.editdatastream.datastream_name == 'Analog') {
        this.analogdatastream.controls.alias.setValue(
          this.editdatastream.alias
        );

        this.analogdatastream.controls.name.setValue(this.editdatastream.name);
        this.analogdatastream.controls.datastream_Id.setValue(
          this.editdatastream.datastream_Id
        );
        this.analogdatastream.controls.datastream_name.setValue(
          this.editdatastream.datastream_name
        );
        this.analogdatastream.controls.datastream_type.setValue(
          this.editdatastream.datastream_type
        );
        this.analogdatastream.controls.pinmode.setValue(
          this.editdatastream.pinmode
        );
        this.analogdatastream.controls.max.setValue(this.editdatastream.max);
        this.analogdatastream.controls.min.setValue(this.editdatastream.min);
        this.analogdatastream.controls.default_value.setValue(
          this.editdatastream.default_value
        );
        this.analogdatastream.controls.pin.setValue(this.editdatastream.pin);
        this.analogdatastream.controls.units.setValue(
          this.editdatastream.units
        );
        this.iconCss.setValue(this.editdatastream.icon);
        this.color = this.editdatastream.color;

      }
      if (this.editdatastream.datastream_name == 'Virtual pin') {
        this.virtualpindatastream.controls.alias.setValue(
          this.editdatastream.alias
        );
        this.virtualpindatastream.controls.name.setValue(
          this.editdatastream.name
        );
        this.virtualpindatastream.controls.datastream_Id.setValue(
          this.editdatastream.datastream_Id
        );
        this.virtualpindatastream.controls.datastream_name.setValue(
          this.editdatastream.datastream_name
        );
        this.virtualpindatastream.controls.datastream_type.setValue(
          this.editdatastream.datastream_type
        );
        this.virtualpindatastream.controls.pinmode.setValue(
          this.editdatastream.pinmode
        );
        this.virtualpindatastream.controls.max.setValue(
          this.editdatastream.max
        );
        this.virtualpindatastream.controls.min.setValue(
          this.editdatastream.min
        );
        this.virtualpindatastream.controls.default_value.setValue(
          this.editdatastream.default_value
        );
        this.virtualpindatastream.controls.pin.setValue(
          this.editdatastream.pin
        );
        this.virtualpindatastream.controls.units.setValue(
          this.editdatastream.units
        );
        this.iconCss.setValue(this.editdatastream.icon);
        this.color = this.editdatastream.color;
        this.virtualpindatastream.controls.decimal_value.setValue(
          this.editdatastream.decimal_value
        );



      }
    } else {

      this.widgetname = this.btn.widget_name;
      this.title = this.btn.title;
      this.datastreamname = this.btn.name;
      this.datastreampin = this.btn.pin;
      this.datastreamdata = this.data.datastream;
      this.switchdatastream.controls.title.valueChanges.subscribe((x: any) => {
        this.widgetname = x;
      });
      this.switchdatastream.controls.onlabel.valueChanges.subscribe(
        (x: any) => {
          this.onlabel = x;
        }
      );
      this.switchdatastream.controls.offlabel.valueChanges.subscribe(
        (x: any) => {
          this.offlabel = x;
        }
      );
      this.leddatastream.controls.title.valueChanges.subscribe((x: any) => {
        this.widgetname = x;
      });
      this.labeldatastream.controls.title.valueChanges.subscribe((x: any) => {
        this.widgetname = x;
      });
      this.sliderdatastream.controls.title.valueChanges.subscribe((x: any) => {
        this.widgetname = x;
      });
      this.Chartdatastream.controls.title.valueChanges.subscribe((x: any) => {
        this.widgetname = x;
      });
      this.sliderdatastream.controls.handlestep.valueChanges.subscribe(
        (x: any) => {
          this.step = x;
        }
      );
      this.gaugedatastream.controls.title.valueChanges.subscribe((x: any) => {
        this.widgetname = x;
      });
      this.terminaldatastream.controls.title.valueChanges.subscribe(
        (x: any) => {
          this.widgetname = x;
        }
      );
      this.terminaldatastream.controls.textcolor.valueChanges.subscribe(
        (x: any) => {
          this.textcolor = x;
        }
      );
      this.terminaldatastream.controls.screencolor.valueChanges.subscribe(
        (x: any) => {
          this.screencolor = x;
        }
      );
      this.terminaldatastream.controls.inputhint.valueChanges.subscribe(
        (x: any) => {
          this.inputhint = x;
        }
      );
      this.Chartdatastream.controls.label.valueChanges.subscribe((x: any) => {
        this.chartlabel = x
        this.basicData = {
          labels: this.chartlabelvalue,
          datasets: [
            {
              label: x,
              data: [this.Chartdatastream.value.Min, this.Chartdatastream.value.Max],
              fill: false,
              tension: 0.4,
              borderColor: documentStyle.getPropertyValue('--blue-500')
            }
          ]
        };


      })
      this.Chartdatastream.controls.Min.valueChanges.subscribe((x: any) => {
        this.min = x
        this.basicData = {
          labels: this.chartlabelvalue,
          datasets: [
            {
              label: this.chartlabel,
              data: [x, this.Chartdatastream.controls.Max.value],
              fill: false,
              tension: 0.4,
              borderColor: documentStyle.getPropertyValue('--blue-500')
            }
          ]
        };


      })
      this.Chartdatastream.controls.Max.valueChanges.subscribe((x: any) => {

        this.basicData = {
          labels: this.chartlabelvalue,
          datasets: [
            {
              label: this.chartlabel,
              data: [this.min, x],
              fill: false,
              tension: 0.4,
              borderColor: documentStyle.getPropertyValue('--blue-500')
            }
          ]
        };


      })
      this.terminaldatastream.controls.inputhint.setValue('Input here');
      this.switchdatastream.controls.title.setValue(this.btn.widget_name);
      this.leddatastream.controls.title.setValue(this.btn.widget_name);
      this.sliderdatastream.controls.title.setValue(this.btn.widget_name);
      this.labeldatastream.controls.title.setValue(this.btn.widget_name);
      this.gaugedatastream.controls.title.setValue(this.btn.widget_name);
      this.terminaldatastream.controls.title.setValue(this.btn.widget_name);
      this.Chartdatastream.controls.title.setValue(this.btn.widget_name);
      if (this.btn.datastream_Id) {
        this.switchdatastream.controls.title.setValue(this.btn.title);
        this.leddatastream.controls.title.setValue(this.btn.title);
        this.sliderdatastream.controls.title.setValue(this.btn.title);
        this.labeldatastream.controls.title.setValue(this.btn.title);
        this.gaugedatastream.controls.title.setValue(this.btn.title);
        this.Chartdatastream.controls.title.setValue(this.btn.title);
        this.terminaldatastream.controls.title.setValue(this.btn.title);
        this.switchdatastream.controls.datastream_Id.setValue(this.btn.datastream_Id)
        this.sliderdatastream.controls.datastream_Id.setValue(this.btn.datastream_Id)
        this.Chartdatastream.controls.datastream_Id.setValue(this.btn.datastream_Id)
        this.labeldatastream.controls.datastream_Id.setValue(this.btn.datastream_Id)
        this.gaugedatastream.controls.datastream_Id.setValue(this.btn.datastream_Id)
        this.terminaldatastream.controls.datastream_Id.setValue(this.btn.datastream_Id)
        this.terminaldatastream.controls.screencolor.setValue(this.btn.screencolor);
        this.terminaldatastream.controls.textcolor.setValue(this.btn.textcolor);
        this.terminaldatastream.controls.inputhint.setValue(this.btn.inputhint);

        if (this.btn.onlabel) {
          this.label = true
          this.switchdatastream.controls.onlabel.setValue(this.btn.onlabel)
          this.switchdatastream.controls.offlabel.setValue(this.btn.offlabel)
        }
        if (this.btn.handlestep) {
          this.sliderdatastream.controls.handlestep.setValue(this.btn.handlestep)
        }

        if (this.btn.Min) {


          this.Chartdatastream.controls.Min.setValue(this.btn.Min)
        }
        if (this.btn.Max) {

          this.Chartdatastream.controls.Max.setValue(this.btn.Max)
        }
        if (this.btn.label) {
          this.Chartdatastream.controls.label.setValue(this.btn.label)
        }
        // this.gaugedatastream.controls.max.setValue('1');
        // this.gaugedatastream.controls.min.setValue('0');

        this.basicData = {
          labels: this.chartlabelvalue,
          datasets: [
            {
              label: this.chartlabel,
              data: [this.Chartdatastream.value.Min, this.Chartdatastream.value.Max],
              fill: false,
              tension: 0.4,
              borderColor: documentStyle.getPropertyValue('--blue-500')
            }
          ]
        };
      }
    }


    this.myFormGroup = new FormGroup({ iconCss: this.iconCss });
  }
  onIconPickerSelect(icon: string): void {
    this.iconCss.setValue(icon);
  }

  onIconSelect(icon: string) {
    this.icon = icon;
  }
  // digital datastream

  Digital() {
    this.digitaldatastream.value.name = this.digitaldatastream.value.name?.replace(/[^\S\r\n]{2,}/g, ' ');
    this.digitaldatastream.value.alias = this.digitaldatastream.value.alias?.replace(/[^\S\r\n]{2,}/g, ' ');
    this.digitaldatastream.value.icon = this.iconCss.value;
    this.digitaldatastream.value.color = this.color;
    if (this.digitaldatastream.invalid) {
      return;
    } else {
      this.action = 'Createdatastream';
      this.dialogRef.close({
        event: this.action,
        data: this.digitaldatastream.value,
      });
      this.toastr.success('Digital Data feeds Created');
    }
  }

  //  Analog datastream

  Analog() {
    this.analogdatastream.value.name = this.analogdatastream.value.name?.replace(/[^\S\r\n]{2,}/g, ' ');
    this.analogdatastream.value.alias = this.analogdatastream.value.alias?.replace(/[^\S\r\n]{2,}/g, ' ');
    this.analogdatastream.value.icon = this.iconCss.value;
    this.analogdatastream.value.color = this.color;
    if (this.analogdatastream.invalid) {
      return;
    } else {
      this.action = 'Createdatastream';
      this.dialogRef.close({
        event: this.action,
        data: this.analogdatastream.value,
      });
      this.toastr.success('Analog Data feeds Created');
    }
  }

  // virtual pin datastream
  Virtual() {
    this.virtualpindatastream.value.name = this.virtualpindatastream.value.name?.replace(/[^\S\r\n]{2,}/g, ' ');
    this.virtualpindatastream.value.alias = this.virtualpindatastream.value.alias?.replace(/[^\S\r\n]{2,}/g, ' ');
    this.virtualpindatastream.value.icon = this.iconCss.value;
    this.virtualpindatastream.value.color = this.color;
    if (this.virtualpindatastream.invalid) {
      return;
    } else {
      this.action = 'Createdatastream';
      this.dialogRef.close({
        event: this.action,
        data: this.virtualpindatastream.value,
      });
      this.toastr.success('Virtual Data feeds Created');
    }
  }

  labeltoggle() {
    if (this.label == true) {
      this.label = false;
    } else {
      this.label = true;
    }
  }

  // remove datastream
  removedatastream() {
    this.switchdatastream.controls.datastream_Id.setValue('');
    this.pin = '';
    this.color = '';
    this.switchdatastream.controls.onlabel.setValue('');
    this.switchdatastream.controls.offlabel.setValue('');
    this.checked = false;
    this.leddatastream.controls.datastream_Id.setValue('');
    this.pin = '';
    this.color = '';
    this.sliderdatastream.controls.datastream_Id.setValue('');
    this.gaugedatastream.controls.datastream_Id.setValue('');
  }

  //selected datastream
  selecteddatastream(event: any) {
    // let datastream_Id = event.target.value;
    // this.dataservice.getsingledatastreamdata(datastream_Id).subscribe((data: any) => {
    //   this.data = (data);
    //   this.singledatastream = this.data.data[0];
    //   this.color = this.singledatastream.color;
    //   this.pin = this.singledatastream.pin;
    //   this.max = this.singledatastream.max;
    //   this.min = this.singledatastream.min;
    // })
  }

  // switch datastream create
  createswitch() {
    this.switchdatastream.value.title = this.switchdatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ')
    this.switchdatastream.value.color = this.color;
    this.switchdatastream.value.widget_Id = this.btn.widget_Id;
    this.action = 'CreateWidgetDatastream';

    this.dialogRef.close({
      event: this.action,
      data: this.switchdatastream.value,
      // pin: this.pin,
      // title: this.title,
    });
  }

  // led datastream create
  createled() {
    this.leddatastream.value.title = this.leddatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ')
    this.leddatastream.value.color = this.color;
    this.leddatastream.value.widget_Id = this.btn.widget_Id;
    this.action = 'CreateWidgetDatastream';
    this.dialogRef.close({
      event: this.action,
      data: this.leddatastream.value,
    });
  }

  // label datastream create
  createlabel() {
    this.labeldatastream.value.title = this.labeldatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ')
    this.labeldatastream.value.widget_Id = this.btn.widget_Id;
    this.action = 'CreateWidgetDatastream';
    this.dialogRef.close({
      event: this.action,
      data: this.labeldatastream.value,
    });
  }

  // slider datastream create
  createslider() {
    this.sliderdatastream.value.title = this.sliderdatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ')
    this.sliderdatastream.value.color = this.color;
    this.sliderdatastream.value.widget_Id = this.btn.widget_Id;
    this.action = 'CreateWidgetDatastream';
    this.dialogRef.close({
      event: this.action,
      data: this.sliderdatastream.value,
    });
  }

  // gauge datastream create
  creategauge() {
    this.gaugedatastream.value.title = this.gaugedatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ')
    this.gaugedatastream.value.color = this.color;
    this.gaugedatastream.value.widget_Id = this.btn.widget_Id;
    this.action = 'CreateWidgetDatastream';
    this.dialogRef.close({
      event: this.action,
      data: this.gaugedatastream.value,
    });
  }

  // terminal datastream create
  createterminal() {
    this.terminaldatastream.value.title = this.terminaldatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ')
    this.terminaldatastream.value.inputhint = this.terminaldatastream.value.inputhint?.replace(/[^\S\r\n]{2,}/g, ' ')
    this.terminaldatastream.value.textcolor = this.textcolor;
    this.terminaldatastream.value.screencolor = this.screencolor;
    this.terminaldatastream.value.widget_Id = this.btn.widget_Id;
    this.action = 'CreateWidgetDatastream';
    this.dialogRef.close({
      event: this.action,
      data: this.terminaldatastream.value,
    });
  }

  // chart datastream create 
  createchart() {
    this.action = 'CreateWidgetDatastream';
    this.Chartdatastream.value.title = this.Chartdatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');
    this.Chartdatastream.value.widget_Id = this.btn.widget_Id;
    this.dialogRef.close({
      event: this.action,
      data: this.Chartdatastream.value,
    });
    console.log(this.Chartdatastream.value)

  }

  // // update switch 
  // updateswitch() {
  //   this.action = 'UpdatewidgetDatastream';
  //   this.dialogRef.close({
  //     event: this.action,
  //     data: this.switchdatastream.value,
  //     pin: this.pin,
  //     title: this.title
  //   })
  // }

  // // update led
  // updateled() {
  //   this.action = 'UpdatewidgetDatastream';
  //   this.dialogRef.close({
  //     event: this.action,
  //     data: this.leddatastream.value,
  //     pin: this.pin,
  //     title: this.title
  //   })
  // }

  // // update label
  // updatelabel() {
  //   this.action = 'UpdatewidgetDatastream';
  //   this.dialogRef.close({
  //     event: this.action,
  //     data: this.labeldatastream.value,
  //     pin: this.pin,
  //     title: this.title
  //   })
  // }

  // // update slider 
  // updateslider() {

  //   this.action = 'UpdatewidgetDatastream';
  //   this.dialogRef.close({
  //     event: this.action,
  //     data: this.sliderdatastream.value,
  //     pin: this.pin,
  //     title: this.title
  //   })
  // }

  // // update gauge
  // updategauge() {
  //   this.action = 'UpdatewidgetDatastream';
  //   this.dialogRef.close({
  //     event: this.action,
  //     data: this.sliderdatastream.value,
  //     pin: this.pin,
  //     title: this.title
  //   })
  // }

  // //  update terminal
  // updateterminal() {
  //   this.action = 'UpdatewidgetDatastream';
  //   this.dialogRef.close({
  //     event: this.action,
  //     data: this.terminaldatastream.value,
  //     pin: this.pin,
  //     title: this.title
  //   })
  // }

  // create device Data
  createDeviceData() {
    if (this.devicedata.invalid) {
      return;
    } else {
      this.action = 'Createdevicedata';

      this.dialogRef.close({
        event: this.action,
        data: this.devicedata.value,
      });
    }
  }
  // create ssid and password
  createhotspot() {

    if (this.devicedata.invalid) {
      return;
    }
    else {
      this.action = 'Createhotspot';
      this.dialogRef.close({
        event: this.action,
        data: this.devicedata.value,
      });
    }
  }


  // create event
  eventcreate() {

    this.event.value.color = this.color;
    this.event.value.email_to = this.fruits.toString()
    if (this.event.invalid) {
      return;
    }
    else {

      this.action = 'CreateEvent';
      this.dialogRef.close({ event: this.action, data: this.event.value })
      this.toastr.success("Event Created")


    }
  }

  //update event 
  eventUpdate() {
    this.event.value.color = this.color;
    this.event.value.email_to = this.fruits.toString()
    if (this.event.invalid) {
      return;
    }
    else {

      this.action = 'UpdateEvent';
      this.dialogRef.close({ event: this.action, data: this.event.value })
      this.toastr.success("Event Updated")


    }

  }

  // create device Data
  updateDeviceData() {
    if (this.devicedata.invalid) {
      return;
    } else {
      if (this.editdatastream.meta_key == "SSID") {
        this.devicedata.value.meta_key = "SSID"
      }
      if (this.editdatastream.meta_key == "Password") {
        this.devicedata.value.meta_key = "Password"
      }
      this.action = 'Updatedevicedata';
      this.dialogRef.close({
        event: this.action,
        data: this.devicedata.value,
      });
    }
  }

  // datastream update
  UpdateDigital() {
    this.digitaldatastream.value.icon = this.iconCss.value;
    this.digitaldatastream.value.color = this.color;
    if (this.digitaldatastream.invalid) {
      return;
    } else {
      this.action = 'Updatedatastream';
      this.dialogRef.close({
        event: this.action,
        data: this.digitaldatastream.value,
      });
    }
  }

  // update Analog

  UpdateAnalog() {
    this.analogdatastream.value.icon = this.iconCss.value;
    this.analogdatastream.value.color = this.color;
    if (this.analogdatastream.invalid) {
      return;
    } else {
      this.action = 'Updatedatastream';
      this.dialogRef.close({
        event: this.action,
        data: this.analogdatastream.value,
      });
    }
  }


  // update Virutal
  UpdateVirtual() {
    this.virtualpindatastream.value.icon = this.iconCss.value;
    this.virtualpindatastream.value.color = this.color;
    if (this.virtualpindatastream.invalid) {
      return;
    } else {
      this.action = 'Updatedatastream';
      this.dialogRef.close({
        event: this.action,
        data: this.virtualpindatastream.value,
      });
    }
  }
  // MATDIALOG CLOSE
  MatdialogClose() {
    this.action = 'Close';
    this.dialogRef.close({ event: this.action });
    this.matdialog.closeAll();
  }

  toggleChanges(event: any) {
    this.checked = event.source.checked;
  }
  createdatastream(event: any) {
    this.show = false;
    this.create = true;
    this.name = event.srcElement.innerText;
    const MatDialogConfig = this.matdialog.open(widgetdatastream, {
      disableClose: true,
      position: { left: '335px', top: '290px' },
      width: '420px',
      hasBackdrop: false,
      data: { name: this.name, hardware: this.hardware },
    });
    MatDialogConfig.afterClosed().subscribe((result: any) => {
      if (result.event == 'Createwidgetdatastream') {
        this.show = true;
        this.create = false;
        this.switchdatastream.controls.datastream_Id.setValue(
          result.data.datastream_Id
        );
        this.datastreamdata.push(result.data);
      }
      if (result.event == 'Close') {
        this.create = false;
        this.show = true;
      }
    });
  }
}

// create widget popup then create datastream popup
@Component({
  selector: 'widgetdatastream',
  templateUrl: 'widgetdatastream.html',
  styleUrls: ['popup.css'],
})
export class widgetdatastream implements OnInit {
  name: any;
  color = '#3633b7';
  Digitalpin: any[] = [];
  AnalogpinOut: any[] = [

  ];
  AnalogpinIn: any[] = [];
  Virtualpin: any[] = [

  ];
  pinmode = ['Output', 'Input', 'Input pull up', 'input pull down'];
  datastream_type = ['Integer', 'Double', 'String'];
  decimallist: any[] = [
    '#',
    '#.#',
    '#.##',
    '#.###',
    '#.####',
    '#.#####',
    '#.0',
    '#.00',
    '#.000',
    '#.0000',
    '#.00000',
  ];
  icons: string[] = ['fa', 'bs', 'fa5', 'mat', 'all'];
  datastreamall: object[] = [];
  datastreamdata: any[] = [];
  myFormGroup!: FormGroup;
  iconCss = new FormControl();
  fallbackIcon = 'fas fa-cube';
  unit: any;
  units: any;
  action: any;

  // digitaldatastream form
  digitaldatastream = this.formbuilder.group({
    datastream_name: ['Digital'],
    datastream_Id: ['0'],
    datastream_type: ['Integer'],
    icon: [''],
    name: ['Digital 0', Validators.required],
    alias: ['Digital 0', Validators.required],
    color: [''],
    pin: ['', Validators.required],
    pinmode: ['', Validators.required],
    decimal_value: [''],
  });

  state = false;
  hardware: any;
  changeState() {
    this.state = !this.state;
  }
  // Analog datastream form
  analogdatastream = this.formbuilder.group({
    datastream_name: ['Analog'],
    datastream_Id: ['0'],
    datastream_type: ['Integer'],
    icon: [''],
    name: ['Analog A0', Validators.required],
    alias: ['Analog A0', Validators.required],
    color: [''],
    pin: ['', Validators.required],
    pinmode: ['', Validators.required],
    units: ['', Validators.required],
    min: ['0', Validators.required],
    max: ['1', Validators.required],
    default_value: ['0', Validators.required],
    decimal_value: [''],
  });

  // Virtual pin form
  virtualpindatastream = this.formbuilder.group({
    datastream_name: ['Virtual pin'],
    datastream_Id: ['0'],
    icon: [''],
    name: ['Integer V0', Validators.required],
    alias: ['Integer V0', Validators.required],
    color: [''],
    pin: ['', Validators.required],
    datastream_type: ['', Validators.required],
    units: ['', Validators.required],
    min: ['0', Validators.required],
    pinmode: [''],
    max: ['1', Validators.required],
    decimal_value: ['', Validators.required],
    default_value: ['0', Validators.required],
  });

  constructor(
    private formbuilder: FormBuilder,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<datastreampopup>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.digitaldatastream.controls.pin.setValue('0', { onlySelf: true });
    this.digitaldatastream.controls.pinmode.setValue('Output', {
      onlySelf: true,
    });

    this.analogdatastream.controls.pin.setValue('32', { onlySelf: true });
    this.analogdatastream.controls.pinmode.setValue('Output', {
      onlySelf: true,
    });
    this.analogdatastream.controls.units.setValue('None', { onlySelf: true });

    this.virtualpindatastream.controls.pin.setValue('V0', { onlySelf: true });
    this.virtualpindatastream.controls.units.setValue('None', {
      onlySelf: true,
    });
    this.virtualpindatastream.controls.datastream_type.setValue('Integer', {
      onlySelf: true,
    });
    this.virtualpindatastream.controls.decimal_value.setValue('#.00000', {
      onlySelf: true,
    });
  }

  ngOnInit(): void {
    this.dataservice.getdatastreamunits().subscribe((data: any) => {
      this.unit = data;
      this.units = this.unit.data;
    });

    this.name = this.data.name;
    this.hardware = this.data.hardware;

    this.dataservice.hardwaredatastreampin(this.hardware).subscribe((res: any) => {
      if (res.status == "200") {
        console.log(res.data)
        this.data = res.data;
        this.data.map((x: any) => {
          if (x.datastream_name == "Digital") {
            this.Digitalpin = x.input.split(',').map(Number)

          }
          if (x.datastream_name == "Analog") {
            this.AnalogpinIn = x.input.split(',').map(String)
            this.AnalogpinOut = x.output.split(',').map(String)

          } if (x.datastream_name == "Virtual") {
            this.Virtualpin = x.input.split(',').map(String)
          }
        })

        this.digitaldatastream.controls.pin.setValue(this.Digitalpin[0], { onlySelf: true });
        this.digitaldatastream.controls.pinmode.setValue('Output', {
          onlySelf: true,
        });

        this.analogdatastream.controls.pin.setValue(this.AnalogpinOut[0], { onlySelf: true });
        this.analogdatastream.controls.pinmode.setValue('Output', {
          onlySelf: true,
        });
        this.analogdatastream.controls.units.setValue('None', { onlySelf: true });

        this.virtualpindatastream.controls.pin.setValue(this.Virtualpin[0], { onlySelf: true });
        this.virtualpindatastream.controls.units.setValue('None', {
          onlySelf: true,
        });
        this.virtualpindatastream.controls.datastream_type.setValue('Integer', {
          onlySelf: true,
        });
        this.virtualpindatastream.controls.decimal_value.setValue('#.00000', {
          onlySelf: true,
        });
      } else {
        this.toastr.error("error occured")
      }
    })

  }

  onIconPickerSelect(icon: string): void {
    this.iconCss.setValue(icon);
  }

  // digital datastream

  Digital() {
    this.digitaldatastream.value.icon = this.iconCss.value;
    this.digitaldatastream.value.color = this.color;
    if (this.digitaldatastream.invalid) {
      return;
    } else {
      this.action = 'Createwidgetdatastream';
      this.dialogRef.close({
        event: this.action,
        data: this.digitaldatastream.value,
      });
    }
  }

  //  Analog datastream

  Analog() {
    this.analogdatastream.value.icon = this.iconCss.value;
    this.analogdatastream.value.color = this.color;
    if (this.analogdatastream.invalid) {
      return;
    } else {
      this.action = 'Createwidgetdatastream';
      this.dialogRef.close({
        event: this.action,
        data: this.analogdatastream.value,
      });
    }
  }

  // virtual pin datastream
  Virtual() {
    this.virtualpindatastream.value.icon = this.iconCss.value;
    this.virtualpindatastream.value.color = this.color;

    if (this.virtualpindatastream.invalid) {
      return;
    } else {
      this.action = 'Createwidgetdatastream';
      this.dialogRef.close({
        event: this.action,
        data: this.virtualpindatastream.value,
      });
      this.toastr.success('Datastream Created');
    }
  }
  MatdialogClose() {
    this.action = 'Close';
    this.dialogRef.close({ event: this.action });
  }
}



// webzone import devices
@Component({
  selector: 'webzonepopup',
  templateUrl: 'webzonepopup.html',
  styleUrls: ['popup.css'],
})

export class webzonepopup implements OnInit {
  webzonevalue: any;

  id: any;
  action: any;

  webzone = this.formbuilder.group({
    Webzone_Id: ['', Validators.required]
  })

  constructor(private formbuilder: FormBuilder,
    private toastr: ToastrService,
    private dataservice: Dataservice,
    private authentication: AuthenticationService,
    private matdialog: MatDialog, public dialogRef: MatDialogRef<ProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    this.webzone.controls.Webzone_Id.valueChanges.subscribe(x => {
      console.log(x)
    })


    const data = this.authentication.getUserData()
    this.dataservice.getwebzonedata(data).subscribe((res: any) => {
      console.log(res)
      if (res.status == '200') {
        this.data = res.data;
        this.webzonevalue = this.data;
      } else {
        this.toastr.error('Error occurred');
      }
    });
  }


  import() {
    if (this.webzone.invalid) {
      return;
    }
    else {
      this.action = 'importwebzone'
      this.dialogRef.close({
        event: this.action,
        data: this.webzone.value.Webzone_Id,
      });
      this.toastr.success('Web Zone Imported');
    }

  }

  // MATDIALOG CLOSE
  MatdialogClose() {
    this.action = 'Close';
    this.dialogRef.close({ event: this.action });
    this.matdialog.closeAll();
  }



}



// cluster dashboard 
@Component({
  selector: 'clusterdashboard',
  templateUrl: 'clusterdashboard.html',
  styleUrls: ['./product.component.css'],
})
export class clusterdashboard implements OnInit {
  product_id: any;
  productdata: any;
  productname: any;
  clusterdahsboardData: any = [];
  kwh!: number;
  currenttime: any;
  deviceonline!: string;
  device_id: any;
  loading!: boolean;
  devicedashboard: any[] = []
  getdashboard: any;
  cOSTIndices: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;

  constructor(private route: ActivatedRoute, private router: Router, private authentication: AuthenticationService, private dataservice: Dataservice, private toastr: ToastrService,) {

    this.loading = true;
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[5];
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })
  }

  ngOnInit(): void {
    this.product_id = this.route.snapshot.paramMap.get('id')
    this.getDashboardData()
    // Initial data fetch
    this.getdashboard = setInterval(() => {
      this.getDashboardData(); // Fetch data every minute
    }, 60000);
    this.dataservice.getproductdata(this.product_id).subscribe((res: any) => {
      if (res.status == "0") {
        this.router.navigate(['/app/cluster'])
      }
      else if (res.status == '200') {
        this.productdata = res.data;
        this.productname = this.productdata.cluster_name;
      }
    });
  }


  getDashboardData() {
    this.dataservice.GetClusterDashboard(this.product_id).subscribe((res: any) => {
      this.loading = false;
      if (res.status == '401') {
        this.clusterdahsboardData = []
      }
      if (res.status == '200') {
        if (this.clusterdahsboardData.length > 0) {
          this.clusterdahsboardData.map((x: any) => {
            const find = res.data.find((y: any) =>
              y.device_id == x.device_id
            )
            if (find) {
              if (x.V1?.kwh_thismonth != find.V1?.kwh_thismonth) {
                x.V1.kwh_thismonth = find.V1.kwh_thismonth
              }
              if (x.V1.current_kwh != find.V1.current_kwh) {
                x.V1.current_kwh = find.V1.current_kwh
              }
              if (x?.cost?.toFixed(1) != find.cost.toFixed(1)) {
                x.cost = find.cost
              }
            }
            this.device_id = x.device_id
            this.currenttime = new Date();
            const timeDifference = +this.currenttime - +new Date(x.last_online);
            if (timeDifference > (x.heartbeat * 100)) {
              x.deviceonline = "#e74c3c";
            } else if (timeDifference < (x.heartbeat * 100)) {
              x.deviceonline = "#2ecc71";
            }
          })
        } else if (this.clusterdahsboardData.length === 0) {
          this.clusterdahsboardData = res.data;
          this.clusterdahsboardData.map((x: any) => {
            if (Number(x.heartbeat) > 20) {
              x.heartbeat = +Number(x.heartbeat) + 5
              x.heartbeat = Number(x.heartbeat) * 12.5
            }
            {
              if (x.V7 == null) {
                x.V7 = 0;
              }
              this.device_id = x.device_id
              this.currenttime = new Date();

              const timeDifference = +this.currenttime - +new Date(x.last_online);

              if (timeDifference > (x.heartbeat * 100)) {
                x.deviceonline = "#e74c3c";
              } else if (timeDifference < (x.heartbeat * 100)) {
                x.deviceonline = "#2ecc71";
              }
            }
          })

        }



      }
    })
  }

  gotodeviceDB(item: any) {
    console.log(item);
    this.router.navigate(['/app/dashboard', item, this.product_id]);
  }
  back() {
    this.router.navigate(['/app/cluster'])
  }
  info() {
    this.router.navigate(['/app/clusterinfo', this.product_id])
  }
  isCardHovered: boolean = false;

  onCardHover(isHovered: boolean) {
    this.isCardHovered = isHovered;
  }

  // async GetRoutes(product_Id: any) {
  //   await this.dataservice.getRoutes(product_Id).subscribe((res: any) => {


  //     if (res.status == '200') {

  //       // Extract device dashboard configuration from the response
  //       if (res?.data[1].length >= 1) {
  //         let device_dashboard: any = res?.data[1][0].device_d_data;
  //         // Parse the JSON data from device_dashboard

  //         device_dashboard = JSON.parse(device_dashboard);
  //         this.devicedashboard = device_dashboard;
  //         this.cOSTIndices = this.devicedashboard
  //           .map((x, i) => ({ widget: x }))
  //           .filter(
  //             (item) =>
  //               item.widget.widgetname === 'Cost' && item.widget.pin === 'V1'
  //           )
  //           .map((item) => item.widget);
  //         this.cOSTIndices = this.cOSTIndices[0];
  //         this.getDashboardData();
  //       } else {
  //         this.getDashboardData();
  //       }
  //     } else {
  //       this.toastr.error('Error occured');
  //     }
  //   });

  // }




  ngOnDestroy() {
    clearInterval(this.getdashboard);

  }
}