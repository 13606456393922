import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { changepassword } from '../account/account.component';
import { Dataservice } from '../services/dataservice';
import { createuser, useredit } from '../user/user.component';
import { FormBuilder } from '@angular/forms';
import { deletepopup } from '../mydevices/mydevices.component';
import { logout } from '../menu/menu.component';
import { AuthenticationService } from '../Authentication/authentication.service';
import { MatCheckbox } from '@angular/material/checkbox';


/**
 * Component for managing user roles and permissions.
 */
@Component({
  selector: 'app-verticalmembers',
  templateUrl: './verticalmembers.component.html',
  styleUrls: ['./verticalmembers.component.css']
})
export class VerticalmembersComponent implements OnInit {

  isAdmin = true;
  isUser = true;
  isStaff = true;
  user_id: any;
  userdata: any;
  loading: boolean;
  id: any;
  filterTerm!: string;
  currenttime = new Date()
  isAuthenticated: boolean;
  checked: boolean;
  allselect: boolean;
  activatedRoute = '';
  data: any;

  user_Id: any[] = [];
  uniqueRollName: any;
  uniqueorgName: any;
  uniqueverticalname: any[] = [];
  selectedCategoriesroll = new Set();

  selectedCategoriesvertical = new Set();
  selectedCategoriesstatus = new Set();
  selectedTabIndex = 0;
  filterUservalue: any;
  uniquestatus: any;
  userdevice: any;
  deviceconfig: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  config: { id: string; itemsPerPage: number; currentPage: number; };
  my_id: any;
  donotclearValue!: boolean;
  current_userId: any;
  suspenduser!: boolean;
  editrole!: boolean;
  page: any = "myverticaluser";
  uservalue: any[] = []
  /**
    * Initialize the UserComponent.
    * @param matdialog - The MatDialog service for opening dialogs.
    * @param router - The Router for navigating to different routes.
    * @param dataservice - The Dataservice for fetching data.
    * @param formbuilder - The FormBuilder for building forms.
    * @param toastr - The ToastrService for displaying toast messages.
    * @param authentication - The AuthenticationService for handling authentication.
    */
  constructor(public matdialog: MatDialog,
    private router: Router,
    private dataservice: Dataservice,
    private formbuilder: FormBuilder,
    private toastr: ToastrService, private authentication: AuthenticationService) {
    this.loading = false;
    this.isAuthenticated = false;
    this.config = {
      id: "user-table",
      itemsPerPage: 6,
      currentPage: 1,
    };
    this.checked = false;
    this.allselect = false;
    // Get user roles from authentication service
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[3];
    // Map user roles to permissions
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })

  }


  showall() {
    this.config.itemsPerPage = this.data.length;
    this.config.currentPage = 1;
  }

  collapse() {
    this.config.itemsPerPage = 6;
    this.config.currentPage = 1;
  }
  /**
  * Lifecycle hook called after component initialization.
  */
  ngOnInit(): void {
    // Notify data services about device status updates
    this.dataservice.MyDevicestatus.next()
    this.dataservice.AllDevicestatus.next()
    this.dataservice.singleDevicestatus.next()
    this.donotclearValue = false
    // Initialize pagination state

    this.dataservice.setPaginationState(null, 'allverticaluser');
    this.dataservice.setPaginationState(null, 'mydevice');
    this.dataservice.setPaginationState(null, 'alldevice');
    const paginationState: any = this.dataservice.getPaginationState(this.page);

    if (paginationState) {
      this.config.currentPage = paginationState
    }
    this.loading = true;
    const data = this.authentication.getUserData();
    this.my_id = data.user_Id
    // Fetch user's vertical data
    this.getmyverticaluser(data);
    // Subscribe to Userrefresh events
    this.dataservice.Userrefresh.subscribe((response: any) => {
      this.getmyverticaluser(data)
    })



  }
  /**
    * Fetch user's vertical data and perform necessary actions.
    * @param data - The user's data.
    */
  async getmyverticaluser(data: any) {
    let filterhistroy: any = null;
    filterhistroy = await this.dataservice.Getfilter("Myuser");
    if (filterhistroy == undefined) {
      filterhistroy = null
    }
    // Fetch and manage user's vertical data
    this.dataservice.getmyverticaluser(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == "200") {
        // Process and update data for rendering
        this.data = res.data;
        // Extract user IDs, roles, org names, vertical names, and status
        this.current_userId = this.data[0].user_Id

        let UnroleUser = this.data.filter((x: any) => {
          return !x.role_name
        })
        let RoleUser = this.data.filter((x: any) => {
          return x.role_name
        })

        this.data = [...RoleUser, ...UnroleUser];
        this.uservalue = this.data;
        // Extract unique role names, org names, vertical names, and statuses
        this.uniqueRollName = this.uservalue.filter(
          (x: any, y: any, z: any) => {
            return y === z.findIndex((t: any) => t.role_name === x.role_name);
          }


        ).map((x: any) =>
        ({
          ...x,
          ischecked: true
        })
        );
        this.uniquestatus = this.uservalue.filter(
          (x: any, y: any, z: any) => {
            return y === z.findIndex((t: any) => t.isactive === x.isactive);
          }
        ).map((x: any) =>
        ({
          ...x,
          ischecked: true
        })
        );



        this.uniqueverticalname = this.uservalue.filter(
          (x: any, y: any, z: any) => {
            return y === z.findIndex((t: any) => t.vertical_name === x.vertical_name);
          }
        ).map((x: any) =>
        ({
          ...x,
          ischecked: true
        })
        );
        // Populate selected categories sets
        this.uniqueRollName.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.userrole.size >= 1) {
              if (filterhistroy.userrole.has(x.role_name)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedCategoriesroll = filterhistroy.userrole;
            } else {
              this.selectedCategoriesroll.add(x.role_name)
            }

          } else {
            this.selectedCategoriesroll.add(x.role_name)
          }

        })

        this.uniqueverticalname.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.vertical.size >= 1) {
              if (filterhistroy.vertical.has(x.vertical_name)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedCategoriesvertical = filterhistroy.vertical;
            } else {
              this.selectedCategoriesvertical.add(x.vertical_name)
            }
          } else {
            this.selectedCategoriesvertical.add(x.vertical_name)
          }
        })
        this.uniquestatus.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.userstatus.size >= 1) {
              if (filterhistroy.userstatus.has(x.isactive)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedCategoriesstatus = filterhistroy.userstatus;
            } else {
              this.selectedCategoriesstatus.add(x.isactive)
            }
          } else {
            this.selectedCategoriesstatus.add(x.isactive)
          }

        });
        // Create filtered user values with selection state
        this.filterUservalue = this.uservalue.map((x: any) => {
          return {
            ...x,
            isSelected: false
          }
        })
        this.filterUservalue = this.filterUservalue.filter((x: any) =>
          (this.selectedCategoriesstatus.has(x.isactive) && this.selectedCategoriesvertical.has(x.vertical_name)) && this.selectedCategoriesroll.has(x.role_name)
        )
      }
      else {
        // Handle error
        this.toastr.error("Error occurred");
      }
    });
  }

  /**
    * Initialize the component and create the form group.
    * @param formbuilder - The FormBuilder instance for creating form controls and groups.
    */
  // Create the form group with initial values
  formGroup = this.formbuilder.group({
    checked: JSON.parse('true')
  })
  /**
  * Filters user data based on role name and updates the selected categories.
  * 
  * @param user - The user data being filtered by role name.
  * @param event - The event triggered by checking/unchecking a role name filter.
  */
  filterroleName(user: any, event: any) {
    this.user_Id = [];
    this.allselect = false;
    this.config.currentPage = 1;
    while (this.user_Id.length > 0) {
      this.user_Id.pop();
    }
    // Update selected categories based on event
    if (event.checked == true) {
      this.selectedCategoriesroll.add(user);
    } else {
      this.selectedCategoriesroll.delete(user);
    }
    // Update filterUservalue based on selected categories
    if (this.selectedCategoriesroll.size >= 1) {
      this.filterUservalue = this.uservalue
        .filter((x: any) => {
          // Condition for selectedCategoriesvertical
          if (this.selectedCategoriesvertical.size >= 1) {
            return this.selectedCategoriesvertical.has(x.vertical_name);
          }
          return x; // No filter applied if the size is less than 1
        })
        .filter((x: any) => {
          // Condition for selectedCategoriesstatus
          if (this.selectedCategoriesstatus.size >= 1) {
            return this.selectedCategoriesstatus.has(x.isactive);
          }
          return x; // No filter applied if the size is less than 1
        })
        .filter((x: any) => {
          // Condition for selectedCategoriesroll
          if (this.selectedCategoriesroll.size >= 1) {
            return this.selectedCategoriesroll.has(x.role_name);
          }
          return x; // No filter applied if the size is less than 1
        })
        .map((x: any) => ({
          ...x,
          isSelected: false
        }));
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;

      this.uniqueverticalname.map((x: any) => {
        const Find = this.filterUservalue.some((y: any) => y.vertical_name == x.vertical_name);
        if (!Find) {
          x.ischecked = false;
          this.selectedCategoriesvertical.delete(x.vertical_name)
        } else {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.vertical_name)
        }
      })


    }
    else {
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;

      // Reset filterUservalue for all users
      this.filterUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));;
    }

    if (this.filterUservalue.length <= 6) {
      this.onFilterTermChange()
    }
  }
  /**
 * Filters user data based on status and updates the selected status categories.
 * 
 * @param user - The user data being filtered by status.
 * @param event - The event triggered by checking/unchecking a status filter.
 */
  filterstatus(user: any, event: any) {
    this.user_Id = [];
    this.allselect = false;
    this.config.currentPage = 1;
    while (this.user_Id.length > 0) {
      this.user_Id.pop();
    }
    // Update selected status categories based on event
    if (event.checked == true) {
      this.selectedCategoriesstatus.add(user);
    } else {
      this.selectedCategoriesstatus.delete(user);
    }
    // Update filterUservalue based on selected status categories
    if (this.selectedCategoriesstatus.size >= 1) {

      this.filterUservalue = this.uservalue
        .filter((x: any) => {
          // Condition for selectedCategoriesvertical
          if (this.selectedCategoriesvertical.size >= 1) {
            return this.selectedCategoriesvertical.has(x.vertical_name);
          }
          return x; // No filter applied if the size is less than 1
        }).filter((x: any) => {
          // Condition for selectedCategoriesroll
          if (this.selectedCategoriesroll.size >= 1) {
            return this.selectedCategoriesroll.has(x.role_name);
          }
          return x; // No filter applied if the size is less than 1
        })
        .filter((x: any) => {
          // Condition for selectedCategoriesstatus
          if (this.selectedCategoriesstatus.size >= 1) {
            return this.selectedCategoriesstatus.has(x.isactive);
          }
          return x; // No filter applied if the size is less than 1
        })
        .map((x: any) => ({
          ...x,
          isSelected: false
        }));

      //   this.filterUservalue = this.uservalue.filter((x: any) =>
      //       this.selectedCategoriesvertical.has(x.vertical_name)
      //   ).filter((x: any) =>
      //     this.selectedCategoriesroll.has(x.role_name)

      //   ).filter((x: any) =>
      //     this.selectedCategoriesstatus.has(x.isactive)
      //  ).map((x: any) => ({ ...x, isSelected: false }));
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;
      this.uniqueverticalname.map((x: any) => {
        const Find = this.filterUservalue.some((y: any) => y.vertical_name == x.vertical_name);
        if (!Find) {
          x.ischecked = false;
          this.selectedCategoriesvertical.delete(x.vertical_name)
        } else {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.vertical_name)
        }
      })

    }
    else {
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;
      // Clear user_Id array

      // Reset filterUservalue for all users
      this.filterUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));;
    }
  }

  SelectallFilters(event: any, eventname: any) {

    if (event) {
      if (eventname == 'status') {
        this.selectedCategoriesstatus.clear()
        let config = { checked: true };
        this.uniquestatus = this.uniquestatus.map((x: any) => {
          x.ischecked = true;
          this.selectedCategoriesstatus.add(x.isactive);
          // this.filterstatus(x.isactive, config);
          return { ...x }
        })
        this.filterUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));
      } else if (eventname == 'role') {
        this.selectedCategoriesroll.clear();
        let config = { checked: true };
        this.uniqueRollName = this.uniqueRollName.map((x: any) => {
          x.ischecked = true;
          this.selectedCategoriesroll.add(x.role_name);
          // this.filterroleName(x.role_name, config);
          return { ...x }
        })
        this.filterUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));;
      } else if (eventname == 'vertical') {
        this.selectedCategoriesvertical.clear();
        let config = { checked: true };
        this.uniqueverticalname = this.uniqueverticalname.map((x: any) => {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.vertical_name);
          // this.filterVerticalName(x.vertical_name, config);
          return { ...x }
        })
        this.filterUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));;
      }

    } else {
      if (eventname == 'status') {
        this.selectedCategoriesstatus.clear()
        let config = { checked: false };
        this.uniquestatus = this.uniquestatus.map((x: any) => {
          x.ischecked = false;

          this.filterstatus(x.isactive, config);
          return { ...x }
        })
      } else if (eventname == 'role') {
        this.selectedCategoriesroll.clear();
        let config = { checked: false };
        this.uniqueRollName = this.uniqueRollName.map((x: any) => {
          x.ischecked = false;

          this.filterroleName(x.role_name, config);
          return { ...x }
        })
      } else if (eventname == 'vertical') {
        this.selectedCategoriesvertical.clear()
        let config = { checked: false };
        this.uniqueverticalname = this.uniqueverticalname.map((x: any) => {
          x.ischecked = false;

          this.filterVerticalName(x.vertical_name, config);
          return { ...x }
        })
      }
    }

  }

  /**
  * Filters user data based on vertical name and updates the selected vertical name categories.
  * 
  * @param user - The user data being filtered by vertical name.
  * @param event - The event triggered by checking/unchecking a vertical name filter.
  */
  filterVerticalName(vertical: any, event: any) {
    this.user_Id = [];
    this.allselect = false;
    this.config.currentPage = 1;
    // Clear user_Id array
    while (this.user_Id.length > 0) {
      this.user_Id.pop();
    }
    // Update selected vertical name categories based on event
    if (event.checked == true) {
      this.selectedCategoriesvertical.add(vertical);
    } else {
      this.selectedCategoriesvertical.delete(vertical);
    }
    // Update filterUservalue based on selected vertical name categories
    if (this.selectedCategoriesvertical.size >= 1) {
      this.filterUservalue = this.uservalue.filter((x: any) =>
        this.selectedCategoriesvertical.has(x.vertical_name)

      ).map((x: any) => ({ ...x, isSelected: false }));;
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;
      this.selectedCategoriesstatus.clear();
      this.selectedCategoriesroll.clear();
      this.uniqueRollName = this.filterUservalue.filter(
        (x: any, y: any, z: any) => {
          return y === z.findIndex((t: any) => t.role_name === x.role_name);
        }


      ).map((x: any) =>
      ({
        ...x,
        ischecked: true
      })
      );
      this.uniquestatus = this.filterUservalue.filter(
        (x: any, y: any, z: any) => {
          return y === z.findIndex((t: any) => t.isactive === x.isactive);
        }
      ).map((x: any) =>
      ({
        ...x,
        ischecked: true
      })
      );
      this.uniquestatus.map((x: any) => {


        this.selectedCategoriesstatus.add(x.isactive)


      });

      this.uniqueRollName.map((x: any) => {

        this.selectedCategoriesroll.add(x.role_name)


      })


    }
    else {
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;
      // Reset filterUservalue for all users
      this.filterUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));;
    }
  }

  /**
 * Resets the user vertical filter and selected vertical categories.
 */


  /**
 * Initiates the logout process by opening a confirmation dialog and handling the result.
 */
  logout() {
    // Configure the dialog
    const MatDialogConfig = this.matdialog.open(logout, { disableClose: true })
    // Subscribe to the dialog's afterClosed event
    MatDialogConfig.afterClosed().subscribe((result: any) => {
      if (result.event == "confirm") {
        // Clear local storage and navigate to the signin page
        localStorage.clear()
        this.router.navigate(['/app/signin']);
      }
    })

  }

  /**
   * Handles the page change event and updates the current page configuration.
   * 
   * @param event - The event containing information about the new page.
   */
  pageChanged(event: any) {
    // Update the current page configuration
    this.config.currentPage = event;
    this.dataservice.setPaginationState(event, this.page)
  }
  /**
   * Handles changes in the filter term and updates the current page configuration.
   * Useful for updating the page number when the filter term changes.
   */

  onFilterTermChange() {
    // Update the current page configuration to the first page
    this.config.currentPage = 1;
  }

  userRemove(user_Id: any) {
    // Configure the dialog
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "removeuser"
      });
      // Subscribe to the dialog's afterClosed event
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation) {
          console.log(user_Id)
          // Clear local storage and navigate to the signin page
          this.dataservice.Removeuser(user_Id).subscribe((res: any) => {
            if (res.status == '201') {
              this.toastr.success("User Removed");
            } else {
              this.toastr.error("Error occurred");
            }
          })
        }
      })
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }


  }

  /**
 * Handles the suspension of a user.
 * 
 * @param user_Id - The ID of the user to be suspended.
 */

  suspendeduser(user_Id: any) {
    // Check if the user has the 'create' permission
    if (this.iscreate == true) {
      // Open a confirmation dialog
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "suspendeduser"
      });
      // Subscribe to the dialog's afterClosed event
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          // Get user's role and create a data object
          const user = this.authentication.getUserData()
          let role = user.role;
          const data = { role, user_Id };
          // Call the dataservice to suspend the user
          this.dataservice.suspendeduser(data).subscribe((res: any) => {
            if (res.status == "200") {
              this.toastr.info("User not a permission");
            }
            if (res.status == "201") {
              this.toastr.success("User Suspended");
            }
            else {
              this.toastr.error("Error occurred");
            }
          })
        }
      });
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }



  }
  /**
   * Handles the page change event and updates the current page configuration.
   * 
   * @param event - The event containing information about the new page.
   */

  pageChanged1(event: any) {
    // Update the current page configuration
    this.config.currentPage = event;
  }

  /**
   * Handles activating a user.
   * 
   * @param user_Id - The ID of the user to be activated.
   */
  activeuser(user_Id: any) {
    // Check if the user has the 'create' permission
    if (this.iscreate == true) {
      // Open a confirmation dialog
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "activeuser"
      });
      // Subscribe to the dialog's afterClosed event
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          // Get user's role and create a data object
          const user = this.authentication.getUserData()
          let role = user.role;
          const data = { role, user_Id }
          // Call the dataservice to activate the user
          this.dataservice.activeuser(data).subscribe((res: any) => {
            if (res.status == "200") {
              this.toastr.info("User not a permission");
            }
            if (res.status == "201") {
              this.toastr.success("User Active");
            } else {
              this.toastr.error("Error occurred");
            }
          })
        }
      });
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }



  }


  /**
   * Opens a dialog to allow the user to change their password.
   */

  changepassword() {
    // Open a dialog for changing the password
    const MatDialogConfig = this.matdialog.open(changepassword, { disableClose: true })
  }

  /**
 * Displays user information and related data.
 * 
 * @param id - The ID of the user to retrieve information for.
 */
  info(id: any) {
    // Set the user_id for further processing
    this.user_id = id;

    if (this.user_id === this.my_id) {
      this.suspenduser = true
    } else {
      this.suspenduser = false
    }
    // Prepare data for retrieving user information
    const data = { "user_Id": id };
    // Get user data and handle the response
    this.dataservice.getuserdata(data).subscribe((res: any) => {
      if (res.status == "200") {
        this.userdata = res.data;
      }
      else {
        this.toastr.error("Error occurred");
      }
    });
    // Get user's device information
    this.getuserdevice(id)
  }

  /**
 * Retrieves and handles user device data.
 * 
 * @param id - The ID of the user to retrieve device data for.
 */
  getuserdevice(id: any) {
    // Get the logged-in user's data
    const user = this.authentication.getUserData()
    // Prepare data for retrieving user device information
    const user_Id = id;
    let role = user.role;
    const org_Id = user.org_Id;
    const data = { user_Id, org_Id, role };
    // Get user's device data and handle the response
    this.dataservice.getmydevicedata(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == '200') {
        this.data = res.data;
        this.userdevice = this.data;

        // setInterval(() => {
        //   let i;
        //   for (i = 0; i < this.userdevice?.length; i++) {
        //     this.userdevice[i].last_online = this.last_online[i];
        //   }
        // }, 0.5);
      } else {
        this.toastr.error('Error occurred');
      }
    });
  }


  /**
 * Initiates the process of inviting a new user.
 * 
 * If the user has permission to create users, a dialog for creating a new user is opened.
 * If the user does not have permission, an informational message is displayed.
 */
  inviteuser() {
    if (this.iscreate == true) {
      // Open a dialog for creating a new user
      const MatDialogConfig = this.matdialog.open(createuser, { disableClose: true })
    }
    else if (this.iscreate == false) {
      // Display an informational message if user does not have permission
      this.toastr.info("User not permitted")
    }
  }


  /**
 * Initiates the process of editing a user's information.
 * 
 * If the user has permission to edit users, a dialog for editing the user's information is opened.
 * If the user does not have permission, an informational message is displayed.
 */
  edituser() {
    if (this.iscreate == true) {
      // Open a dialog for editing user information, passing the user data as data
      const MatDialogConfig = this.matdialog.open(useredit, { disableClose: true, data: this.userdata })
    } else if (this.iscreate == false) {
      // Display an informational message if user does not have permission
      this.toastr.info("User not permitted")
    }
  }


  /**
 * Initiates the process of generating and downloading a CSV file containing user data of the current user's vertical.
 * 
 * If the user has permission to generate the CSV file, the user data is requested from the server
 * and a CSV file is generated and downloaded. If the user does not have permission, an informational message is displayed.
 */
  myverticalusercsv() {
    if (this.iscreate == true) {
      // Get user data for generating CSV from authentication service
      const data = this.authentication.getUserData()
      // Request user data for CSV generation from the dataservice
      this.dataservice.myverticalusercsv(data).subscribe((res: any) => {
        if (res.status == '400') {
          // Display an error message if an error occurs during data retrieval
          this.toastr.error("Error occurred")
        } else {
          // Create a download link for the generated CSV file and trigger the download
          const a = document.createElement('a');
          const fileUrl = window.URL.createObjectURL(res);
          a.href = fileUrl;
          a.download = 'myverticaluser.csv';
          a.click();
          window.URL.revokeObjectURL(fileUrl);
        }
      })
    }
    else if (this.iscreate == false) {
      // Display an informational message if user does not have permission
      this.toastr.info("User not permitted")
    }
  }


  /**
 * Handles the change of checkbox state for a user in the list.
 * 
 * Depending on user permissions, this method updates the selected user list, handles user role editing,
 * and updates the 'allselect' and 'checked' flags accordingly.
 * 
 * @param event - The checkbox change event.
 * @param user_Id - The ID of the user associated with the checkbox.
 */
  onCheckboxChange(event: any, user_Id: any) {
    if (this.iscreate == true) {
      if (event.checked == true) {
        // Add user to the selected list
        this.user_Id.push(user_Id);
        // Determine if the current user is selected
        const user_Idd = this.authentication.getUserData().user_Id
        if (this.user_Id.includes(user_Idd)) {
          this.suspenduser = true
        } else {
          this.suspenduser = false
        }
        // Update the selected user's state in the filterUservalue array
        this.filterUservalue.map((x: any) => {


          if (x.user_Id == user_Id) {

            x.isSelected = true
            // Determine if user has a role for role editing
            if (x.role_name == null) {
              this.editrole = true
            } else {
              this.editrole = false
            }
          }
        })

        // Update 'allselect' flag
        if ((this.filterUservalue.length === this.user_Id.length) || (this.config.itemsPerPage === this.user_Id.length)) {
          this.allselect = true
        }
        this.checked = true;
      } else {
        // Remove user from the selected list
        var index = this.user_Id.indexOf(user_Id)
        this.user_Id.splice(index, 1);
        // Update the selected user's state in the filterUservalue array
        this.filterUservalue.map((x: any) => {
          if (x.user_Id == user_Id) {
            x.isSelected = false

            // Determine if user has a role for role editing
            if (x.role_name == null) {
              this.editrole = false
            } else {
              this.editrole = true
            }
          }
        })

        // Determine if the current user is still selected
        const user_Idd = this.authentication.getUserData().user_Id
        if (!this.user_Id.includes(user_Idd)) {
          this.suspenduser = false
        }
        // Update 'checked' flag and 'allselect' flag
        if (this.user_Id.length <= 0) {
          this.checked = false;
        }
        if (this.filterUservalue.length !== this.user_Id.length) {
          this.allselect = false
        }
      }
    }
    else if (this.iscreate == false) {
      // this.ref._checked = false
      // this.refhead._checked = false;
      // Display an informational message if user does not have permission
      this.toastr.info("User not permitted")
    }

  }

  /**
   * Handles the change of the "Select All" checkbox state.
   * 
   * Depending on user permissions, this method either selects or deselects all users in the current page.
   * It also manages the 'editrole', 'checked', 'allselect', and 'user_Id' arrays accordingly.
   * 
   * @param event - The checkbox change event.
   */

  allCheckboxChange(event: any) {
    if (this.iscreate == true) {
      const itemsPerPage = 6;
      const currentPage = this.config.currentPage;
      const startIndex = (currentPage - 1) * this.config.itemsPerPage;
      const endIndex = startIndex + this.config.itemsPerPage;
      // Reset the 'editrole' flag
      this.editrole = false
      if (event.checked == true) {
        // Select all users in the current page
        this.filterUservalue.slice(startIndex, endIndex).forEach((x: any) => {
          // Determine if user has a role for role editing

          if (x.role_name == null) {
            console.log("if works");
            this.editrole = true
          } else {
            console.log("else works");

            this.editrole = false
          }

          x.isSelected = true;
        });
        this.checked = true;
        this.allselect = true;
        // Add all selected users' IDs to 'user_Id' array
        this.filterUservalue.slice(startIndex, endIndex).forEach((x: any) => {
          if (this.user_Id.indexOf(x.user_Id) === -1) {
            this.user_Id.push(x.user_Id);

          }
        });
        // Determine if the current user is selected
        const user_Idd = this.authentication.getUserData().user_Id
        if (this.user_Id.includes(user_Idd)) {
          this.suspenduser = true
        } else {
          this.suspenduser = false
        }
      } else {
        // Deselect all users in the current page
        this.filterUservalue.slice(startIndex, endIndex).forEach((x: any) => {
          x.isSelected = false;
        });
        this.allselect = false;
        this.checked = false;
        // Clear the 'user_Id' array
        while (this.user_Id.length > 0) {
          this.user_Id.pop();
        }
      }
    }
    else if (this.iscreate == false) {
      // this.ref._checked = false
      // this.refhead._checked = false;
      // Display an informational message if user does not have permission
      this.toastr.info("User not permitted")
    }

  }

  /**
 * Validates user permission before performing an action.
 * 
 * If the user does not have permission ('iscreate' is false), an informational message is shown,
 * and the default action of the event is prevented.
 * 
 * @param $event - The event triggered by the action.
 */

  validatepermission($event: any) {
    if (!this.iscreate) {
      // Display an informational message if user does not have permission
      this.toastr.info("User not permitted");
      // Prevent the default action of the event
      $event.preventDefault()
    }
  }

  /**
  * Redirects to the user information page for the specified user.
  * 
  * Sets the pagination state in the service to remember the current page
  * before navigating to the user information page.
  * 
  * @param user_Id - The ID of the user for whom to display information.
  */
  userinfo(user_Id: any) {
    // Flag to retain filter value on navigation
    this.donotclearValue = true
    // Navigate to the user information page
    this.router.navigate(['/app/userinfo', user_Id])
  }

  /**
 * Suspends multiple users based on selected user IDs.
 * 
 * Opens a confirmation dialog and suspends the selected users if confirmed.
 */

  multipleusersuspend() {
    if (this.iscreate == true) {
      // Open a confirmation dialog
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "suspendeduser"
      });
      // Subscribe to the dialog's result
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {

          const user = this.authentication.getUserData()
          let user_Id = [];
          let role = user.role;
          user_Id = this.user_Id;

          const data = { role, user_Id }
          // Call the dataservice to suspend multiple users
          this.dataservice.multipleusersuspend(data).subscribe((res: any) => {
            if (res.status == "200") {
              this.toastr.info("User not permitted");
            }
            if (res.status == "201") {
              this.toastr.success("User Suspended");
              this.allselect = false;
              this.checked = false
              // Clear the selected user IDs
              while (this.user_Id.length > 0) {
                this.user_Id.pop()
              }
            }
            else {
              this.toastr.error("Error occurred")
            }
          })
        }
      });
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }



  }

  /**
 * Activates multiple users based on selected user IDs.
 * 
 * Opens a confirmation dialog and activates the selected users if confirmed.
 */

  multipleuseractive() {
    if (this.iscreate == true) {
      // Open a confirmation dialog
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "activeuser"
      });
      // Subscribe to the dialog's result
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {

          const user = this.authentication.getUserData()
          let user_Id = [];
          let role = user.role;
          user_Id = this.user_Id

          const data = { role, user_Id }
          // Call the dataservice to activate multiple users
          this.dataservice.multipleuseractive(data).subscribe((res: any) => {
            if (res.status == "200") {
              this.toastr.info("User not permitted");
            }
            if (res.status == "201") {
              console.log(res);

              this.toastr.success("User Active");
              this.allselect = false;
              this.checked = false;
              // Clear the selected user IDs
              while (this.user_Id.length > 0) {
                this.user_Id.pop()

              }
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1500)
            }
            else {
              this.toastr.error("Error occurred")
            }
          })
        }

      });
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }

  /**
 * Lifecycle hook called when the component is about to be destroyed.
 * Clears the pagination state in the service if `donotclearValue` is not set.
 */
  ngOnDestroy() {
    const data = { vertical: this.selectedCategoriesvertical, userstatus: this.selectedCategoriesstatus, userrole: this.selectedCategoriesroll };
    this.dataservice.Storefilter(data, "Myuser")
  }

  /**
 * Opens a dialog to confirm force logout for a user.
 * @param user_Id - The ID of the user to force logout.
 */
  forcelogout(user_Id: any) {
    const MatDialogConfig = this.matdialog.open(deletepopup, {
      disableClose: true, data: "logout"
    });
    MatDialogConfig.afterClosed().subscribe((result: any) => {
      if (result.confirmation == true) {
        // Prepare data for the force logout
        const data = { user_Id: user_Id };
        // Call the dataservice to perform the force logout
        this.dataservice.forcelogout(data).subscribe((res: any) => {
          if (res.status == '201') {
            this.toastr.success('Force Logout Successfully!');
          } else {
            this.toastr.error('Error occurred');
          }
        });
      }
    });
  }
}

@Component({
  selector: 'allmembers',
  templateUrl: 'allmembers.html',
  styleUrls: ['verticalmembers.component.css']
})
export class allmembers implements OnInit {

  isAdmin = true;
  isUser = true;
  isStaff = true;
  user_id: any;
  userdata: any;
  loading: boolean;
  id: any;
  filterTerm!: string;
  isAuthenticated: boolean;
  checked: boolean;
  allselect: boolean;
  activatedRoute = '';
  data: any;
  config: any;
  uniqueRollName: any;
  uniqueorgName: any;
  uniqueverticalname: any;
  selectedCategoriesroll = new Set();
  selectedCategories1 = new Set();
  selectedCategoriesvertical = new Set();
  selectedCategoriesstatus = new Set();
  filterallUservalue: any;
  user_Id: any[] = [];
  uniquestatus: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  my_id: any;
  suspenduser!: boolean;
  page: any = "allverticaluser"

  uservalue: any[] = []
  /**
 * Component constructor responsible for initializing the component and its properties.
 * @param matdialog - The MatDialog service to open dialogs.
 * @param router - The Router service to navigate between routes.
 * @param formBuilder - The FormBuilder service to create and manage forms.
 * @param dataservice - The Dataservice to interact with data-related operations.
 * @param toastr - The ToastrService to show toast notifications.
 * @param authentication - The AuthenticationService to manage user authentication.
 */
  constructor(public matdialog: MatDialog,
    private router: Router, private formBuilder: FormBuilder,
    private dataservice: Dataservice,
    private toastr: ToastrService, private authentication: AuthenticationService) {
    // Initialize component properties
    this.loading = false;
    this.isAuthenticated = false;
    this.config = {
      itemsPerPage: 6,
      currentPage: 1,
    };
    this.checked = false;
    this.allselect = false;
    // Get and map user roles
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[3];
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })
  }


  showall() {
    this.config.itemsPerPage = this.data.length;
    this.config.currentPage = 1;
  }

  userRemove(user_Id: any) {
    // Configure the dialog
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "removeuser"
      });
      // Subscribe to the dialog's afterClosed event
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation) {
          console.log(user_Id)
          // Clear local storage and navigate to the signin page
          this.dataservice.Removeuser(user_Id).subscribe((res: any) => {
            if (res.status == '201') {
              this.toastr.success("User Removed");
            } else {
              this.toastr.error("Error occurred");
            }
          })
        }
      })
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }


  }

  collapse() {
    this.config.itemsPerPage = 6;
    this.config.currentPage = 1;
  }

  /**
 * Component lifecycle hook called when the component is initialized.
 */
  ngOnInit(): void {
    // Notify subscribers about device status changes
    this.dataservice.MyDevicestatus.next()
    this.dataservice.AllDevicestatus.next()
    this.dataservice.singleDevicestatus.next()
    // Initialize loading state
    this.loading = true;
    // Get user data from authentication service
    const data = this.authentication.getUserData();

    // Set the user ID
    this.my_id = data.user_Id;
    // Fetch and display all members' data
    this.getallmembers(data);
    this.dataservice.setPaginationState(null, 'myverticaluser');
    this.dataservice.setPaginationState(null, 'mydevice');
    this.dataservice.setPaginationState(null, 'alldevice');
    // Retrieve the pagination state from the data service
    let paginationState: any = this.dataservice.getPaginationState(this.page);
    if (paginationState) {
      this.config.currentPage = paginationState
    }
    // Subscribe to Userrefresh event for updating data
    this.dataservice.Userrefresh.subscribe((response: any) => {
      this.getallmembers(data)
    })

  }

  /**
 * Fetch and display all members' data based on the provided user data.
 * @param data - User data from the authentication service.
 */
  async getallmembers(data: any) {
    let filterhistroy: any = null;
    filterhistroy = await this.dataservice.Getfilter("Alluser");
    if (filterhistroy == undefined) {
      filterhistroy = null
    }
    // Fetch all members' data from the dataservice
    this.dataservice.getverticalalluser(data).subscribe((res: any) => {
      // Disable loading state
      this.loading = false;
      if (res.status == "200") {
        // Update the data with the fetched response
        this.data = res.data;
        // Separate role and unrole users
        let UnroleUser = this.data.filter((x: any) => {
          return !x.role_name
        }
        )
        let RoleUser = this.data.filter((x: any) => {
          return x.role_name
        })

        this.data = [...RoleUser, ...UnroleUser];
        this.uservalue = this.data;
        // Create filtered and mapped data for display
        this.filterallUservalue = this.uservalue.map((x: any) => {
          return {
            ...x,
            isSelected: false
          }
        });



        // Filter unique values for various categories
        this.uniqueRollName = this.uservalue.filter(
          (x: any, y: any, z: any) => {
            return y === z.findIndex((t: any) => t.role_name === x.role_name);
          }


        );


        this.uniquestatus = this.uservalue.filter(
          (x: any, y: any, z: any) => {
            return y === z.findIndex((t: any) => t.isactive === x.isactive);
          }


        );

        this.uniqueverticalname = this.uservalue.filter(
          (x: any, y: any, z: any) => {

            return y === z.findIndex((t: any) => t.vertical_name === x.vertical_name);

          }


        );
        // Add unique values to selected categories
        // Populate selected categories sets
        this.uniqueRollName.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.userrole.size >= 1) {
              if (filterhistroy.userrole.has(x.role_name)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedCategoriesroll = filterhistroy.userrole;
            } else {
              this.selectedCategoriesroll.add(x.role_name)
            }

          } else {
            this.selectedCategoriesroll.add(x.role_name)
          }

        })

        this.uniqueverticalname.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.vertical.size >= 1) {
              if (filterhistroy.vertical.has(x.vertical_name)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedCategoriesvertical = filterhistroy.vertical;
            } else {
              this.selectedCategoriesvertical.add(x.vertical_name)
            }
          } else {
            this.selectedCategoriesvertical.add(x.vertical_name)
          }
        })
        this.uniquestatus.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.userstatus.size >= 1) {
              if (filterhistroy.userstatus.has(x.isactive)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedCategoriesstatus = filterhistroy.userstatus;
            } else {
              this.selectedCategoriesstatus.add(x.isactive)
            }
          } else {
            this.selectedCategoriesstatus.add(x.isactive)
          }

        });

        this.filterallUservalue = this.filterallUservalue.filter((x: any) =>
          (this.selectedCategoriesstatus.has(x.isactive) && this.selectedCategoriesvertical.has(x.vertical_name)) && this.selectedCategoriesroll.has(x.role_name)
        )

      }
      else {
        this.toastr.error("Error occurred");
      }
    });

  }


  filterroleName(user: any, event: any) {
    this.user_Id = [];
    this.allselect = false;
    this.config.currentPage = 1;
    while (this.user_Id.length > 0) {
      this.user_Id.pop();
    }
    // Update selected categories based on event
    if (event.checked == true) {
      this.selectedCategoriesroll.add(user);
    } else {
      this.selectedCategoriesroll.delete(user);
    }
    // Update filterUservalue based on selected categories
    if (this.selectedCategoriesroll.size >= 1) {
      this.filterallUservalue = this.uservalue
        .filter((x: any) => {
          // Condition for selectedCategoriesvertical
          if (this.selectedCategoriesvertical.size >= 1) {
            return this.selectedCategoriesvertical.has(x.vertical_name);
          }
          return x; // No filter applied if the size is less than 1
        })
        .filter((x: any) => {
          // Condition for selectedCategoriesstatus
          if (this.selectedCategoriesstatus.size >= 1) {
            return this.selectedCategoriesstatus.has(x.isactive);
          }
          return x; // No filter applied if the size is less than 1
        })
        .filter((x: any) => {
          // Condition for selectedCategoriesroll
          if (this.selectedCategoriesroll.size >= 1) {
            return this.selectedCategoriesroll.has(x.role_name);
          }
          return x; // No filter applied if the size is less than 1
        })
        .map((x: any) => ({
          ...x,
          isSelected: false
        }));
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;

      this.uniqueverticalname.map((x: any) => {
        const Find = this.filterallUservalue.some((y: any) => y.vertical_name == x.vertical_name);
        if (!Find) {
          x.ischecked = false;
          this.selectedCategoriesvertical.delete(x.vertical_name)
        } else {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.vertical_name)
        }
      })


    }
    else {
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;

      // Reset filterUservalue for all users
      this.filterallUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));;
    }

    if (this.filterallUservalue.length <= 6) {
      this.onFilterTermChange()
    }
  }
  /**
 * Filters user data based on status and updates the selected status categories.
 * 
 * @param user - The user data being filtered by status.
 * @param event - The event triggered by checking/unchecking a status filter.
 */
  filterstatus(user: any, event: any) {
    this.user_Id = [];
    this.allselect = false;
    this.config.currentPage = 1;
    while (this.user_Id.length > 0) {
      this.user_Id.pop();
    }
    // Update selected status categories based on event
    if (event.checked == true) {
      this.selectedCategoriesstatus.add(user);
    } else {
      this.selectedCategoriesstatus.delete(user);
    }
    // Update filterUservalue based on selected status categories
    if (this.selectedCategoriesstatus.size >= 1) {
      this.filterallUservalue = this.uservalue
        .filter((x: any) => {
          // Condition for selectedCategoriesvertical
          if (this.selectedCategoriesvertical.size >= 1) {
            return this.selectedCategoriesvertical.has(x.vertical_name);
          }
          return x; // No filter applied if the size is less than 1
        })
        .filter((x: any) => {
          // Condition for selectedCategoriesroll
          if (this.selectedCategoriesroll.size >= 1) {
            return this.selectedCategoriesroll.has(x.role_name);
          }
          return x; // No filter applied if the size is less than 1
        })
        .filter((x: any) => {
          // Condition for selectedCategoriesstatus
          if (this.selectedCategoriesstatus.size >= 1) {
            return this.selectedCategoriesstatus.has(x.isactive);
          }
          return x; // No filter applied if the size is less than 1
        })
        .map((x: any) => ({
          ...x,
          isSelected: false
        }));

      //   this.filterallUservalue = this.uservalue.filter((x: any) =>
      //       this.selectedCategoriesvertical.has(x.vertical_name)
      //   ).filter((x: any) =>
      //     this.selectedCategoriesroll.has(x.role_name)

      //   ).filter((x: any) =>
      //     this.selectedCategoriesstatus.has(x.isactive)
      //  ).map((x: any) => ({ ...x, isSelected: false }));
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;
      this.uniqueverticalname.map((x: any) => {
        const Find = this.filterallUservalue.some((y: any) => y.vertical_name == x.vertical_name);
        if (!Find) {
          x.ischecked = false;
          this.selectedCategoriesvertical.delete(x.vertical_name)
        } else {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.vertical_name)
        }
      })

    }
    else {
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;
      // Clear user_Id array

      // Reset filterUservalue for all users
      this.filterallUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));;
    }
  }

  SelectallFilters(event: any, eventname: any) {

    if (event) {
      if (eventname == 'status') {
        this.selectedCategoriesstatus.clear()
        let config = { checked: true };
        this.uniquestatus = this.uniquestatus.map((x: any) => {
          x.ischecked = true;
          this.selectedCategoriesstatus.add(x.isactive);
          // this.filterstatus(x.isactive, config);
          return { ...x }
        })
        this.filterallUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));;
      } else if (eventname == 'role') {
        this.selectedCategoriesroll.clear();
        let config = { checked: true };
        this.uniqueRollName = this.uniqueRollName.map((x: any) => {
          x.ischecked = true;
          this.selectedCategoriesroll.add(x.role_name);
          // this.filterroleName(x.role_name, config);
          return { ...x }
        })

        this.filterallUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));;
      } else if (eventname == 'vertical') {
        this.selectedCategoriesvertical.clear();
        let config = { checked: true };
        this.uniqueverticalname = this.uniqueverticalname.map((x: any) => {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.vertical_name);
          // this.filterVerticalName(x.vertical_name, config);
          return { ...x }
        })
        this.filterallUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));;
      }

    } else {
      if (eventname == 'status') {
        this.selectedCategoriesstatus.clear()
        let config = { checked: false };
        this.uniquestatus = this.uniquestatus.map((x: any) => {
          x.ischecked = false;

          this.filterstatus(x.isactive, config);
          return { ...x }
        })
      } else if (eventname == 'role') {
        this.selectedCategoriesroll.clear();
        let config = { checked: false };
        this.uniqueRollName = this.uniqueRollName.map((x: any) => {
          x.ischecked = false;

          this.filterroleName(x.role_name, config);
          return { ...x }
        })
      } else if (eventname == 'vertical') {
        this.selectedCategoriesvertical.clear()
        let config = { checked: false };
        this.uniqueverticalname = this.uniqueverticalname.map((x: any) => {
          x.ischecked = false;

          this.filterVerticalName(x.vertical_name, config);
          return { ...x }
        })
      }
    }

  }

  /**
  * Filters user data based on vertical name and updates the selected vertical name categories.
  * 
  * @param user - The user data being filtered by vertical name.
  * @param event - The event triggered by checking/unchecking a vertical name filter.
  */
  filterVerticalName(vertical: any, event: any) {
    this.user_Id = [];
    this.allselect = false;
    this.config.currentPage = 1;
    // Clear user_Id array
    while (this.user_Id.length > 0) {
      this.user_Id.pop();
    }
    // Update selected vertical name categories based on event
    if (event.checked == true) {
      this.selectedCategoriesvertical.add(vertical);
    } else {
      this.selectedCategoriesvertical.delete(vertical);
    }
    // Update filterUservalue based on selected vertical name categories
    if (this.selectedCategoriesvertical.size >= 1) {
      this.filterallUservalue = this.uservalue.filter((x: any) =>
        this.selectedCategoriesvertical.has(x.vertical_name)

      ).map((x: any) => ({ ...x, isSelected: false }));;
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;
      this.selectedCategoriesstatus.clear();
      this.selectedCategoriesroll.clear();
      this.uniqueRollName = this.filterallUservalue.filter(
        (x: any, y: any, z: any) => {
          return y === z.findIndex((t: any) => t.role_name === x.role_name);
        }


      ).map((x: any) =>
      ({
        ...x,
        ischecked: true
      })
      );
      this.uniquestatus = this.filterallUservalue.filter(
        (x: any, y: any, z: any) => {
          return y === z.findIndex((t: any) => t.isactive === x.isactive);
        }
      ).map((x: any) =>
      ({
        ...x,
        ischecked: true
      })
      );
      this.uniquestatus.map((x: any) => {


        this.selectedCategoriesstatus.add(x.isactive)


      });

      this.uniqueRollName.map((x: any) => {

        this.selectedCategoriesroll.add(x.role_name)


      })


    }
    else {
      // Reset allselect and checked
      this.allselect = false;
      this.checked = false;
      // Reset filterUservalue for all users
      this.filterallUservalue = this.uservalue.map((x: any) => ({ ...x, isSelected: false }));;
    }
  }

  /**
 * Initiates the logout process by opening a confirmation dialog.
 * Clears local storage and navigates to the sign-in page upon confirmation.
 */
  logout() {
    // Open a confirmation dialog
    const MatDialogConfig = this.matdialog.open(logout, { disableClose: true })

    // Subscribe to the dialog's result
    MatDialogConfig.afterClosed().subscribe((result: any) => {
      // Check if the user confirmed logout
      if (result.event == "confirm") {
        // Clear local storage and navigate to sign-in page
        localStorage.clear()
        this.router.navigate(['/app/signin']);
      }
    })
  }

  /**
 * Handles the pagination page change event.
 * Updates the current page configuration when the page changes.
 *
 * @param event - The page change event containing the new page number.
 */
  pageChanged(event: any) {
    // Update the current page in the configuration
    this.config.currentPage = event;
    this.dataservice.setPaginationState(event, this.page)
  }

  /**
 * Handles the filter term change event.
 * Resets the current page to the first page whenever the filter term changes.
 */
  onFilterTermChange() {
    // Reset the current page to the first page
    this.config.currentPage = 1;
  }

  /**
   * Handles suspending a user based on user permission and confirmation.
   * Opens a dialog for confirmation and suspends the user if permitted.
   *
   * @param user_Id - The ID of the user to be suspended.
   */
  suspendeduser(user_Id: any) {
    // Check if the user has permission to perform the action
    if (this.iscreate == true) {
      // Open a confirmation dialog
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "suspendeduser"
      });
      // Subscribe to the dialog's afterClosed event
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          // Get user's role and prepare data for suspension
          const user = this.authentication.getUserData()
          let role = user.role;
          const data = { role, user_Id }
          // Call the suspendeduser API endpoint
          this.dataservice.suspendeduser(data).subscribe((res: any) => {
            if (res.status == "200") {
              this.toastr.info("User not a permission");
            }
            if (res.status == "201") {
              this.toastr.success("User Suspended");

            }
            else {
              this.toastr.error("Error occurred");
            }
          })
        }
      });
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }


  }
  /**
   * Handles activating a user based on user permission and confirmation.
   * Opens a dialog for confirmation and activates the user if permitted.
   *
   * @param user_Id - The ID of the user to be activated.
   */
  activeuser(user_Id: any) {
    // Check if the user has permission to perform the actions
    if (this.iscreate == true) {
      // Open a confirmation dialog
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "activeuser"
      });
      // Subscribe to the dialog's afterClosed event
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          // Get user's role and prepare data for activation
          const user = this.authentication.getUserData()
          let role = user.role;
          const data = { role, user_Id }
          // Call the activeuser API endpoint
          this.dataservice.activeuser(data).subscribe((res: any) => {
            if (res.status == "200") {
              this.toastr.info("User not a permission");
            }
            if (res.status == "201") {
              this.toastr.success("User Active");

            } else {
              this.toastr.error("Error occurred");
            }
          })
        }
      });
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }

  }

  /**
   * Opens a dialog to allow the user to change their password.
   * The dialog is opened with a 'disableClose' configuration to prevent closing by clicking outside.
   */
  changepassword() {
    // Open a dialog to change the password
    const MatDialogConfig = this.matdialog.open(changepassword, { disableClose: true })
  }
  /**
 * Opens a dialog to edit user information.
 * If the user has the required permission ('iscreate' is true), the dialog is opened with user data.
 * If the user does not have the required permission, a notification is shown indicating that the user is not permitted.
 */
  edituser() {
    if (this.iscreate == true) {
      // Open a dialog to edit user with user data
      const MatDialogConfig = this.matdialog.open(useredit, { disableClose: true, data: this.userdata })
    }
    else if (this.iscreate == false) {
      // Show a notification indicating user is not permitted
      this.toastr.info("User not permitted")
    }
  }

  /**
 * Retrieves user information and sets the 'suspenduser' flag based on user_id comparison.
 * Updates 'userdata' with the fetched user data or shows an error notification.
 * 
 * @param id - The user ID for which information is to be retrieved.
 */
  info(id: any) {
    // Set the user_id and determine whether to set the suspenduser flag
    this.user_id = id;
    if (this.user_id == this.my_id) {
      this.suspenduser = true
    } else {
      this.suspenduser = false
    }
    // Fetch user data using the provided user_id
    const data = { "user_Id": id }
    this.dataservice.getuserdata(data).subscribe((res: any) => {
      if (res.status == "200") {
        // Set 'userdata' with the fetched user data
        this.userdata = res.data;
      }
      else {
        // Show an error notification if fetching data fails
        this.toastr.error("Error occurred");
      }
    })
  }

  /**
 * Navigates to the user information page with pagination state and user ID parameters.
 * 
 * @param user_Id - The user ID for which the user information page will be navigated to.
 */
  userinfo(user_Id: any) {

    // // Create a pagination state object with relevant information
    //     const paginationState = {
    //       currentPage: this.config.currentPage,
    //       deletedevice_id: this.user_Id,
    //       checked: this.checked
    //     };
    //   // Set the pagination state using the dataservice
    //     this.dataservice.setPaginationState(paginationState);
    // Navigate to the user information page with the user ID parameter

    this.router.navigate(['/app/userinfo', user_Id])
  }

  /**
   * Handles the checkbox state change for selecting or deselecting all users.
   * 
   * @param event - The event object representing the checkbox state change.
   */
  allCheckboxChange(event: any) {
    if (this.iscreate == true) {
      const itemsPerPage = 6;
      const currentPage = this.config.currentPage;
      const startIndex = (currentPage - 1) * this.config.itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      // Check if the checkbox was checked or unchecked
      if (event.checked == true) {
        // Select all users on the current page
        this.filterallUservalue.slice(startIndex, endIndex).forEach((x: any) => {
          x.isSelected = true;
        });
        this.checked = true;
        this.allselect = true;
        // Add user IDs to the user_Id array
        this.filterallUservalue.slice(startIndex, endIndex).forEach((x: any) => {
          if (this.user_Id.indexOf(x.user_Id) === -1) {
            this.user_Id.push(x.user_Id);
          }
        });
      } else {
        // Deselect all users on the current page
        this.filterallUservalue.slice(startIndex, endIndex).forEach((x: any) => {
          x.isSelected = false;
        });
        this.allselect = false;
        this.checked = false;
        // Clear the user_Id array
        while (this.user_Id.length > 0) {
          this.user_Id.pop();
        }
      }
    }
    else if (this.iscreate == false) {
      // Show a notification for user without permission
      this.toastr.info("User not permitted")
    }

  }

  /**
   * Handles the checkbox state change for individual user selection.
   * 
   * @param event - The event object representing the checkbox state change.
   * @param user_Id - The ID of the user associated with the checkbox.
   */
  onCheckboxChange(event: any, user_Id: any) {
    if (this.iscreate == true) {
      // Check if the checkbox was checked or unchecked
      if (event.checked == true) {
        // Add user ID to the user_Id array and update isSelected state
        this.user_Id.push(user_Id);
        this.filterallUservalue.map((x: any) => {
          if (x.user_Id == user_Id) {
            x.isSelected = true
          }
        })
        // Update allselect and checked states
        if ((this.filterallUservalue.length === this.user_Id.length) || (this.config.itemsPerPage === this.user_Id.length)) {
          this.allselect = true
        }
        this.checked = true;
      } else {
        // Remove user ID from the user_Id array and update isSelected state
        var index = this.user_Id.indexOf(user_Id)
        this.user_Id.splice(index, 1)
        this.filterallUservalue.map((x: any) => {
          if (x.user_Id == user_Id) {
            x.isSelected = false
          }
        })
        // Update checked state and allselect if needed
        if (this.user_Id.length <= 0) {
          this.checked = false;
        }
        if (this.filterallUservalue.length !== this.user_Id.length) {
          this.allselect = false
        }
      }
    }
    else if (this.iscreate == false) {
      // Show a notification for user without permission
      this.toastr.info("User not permitted")
    }

  }


  /**
 * Validates user permission and shows a notification if the user is not permitted.
 * 
 * @param $event - The event object, typically used to prevent the default action.
 */
  validatepermission($event: any) {
    if (!this.iscreate) {
      // Show a notification for user without permission
      this.toastr.info("User not permitted")
      // Prevent the default action (if applicable)
      $event.preventDefault()
    }
  }




  /**
   * Downloads a CSV file containing data of all users within the same vertical.
   * 
   * If the user has permission (`iscreate` is `true`), the function fetches user data, such as user ID, role, organization ID, and vertical ID.
   * Then, it sends a request to the backend to generate the CSV file. Upon receiving the file, it creates a download link and triggers a download.
   * If the user doesn't have permission, the function displays a notification.
   */

  verticalallusercsv() {
    if (this.iscreate == true) {
      // Fetch user data
      const user = this.authentication.getUserData()
      const user_Id = user.user_Id;
      let role = user.role;
      const org_Id = user.org_Id;
      const vertical_Id = user.vertical_Id;
      const data = { user_Id, role, org_Id, vertical_Id };
      // Request backend to generate CSV file
      this.dataservice.allverticalusercsv(data).subscribe((res: any) => {
        if (res.status == '400') {
          this.toastr.error("Error occurred")
        } else {
          // Create and trigger download link for the CSV file
          const a = document.createElement('a');
          const fileUrl = window.URL.createObjectURL(res);
          a.href = fileUrl;
          a.download = 'verticalalluser.csv';
          a.click();
          window.URL.revokeObjectURL(fileUrl);
        }
      })
    } else if (this.iscreate == false) {
      // Show a notification for user without permission
      this.toastr.info("User not permitted")
    }
  }
  /**
   * Opens a dialog to invite a new user.
   * 
   * If the user has permission (`iscreate` is `true`), the function opens a dialog window for creating a new user (or sending an invitation).
   * If the user doesn't have permission, the function displays a notification.
   */

  inviteuser() {
    if (this.iscreate == true) {
      // Open a dialog to invite/create a new user
      const MatDialogConfig = this.matdialog.open(createuser, { disableClose: true })
    }
    else if (this.iscreate == false) {
      // Show a notification for user without permission
      this.toastr.info("User not permitted")
    }
  }


  /**
 * Initiates suspension of multiple users.
 * 
 * If the user has permission (`iscreate` is `true`), the function opens a dialog to confirm the suspension of multiple selected users.
 * If the user confirms, it sends a request to suspend the selected users using the `dataservice` and displays appropriate notifications.
 * If the user doesn't have permission, the function displays a notification.
 */
  multipleusersuspend() {
    if (this.iscreate == true) {
      // Open a dialog to confirm the suspension of selected users
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "suspendeduser"
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          // Get user data and prepare the request data
          const user = this.authentication.getUserData()
          let user_Id = [];
          let role = user.role;
          user_Id = this.user_Id;
          const data = { role, user_Id }
          // Send a request to suspend the selected users
          this.dataservice.multipleusersuspend(data).subscribe((res: any) => {
            if (res.status == "200") {
              this.toastr.info("User not a permission");
            }
            if (res.status == "201") {
              // Show success notification and reset selection
              this.toastr.success("User Suspended");
              this.allselect = false;
              this.checked = false
              while (this.user_Id.length > 0) {
                this.user_Id.pop()

              }

            }
            else {

              this.toastr.error("Error occurred")
            }
          })
        }
      });
    } else if (this.iscreate == false) {
      // Show a notification for user without permission
      this.toastr.info("User not permitted")
    }


  }


  /**
 * Initiates activation of multiple users.
 * 
 * If the user has permission (`iscreate` is `true`), the function opens a dialog to confirm the activation of multiple selected users.
 * If the user confirms, it sends a request to activate the selected users using the `dataservice` and displays appropriate notifications.
 * If the user doesn't have permission, the function displays a notification.
 */
  multipleuseractive() {
    if (this.iscreate == true) {
      // Open a dialog to confirm the activation of selected users
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "activeuser"
      });

      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          // Get user data and prepare the request data
          const user = this.authentication.getUserData()
          let user_Id = [];
          let role = user.role;
          user_Id = this.user_Id

          const data = { role, user_Id }
          // Send a request to activate the selected users
          this.dataservice.multipleuseractive(data).subscribe((res: any) => {
            if (res.status == "200") {
              this.toastr.info("User not a permission");
            }
            if (res.status == "201") {
              // Show success notification and reset selection
              this.toastr.success("User Active");
              this.allselect = false;
              this.checked = false
              while (this.user_Id.length > 0) {
                this.user_Id.pop()

              }

            }
            else {

              this.toastr.error("Error occurred")
            }
          })
        }

      });
    }
    else if (this.iscreate == false) {
      // Show a notification for user without permission
      this.toastr.info("User not permitted")
    }



  }
  ngOnDestroy() {
    const data = { vertical: this.selectedCategoriesvertical, userstatus: this.selectedCategoriesstatus, userrole: this.selectedCategoriesroll };

    this.dataservice.Storefilter(data, "Alluser")
  }

  /**
 * Initiates force logout for a specific user.
 * 
 * Opens a dialog to confirm the force logout action.
 * If the user confirms, it sends a request to the `dataservice` to perform the force logout.
 * Displays success or error notifications based on the response.
 * 
 * @param user_Id - The user ID of the user to be forcefully logged out.
 */
  forcelogout(user_Id: any) {
    // Open a dialog to confirm the force logout action
    const MatDialogConfig = this.matdialog.open(deletepopup, {
      disableClose: true, data: "logout"
    });
    MatDialogConfig.afterClosed().subscribe((result: any) => {
      if (result.confirmation == true) {
        // Prepare data and send request to force logout
        const data = { user_Id: user_Id };
        this.dataservice.forcelogout(data).subscribe((res: any) => {
          if (res.status == '201') {
            // Show success notification for force logout
            this.toastr.success('Force Logout Successfully!');
          } else {
            // Show error notification if there's an issue with force logout
            this.toastr.error('Error occurred');
          }
        });
      }
    });
  }



}


