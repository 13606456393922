<div class="content" *ngIf="isread == false">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p style="font-weight: 500; text-transform: capitalize; margin: 0 !important">
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>
<mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="loading" id="spinner">
</mat-spinner>
<div class="content" *ngIf="productvalue?.length == 0 && isread == true &&!loading">
  <div>
    <h1 style="font-weight: 500">Start by creating your first Cluster</h1>
    <p style="font-weight: 500">
      Cluster is a digital model of a physical object. It is used in Devsbot
      platform as a Cluster to be assigned to devices.
    </p>
  </div>
  <!-- <div>
    <button class="btn btn-primary" (click)="open()">+ New Cluster</button>
  </div> -->
</div>

<div style="height: 100%;" *ngIf="!loading">
  <div class="main-head" *ngIf="this.ClusterAnalytics">
    <div class="heading-div">
      <h1 class="heading"> {{ this.clustername + " Analytics" }}</h1>
      <i (click)="ExportAnalytics()" matTooltip="Export
    Analytics(CSV)" id="more-vert1" class="fa-solid fa-file-export"></i>
    </div>
    <div class="heading-div-filter" [formGroup]="range">
      <mat-form-field *ngIf="this.vertical_type==2" appearance="fill">
        <mat-label>Select a Interval</mat-label>
        <mat-select formControlName="Interval">
          <mat-option value="{{ item.value }}" *ngFor="let item of interval">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Select a Cluster</mat-label>
          <mat-select panelClass="myPanelClass" formControlName="cluster_Id">
            <mat-option value="{{ cluster.group_id }}" *ngFor="let cluster of productvalue">{{ cluster.group_name }}
              <span>({{cluster.vertical_name}})</span></mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <mat-form-field appearance="fill">
        <mat-label>Select a Cluster</mat-label>
        <mat-select panelClass="myPanelClass"  formControlName="cluster_Id">
          <mat-option
            value="{{ cluster.group_id }}"
            *ngFor="let cluster of productvalue"
            >{{ cluster.group_name }} <span>({{cluster.vertical_name}})</span></mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field appearance="fill">
        <mat-label>Enter a date range <span style="color: red;">*</span> </mat-label>
        <mat-date-range-input [rangePicker]="picker" [max]="this.enddate">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>
    </div>
  </div>

  <mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="Finding" id="spinner">
  </mat-spinner>

  <div class="analytics_main" *ngIf="!Finding && ClusterAnalytics && !nodata">
    <div class="charts">


      <div class="label-box" *ngIf="this.vertical_type!=2" >
        <div>
          <div class="card label-card">
            <h5>
              <iconify-icon style="background-color: #2b9de2" icon="simple-line-icons:energy"></iconify-icon>
              Total Energy
            </h5>
            <h3>{{ this.TotalKwh }} kWh</h3>
          </div>
          <div class="card label-card">
            <h5>
              <iconify-icon style="background-color: #ea8a59" icon="material-symbols:attach-money"></iconify-icon>
              Total Cost
            </h5>
            <h3>₹ {{ this.TotalCost }}</h3>
          </div>
        </div>
        <div>
          <div class="card label-card">
            <h5>
              <iconify-icon style="background-color: #34ac32" icon="mdi:molecule-co2"></iconify-icon>
              Co2 Emission
            </h5>
            <h3>{{ this.TotalCo2 }} tCO2</h3>
          </div>
          <div class="card label-card">
            <h5>
              <iconify-icon style="background-color: #cb56d8" icon="prime:chart-line"></iconify-icon>
              AVG Energy Consumed
            </h5>
            <h3>{{ this.AvgKwh }} kWh</h3>
          </div>
        </div>
      </div>


      
      <div class="card chart-card" *ngIf="this.vertical_type==2">
        <h5>Lines Series Devices Counting</h5>
        <div class="linechart">
          <mat-spinner
            role="progressbar"
            diameter="50"
            aria-hidden="true"
            *ngIf="Finding"
            id="spinner"
          >
          </mat-spinner>
          <ngx-charts-line-chart
            *ngIf="this.LineChartData && !Finding"
            
            class="chart-container"
            [scheme]="colorScheme"
            [schemeType]="schemeType"
            [results]="this.LineChartData"
            [animations]="false"
            [legend]="true"
            [legendTitle]="'legend'"
            [legendPosition]="legendPosition"
            [xAxis]="true"
            [yAxis]="true"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxisLabel]="'Time'"
            [yAxisLabel]="'Value'"
            [autoScale]="false"
            [xScaleMin]="this.Xaxismin"
            [xScaleMax]="this.Xaxismax"
            [roundDomains]="true"
            [showGridLines]="false"
            [xAxisTicks]="this.Xaxisticks"
            [rangeFillOpacity]="0.5"
            [xAxisTickFormatting]="xAxisTickFormattingFunction"
            >
          </ngx-charts-line-chart>
        </div>
      </div>
      <!-- <div class="card chart-card">
        <h5>Energy Consumption</h5>
        <div class="barchart">
       
          <div
            style="display: flex; justify-content: center; margin: 50px"
            *ngIf="this.Clusterpiechartdata[0]?.value <= 0"
          >
            <h1>No Data Found</h1>
          </div>
          <ngx-charts-pie-chart
            [view]="[700, 200]"
            *ngIf="this.Clusterpiechartdata[0]?.value >= 1 && !Finding"
            class="chart-container"
            [scheme]="colorScheme"
            [results]="this.Clusterpiechartdata"
            [animations]="true"
            [legend]="true"
            [labels]="true"
            [legendPosition]="legendPosition"
          >
          </ngx-charts-pie-chart>
        </div>
      </div> -->
    </div>
    <!-- label  -->
    <div class="main-box">

      <div class="card" *ngIf="this.vertical_type!=2">
        <h5 style="background-color: white; position: sticky; top: 0px">
          Energy Consumption
        </h5>
        <div class="no-data" *ngIf="novalue">
          <mat-icon> insert_drive_file</mat-icon>
          <h6>No Data</h6>
        </div>
        <ngx-charts-advanced-pie-chart *ngIf="!novalue" label="Total kWh" [view]="[680, 300]" [scheme]="colorScheme"
          [results]="this.Clusterpiechartdata">
        </ngx-charts-advanced-pie-chart>
        <!-- pie chart will come  -->
      </div>
      <div class="card" *ngIf="this.vertical_type==2">
        <h5 style="background-color: white; position: sticky; top: 0px">
          Total Devices Count
        </h5>
        <div class="no-data" *ngIf="novalue">
          <mat-icon> insert_drive_file</mat-icon>
          <h6>No Data</h6>
        </div>
        <ngx-charts-advanced-pie-chart *ngIf="!novalue" label="Total Count" [view]="[680, 300]" [scheme]="colorScheme"
          [results]="this.Clusterpiechartdata">
        </ngx-charts-advanced-pie-chart>
        <!-- pie chart will come  -->
      </div>
      <div class="table-box"  *ngIf="this.vertical_type!=2">
        <div class="tabel">
          <div class="card tabel-card">
            <h5 style="background-color: white; position: sticky; top: 0px">
              High Energy Consumed Devices
            </h5>
            <div style="padding: 0px 30px">
              <table>
                <thead>
                  <tr>
                    <th>Device name</th>
                    <th>kWh</th>
                    <th>Co2</th>
                    <th>Cost</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of Tabledata" (click)="
                      GetDeviceAnalytics(item.device_id, item.device_name)
                    ">
                    <td>{{ item.device_name }}</td>
                    <td>{{ item.kwh + " kWh" }}</td>
                    <td>{{ item.co2 + " tCO2" }}</td>
                    <td>{{ "₹ " + item.cost }}</td>
                    <td style="text-align: center">
                      {{ item.updated_time | date : "short" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="table-box"  *ngIf="this.vertical_type==2">
        <div class="tabel">
          <div class="card tabel-card">
            <h5 style="background-color: white; position: sticky; top: 0px">
              High Counting Devices
            </h5>
            <div style="padding: 0px 30px">
              <table>
                <thead>
                  <tr>
                    <th>Device name</th>
                    <th>Count</th>
                 
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of Tabledata" (click)="
                      GetDeviceAnalytics(item.device_id, item.device_name)
                    ">
                    <td>{{ item.device_name }}</td>
                    <td>{{ item.V13 }}</td>
               
                    <td >
                      {{ item.updated_time | date : "short" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- <div *ngIf="vertical_type == 2">
      <div *ngFor="let device of TableallParams;let i=index;">
  
        <table>
          <thead *ngIf="i==0" >
            <tr >
              <th>Device Name</th>
              <th>Time</th>
              <th *ngFor="let key of getRawValueKeys(device.raw_value)">
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ device.device_name }}</td>
              <td>{{ device.updated_time | date:'short' }}</td>
              <td *ngFor="let key of getRawValueKeys(device.raw_value)">
                {{ device.raw_value[key] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
  </div>

  <div class="content" *ngIf="nodata">
    <h1>No Data Found</h1>
  </div>



  <!-- <div class="main-body" *ngIf="!Finding && ClusterAnalytics">
    <div style="width: 60%">
      <div>
        <div class="box-head"><h2>Total Kwh</h2></div>
        <div class="box-body">
          <h1>{{ this.TotalKwh }} Kwh</h1>
        </div>
      </div>
      <div>
        <div class="box-head"><h2>Total Cost</h2></div>
        <div class="box-body">
          <h1>{{ this.TotalCost }} ₹</h1>
        </div>
      </div>
      <div>
        <div class="box-head"><h2>Total Co2</h2></div>
        <div class="box-body">
          <h1>{{ this.TotalCo2 }} µmol</h1>
        </div>
      </div>
      <div>
        <div class="box-head"><h2>AVG Kwh</h2></div>
        <div class="box-body">
          <h1>{{ this.AvgKwh }} AVG Kwh</h1>
        </div>
      </div>
    </div>

    <div>
      <div>
        <div class="box-head"><h2>Timeline</h2></div>

        <div class="box-body">
          <mat-spinner
            role="progressbar"
            diameter="50"
            aria-hidden="true"
            *ngIf="Finding"
            id="spinner"
          >
          </mat-spinner>
          <ngx-charts-line-chart
            *ngIf="this.LineChartData && !Finding"
            class="chart-container"'
            ;'
            ;'
            
            ';'
            [view]="[470, 180]"
            [scheme]="colorScheme"
            [schemeType]="schemeType"
            [results]="this.LineChartData"
            [animations]="false"
            [legend]="false"
            [legendTitle]="'legend'"
            [legendPosition]="legendPosition"
            [xAxis]="true"
            [yAxis]="true"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxisLabel]="'Time'"
            [yAxisLabel]="'kwh'"
            [autoScale]="false"
            [xScaleMin]="this.Xaxismin"
            [xScaleMax]="this.Xaxismax"
            [roundDomains]="true"
            [showGridLines]="true"
            [xAxisTicks]="this.Xaxisticks"
            [rangeFillOpacity]="0.5"
            [xAxisTickFormatting]="xAxisTickFormattingFunction"
          >
          </ngx-charts-line-chart>
        </div>
      </div>
      <div style="gap: 0px">
        <div class="box-head">
          <h2>High Kwh Devices</h2>
        </div>
        <div style="width: 100%; overflow: auto; height: 35vh">
          <table>
            <thead>
              <tr>
                <th>Device name</th>
                <th>Kwh</th>
                <th>Co2</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of data"
                (click)="GetDeviceAnalytics(item.device_id, item.device_name)"
              >
                <td>{{ item.device_name }}</td>
                <td>{{ item.kwh }}</td>
                <td>{{ item.co2 }}</td>
                <td>{{ item.updated_time }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div style="width: 75%">
      <div>
        <div class="box-head"><h2>Pie Chart</h2></div>

        <div class="box-body" style="display: flex; align-items: center">
          <mat-spinner
            role="progressbar"
            diameter="50"
            aria-hidden="true"
            *ngIf="Finding"
            id="spinner"
          >
          </mat-spinner>
          <ngx-charts-pie-chart
            *ngIf="this.Clusterpiechartdata && !Finding"
            class="chart-container"
            [view]="[400, 250]"
            [scheme]="colorScheme"
            [results]="this.Clusterpiechartdata"
            [animations]="true"
            [legend]="true"
            [legendTitle]="'legend'"
            [legendPosition]="legendPosition"
            [explodeSlices]="false"
            [labels]="true"
            [doughnut]="false"
            [arcWidth]="0.25"
            [gradient]="false"
            [tooltipDisabled]="false"
          >
          </ngx-charts-pie-chart>
        </div>
      </div>
    </div>
  </div> -->

  <ng-container *ngIf="!ClusterAnalytics">
    <app-deviceanalytics (messageEvent)="receiveMessage($event)" [devicedata]="devicedata">
    </app-deviceanalytics>
  </ng-container>
</div>