import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { Dataservice } from '../services/dataservice';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../Authentication/authentication.service';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LegendPosition, ScaleType, colorSets, escapeLabel, formatLabel } from '@swimlane/ngx-charts';
import { HomeComponent } from '../home/home.component';
import { newproduct } from '../product/product.component';
import { Overlay } from '@angular/cdk/overlay';
import { CustomSelectOverlay } from './custom-select-overlay';
import { ActivatedRoute, Router } from '@angular/router';
// import { createcluster } from '../cluster/cluster.component';


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
  viewProviders: [{
    provide: Overlay,
    useClass: CustomSelectOverlay
  }],
  encapsulation: ViewEncapsulation.Emulated

})

export class AnalyticsComponent implements OnInit {
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  reports: any[] = [];
  data: any[] = [];
  nodata: boolean = false;
  ;
  productvalue: any;
  clustername: any;
  loading!: boolean;
  colorSets: any = colorSets;
  Xaxisticks: any[] = [];
  Xaxismin: any;
  Xaxismax: any;
  ClusterAnalytics!: boolean;
  devicedata: any;


  cluster_id!: Number;
  Finding!: boolean;
  Clusterpiechartdata: any[] = [];
  LineChartData: any[] = [];
  colorScheme: any;
  schemeType = ScaleType.Ordinal;
  selectedColorScheme!: string;
  legendPosition = LegendPosition.Right;
  TotalKwh: any = 0;

  TotalCo2: any = 0;
  TotalCost: any = 0;
  AvgKwh: any = 0;
  chartfilter: any = "hour";
  Tabledata: any[] = [];
  novalue: any;
  noco2: boolean = false;
  vertical_Id: any;
  vertical_type: any;
  interval: any[] = [{ name: "1 Minute", value: 1 }, { name: "5 Minutes", value: 5 }, { name: "30 Minutes", value: 30 }, { name: "Hour", value: 60 }]
  Startdate = new Date().setDate(1);
  enddate = new Date();
  range = this.formbuilder.group({
    Interval: ['', Validators.required],
    cluster_Id: ['', Validators.required],
    start: [new Date(this.Startdate), [Validators.required]],
    end: [new Date(this.enddate.setDate(this.enddate.getDate() - 1)), [Validators.required]]
  });
  initMulti: any[] = [];
  TableallParams:any[]=[];
  constructor(public matdialog: MatDialog,
    private dataservice: Dataservice,
    private authentication: AuthenticationService,
    private homecomponent: HomeComponent, private route: ActivatedRoute,
    private toastr: ToastrService,
    private formbuilder: FormBuilder, private elementRef: ElementRef, private renderer: Renderer2) {
    this.loading = false;
    this.novalue = false;
    this.ClusterAnalytics = true;
    this.vertical_type = this.authentication.getUserData().vertical_type;
    if (this.vertical_type == 2) {
      this.range.controls.Interval.enable();
      this.range.controls.Interval.setValue(String(this.interval[3].value))
    } else {
      this.range.controls.Interval.disable()
    }


  }
  // range = this.formbuilder. FormGroup({
  //   cluster_Id: new FormControl<Number | null>(null, [Validators.required]),
  //   start: new FormControl<Date | null>(new Date(), [Validators.required]),
  //   end: new FormControl<Date | null>(new Date(), [Validators.required]),
  // });


  // getRawValues(rawValue: any) {
  //   return Object.entries(rawValue).map(([key, value]) => ({ key, value }));
  // }

    // Get the keys of raw_value to use as table headers
    getRawValueKeys(rawValue: any): string[] {
      return Object.keys(rawValue);
    }

  async updateRouteParams() {
    this.vertical_type = this.route.snapshot.paramMap.get('id1');
    if (this.vertical_type == 2) {
      this.range.controls.Interval.enable();
      this.range.controls.Interval.setValue(String(this.interval[3].value))
    } else {
      this.range.controls.Interval.disable()
    }
  }

  private styleElement!: HTMLStyleElement;
  ngOnInit(): void {

    this.route.paramMap.subscribe(async (param: any) => {


      await this.updateRouteParams();
      


    })
    this.vertical_type = this.route.snapshot.paramMap.get('id1');

    this.setColorScheme('cool')
    // this.homecomponent.routes();
    this.dataservice.setPaginationState(null, 'all');
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[10];
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })
    this.dataservice.MyDevicestatus.next()
    this.dataservice.AllDevicestatus.next()
    this.dataservice.singleDevicestatus.next();


    // Fetch user data for the current session
    const data = this.authentication.getUserData();
    this.vertical_Id = data.vertical_Id
    this.loading = true;
    this.Finding = true;
    // Fetch the list of products
    // this.dataservice.getproduct(data).subscribe((res: any) => {
    //   if (res.status == '200') {
    //     this.productvalue = res.data;
    //     if(this.productvalue.length>=1){
    //       this.clustername = this.productvalue[0].cluster_name;
    //       this.cluster_id = this.productvalue[0].cluster_id;
    //       this.range.controls.cluster_Id.setValue(String(this.cluster_id));
    //       const Firstcluster = { ...this.range.value };
    //       this.GetAnalyticsData(Firstcluster, this.loading);
    //     }else{
    //       this.loading=false;
    //       this.Finding=false;
    //     }

    //   } else {
    //     this.toastr.error('Error occurred');
    //   }
    // });
    this.dataservice.getclusterdata(data.vertical_Id).subscribe((res: any) => {
      if (res.status == '200') {
        this.productvalue = res.data;
        // this.productvalue = this.productvalue.filter((x:any) => x.vertical_type == this.vertical_type)

        if (this.productvalue.length >= 1) {



          this.clustername = this.productvalue[0].group_name;


          this.cluster_id = this.productvalue[0].group_id;


          this.range.controls.cluster_Id.setValue(String(this.cluster_id));
          const Firstcluster = { ...this.range.value };
          this.GetAnalyticsData(Firstcluster, this.loading);
        } else {
          this.loading = false;
          this.Finding = false;
        }

      } else {
        this.toastr.error('Error occurred');
      }
    });


    this.range.controls.cluster_Id.valueChanges.subscribe((res: any) => {

      this.productvalue.map((x: any) => {


        if (x.group_id == res) {
          this.clustername = x.group_name;
          // console.log(this.clustername);

        }
      })
    });

    this.range.controls.cluster_Id.valueChanges.subscribe((res: any) => {

      if (this.range.valid) {

        this.range.value.cluster_Id = res;
        const data = { ...this.range.value };
        this.GetAnalyticsData(data, this.loading);
      }

    });


    this.range.controls.start.valueChanges.subscribe((res: any) => {
      if (!this.range.controls.start.hasError('matStartDateInvalid')) {
        this.range.value.start = res;
        const data = { ...this.range.value };
        this.GetAnalyticsData(data, this.loading);
      }
    })
    this.range.controls.end.valueChanges.subscribe((res: any) => {
      if (!this.range.controls.start.hasError('matEndDateInvalid')) {
        this.range.value.end = res;
        const data = { ...this.range.value };
        this.GetAnalyticsData(data, this.loading);
      }
    })

    this.range.controls.Interval.valueChanges.subscribe((res: any) => {
      if (!this.range.controls.start.hasError('matEndDateInvalid')) {
        this.range.value.Interval = res;
        const data = { ...this.range.value };
        this.GetAnalyticsData(data, this.loading);
      }
    })

  }





  // open() {
  //   if (this.iscreate == true) {
  //     const MatDialogConfig = this.matdialog.open(createcluster, {
  //       width: '600px',
  //       disableClose: true,
  //     });
  //   } else if (this.iscreate == false) {
  //     this.toastr.info("User not permitted")
  //   }
  // }

  GetAnalyticsData(data: any, condition: any) {
    // console.log(data,condition);

    this.Clusterpiechartdata = [];
    this.LineChartData = [];
    this.Xaxisticks = [];
    this.Finding = true;
    let startDate;
    let endDate;
    this.data = [];
    this.Tabledata = [];
    this.TotalCo2 = null;
    this.TotalKwh = null;
    this.TotalCost = null;
    this.AvgKwh = null;
    startDate = new Date(String(this.range.value.start));
    endDate = new Date(String(this.range.value.end));

    startDate = startDate.getDate();
    endDate = endDate.getDate();
    if (startDate == endDate) {
      this.chartfilter = "hour";
      // Start from 00:00 on the start date
      let currentTimestamp = new Date(String(this.range.value.start))
      currentTimestamp.setHours(0, 0, 0, 0);
      // End at 23:59 on the start date
      const endOfDay = new Date(startDate);
      endOfDay.setHours(23, 59, 59, 999);
      for (let i = 0; i <= 6; i++) {
        this.Xaxisticks.push(+new Date(currentTimestamp) + i * (240 * 60000));
      }
      this.Xaxismin = this.Xaxisticks[0];
      this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1]
      this.Xaxisticks.shift();
      this.Xaxisticks = [...this.Xaxisticks];
      this.LineChartData = [...this.LineChartData];
    } else if (+endDate - +startDate <= 5) {
      let Diff = +endDate - startDate;

      this.chartfilter = "Day";
      // Start from 00:00 on the start date
      let currentTimestamp = new Date(String(this.range.value.start));
      currentTimestamp.setHours(0, 0, 0, 0);
      // End at 23:59 on the start date
      let endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);
      for (let i = 0; i <= Diff + 1; i++) {
        this.Xaxisticks.push(+new Date(currentTimestamp) + i * (1440 * 60000))
      }
      this.Xaxismin = this.Xaxisticks[0];
      this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1]
      this.Xaxisticks.shift();
      this.Xaxisticks.pop();
      this.Xaxisticks = [...this.Xaxisticks];

    } else {

      let Diff = +endDate - startDate;

      this.chartfilter = "Day";
      if (Diff >= 6 && Diff <= 15) {

        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.range.value.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 4; i++) {
          this.Xaxisticks.push(+new Date(currentTimestamp) + i * (4440 * 60000))
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1]
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      } else if (Diff > 15 && Diff <= 24) {

        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.range.value.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 5; i++) {
          this.Xaxisticks.push(+new Date(currentTimestamp) + i * ((4 * 1440) * 60000))
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1]
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      }
      else {
        console.log('else')
        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.range.value.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 5; i++) {
          this.Xaxisticks.push(+new Date(currentTimestamp) + i * ((5 * 1440) * 60000))
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1]
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      }
    }

    if (this.vertical_type != 2) {
      this.dataservice.GetClusterAnalytics(data).subscribe(async (res: any) => {
        if (res.status == "200") {
         
          this.data = res.data;
          this.Tabledata = res.Tabledata;
          if (this.Tabledata.length == 0 && this.data.length == 0) {
            this.nodata = true
          } else {
            this.nodata = false
          }
          this.data.forEach((x: any) => {

            const FindDevice_Id = this.LineChartData.some(item => item.extra === x.device_id);
            if (!FindDevice_Id) {
              this.LineChartData.push({ name: x.device_name, series: [], extra: x.device_id })
            }

          });


          this.Tabledata.forEach((x: any) => {
            x.kwh = Number(x.kwh).toFixed(1);


            x.co2 = Number(x.co2).toFixed(2);
            if (x.co2 === 'NaN' || x.co2 === null) {
              this.noco2 = true
            }
            x.cost = Number(x.cost).toFixed(1);

            const Clusterpiechart = this.Clusterpiechartdata.some(item => item.extra === x.device_id)
            if (!Clusterpiechart) {
              this.Clusterpiechartdata.push({ name: x.device_name, value: x.kwh, extra: x.device_id });
            }
            this.novalue = this.Clusterpiechartdata.every((x: any) => x.value == 0)
            this.TotalKwh = Number(Number(this.TotalKwh) + Number(x.kwh)).toFixed(1)
            this.TotalCo2 = Number(Number(this.TotalCo2) + Number(x.co2)).toFixed(2);
            this.TotalCost = Number((Number(this.TotalCost) + Number(x.cost))).toFixed(0);
          })
          this.AvgKwh = (this.TotalKwh / this.Tabledata.length).toFixed(1);
          await Promise.all(this.LineChartData.map((y: any) => {
            const Matches = this.data.filter((x: any) => x.device_id === y.extra);
            Matches.forEach((match: any) => {
              y.series.push({ "name": new Date(match.updated_time), "value": match.kwh })
            })
          }))

          this.Finding = false;
          if (condition) {
            this.loading = false;
          }
        } else if (res.status == "401") {
          this.Finding = false;
          this.loading = false;
        }
        else {
          this.toastr.error('Error occurred');
        }
      });
    } else if (this.vertical_type == 2) {
      this.dataservice.GetProductionAnalytics(data).subscribe(async (res: any) => {
        if (res.status == "200") {

          this.Tabledata = res.Tabledata;
          this.Clusterpiechartdata = res.PieCharatdata;
          this.TableallParams=res.TableallParams;

          if (this.Tabledata.length == 0) {
            this.nodata = true
          } else {
            this.nodata = false
          }
          this.Tabledata.forEach((x: any) => {

            const FindDevice_Id = this.LineChartData.some(item => item.extra === x.device_id);
            if (!FindDevice_Id) {
              this.LineChartData.push({ name: x.device_name, series: [], extra: x.device_id })
            }

          });








          await Promise.all(this.LineChartData.map((y: any) => {
            const Matches = this.Tabledata.filter((x: any) => x.device_id === y.extra);
            Matches.forEach((match: any) => {
              y.series.push({ "name": new Date(match.updated_time), "value": match.V13, "extra": match.device_name })
            })
          }));
          this.initMulti = this.LineChartData;
          this.Finding = false;
          if (condition) {
            this.loading = false;
          }
        } else if (res.status == "401") {
          this.Finding = false;
          this.loading = false;
        }
        else {
          this.toastr.error('Error occurred');
        }
      })

    }




  }


  onSelect(event: any) {
    if (this.isLegend(event)) {
      if (this.isDataShown(event)) {
        const tempData = JSON.parse(JSON.stringify(this.LineChartData));
        tempData.forEach((country: any) => {
          country.series.forEach((year: any) => {
            if (year.extra === event) {
              year.value = 0;
            }
          });
        });
        this.LineChartData = tempData;
      } else {
        console.log(this.initMulti)
        this.initMulti.forEach((country: any) => {

          country.series.forEach((year: any) => {
            console.log(year, event)
            if (year.extra === event && year.value !== 0) {
              this.setChartDataBackToInitData(country.name, year.extra, year.value)
            }
          });
        });
      }
    } else {
      console.log('emit event and do something else')
      // this.selection.emit(event);
    }
  }

  isLegend = (event: any) => typeof event === 'string';

  isDataShown = (event: any) => {

    const selectedBar = this.LineChartData.find(model => {
      return model.series.find((singleModel: any) => {
        return singleModel.extra === event && singleModel.value !== 0;
      });
    });

    return typeof selectedBar !== 'undefined';
  }

  setChartDataBackToInitData = (country: any, yearName: any, yearDefValue: any) => {
    console.log(country, yearName, yearDefValue)
    const tempData = this.LineChartData;

    tempData.find((_country: any) =>
      _country.name === country).series.find((_year: any) => _year.extra === yearName).value = yearDefValue;
    this.LineChartData = [...tempData];
  }


  GetDeviceAnalytics(Device_Id: any, devicename: any) {
    this.ClusterAnalytics = false;
    this.devicedata = { "device_id": Device_Id, "devicename": devicename, ...this.range.value };
  }


  pieTooltipText(data: any) {
    const label = formatLabel(data.name);
    const val = formatLabel(data.value);
    return `
      <span class="tooltip-label">${escapeLabel(label)}</span>
      <span class="tooltip-val">$${val}</span>
    `;
  }

  setColorScheme(name: any) {
    this.selectedColorScheme = name;
    this.colorScheme = this.colorSets.find((s: any) => s.name === name);
  }



  xAxisTickFormattingFunction = (value: any) => {

    let formatvalue;
    if (this.chartfilter == "hour") {

      formatvalue = new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    }
    else if (this.chartfilter == "Day") {

      formatvalue = new Date(value).toLocaleDateString('en-US', { weekday: 'short', day: 'numeric' });
    }
    else if (this.chartfilter == "Week") {

      formatvalue = new Date(value).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    }
    return formatvalue;

  }

  receiveMessage(event: any) {
    if (event) {
      this.ClusterAnalytics = true;
    } else {
      this.ClusterAnalytics = false;
    }
  }



  ExportAnalytics() {
    if (this.range.valid && !this.range.controls.start.hasError('matStartDateInvalid') && !this.range.controls.end.hasError('matEndDateInvalid')) {
      if (this.vertical_type != 2) {
        const data = { ...this.range.value, "clustername": this.clustername };
        this.dataservice.ExportClusterAnalytics(data).subscribe((res: any) => {
          if (res.status == '400') {
            this.toastr.error('Error occurred');
          } else {
            const a = document.createElement('a');
            const fileUrl = window.URL.createObjectURL(res);
            a.href = fileUrl;
            a.download = this.clustername + '.csv';
            a.click();
            window.URL.revokeObjectURL(fileUrl);
          }
        })
      } else if (this.vertical_type == 2) {
        const data = { ...this.range.value, "clustername": this.clustername };
        this.dataservice.GetProductionAnalyticsCsv(data).subscribe((res: any) => {
          if (res.status == '400') {
            this.toastr.error('Error occurred');
          } else {
            const a = document.createElement('a');
            const fileUrl = window.URL.createObjectURL(res);
            a.href = fileUrl;
            a.download = this.clustername + '.csv';
            a.click();
            window.URL.revokeObjectURL(fileUrl);
          }
        })
      }

    }
  }


}



@Component({
  selector: 'app-deviceanalytics',
  templateUrl: 'deviceanalytics.html',
  styleUrls: ['./analytics.component.css'],
})

export class deviceanalytics implements OnInit {
  @Input() devicedata: any;
  @Output() messageEvent = new EventEmitter<boolean>();
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  reports: any[] = [];
  data: any;
  productvalue: any;
  clustername: any;
  loading!: boolean;
  colorSets: any = colorSets;
  Xaxisticks: any[] = [];
  Xaxismin: any;
  Xaxismax: any;
  ClusterAnalytics!: boolean;
  Finding!: boolean;
  KwhRYBpiechartdata: any[] = [];
  LineChartData: any[] = [];
  colorScheme: any;

  schemeType = ScaleType.Ordinal;
  selectedColorScheme!: string;
  legendPosition = LegendPosition.Right;
  TotalKwh: any = 0;
  TotalCo2: any = 0;
  TotalCost: any = 0;
  AvgKwh: any = 0;
  chartfilter: any = "hour";
  devicename: any = "Test";
  device_id: any;
  range: any;
  Tabledata: any;
  noco2: boolean = false;
  vertical_type: any
  Clusterpiechartdata: any[] = [];
  constructor(public matdialog: MatDialog,
    private dataservice: Dataservice,private route:ActivatedRoute,
    private authentication: AuthenticationService, private toastr: ToastrService, private formbuilder: FormBuilder) {
    this.loading = false;
    this.vertical_type = this.authentication.getUserData().vertical_type;
  }


  async updateRouteParams() {
    this.vertical_type = this.route.snapshot.paramMap.get('id1');
  
  }


  ngOnInit(): void {
    this.setColorScheme('cool');
    this.route.paramMap.subscribe(async (param: any) => {


      await this.updateRouteParams();
      


    })
    this.vertical_type = this.route.snapshot.paramMap.get('id1');


    this.devicename = this.devicedata?.devicename;
    this.device_id = this.devicedata?.device_id;

    this.GetAnalyticsData(this.devicedata, this.loading);

    this.range = this.formbuilder.group({
      start: [new Date(String(this.devicedata.start)), Validators.required],
      end: [new Date(String(this.devicedata.end)), Validators.required]
    })
  }


  async GetAnalyticsData(data: any, condition: any) {

    this.KwhRYBpiechartdata = [];
    this.LineChartData = [];
    this.data = [];
    this.Xaxisticks = [];
    this.Finding = true;
    let startDate;
    let endDate;
    this.Tabledata = []
    startDate = new Date(String(this.devicedata.start));
    endDate = new Date(String(this.devicedata.end));
    startDate = startDate.getDate();
    endDate = endDate.getDate();

    if (startDate == endDate) {
      this.chartfilter = "hour";
      // Start from 00:00 on the start date
      let currentTimestamp = new Date(String(this.devicedata.start))
      currentTimestamp.setHours(0, 0, 0, 0);

      // End at 23:59 on the start date
      const endOfDay = new Date(startDate);
      endOfDay.setHours(23, 59, 59, 999);
      for (let i = 0; i <= 6; i++) {
        this.Xaxisticks.push(+new Date(currentTimestamp) + i * (240 * 60000))
      }



      this.Xaxismin = this.Xaxisticks[0];
      this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1]
      this.Xaxisticks.shift();
      this.Xaxisticks = [...this.Xaxisticks];
    } else if (+endDate - +startDate <= 5) {
      let Diff = +endDate - startDate;
      this.chartfilter = "Day";
      // Start from 00:00 on the start date
      let currentTimestamp = new Date(String(this.devicedata.start));
      currentTimestamp.setHours(0, 0, 0, 0);

      // End at 23:59 on the start date
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);

      for (let i = 0; i <= Diff + 1; i++) {
        this.Xaxisticks.push(+new Date(currentTimestamp) + i * (1440 * 60000))
      }

      this.Xaxismin = this.Xaxisticks[0];
      this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1]
      this.Xaxisticks.shift();
      this.Xaxisticks.pop();
      this.Xaxisticks = [...this.Xaxisticks];

    } else {
      let Diff = +endDate - startDate;
      this.chartfilter = "Day";
      if (Diff >= 6 && Diff <= 15) {

        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.devicedata.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 4; i++) {
          this.Xaxisticks.push(+new Date(currentTimestamp) + i * (4440 * 60000))
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1]
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      } else if (Diff > 15 && Diff <= 24) {

        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.devicedata.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 5; i++) {
          this.Xaxisticks.push(+new Date(currentTimestamp) + i * ((4 * 1440) * 60000))
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1]
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      }
      else {
        console.log('else')
        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.devicedata.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 5; i++) {
          this.Xaxisticks.push(+new Date(currentTimestamp) + i * ((5 * 1440) * 60000))
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1]
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      }

    }


    if (this.vertical_type != 2) {
      this.dataservice.GetDeviceAnalytics(data).subscribe((res: any) => {

        this.data = res.DeviceResultPerhour;
        this.Tabledata = res.DeviceResultPerday;
        this.Tabledata.forEach((x: any) => {
          x.kwh = Number(x.kwh).toFixed(2);
          x.co2 = Number(x.co2).toFixed(2);
          console.log(x.co2);
          if (x.co2 === 'NaN' || x.co2 === null) {
            this.noco2 = true
          }
          this.TotalCo2 = (Number(Number(x.co2) + Number(this.TotalCo2))).toFixed(2);
          console.log(this.TotalKwh);

          this.TotalKwh = (Number(Number(x.kwh) + Number(this.TotalKwh))).toFixed(2);
        })

        this.AvgKwh = parseFloat(res.AvgKwh).toFixed(1);
        // this.TotalKwh = parseFloat(res.TotalKwh).toFixed(1);
        // this.TotalCo2 = parseFloat(res.TotalCo2).toFixed(1);
        this.TotalCost = parseFloat(res.TotalCost).toFixed(1);
        this.KwhRYBpiechartdata.push({ "name": "R", "value": res.Kwh_R }, { "name": "Y", "value": res.Kwh_Y }, { "name": "B", "value": res.Kwh_B })
        // this.KwhRYBpiechartdata.push({ "name": "R", "value": 20 }, { "name": "Y", "value": 40 }, { "name": "B", "value": 60 });

        this.Finding = false;

        this.data.forEach((x: any) => {
          const FindDevice_Id = this.LineChartData.some(item => item.extra === x.device_id);
          if (!FindDevice_Id) {
            this.LineChartData.push({ name: x.device_name, series: [], extra: x.device_id })
          }
        })

        this.LineChartData.map((y: any) => {
          const Matches = this.data.filter((x: any) => x.device_id === y.extra);
          Matches.forEach((match: any) => {
            y.series.push({ "name": new Date(match.updated_time), "value": match.kwh })
          })
        });

        this.Finding = false;
        if (condition) {
          this.loading = false;
        }


      });
    } else if (this.vertical_type == 2) {

      this.dataservice.GetDeviceProductionAnalytics(data).subscribe(async (res: any) => {
        if (res.status == "200") {

          this.Tabledata = res.Tabledata;
          this.Clusterpiechartdata = res.PieCharatdata;


          this.Tabledata.forEach((x: any) => {

            const FindDevice_Id = this.LineChartData.some(item => item.extra === x.device_id);
            if (!FindDevice_Id) {
              this.LineChartData.push({ name: x.device_name, series: [], extra: x.device_id })
            }

          });



          // this.Tabledata.forEach((x: any) => {
          //   x.kwh = Number(x.kwh).toFixed(1);


          //   x.co2 = Number(x.co2).toFixed(2);
          //   if (x.co2 === 'NaN' || x.co2 === null) {
          //     this.noco2 = true
          //   }
          //   x.cost = Number(x.cost).toFixed(1);

          //   const Clusterpiechart = this.Clusterpiechartdata.some(item => item.extra === x.device_id)
          //   if (!Clusterpiechart) {
          //     this.Clusterpiechartdata.push({ name: x.device_name, value: x.kwh, extra: x.device_id });
          //   }
          //   this.novalue = this.Clusterpiechartdata.every((x: any) => x.value == 0)
          //   this.TotalKwh = Number(Number(this.TotalKwh) + Number(x.kwh)).toFixed(1)
          //   this.TotalCo2 = Number(Number(this.TotalCo2) + Number(x.co2)).toFixed(2);
          //   this.TotalCost = Number((Number(this.TotalCost) + Number(x.cost))).toFixed(0);
          // })
          // this.AvgKwh = (this.TotalKwh / this.Tabledata.length).toFixed(1)





          await Promise.all(this.LineChartData.map((y: any) => {
            const Matches = this.Tabledata.filter((x: any) => x.device_id === y.extra);
            Matches.forEach((match: any) => {
              y.series.push({ "name": new Date(match.updated_time), "value": match.V13, "extra": match.device_name })
            })
          }));

          this.Finding = false;
          if (condition) {
            this.loading = false;
          }
        } else if (res.status == "401") {
          this.Finding = false;
          this.loading = false;
        }
        else {
          this.toastr.error('Error occurred');
        }
      })




    }









  }




  ExportAnalytics() {

    if (this.vertical_type != 2) {
      const data = { ...this.devicedata, "devicename": this.devicename };
      this.dataservice.ExportDeviceAnalytics(data).subscribe((res: any) => {
        if (res.status == '400') {
          this.toastr.error('Error occurred');
        } else {
          const a = document.createElement('a');
          const fileUrl = window.URL.createObjectURL(res);
          a.href = fileUrl;
          a.download = this.devicename + '.csv';
          a.click();
          window.URL.revokeObjectURL(fileUrl);
        }
      })
    } else if (this.vertical_type == 2) {
      const data = { ...this.devicedata, "devicename": this.devicename };
      this.dataservice.GetDeviceProductionAnalyticsCsv(data).subscribe((res: any) => {
        if (res.status == '400') {
          this.toastr.error('Error occurred');
        } else {
          const a = document.createElement('a');
          const fileUrl = window.URL.createObjectURL(res);
          a.href = fileUrl;
          a.download = this.devicename + '.csv';
          a.click();
          window.URL.revokeObjectURL(fileUrl);
        }
      })
    }



  }



  close() {
    this.ClusterAnalytics = true;
    this.messageEvent.emit(this.ClusterAnalytics)
  }

  pieTooltipText(data: any) {
    const label = formatLabel(data.name);
    const val = formatLabel(data.value);

    return `
      <span class="tooltip-label">${escapeLabel(label)}</span>
      <span class="tooltip-val">$${val}</span>
    `;
  }

  setColorScheme(name: any) {
    this.selectedColorScheme = name;
    this.colorScheme = this.colorSets.find((s: any) => s.name === name);
  }



  xAxisTickFormattingFunction = (value: any) => {

    let formatvalue;
    if (this.chartfilter == "hour") {

      formatvalue = new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    }
    else if (this.chartfilter == "Day") {

      formatvalue = new Date(value).toLocaleDateString('en-US', { weekday: 'short', day: 'numeric' });
    }
    else if (this.chartfilter == "Week") {

      formatvalue = new Date(value).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    }
    return formatvalue;

  }
}









@Component({
  selector: 'analyticspopup',
  templateUrl: 'analytics.html',
  styleUrls: ['matdialog.css']

})
export class analyticspopup implements OnInit {
  data: any;
  productvalue: any;
  devicevalue: any;
  Device_Id: any[] = [];
  Analyticsreport = this.formbuilder.group({

    cluster_Id: ['', Validators.required],
    device_Id: ['', Validators.required],
    description: ['', Validators.maxLength(75)]

  });
  constructor(
    private dataservice: Dataservice,
    private formbuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AnalyticsComponent>,
    @Inject(MAT_DIALOG_DATA)
    private toastr: ToastrService,
    private authentication: AuthenticationService
  ) {

  }
  ngOnInit(): void {
    // Fetch user data for the current session
    const data = this.authentication.getUserData();

    // Fetch the list of products
    this.dataservice.getproduct(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.productvalue = this.data;
      } else {
        this.toastr.error('Error occurred');
      }
    });




    this.Analyticsreport.controls['cluster_Id'].valueChanges.subscribe((x: any) => {
      const cluster_Id = x;

      this.dataservice.getproductdevices(cluster_Id).subscribe((res: any) => {
        if (res.status == '200') {
          this.Device_Id = [];
          this.data = res.data;
          this.devicevalue = this.data;
        } else {
          this.toastr.error('Error occurred');
        }
      });


    });






  }

  DownloadReport() {
    const cluster_Id = this.Analyticsreport.value.cluster_Id;

    const foundItem = this.productvalue.find((x: any) => Number(x.cluster_id) === Number(cluster_Id))

    this.dialogRef.close({
      event: "success",
      data: { ...this.Analyticsreport.value, "cluster_name": foundItem?.cluster_name }
    })

    this.dataservice.downloadReport();
    let data: any;
    // this.dataservice.downloadAnalytics(data).subscribe((res: any) => {
    //   if (res.status == '400') {
    //     this.toastr.error('Error occurred');
    //   } else {
    //     const a = document.createElement('a');
    //     const fileUrl = window.URL.createObjectURL(res);
    //     a.href = fileUrl;
    //     a.download = foundItem?.cluster_name + '-Analytics.csv';
    //     a.click();
    //     window.URL.revokeObjectURL(fileUrl);
    //   }
    // });
  }


  getDeviceId(event: {
    isUserInput: any;
    source: { value: any; selected: any };
  }) {

    if (event.isUserInput) {
      if (event.source.selected === true) {
        this.Device_Id.push(event.source.value)
        console.log(event.source.value)
      } else {
        const Findindex = this.Device_Id.indexOf(event.source.value);
        this.Device_Id.splice(Findindex, 1);

      }
    }

  }





}
