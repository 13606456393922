import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from '@angular/material/dialog';
import { Dataservice } from '../services/dataservice';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { deletepopup } from '../mydevices/mydevices.component';
import { AuthenticationService } from '../Authentication/authentication.service';
/**
 * This component handles role permissions and displays a list of roles.
 */
@Component({
  selector: 'app-roles-permission',
  templateUrl: './roles-permission.component.html',
  styleUrls: ['./roles-permission.component.css'],
})

export class RolesPermissionComponent implements OnInit {
  loading!: boolean;
  /**
  * A search term for filtering roles.
  */
  filterTerm!: string;
  /**
 * Configuration object for pagination settings.
 */
  config: any;
  Roles: any[] = [];
  id: any;
  role_id: any;
  group_name: any;
  /**
   * The user's roles and associated permission flags.
   */
  roles: any;
  /**
     * Flag indicating whether the user has read permission.
     */
  isread!: boolean;

  /**
   * Flag indicating whether the user has create permission.
   */
  iscreate!: boolean;

  /**
   * Flag indicating whether the user has delete permission.
   */
  isdelete!: boolean;
  /**
   * Constructor of the RolesPermissionComponent.
   * @param matdialog - The MatDialog service for displaying dialogs.
   * @param dataservice - The Dataservice for fetching data.
   * @param toastr - The ToastrService for displaying notifications.
   * @param router - The Router service for navigation.
   * @param authentication - The AuthenticationService for user authentication.
   */
  constructor(
    public matdialog: MatDialog,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private router: Router,
    private authentication: AuthenticationService
  ) {
    this.loading = false;

    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
    };
    // Get user roles and set permission flags
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[8];
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })

  }

  /**
   * Initializes the component by fetching initial data.
   */
  ngOnInit(): void {
    this.dataservice.setPaginationState(null, 'all');
    this.dataservice.MyDevicestatus.next()
    this.dataservice.AllDevicestatus.next()
    this.dataservice.singleDevicestatus.next()
    // Fetch user data
    const data = this.authentication.getUserData();

    // Fetch role group data

    this.getrolegroupdata(data)
    // Subscribe to refresh required event
    this.dataservice.Rolesrefresh.subscribe((response: any) => {

      this.getrolegroupdata(data)
    })


  }

  /**
   * Fetches role group data and updates Roles array.
   * @param data - User data.
   */
  getrolegroupdata(data: any) {
    this.loading = true
    this.dataservice.getroleGroupdata(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.loading = false
        this.Roles = res.data;

        this.Roles.map((x: any) => {
          x.group_permission = JSON.parse(x.group_permission);
        });
      } else {
        this.toastr.error('error occured');
      }
    });
  }

  /**
    * Opens a dialog for creating a new role.
    */
  open() {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(createRole, {
        disableClose: true,
      });
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }
  /**
     * Navigates to the role information page for editing.
     * @param id - The ID of the role to edit.
     */
  edit(id: any) {
    this.role_id = id;
    this.router.navigate(['/app/rolesinfo', this.role_id]);
  }
}

@Component({
  selector: 'createRole',
  templateUrl: './create-role.html',
  styleUrls: ['./roles-permission.component.css'],
})
export class createRole implements OnInit {
  rolename: any;
  loading!: boolean;
  permission: any;
  /**
  * Stores the form group for role creation.
  */
  Roles = this.formbuilders.group({
    rolename: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
        Validators.pattern('^[A-Za-z_][A-Za-z0-9_ ]*$'),
      ],
    ],
  });
  menuNames: any;
  update: any;
  read: any;
  id: any;
  /**
   * Data of the created role and associated permissions.
   */
  RoleData: any;

  /**
   * Constructor of the createRole component.
   * @param formbuilders - The UntypedFormBuilder service for building forms.
   * @param matdialog - The MatDialog service for displaying dialogs.
   * @param dataservice - The Dataservice for fetching and updating data.
   * @param toastr - The ToastrService for displaying notifications.
   * @param authentication - The AuthenticationService for user authentication.
   */
  constructor(
    private formbuilders: UntypedFormBuilder,
    public matdialog: MatDialog,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private authentication: AuthenticationService
  ) { }
  /**
   * Initializes the component by fetching menu names and default permissions.
   */
  ngOnInit(): void {
    // Fetch menu names and set default permissions
    this.dataservice.getRoleData().subscribe((res: any) => {
      this.loading = false;

      if (res.status == '200') {
        this.menuNames = res.data;
        // Set default create, read, and delete permissions for each menu
        this.menuNames.forEach((x: any) => {
          x.create = 1;
          x.read = 1;
          x.delete = 1;
        });

      } else {
        this.toastr.error('Error occurred');
      }
    });
  }
  /**
    * Handles the change in permission checkbox values.
    * @param Id - The ID of the menu for which the permission is being changed.
    * @param checkboxType - The type of permission checkbox (create, read, delete).
    * @param checked - Whether the permission is checked (true) or unchecked (false).
    */
  onChange(Id: any, checkboxType: string, checked: boolean) {
    // Convert checked status to 1 (true) or 0 (false)
    let value = checked ? 1 : 0;
    // Update permission values based on checkbox changes
    this.menuNames.map((x: any) => {
      if (x.menu_Id == Id) {
        if (checkboxType == 'createOrUpdate') {
          x.create = value;
          if (checked) {
            x.read = 1; // automatically check Read when Create/Update is checked'
            x.read_disabled = true;
          }
        }
        if (checkboxType == 'read') {
          x.read = value;
          if (!checked) {
            x.create = 0;
            x.delete = 0;
          }
        }
        if (checkboxType == 'delete') {
          x.delete = value;
        }
      }
    });
  }
  /**
    * Handles the submission of the role creation form.
    */
  onSubmit() {
    const user = this.authentication.getUserData()
    const user_Id = user.user_Id;
    const org_Id = user.org_Id;
    // Prepare permissions data for each menu
    const permissions = this.menuNames.reduce((acc: any, menu: any) => {
      acc[menu.menu_Id] = [menu.create, menu.read, menu.delete];
      return acc;
    }, {});

    // Clean and prepare role name
    let rolename = this.Roles.controls['rolename'].value.replace(/[^\S\r\n]{2,}/g, ' ');

    // Prepare data for creating the role
    const data = { org_Id, user_Id, permissions, rolename };

    // Submit data to create the role
    this.dataservice.CreateRoleData(data).subscribe((res: any) => {
      if (res.status == '201') {
        this.toastr.success('Roles & Permissions Created');
        this.RoleData = res.data;
        this.matdialog.closeAll();
      } else {
        this.toastr.error('Roles & Permissions Creation Failed!');
      }
    });
  }
}
/**
 * This component is responsible for creating new roles and assigning permissions to them.
 */
@Component({
  selector: 'rolesinfo',
  templateUrl: './RolesInfo.html',
  styleUrls: ['./roles-permission.component.css'],
})
export class rolesinfo implements OnInit {
  loading: boolean;
  id: any;
  Roles: any[] = [];
  menuNames: any[] = [];
  group_name: any;
  group_Id!: number;
  Rolesedit!: boolean;
  permission: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  isdefault: any
  Rolesinfo: any = false


  groupForm!: FormGroup; // declare the form group
  editMode = false; // set to false by default
  /**
    * Constructor for the rolesinfo component.
    * @param matdialog - The MatDialog service for displaying dialogs.
    * @param dataservice - The Dataservice for fetching and updating data.
    * @param route - The ActivatedRoute for accessing route parameters.
    * @param toastr - The ToastrService for displaying notifications.
    * @param authentication - The AuthenticationService for user authentication.
    * @param router - The Router for navigating between routes.
    * @param formbuilders - The UntypedFormBuilder service for building forms.
    */
  constructor(
    public matdialog: MatDialog,
    private dataservice: Dataservice,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authentication: AuthenticationService,
    private router: Router,
    private formbuilders: UntypedFormBuilder,
  ) {
    this.loading = false;
    // Get the user's roles from the authentication service
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[8];
    // Iterate through the roles and determine the user's permissions
    this.roles.map((x: any, i: any) => {
      // i is the index of the current role in the roles array
      if (i == 0) {
        // If the first role's permission is 1, set iscreate to true, else false
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
        // Similarly, for the second role's permission
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })
  }

  /**
    * Initializes the component and fetches data when it is created.
    */
  async ngOnInit(): Promise<void> {
    // Get the 'id' parameter from the route snapshot
    this.id = this.route.snapshot.paramMap.get('id');
    // Initialize the 'groupForm' FormGroup with validation rules for 'group_name'
    this.groupForm = new FormGroup({
      'group_name': new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern(/^[a-zA-Z ]+$/)])
    });

    try {
      // Get role data from the server
      let getroledata: any = await this.dataservice.getRoleData().toPromise();
      this.loading = false;
      if (getroledata.status == '200') {
        this.menuNames = getroledata.data;
      } else {
        this.toastr.error('Error occurred');
      }
      // Get single role data based on the 'id' parameter
      let getsingleroledata: any = await this.dataservice.getSingleRoleData(this.id).toPromise();
      if (getsingleroledata.status == '0') {
        this.router.navigate(['/app/roles-permissions'])
      } else if (getsingleroledata.status == '200') {
        this.Roles = getsingleroledata.data;
        this.group_name = this.Roles[0]?.group_name;
        // set the initial value of the form control with the value of this.group_name
        this.groupForm.controls['group_name'].setValue(this.group_name);
        this.group_Id = this.Roles[0]?.group_Id;
        this.isdefault = this.Roles[0]?.isdefault;
        this.Roles.forEach((role) => {
          // Parse the 'group_permission' JSON string and assign it to the 'group_permission' property of the role
          role.group_permission = JSON.parse(role.group_permission);

          // Extract the permissions from the 'group_permission' property
          const permissions = role.group_permission;

          // Iterate through each menu item in the 'menuNames' array
          this.menuNames.map((menu) => {
            if (menu.menu_Id) {
              // Update the 'create', 'read', and 'delete' properties of the menu item
              menu.create = permissions[menu.menu_Id][0];
              menu.read = permissions[menu.menu_Id][1];
              menu.delete = permissions[menu.menu_Id][2];
            }
          });
        });

      } else {
        this.toastr.error('Error occurred');
      }
    } catch (err) {

      this.toastr.error('Error occurred');
    }
    // Subscribe to the Refreshrequired event from the dataservice
    this.dataservice.Rolesrefresh.subscribe(async (response: any) => {
      if (this.id) {
        try {
          let getroledata: any = await this.dataservice.getRoleData().toPromise();
          this.loading = false;
          if (getroledata.status == '200') {
            this.menuNames = getroledata.data;
          } else {
            this.toastr.error('Error occurred');
          }
          // Get single role data based on the 'id' parameter
          let getsingleroledata: any = await this.dataservice.getSingleRoleData(this.id).toPromise();
          if (getsingleroledata.status == '0') {
            this.router.navigate(['/app/roles-permissions'])
          } else if (getsingleroledata.status == '200') {
            // If role data is found, populate the component properties based on retrieved data
            this.Roles = getsingleroledata.data;
            this.group_name = this.Roles[0]?.group_name;
            // set the initial value of the form control with the value of this.group_name
            this.groupForm.controls['group_name'].setValue(this.group_name);
            this.group_Id = this.Roles[0]?.group_Id;
            this.isdefault = this.Roles[0]?.isdefault;
            this.Roles.forEach((role) => {
              role.group_permission = JSON.parse(role.group_permission);
              const permissions = role.group_permission;
              this.menuNames.map((menu) => {
                if (menu.menu_Id) {
                  menu.create = permissions[menu.menu_Id][0];
                  menu.read = permissions[menu.menu_Id][1];
                  menu.delete = permissions[menu.menu_Id][2];
                }
              });
            });
          } else {
            this.toastr.error('Error occurred');
          }
        } catch (err) {

          this.toastr.error('Error occurred');
        }
      }

    })


  }

  /**
   * Opens a section based on the user's permission to create.
   */
  open(): void {
    // Check if the user has the permission to create
    if (this.iscreate == true) {
      // If the user has the permission, set the 'Rolesinfo' flag to true
      this.Rolesinfo = true;
    }
    // If the user does not have the permission to create
    else if (this.iscreate == false) {
      // Display a toastr message indicating that the user is not permitted
      this.toastr.info("User not permitted");
    }
  }

  /**
   * Handles the deletion of roles based on user's permission.
   */
  Rolesdelete() {
    // Check if the user has the permission to delete
    if (this.isdelete == true) {
      let group_Id = this.group_Id
      // Prepare data for deletion
      const data = { group_Id };
      // Open a dialog for confirmation using the 'deletepopup' component
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'delete',
      });
      MatDialogConfig.afterClosed().subscribe(async (result: any) => {
        // Check if user confirmed the deletion
        if (result.confirmation == true) {
          // Call the dataservice to delete the role
          await this.dataservice.DeleteRoleData(data).subscribe((res: any) => {
            if (res.status == '201') {
              this.toastr.success('Roles Deleted Successfully');

              this.router.navigate(['/app/roles-permissions']);
            } else if (res.status == '0') {
              this.toastr.error("Could not delete, User is active");
            } else {
              this.toastr.error('error occurred');
            }
          });
        }
      });
    }
    // If the user does not have the permission to delete
    else if (this.isdelete == false) {
      // Display a toastr message indicating that the user is not permitted
      this.toastr.info("User not permitted")
    }
  }

  /**
 * Handles changes in checkboxes based on the given parameters.
 * @param Id The ID of the checkbox menu item.
 * @param checkboxType The type of checkbox ('createOrUpdate', 'read', 'delete').
 * @param checked Whether the checkbox is checked (true) or not (false).
 */
  onChange(Id: any, checkboxType: string, checked: boolean) {
    let value = checked ? 1 : 0;
    // Iterate through the menuNames array
    this.menuNames.map((x: any) => {
      // Find the menu item with the given ID
      if (x.menu_Id == Id) {

        if (checkboxType == 'createOrUpdate') {
          x.create = value;
          if (checked) {
            if (Id == 12) {
              x.read = 1; // automatically check Read when Create/Update is checked
              x.delete = 1
            } else {
              x.read = 1; // automatically check Read when Create/Update is checked
            }

          }
          else if (!checked) {
            if (Id == 12) {
              x.delete = 0
            }
          }
        }
        if (checkboxType == 'read') {
          // Handle Read checkbox
          x.read = value;
          if (!checked) {
            x.create = 0;
            x.delete = 0;
          }
        }
        if (checkboxType == 'delete') {
          // Handle Delete checkbox
          x.delete = value;
          if (checked) {
            if (Id == 12) {
              x.create = 1;
            }
          } else if (!checked) {
            if (Id == 12) {
              x.create = 0;
            }
          }

        }
      }
    });
  }
  /**
   * Edits the role based on user's permission and form validity.
   * Calls the editRole1() function if conditions are met.
   */
  editRole() {
    if (this.iscreate == true) {
      if (this.groupForm.valid) {
        // Call the editRole1() function if form is valid
        this.editRole1()
      } else {
        this.toastr.error("form invalid")
        this.groupForm.markAllAsTouched();
      }
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }

  }
  /**
 * Updates the role information based on user's permission and form data.
 * Calls the dataservice's UpdateRoleData() method to send updated role data to the server.
 */
  editRole1() {
    // Create a permissions object using the menuNames array
    const permissions = this.menuNames.reduce((acc: any, menu: any) => {
      acc[menu.menu_Id] = [menu.create, menu.read, menu.delete];
      return acc;
    }, {});
    // Get user information
    const user = this.authentication.getUserData()
    const user_Id = user.user_Id;
    // Get the updated group name from the form and sanitize it
    const group_name = this.groupForm.controls['group_name'].value.replace(/[^\S\r\n]{2,}/g, ' ')
    // Construct data object for updating role
    const data = {
      group_name,
      user_Id,
      group_Id: this.group_Id,
      permissions: permissions
    };
    // Send the updated role data to the server using dataservice's UpdateRoleData()
    this.dataservice.UpdateRoleData(data).subscribe((res: any) => {
      if (res.status == '201') {
        this.toastr.success('Roles Updated Successfully');
        this.Rolesinfo = false;
      } else {
        this.toastr.error('error occurred');
      }
    });
  }


  /**
   * Prompts the user to confirm leaving the page without saving changes.
   * If confirmed, hides the Rolesinfo panel and reloads the page.
   * If canceled, keeps the Rolesinfo panel visible.
   */
  exit() {
    if (window.confirm('Leave this page without saving your changes?')) {
      // User confirmed leaving the page without saving
      this.Rolesinfo = false;
      this.ngOnInit()// Reload the page to revert any unsaved changes
    } else {
      this.Rolesinfo = true;
    }

  }


  ngOnDestroy() {
    this.id = ''
  }


}
