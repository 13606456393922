import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../Authentication/authentication.service';
import { Dataservice } from '../services/dataservice';

@Component({
  selector: 'app-clouds',
  templateUrl: './clouds.component.html',
  styleUrls: ['./clouds.component.css']
})
export class cloudsComponent implements OnInit {
  data: any;
  person: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;


  /**
   * Initialize the RolesPermissionComponent.
   * @param matdialog - The MatDialog service for opening dialogs.
   * @param dataservice - The Dataservice for fetching data.
   * @param authentication - The AuthenticationService for handling authentication.
   */
  constructor(public matdialog: MatDialog, private dataservice: Dataservice, private authentication: AuthenticationService) {

  }
  /**
  * Lifecycle hook called after component initialization.
  */

  ngOnInit(): void {
    this.dataservice.setPaginationState(null,'all');
    // Get user roles from authentication service
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[9];
    // Map user roles to permissions
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })

  }


  open() {
    const MatDialogConfig = this.matdialog.open(popup)
  }

}
@Component({
  selector: 'popup',
  templateUrl: './popup.html',
  styleUrls: ['./popupstyles.css']
})
export class popup {
  webform = false;
  customjson = false;
  plaintext = false;
  basic = false;
  auth = false;
  isSend = false;
  constructor() { }
  webformfun() {
    this.webform = true;
    this.plaintext = false;

  }
  plainfun() {
    this.webform = false;
    this.plaintext = true;

  }
  getfun() {
    this.plaintext = false;
  }

  basicfun() {
    this.auth = false;
    this.basic = true;
  }

  authfun() {
    this.auth = true;
    this.basic = false;
  }
  nonefun() {
    this.auth = false;
    this.basic = false;
  }
  sendfun() {
    this.isSend = true;
  }
}
