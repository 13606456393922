<mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="loading" id="spinner">
</mat-spinner>
<div class="sidenav-dbdesign" *ngIf="!loading">
  <div class="darkmode" [ngClass]="{'dark-mode': darkMode}">
    <div class="dbsidenav">
      <div class="db-side">
        <div class="routes-buttons">
          <div class="dashboard-heading">
            <div style="cursor: pointer; height: 23px" (click)="close()">
              <mat-icon>arrow_back</mat-icon>
            </div>
            <h1 [ngClass]="{'dark-mode': darkMode}">
              {{ item?.device_name }} Dashboard
            </h1>
            <span matTooltip="Last Sync Time {{
              this.last_sync_time | date : 'short'
             }}" matTooltipPosition="right" class="status-online" *ngIf="
            +this.currenttime - +item?.last_online <
              100 * item?.heartbeat && (item.status_Id == 1 || item.status_Id==2)
          ">Online</span>

            <iconify-icon class="highlighted" *ngIf="+this.currenttime - +item?.last_online <
            100 * item?.heartbeat &&item.status_Id == 2" style="font-size: 23px;color: red;"
              icon="fluent:plug-disconnected-28-regular"></iconify-icon>


            <span class="status-offline" *ngIf="
            +this.currenttime - +item?.last_online >
              100 * item?.heartbeat && (item.status_Id == 1  || item.status_Id == 2 )
          " matTooltip="Last Online Since {{
            item?.last_online | date : 'short'
          }}" matTooltipPosition="right">Offline</span>


            <span style="color:black" class="status-inactive" *ngIf="
            (item.status_Id == 0 )
          ">Inactive</span>

          </div>
          <div *ngIf="iscreate" [ngClass]="{'dark-mode': darkMode}" class="btn-clear cancel-db db-cancel"
            (click)="deviceinfo()">
            Info
          </div>
          <iconify-icon *ngIf="!darkMode" (click)="toggleDarkMode()" class="btn-clear night-mode"
            icon="line-md:sunny-filled-loop-to-moon-alt-filled-loop-transition"></iconify-icon>
          <iconify-icon *ngIf="darkMode" (click)="toggleDarkMode()" class="btn-clear night-mode"
            style="color: rgb(255, 255, 255)"
            icon="line-md:moon-filled-alt-to-sunny-filled-loop-transition"></iconify-icon>

          <iconify-icon (click)="toggleFullscreen()" *ngIf="!darkMode" class="btn-clear full-screenn"
            icon="material-symbols:fullscreen"></iconify-icon>
          <iconify-icon (click)="toggleFullscreen()" *ngIf="darkMode" class="btn-clear full-screenn"
            style="color: rgb(255, 255, 255)" icon="material-symbols:fullscreen"></iconify-icon>
          <!-- <div class="dark-toggle">
          <mat-slide-toggle (change)="toggleDarkMode()">Dark Mode</mat-slide-toggle>
        </div> -->
        </div>

        <div class="widgets">
          <div class="wed-dash-right">
            <div class="dash-right-body">
              <mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="dashboardloader" id="spinner">
              </mat-spinner>
              <div class="no-dashboard" *ngIf="this.devicedashboard.length<=0&&!dashboardloader">
                <h1 [ngClass]="{'no-dashboard-text': darkMode}">No Dashboard Widgets</h1>
              </div>
              <gridster *ngIf="this.devicedashboard.length>=1&&!dashboardloader" #gridster class="gridster"
                [options]="gridsterOptions">
                <gridster-item [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode}"
                  class="dashboard-widgets" *ngFor="let widget of devicedashboard;let i =index;" [item]="widget">
                  <div class="widgets widget_title" *ngIf="widget?.widgetname !== 'Weather'">
                    <div class="box-head drag-handler">
                      <h2 *ngIf="widget?.title?.length <= 0&&!widget?.isShow">
                        {{ widget?.widgetname }}
                      </h2>
                      <h2 *ngIf="widget?.title?.length >= 1&&!widget?.isShow">
                        {{ widget?.title }}
                      </h2>
                      <span></span>
                    </div>
                    <div class="aprox_value">
                      <span matTooltip="Estimated Consumption Forecast" matTooltipPosition="above"
                        *ngIf="widget?.pin ==='V1'&&widget?.approximatelyKWh">

                        <h3 style="font-size: 15px;
                      font-weight: 300 !important;
                      gap: 4px;
                      display: flex;
                      align-items: center;text-transform: none;"
                          [style.color]="(widget?.previousMonthKWH == null ?widget?.params>widget?.approximatelyKWh.toFixed(1):widget?.previousMonthKWH>widget?.approximatelyKWh.toFixed(1))===true?'red':' rgb(13, 235, 13)'">
                          {{widget?.approximatelyKWh.toFixed(1) +" kWh"}}
                          <iconify-icon style="color: red"
                            *ngIf="widget?.previousMonthKWH == null ?widget?.params>widget?.approximatelyKWh.toFixed(1):widget?.previousMonthKWH>widget?.approximatelyKWh.toFixed(1)"
                            icon="line-md:uploading-loop"></iconify-icon>
                          <iconify-icon style="color: #25ff25"
                            *ngIf="widget?.previousMonthKWH == null ?widget?.params<widget?.approximatelyKWh.toFixed(1):widget?.previousMonthKWH<widget?.approximatelyKWh.toFixed(1)"
                            icon="line-md:downloading-loop"></iconify-icon>
                        </h3>

                      </span>

                      <span *ngIf="widget?.cost && (widget?.pin ==='V1'&&approximatelyCost)">

                        <h3 matTooltip="Estimated Cost Forecast" matTooltipPosition="above" style="font-size: 15px;
                        font-weight: 400 !important;
                        gap: 4px;
                        display: flex;
                        align-items: center;"
                          [style.color]="(this.previousMonthCost == null ? widget?.cost>approximatelyCost.toFixed(1):this.previousMonthCost>approximatelyCost.toFixed(1))===true?'red':'rgb(1 172 33)'">
                          <span
                            [style.color]="(this.previousMonthCost == null ? widget?.cost>approximatelyCost.toFixed(1):this.previousMonthCost>approximatelyCost.toFixed(1))===true?'red':'rgb(1 172 33)'">₹</span>
                          {{approximatelyCost.toFixed(1)}}
                          <iconify-icon style="color: red"
                            *ngIf="this.previousMonthCost == null ? widget?.cost>approximatelyCost.toFixed(1):this.previousMonthCost>approximatelyCost.toFixed(1) "
                            icon="line-md:uploading-loop"></iconify-icon>

                          <iconify-icon style="color: #25ff25"
                            *ngIf="this.previousMonthCost == null ? widget?.cost<approximatelyCost.toFixed(1):this.previousMonthCost<approximatelyCost.toFixed(1) "
                            icon="line-md:downloading-loop"></iconify-icon>

                        </h3>
                      </span>
                    </div>
                  </div>
                  <div style="margin-top: 8px" class="widget-body" *ngIf="widget?.widgetname == 'Switch'">
                    <div (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()">
                      <ui-switch (change)="
                    onEnter(
                      $event,
                      item?.device_auth_token,
                      item?.last_online,
                      widget?.onvalue,
                      widget?.offvalue,
                      widget?.pin,
                      widget?.pinmode,
                      widget?.datastream_name,
                      item?.isOnline,
                      item?.heartbeat
                    )
                  " [checked]="widget?.devicedata==0">
                      </ui-switch>
                      <!--                       
                      <mat-slide-toggle
                        style="padding-top: 17px"
                        [color]="darkMode == false ? 'primary' : ''"
                        (change)="
                onEnter(
                  $event,
                  item.device_auth_token,
                  item.last_online,
                  widget?.onvalue,
                  widget?.offvalue,
                  widget?.pin,
                  widget?.pinmode,
                  widget?.datastream_name,
                  item.isOnline
                )
              "
                        [checked]="widget?.devicedata==1"
                      >
                      </mat-slide-toggle> -->
                    </div>
                    <div *ngIf="widget?.max==1">
                      <h2>{{ widget?.onlabel }}</h2>
                    </div>
                    <div *ngIf="widget?.max==0">
                      <h2>{{ widget?.offlabel }}</h2>
                    </div>
                  </div>
                  <div *ngIf="widget?.widgetname == 'Chart'" [class]="dark">
                    <!-- <mat-form-field
                      *ngIf="!this.ChartRefresh&&widget?.chartfilter=='Day'"
                      [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode}"
                      class="chart-filter-select"
                    >
                      <mat-label>Select an option</mat-label>
                      <mat-select
                        [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode,'mat-option-dark': darkMode}"
                        [(ngModel)]="widget?.chartfilter"
                        (ngModelChange)="Chartfilter(widget?.widget_Id,widget?.chartfilter)"
                      >
                        <mat-option
                          [ngClass]="{'mat-option-dark': darkMode}"
                          value="Day"
                          >1 Day</mat-option
                        >
                        <mat-option
                          [ngClass]="{'mat-option-dark': darkMode}"
                          value="Week"
                          >1 Week</mat-option
                        >
                        <mat-option
                          [ngClass]="{'mat-option-dark': darkMode}"
                          value="Month"
                          >1 Month</mat-option
                        >
                      </mat-select>
                    </mat-form-field> -->
                    <!-- <iconify-icon   *ngIf="!this.ChartRefresh" icon="svg-spinners:blocks-shuffle-3"></iconify-icon> -->
                    <mat-spinner role="progressbar" diameter="50" aria-hidden="true"
                      *ngIf="this.ChartRefresh&&!widget?.isShow" id="spinner">
                    </mat-spinner>
                    <div style="
                        display: flex;
                        justify-content: center;
                        margin: 50px;
                      " *ngIf="widget?.devicedata?.length <= 0&&!this.ChartRefresh">
                      <h1>No Data Found</h1>
                    </div>

                    <ngx-charts-line-chart
                      *ngIf="widget?.chart_type === 'line-chart'&&(!this.ChartRefresh)&&widget?.devicedata?.length >= 1"
                      [view]="widget?.KwhDay==='V1'?[1300,180]:[600,180]" class="chart-container"
                      [scheme]="widget?.color_scheme" [schemeType]="schemeType" [results]="widget?.devicedata"
                      [animations]="false" [legend]="false" [strokeColor]="strokeColor" [legendTitle]="widget?.label"
                      [legendPosition]="legendPosition" [xAxis]="showXAxis" [yAxis]="showYAxis"
                      [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                      [xAxisLabel]="widget?.xAxisLabel" [yAxisLabel]="widget?.yAxisLabel" [autoScale]="false"
                      [xScaleMin]="widget?.xScaleMin" [xScaleMax]="widget?.xScaleMax"
                      [yScaleMin]="widget?.autoScale===true?'':widget?.Min"
                      [yScaleMax]="widget?.autoScale===true?'':widget?.Max" [roundDomains]="roundDomains"
                      [showGridLines]="showGridLines" [xAxisTicks]="widget?.xAxisTicks"
                      [xAxisTickFormatting]="xAxisTickFormattingFunction" [rangeFillOpacity]="0.15"
                      [curve]="widget?.KwhDay==='V1'? this.curves['Linear']:this.curves['Linear']">
                      <ng-template #tooltipTemplate let-model="model">
                        <h3>{{ getFlag(widget?.pin,model.extra) }}</h3>
                        <h4>
                          {{ model.name | date: 'short' }} , {{ model.value }}
                          {{ getFlag(widget?.pin,model.extra) }}
                        </h4>
                      </ng-template>
                    </ngx-charts-line-chart>
                    <ngx-charts-bar-vertical
                      *ngIf="widget?.chart_type === 'bar-vertical'&&(!this.ChartRefresh&&!widget?.isShow)&&widget?.devicedata?.length >= 1"
                      [view]="widget?.chartfilter==='Month'?[1300,180]:[600,180]" class="chart-container"
                      [scheme]="widget?.color_scheme" [schemeType]="schemeType" [results]="widget?.devicedata"
                      [animations]="false" [legend]="false" [strokeColor]="strokeColor" [legendTitle]="widget?.label"
                      [legendPosition]="legendPosition" [xAxis]="showXAxis" [yAxis]="showYAxis"
                      [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                      [xAxisLabel]="widget?.xAxisLabel" [yAxisLabel]="widget?.yAxisLabel" [autoScale]="false"
                      [roundDomains]="roundDomains" [showGridLines]="showGridLines" [rangeFillOpacity]="0.15"
                      (select)="onSelect($event,widget?.isShow,widget?.widget_Id)">
                    </ngx-charts-bar-vertical>
                    <div *ngIf="widget?.isShow">
                      <!-- <mat-sidenav  style="width: 100%;z-index: 99999;" #oneDay_chart_sidenav position="end"> -->
                      <mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="widget?.Loader"
                        id="spinner">
                      </mat-spinner>
                      <div class="chart-close-icon" (click)="closeSingleChart(widget?.widget_Id)">
                        <mat-icon>arrow_back</mat-icon>
                        <h2>{{widget?.oneDay}} kWh</h2>
                      </div>

                      <ngx-charts-line-chart *ngIf="!widget?.Loader" class="chart-container"
                        [view]="widget?.chartfilter==='Month'?[1300,180]:[600, 180]" [legendTitle]="'legend'"
                        [schemeType]="schemeType" [results]="widget?.Singledaydata" [animations]="false"
                        [gradient]="gradient" [strokeColor]="strokeColor" [xAxis]="showXAxis" [yAxis]="showYAxis"
                        [xScaleMin]="widget?.ticksmin" [xScaleMax]="widget?.ticksmax" [xAxisTicks]="widget?.ticks"
                        [legend]="false" [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                        [tooltipDisabled]="tooltipDisabled" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                        [showGridLines]="showGridLines" [roundDomains]="roundDomains" [yScaleMax]="yScaleMax"
                        [wrapTicks]="wrapTicks">
                      </ngx-charts-line-chart>
                      <!-- </mat-sidenav> -->
                    </div>
                  </div>

                  <div class="label-wid" *ngIf="widget?.widgetname == 'Cost'">
                    <h3 [style.color]="widget?.color" *ngIf="!widget?.cost">
                      --
                    </h3>
                    <div *ngIf="widget?.cost">
                      <div style="display: flex">
                        <h3 style="text-transform: none" [style.color]="widget?.color"
                          *ngIf="widget?.cost && widget?.pin ==='V1'">
                          <span [style.color]="widget?.color">₹</span>
                          {{ widget?.cost.toFixed(1) }}
                        </h3>
                        <h3 style="text-transform: none" [style.color]="widget?.color"
                          *ngIf="widget?.devicedata && widget?.pin !== 'V1'">
                          {{ widget?.devicedata}}
                          <span [ngClass]="{'gridster-itemm': darkMode, 'reading-affix:last-child': darkMode}"></span>
                        </h3>

                        <!-- <span
                          style="
                            position: absolute;
                            /* font-size: 18px; */
                            /* left: 170px; */
                            top: 32px;
                            display: flex;
                            right: 27px;
                            gap: 8px;
                            align-items: baseline;
                          "
                          *ngIf="widget?.cost && (widget?.pin ==='V1'&&approximatelyCost)"
                        >
                          <pre
                            [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode}"
                            style="position: absolute; top: -8px"
                          >
Approx Cost</pre
                          >
                          <h3
                            style="font-size: 18px"
                            [style.color]="(this.previousMonthCost == null ? widget?.cost>approximatelyCost.toFixed(1):this.previousMonthCost>approximatelyCost.toFixed(1))===true?'red':'rgb(13, 235, 13)'"
                          >
                            <span
                              [style.color]="(this.previousMonthCost == null ? widget?.cost>approximatelyCost.toFixed(1):this.previousMonthCost>approximatelyCost.toFixed(1))===true?'red':'rgb(13, 235, 13)'"
                              >₹</span
                            >
                            {{approximatelyCost.toFixed(1)}}
                          </h3>
                          <iconify-icon
                            style="color: red"
                            *ngIf="this.previousMonthCost == null ? widget?.cost>approximatelyCost.toFixed(1):this.previousMonthCost>approximatelyCost.toFixed(1) "
                            icon="typcn:arrow-up-outline"
                          ></iconify-icon>
                          <iconify-icon
                            style="color: #25ff25"
                            *ngIf="this.previousMonthCost == null ? widget?.cost<approximatelyCost.toFixed(1):this.previousMonthCost<approximatelyCost.toFixed(1) "
                            icon="typcn:arrow-up-outline"
                            rotate="180deg"
                          ></iconify-icon>
                        </span> -->
                      </div>
                    </div>
                  </div>

                  <!-- pull up on of  -->

                  <div class="ledon ledoff" *ngIf="widget?.widgetname == 'LED'">
                    <div *ngIf="widget?.devicedata == widget?.Onvalue" [style.color]="widget?.color">
                      <label class="switch1">
                        <input class="chk" type="checkbox" />
                        <span class="slider"><span class="slider-on">ON</span>
                        </span>
                      </label>
                    </div>
                    <div *ngIf="widget?.devicedata == widget?.Offvalue">
                      <label class="switch1">
                        <input class="chk" type="checkbox" />
                        <span class="slider"><span class="slider-off">OFF</span>
                        </span>
                      </label>
                    </div>
                    <div class="ledoff" *ngIf="widget?.devicedata == null">
                      <label class="switch1">
                        <input class="chk" type="checkbox" />
                        <span class="slider"><span class="slider-off">OFF</span>
                        </span>
                      </label>
                    </div>
                  </div>

                  <!-- text  -->

                  <!-- <div  class="ledon ledoff" *ngIf="widget?.widgetname == 'LED'">
                    <div
                     
                      *ngIf="widget?.devicedata == 1"
                      [style.color]="widget?.color"
                    >
                    
                      <h2 *ngIf="widget?.title?.length <= 0">
                        {{ widget?.widgetname }}
                      </h2>
                      <h2 *ngIf="widget?.title?.length >= 1">
                        {{ widget?.title }}
                      </h2>
                      <span></span>
                 
                    </div>
                    <div  *ngIf="widget?.devicedata == 0">
                        <h2 *ngIf="widget?.title?.length <= 0">
                        {{ widget?.widgetname }}
                      </h2>
                      <h2 *ngIf="widget?.title?.length >= 1">
                        {{ widget?.title }}
                      </h2>
                      <span></span>
                    </div>
                    <div class="ledoff"  *ngIf="widget?.devicedata == null">
                        <h2 *ngIf="widget?.title?.length <= 0">
                        {{ widget?.widgetname }}
                      </h2>
                      <h2 *ngIf="widget?.title?.length >= 1">
                        {{ widget?.title }}
                      </h2>
                      <span></span>
                    </div>
                   
                  </div> -->

                  <div class="widget-body-gauge" (mousedown)="$event.stopPropagation()"
                    (touchstart)="$event.stopPropagation()" *ngIf="widget?.widgetname == 'Speedometer'">
                    <div class="gauge-container">
                      <rg-gauge-chart [canvasWidth]="380" [needleValue]="(widget?.devicedata ==undefined) || (widget?.devicedata ==null) ? 0 :widget?.devicedata"
                        [options]="widget?.gaugeoptions"
                        [bottomLabel]="(widget?.devicedata ==undefined) || (widget?.devicedata ==null) ? 0+' '+widget?.units :widget?.devicedata+' '+widget?.units"></rg-gauge-chart>

                    </div>
                  </div>

                  <div *ngIf="widget?.widgetname == 'Slider'" (mousedown)="$event.stopPropagation()"
                    (touchstart)="$event.stopPropagation()">
                    <ngx-slider [ngClass]="{'ngx-slider-pointer': darkMode}" (valueChange)="
          onInputChange(
            $event,
            item?.device_auth_token,
            item?.last_online,
            widget?.pin,
            widget?.pinmode,
            widget?.datastream_name,
            item?.isOnline,
            item?.heartbeat
          )
        " [value]="widget?.devicedata||widget?.Min" [options]="{'floor':widget?.Min,'disabled':!item.isOnline,'ceil':widget?.Max,'step':widget?.handlestep,'showSelectionBar':true,'selectionBarGradient': {
            from: 'white',
            to: '#1e84ff'
          }}"></ngx-slider>
                  </div>
                  <div class="widget-body-gauge" (mousedown)="$event.stopPropagation()"
                    (touchstart)="$event.stopPropagation()" *ngIf="widget?.widgetname == 'Gauge'">
                    <div class="gauge-container" *ngIf="widget?.gaugeType === 'arch'">
                      <ngx-gauge id="gauge-text-color" style="bottom: 18px"
                        [style]="'bottom: 18px; color: ' + (widget?.color || 'defaultColor')" [size]="230"
                        [readonly]="true" [strokeWidth]="5" [foregroundColor]="widget?.color" [min]="widget?.Min"
                        [max]="widget?.Max"
                        [value]="(widget?.pin === 'V1' ? widget?.params : widget?.devicedata === undefined ? '--' : widget?.devicedata)"
                        [type]="widget?.gaugeType" rangeColor="lightgray"
                        append="{{ (widget?.units != null || widget?.units != undefined) ? widget?.units:widget?.pin === 'V2' ? 'Volt (phase)' : (widget?.pin === 'V3' ? 'Voltage' : (widget?.pin === 'V4' ? 'A' : (widget?.pin === 'V5' ? 'PF' : (widget?.pin === 'V6' ? 'KW' : (widget?.pin === 'V7' ? 'CO2' : (widget?.pin === 'V1' ? 'kWh' :'Value')))))) }}"
                        [thick]="25" [markers]="widget?.markers==1 ? widget?.markersconfig:'' " cap="butt"
                        [ticks]="true" ></ngx-gauge>

                      <div class="value-container" *ngIf="widget?.markers==0">
                        <p class="min-value">{{widget?.Min}}</p>
                        <p class="max-value">{{widget?.Max}}</p>
                      </div>

                    </div>
                    <div class="gauge-container" *ngIf="widget?.gaugeType != 'arch'" [class]="widget?.gaugeType">
                      <!-- pin v1 gauge holds kwh values  -->
                      <ngx-gauge id="gauge-text-color" style="bottom: 18px"
                        [style]="'bottom: 18px; color: ' + (widget?.color || 'defaultColor')"
                        [size]="widget?.gaugeType === 'semi' ? 230 : 200" [readonly]="true" [strokeWidth]="5"
                        [foregroundColor]="widget?.color" [min]="widget?.Min" [max]="widget?.Max"
                        [value]="widget?.pin === 'V1' ? widget?.params : widget?.devicedata == undefined  ? '--':widget?.devicedata"
                        [type]="widget?.gaugeType" rangeColor="lightgray"
                        append="{{(widget?.units != null || widget?.units != undefined)? widget?.units: widget?.pin === 'V2' ? 'Volt (phase)' : (widget?.pin === 'V3' ? 'Voltage' : (widget?.pin === 'V4' ? 'A' : (widget?.pin === 'V5' ? 'PF' : (widget?.pin === 'V6' ? 'KW' : (widget?.pin === 'V7' ? 'CO2' : (widget?.pin === 'V1' ? 'kWh' :'Value')))))) }}"
                        [thick]="25" cap="butt" [markers]="widget?.markers==1 ? widget?.markersconfig:'' ">
                      </ngx-gauge>
                      <div class="value-container1">
                        <p class="min-value">{{widget?.Min}}</p>
                        <p class="max-value">{{widget?.Max}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="widget-body-gauge gauge3" (mousedown)="$event.stopPropagation()"
                    (touchstart)="$event.stopPropagation()" *ngIf="widget?.widgetname == 'Gauge3Phase'">
                    <div class="gauge-container">
                      <ngx-gauge id="gauge-text-color" style="bottom: 18px" [style]="'bottom: 18px; color: #FE6C6C'"
                        [size]="200" [readonly]="true" [strokeWidth]="5" foregroundColor="#FE6C6C" [min]="widget?.Min"
                        [max]="widget?.Max" [value]="widget?.devicedata?.R" [type]="widget?.gaugeType"
                        rangeColor="lightgray"
                        append="{{ (widget?.unit1 != null || widget?.unit1 != undefined) ? widget?.unit1:widget?.pin === 'V8' ? 'Current R' : (widget?.pin === 'V3' ? 'Voltage (line)' : (widget?.pin === 'V4' ? 'A' : (widget?.pin === 'V5' ? 'PF' : (widget?.pin === 'V6' ? 'KW' : (widget?.pin === 'V7' ? 'CO2' : (widget?.pin === 'V11' ? 'W R' :(widget?.pin === 'V22' ? 'Value' :'kWh R'))))))) }}"
                        [thick]="15" cap="round">
                      </ngx-gauge>
                    </div>
                    <div class="gauge-container">
                      <ngx-gauge id="gauge-text-color" style="bottom: 18px" [style]="'bottom: 18px; color: #FEF83E'"
                        [size]="200" [readonly]="true" [strokeWidth]="5" foregroundColor="#FEF83E" [min]="widget?.Min"
                        [max]="widget?.Max" [value]="widget?.devicedata?.Y" [type]="widget?.gaugeType"
                        rangeColor="lightgray"
                        append="{{ (widget?.unit2 != null || widget?.unit2 != undefined) ? widget?.unit2: widget?.pin === 'V8' ? 'Current Y' : (widget?.pin === 'V3' ? 'Voltage (line)' : (widget?.pin === 'V4' ? 'A' : (widget?.pin === 'V5' ? 'PF' : (widget?.pin === 'V6' ? 'KW' : (widget?.pin === 'V7' ? 'CO2' : (widget?.pin === 'V11' ? 'W Y' : (widget?.pin === 'V22' ? 'Value' :'kWh Y'))))))) }}"
                        [thick]="15" cap="round">
                      </ngx-gauge>
                    </div>
                    <div class="gauge-container">
                      <ngx-gauge id="gauge-text-color" style="bottom: 18px" [style]="'bottom: 18px; color: #3EFBFE'"
                        [size]="200" [readonly]="true" [strokeWidth]="5" foregroundColor="#3EFBFE" [min]="widget?.Min"
                        [max]="widget?.Max" [value]="widget?.devicedata?.B" [type]="widget?.gaugeType"
                        rangeColor="lightgray"
                        append="{{(widget?.unit3 != null || widget?.unit3 != undefined)? widget?.unit3: widget?.pin === 'V8' ? 'Current B' : (widget?.pin === 'V3' ? 'Voltage (line)' : (widget?.pin === 'V4' ? 'A' : (widget?.pin === 'V5' ? 'PF' : (widget?.pin === 'V6' ? 'KW' : (widget?.pin === 'V7' ? 'CO2' : (widget?.pin === 'V11' ? 'W B' : (widget?.pin === 'V22' ? 'Value' :'kWh B'))))))) }}"
                        [thick]="15" cap="round">
                      </ngx-gauge>
                    </div>
                  </div>

                  <div class="pie" (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()"
                    *ngIf="widget?.widgetname == 'piechart'">
                    <div class="pie">
                      <p-chart width="650" height="650" #chart type="pie"
                        [data]="{ labels: ['R', 'Y', 'B'], datasets: [{ data: [widget?.devicedata?.R, widget?.devicedata?.Y, 
                           widget?.devicedata?.B], backgroundColor: ['rgba(255, 0, 0, 0.3)', 'rgba(255, 236, 0, 0.3)', 'rgba(0, 210, 255, 0.3)'],borderColor: ['rgba(255, 0, 0, 0.6)', 'rgba(255, 236, 0, 0.6)', 'rgba(0, 210, 255, 0.6)'] }] }"
                        [options]="{
                        animation: { duration: 0 },
                        plugins: {
                          legend: { position: 'right', labels: { usePointStyle: true } },
                          tooltip: { enabled: true }
                        }
                      }"></p-chart>
                    </div>
                  </div>

                  <div class="label-wid" *ngIf="widget?.widgetname == 'Label'">
                    <h3 style="text-transform: none;" [style.color]="widget?.color"
                      *ngIf="!widget?.params && !widget?.devicedata">
                      --
                    </h3>
                    <div>
                      <div style="display: flex">
                        <h3 style="text-transform: none;" [style.color]="widget?.color"
                          *ngIf="widget?.params && widget?.pin ==='V1'">
                          {{ widget?.params }} {{ " "+(widget?.units != null || widget?.units !=
                          undefined)?widget?.units:'kWh'}}
                          <span [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode}"></span>
                        </h3>
                        <!-- <span
                          style="
                            position: absolute;
                            /* font-size: 18px; */
                            /* left: 170px; */
                            top: 32px;
                            display: flex;
                            right: 27px;
                            gap: 8px;
                            align-items: baseline;
                          "
                          *ngIf="widget?.pin ==='V1'&&widget?.approximatelyKWhWeek"
                        >
                          <pre
                            [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode}"
                            style="position: absolute; top: -8px"
                          >
Approx kWh</pre
                          >
                          <h3
                            style="font-size: 18px"
                            [style.color]="widget?.params<widget?.approximatelyKWhWeek.toFixed(1)===true?'red':'rgb(13, 235, 13)'"
                          >
                            {{widget?.approximatelyKWhWeek.toFixed(1) +" kWh"}}
                          </h3>
                          <iconify-icon
                            style="color: red"
                            *ngIf="widget?.params<widget?.approximatelyKWhWeek.toFixed(1)"
                            icon="typcn:arrow-up-outline"
                          ></iconify-icon>
                          <iconify-icon
                            style="color: #25ff25"
                            *ngIf="widget?.params>widget?.approximatelyKWhWeek.toFixed(1)"
                            icon="typcn:arrow-up-outline"
                            rotate="180deg"
                          ></iconify-icon>
                        </span> -->
                        <!-- <span
                          style="
                            position: absolute;
                            /* font-size: 18px; */
                            /* left: 170px; */
                            top: 32px;
                            display: flex;
                            right: 27px;
                            gap: 8px;
                            align-items: baseline;
                          "
                          *ngIf="widget?.pin ==='V1'&&widget?.approximatelyKWh"
                        >
                          <pre
                            [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode}"
                            style="position: absolute; top: -8px"
                          >
Approx kWh</pre
                          >
                          <h3
                            style="font-size: 18px"
                            [style.color]="(widget?.previousMonthKWH == null ?widget?.params>widget?.approximatelyKWh.toFixed(1):widget?.previousMonthKWH>widget?.approximatelyKWh.toFixed(1))===true?'red':' rgb(13, 235, 13)'"
                          >
                            {{widget?.approximatelyKWh.toFixed(1) +" kWh"}}
                          </h3>
                          <iconify-icon
                            style="color: red"
                            *ngIf="widget?.previousMonthKWH == null ?widget?.params>widget?.approximatelyKWh.toFixed(1):widget?.previousMonthKWH>widget?.approximatelyKWh.toFixed(1)"
                            icon="typcn:arrow-up-outline"
                          ></iconify-icon>
                          <iconify-icon
                            style="color: #25ff25"
                            *ngIf="widget?.previousMonthKWH == null ?widget?.params<widget?.approximatelyKWh.toFixed(1):widget?.previousMonthKWH<widget?.approximatelyKWh.toFixed(1)"
                            icon="typcn:arrow-up-outline"
                            rotate="180deg"
                          ></iconify-icon>
                        </span> -->
                        <!-- <span
                          style="
                            position: absolute;
                            /* font-size: 18px; */
                            /* left: 170px; */
                            top: 32px;
                            display: flex;
                            right: 27px;
                            gap: 8px;
                            align-items: baseline;
                          "
                          *ngIf="widget?.pin ==='V1'&&widget?.approximatelyAvgKWh"
                        >
                          <pre
                            [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode}"
                            style="position: absolute; top: -8px"
                          >
Approx kWh</pre
                          >
                          <h3
                            style="font-size: 18px"
                            [style.color]="(widget?.previousMonthAVG ==null ?widget?.params<widget?.approximatelyAvgKWh.toFixed(1):widget?.previousMonthAVG<widget?.approximatelyAvgKWh.toFixed(1))===true?'red':'green'"
                          >
                            {{widget?.approximatelyAvgKWh?.toFixed(1) +" KWH"}}
                          </h3>
                          <iconify-icon
                            style="color: red"
                            *ngIf="widget?.previousMonthAVG ==null ?widget?.params<widget?.approximatelyAvgKWh.toFixed(1):widget?.previousMonthAVG<widget?.approximatelyAvgKWh.toFixed(1)"
                            icon="typcn:arrow-up-outline"
                          ></iconify-icon>
                          <iconify-icon
                            style="color: green"
                            *ngIf="widget?.previousMonthAVG ==null ?widget?.params>widget?.approximatelyAvgKWh.toFixed(1):widget?.previousMonthAVG>widget?.approximatelyAvgKWh.toFixed(1)"
                            icon="typcn:arrow-up-outline"
                            rotate="180deg"
                          ></iconify-icon>
                        </span> -->
                        <h3 [style.color]="widget?.color" *ngIf="widget?.devicedata && widget?.pin !== 'V1'">
                          {{ widget?.devicedata}} {{ " "+(widget?.units != null || widget?.units != undefined)?
                          widget?.units:''}}
                          <span [ngClass]="{'gridster-itemm': darkMode, 'reading-affix:last-child': darkMode}"></span>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class="ther" (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()"
                    *ngIf="widget?.widgetname == 'Thermometer'">
                    <div class="thermo">
                      <div class="donation-meter">
                        <!-- <strong class="goal">100<sup>o</sup></strong> -->
                        <span class="glass">
                          <strong class="total" [style.bottom]="widget?.devicedata?.topvalue">{{
                            widget?.devicedata?.temp
                            }}<sup>o</sup></strong>
                          <span class="amount" [style.height]="widget?.devicedata?.topvalue"
                            [style.background]="widget?.devicedata?.tcolor"></span>
                        </span>
                        <div class="bulb">
                          <span class="red-circle" [style.background]="widget?.devicedata?.tcolor"></span>
                          <span class="filler" [style.background]="widget?.devicedata?.tcolor">
                            <span></span>
                          </span>
                        </div>
                      </div>
                      <div class="value">
                        <h2 [style.color]="tcolor">{{ widget?.devicedata?.temp }}<sup>°</sup>C</h2>
                        <p>{{widget?.devicedata?.tempFahrenheit}} ° Fahrenheit</p>
                      </div>
                    </div>
                  </div>
                  <div style="margin-left: -15px" class="ther" *ngIf="widget?.widgetname == 'Weather'">
                    <angular-weather-widget [APIKEY]="'1a517ebf85b397d774c1ff67cee37c03'" [width]="'330px'"
                      [height]="'auto'" [backgroundColor]="'transparent'" [isBoxShadow]="true" [borderRadius]="'5px'"
                      [locationFontSize]="'20px'" [locationFontColor]="darkMode==true?'#fff':'rgba(0,0,0,.87)'"
                      [status]="false" [statusFontColor]="darkMode==true?'#fff':'rgba(0,0,0,.87)'"
                      [statusFontSize]="darkMode==true?'#fff':'rgba(0,0,0,.87)'" [temperature]="true"
                      [tempratureFontColor]="darkMode==true?'#fff':'rgba(0,0,0,.87)'" [tempratureFontSize]="'45px'"
                      [weatherImages]="true" [weatherImageWidth]="'110px'" [weatherImageHeight]="'110px'"
                      [geoLocation]="geolocation" [location]="city" [isWind]="true"
                      [windFontColor]="darkMode==true?'#fff':'rgba(0,0,0,.87)'" [windFontSize]="'17px'"
                      [humidityFontColor]="darkMode==true?'#fff':'rgba(0,0,0,.87)'" [humidityFontSize]="'17px'">
                    </angular-weather-widget>
                  </div>

                  <div class="terminal-div" *ngIf="widget?.widgetname == 'Terminal'">
                    <div *ngIf="widget?.screencolor" class="widget-body widget-terminal">
                      <div class="success-msg" [style.background]="widget?.screencolor">
                        <ul>
                          <li *ngFor="let terminal of terminalmsg" [style.color]="widget?.textcolor">
                            >{{ terminal }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div *ngIf="!widget?.screencolor" class="widget-body widget-terminal">
                      <div class="success-msg" style="background-color: black">
                        <ul>
                          <li *ngFor="let terminal of terminalmsg" style="color: white">
                            >{{ terminal }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div [formGroup]="terminalform">
                      <input *ngIf="widget?.screencolor" type="text" class="form-control"
                        [style.color]="widget?.textcolor" [style.background]="widget?.screencolor"
                        [placeholder]="widget?.inputhint" formControlName="terminal" (keydown.enter)="
                sendmsg(
                  $event,
                  item.device_auth_token,
                  item?.last_online,
                  widget?.pin,
                  widget?.pinmode,
                  widget?.datastream_name,
                  item?.isOnline,
                  item?.heartbeat
                )
              " />
                      <input type="text" formControlName="terminal" (keydown.enter)="
              sendmsg(
                $event,
                item?.device_auth_token,
                item?.last_online,
                widget?.pin,
                widget?.pinmode,
                widget?.datastream_name,
                item?.isOnline,
                item?.heartbeat
              )
            " placeholder="Input Hint" style="background-color: black; color: white" class="form-control"
                        *ngIf="!widget?.screencolor" />
                    </div>
                  </div>




                </gridster-item>




              </gridster>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>