<mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="loading" id="spinner"></mat-spinner>
<div class="main" *ngIf="!loading">

    <div class="col-md-12 d-flex">
        <div class="col-md-6">
        <div class="main-head">
          <div>
            <h1>All Users</h1>
          </div>
        </div>
        <div class="count-user">
            <div>
                <span class="count-user-font">
                    <h4>No Of Users : {{data?.length}} </h4>
                </span>
            </div>
        </div>
    
      </div>
        <div class="col-md-6 d-flex new-dev-sec"><div class="buttons"></div>
          <div class="search-div" ><input type="search" #search class="form-control " placeholder="Search Users"
              [(ngModel)]="filterTerm">
            <mat-icon class="search-icon" (click)="search.focus()">search</mat-icon>
            </div>
          </div>
        </div>
        
    <div class="main-body">

        <table>
            <thead>
                <tr>
                    <th id="checkbox">
                      
                    </th>

                    <th>Name</th>
                    <th>E-mail</th>
                    <th id="role">Role Name</th>
                    <th>Status</th>
                    <th style="padding: 0;">Registration Date</th>
                    <th  style="padding: 0;">Action</th>
                  
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data|filter: filterTerm|paginate:config">
                    <td>
                        <div class="profilelogo1" *ngIf="item.user_profile?.length<=0">
                            {{item.firstname.slice(0,1)|uppercase}}</div>

                 
                    </td>

                    <td>
                        <span class="data-info">{{item.firstname}}</span>
                    </td>
                    <td>
                        <span class="data-info">{{item.email_id}}</span>
                    </td>
                    <td class="role">
                        <span class="data-info" >{{item.role_name}}</span>
                    </td>

                    <td>
                            <div *ngIf="item.isactive == 0" class="alluseractive">Active</div>
                            <div   *ngIf="item.isactive == 1" class="allusersuspend">Suspended</div>
                    </td>
                    <td ><span
                            class="data-info">{{item.reg_date|date:"medium"}}</span></td>
                    <td>

                        <iconify-icon matTooltip="Activate User" matTooltipPosition="left"  *ngIf="item.user_Id!=currentuserid" style="font-size: 25px;
                        justify-content: center; color:green" (click)="activeuser(item.user_Id)" [ngClass]="{
                            'active':item.isactive==0,
                            'inactive':item.isactive==1
                          }" icon="streamline:interface-user-check-actions-close-checkmark-check-geometric-human-person-single-success-up-user"></iconify-icon>


                        <iconify-icon matTooltip="Suspend User" matTooltipPosition="left" *ngIf="item.user_Id!=currentuserid" style="font-size: 25px;
                        justify-content: center; color:red"  (click)="suspendeduser(item.user_Id)" [ngClass]="{
                            'active':item.isactive==1,
                            'inactive':item.isactive==0
                          }" icon="streamline:interface-user-block-actions-block-close-denied-deny-geometric-human-person-single-up-user"></iconify-icon>

<!--                         
                        <button mat-menu-item class="mat-menu-button" (click)="suspendeduser(item.user_Id)" [ngClass]="{
                            'active':item.isactive==1,
                            'inactive':item.isactive==0
                          }">
                            <mat-icon class="menu-btn-icon">not_interested</mat-icon>Suspend User
                        </button>
                        <button mat-menu-item class="mat-menu-button" (click)="activeuser(item.user_Id)" [ngClass]="{
                            'active':item.isactive==0,
                            'inactive':item.isactive==1
                          }">
                            <mat-icon class="menu-btn-icon">refresh</mat-icon>Reactivate User
                        </button> -->
                    </td>
    
                </tr>

            </tbody>
        </table>
        <pagination-controls class="pagination" (pageChange)="pageChanged($event)"></pagination-controls>
    </div>



</div>

