<!-- general settings 
organizationform-start -->
<mat-spinner
  role="progressbar"
  diameter="50"
  aria-hidden="true"
  *ngIf="loading"
  id="spinner"
>
</mat-spinner>

<div class="content" *ngIf="isread == false">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p
      style="font-weight: 500; text-transform: capitalize; margin: 0 !important"
    >
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>

<div class="main-div" *ngIf="this.org_name?.length >= 1 && isread == true">
  <form [formGroup]="organization">
    <div class="container-head">
      <!-- form title -->
      <div class="general d-flex">
        <h1>{{ "general" | translate }}</h1>
      </div>

      <!-- form buttons save and cancel -->
      <div class="btn-div">
        <div class="cancel-btn">
          <button class="btn btn-danger" (click)="resetcancel()">
            {{ "Cancel" | translate }}
          </button>
        </div>
        <div class="save-btn">
          <button
            class="btn btn-success"
            type="submit"
            (click)="EditOrg()"
            [disabled]="!this.organization.valid"
          >
            {{ "Save" | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- form container -->
    <div class="container">
      <!-- form leftside -->
      <div class="leftside-div">
        <div class="form-field">
          <!-- organization name required field -->
          <label class="form-label"
            >{{ "Organization Name" | translate
            }}<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control"
            placeholder="My Organization-0008"
            formControlName="name"
            (keydown.enter)="onEnter($event)"
          />
          <div *ngIf="organization.controls['name'].dirty">
            <span
              *ngIf="organization.controls['name']?.errors?.['required']"
              style="color: red"
            >
              *Name is required.
            </span>
            <span
              *ngIf="organization.controls['name']?.errors?.['minlength'] && organization.controls['name']?.errors?.['minlength'].actualLength !== 0"
              style="color: red"
            >
              *Minimum 3 characters.
            </span>
            <span
              *ngIf="organization.controls['name']?.errors?.['maxlength']"
              style="color: red"
            >
              *Maximum 50 characters only.
            </span>
          </div>
        </div>

        <div class="form-field">
          <!-- description -->
          <label class="form-label">
            {{ "Description(Optional)" | translate }}</label
          >

          <textarea
            style="resize: none"
            formControlName="description"
            class="form-control description-input"
            rows="4"
            cols="50"
            placeholder="Enter Description"
          ></textarea>
          <div *ngIf="organization.controls['description'].dirty">
            <span
              *ngIf="organization.controls['description']?.errors?.['maxlength']"
              style="color: red"
            >
              Maximum 100 characters only
            </span>
          </div>
        </div>
        <div class="form-field">
          <!-- phone number required field -->
          <label class="form-label">
            {{ "Phone Number" | translate
            }}<span style="color: red">*</span></label
          >
          <input
            *ngIf="this.countrycode"
            (keydown.enter)="onEnter($event)"
            #telInput
            class="form-control blur"
            (intlTelInputObject)="telInputObject($event)"
            (countryChange)="onCountryChange($event)"
            [ng2TelInputOptions]="{
              initialCountry: this.countrycode,
              separateDialCode: true
            }"
            formControlName="number"
            ng2TelInput
          />
          <div *ngIf="organization.controls['number'].dirty">
            <span
              *ngIf="organization.controls['number']?.errors?.['required']"
              style="color: red"
            >
              *Number is required.
            </span>
            <span
              *ngIf="organization.controls['number']?.errors?.['pattern']"
              style="color: red"
            >
              *Number is invalid.
            </span>
            <span
              *ngIf="organization.controls['number']?.errors?.['minlength'] && organization.controls['number']?.errors?.['minlength'].actualLength !== 0 && !organization.controls['number']?.errors?.['pattern']"
              style="color: red"
            >
              *Minimum 7 Digits.
            </span>
            <span
              *ngIf="organization.controls['number']?.errors?.['maxlength']"
              style="color: red"
            >
              *Maximum 15 Digits.
            </span>
          </div>
        </div>
        <div class="form-field language">
          <label class="form-label"> {{ "Language" | translate }}</label>
          <span class="form-inline">
            <span class="form-inline">
              <select
                class="form-select language-select"
                formControlName="language"
                #selectedLang
                (change)="switchLang(selectedLang.value)"
              >
                <option
                  *ngFor="let language of translate.getLangs()"
                  [value]="language"
                  [selected]="language === translate.currentLang"
                >
                  {{ language }}
                </option>
              </select>
            </span>
          </span>
        </div>
        <div class="form-field-key">
          <!-- phone number required field -->
          <label class="form-label">
            {{ "Encryption Key" | translate
            }}<span style="color: red">*</span></label
          >
          <input
            style="
              pointer-events: none;
              cursor: not-allowed;
              background-color: rgb(136 221 244 / 28%);
            "
            [type]="hide ? 'password' : 'text'"
            class="form-control"
            formControlName="encryptkey"
            placeholder="xxxxxxxx"
            #password
            [disabled]="isDisabled"
          />

          <mat-icon
            [disabled]="true"
            class="hideeye"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
            style="margin-left: 2px"
            >{{ hide ? "visibility_off" : "visibility" }}</mat-icon
          >
        </div>
      </div>
      <div class="rightside-div">
        <!-- logo optional -->
        <div class="logo-input" [formGroup]="fileUploadForm">
          <label class="form-label">{{ "Logo(Optional)" | translate }}</label>
          <div class="input-div" *ngIf="this.org_logo?.length <= 0">
            <div *ngIf="url || this.org_logo?.length >= 1" class="org-image">
              <img [src]="url" alt="" />
              <mat-icon class="delete" (click)="reset()">delete</mat-icon>
            </div>
            <div>
              <input
                type="file"
                class="form-control"
                id="file"
                #fileupload
                name="Image"
                (change)="onFileChanged($event)"
                *ngIf="this.org_logo?.length <= 0 && !url"
                size="2097152"
                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
              />
            </div>
          </div>
          <div class="input-div" *ngIf="this.org_logo?.length >= 1">
            <div class="org-image">
              <img
                class="image"
                src="../../assets/organization/{{ this.org_logo }}"
                alt=""
              />
              <mat-icon class="delete" (click)="removeOrgImag()"
                >delete</mat-icon
              >
            </div>
          </div>

          <div
            *ngIf="this.org_logo?.length <= 0 && !url"
            class="upload-file-text"
            (click)="choosefile()"
          >
            <div>
              <mat-icon class="upload-icon">cloud_upload</mat-icon>
            </div>
            <h3>{{ "Upload Logo (optional)" | translate }}</h3>
            <p class="upload-description">
              {{
                "Upload from computer .png, .jpg or .jpeg Maximum Size 2MB"
                  | translate
              }}
            </p>
          </div>
        </div>

        <div class="form-field">
          <!-- timezone formfield -->
          <label class="form-label"> {{ "TimeZone" | translate }}</label>
          <ng-moment-timezone-picker
      
            style="padding: 0.375rem 0px 0.375rem 1px !important; height: 38px"
            class="form-select"
            id="timezone-picker"
            [config]="this.config"
            [getUserZone]="this.validatetimezone"
            [customPlaceholderText]="
              this.timezone === null ? '' : this.timezone === 'undefined'?'':this.timezone === undefined ? '':this.timezone
            "
            (click)="gettimezone()"
          >
          </ng-moment-timezone-picker>
        </div>

        <div class="advanced-slider">
          <div class="slider" class="d-flex">
            <mat-slide-toggle
              [checked]="this.app_mode == 0"
              (change)="onToggleChange($event)"
            ></mat-slide-toggle>
            <div class="dev-mode">
              <h4>Developer Mode</h4>
              <mat-checkbox
                [checked]="this.app_mode_force == '1'"
                (change)="onCheckboxChange($event)"
                [disabled]="this.app_mode == 0"
                >Strictly Verify</mat-checkbox
              >
            </div>
          </div>
          <p>{{ toggleText }}</p>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- organization form end -->
