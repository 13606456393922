
<!-- Account page start -->

<!-- loading-spinner -->
<mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="loading" id="spinner">
</mat-spinner>

<!-- Account content-page-start-->
<div *ngIf="!loading" class="content-acc">
  <!-- account-content-head-start  -->
  <div class="content-head">
    <div class="profilelogo" *ngIf="this.userdata?.user_profile?.length<=0">
      <!-- profilelogo -->
      {{this.userdata?.firstname.slice(0,1)|uppercase}}
    </div>
    <div class="userprofile-logo-img" *ngIf="this.userdata?.user_profile?.length>=1">
      <img src="../../assets/user/{{this.userdata?.user_profile}}" class="profile-img-sidenav" alt="">
    </div>
    <div>
      <!-- username -->
      <h1 style="font-weight:500;">{{this.userdata?.firstname}}</h1>
    </div>
    <div>
      <!-- smart-menu  -->
      <!-- <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon> -->
      <i class="fa-solid fa-ellipsis" [matMenuTriggerFor]="menu" id="more-vert"></i>
      <mat-menu #menu="matMenu">
     
        <button mat-menu-item class="mat-menu-button" (click)="changepassword()">
          <mat-icon class="menu-btn-icon">lock</mat-icon>Change Password
        </button>
      </mat-menu>
    </div>

    <!-- account-logout -->
    <div class="btn-div"><button class="btn btn-danger" id="signout" (click)="logout()">
        <mat-icon>logouts</mat-icon>Logout
      </button></div>
  </div>
</div>
<!-- account-content-head-end  -->
<!-- account-page-content-body -->
<div *ngIf="!loading" class="content-body">
  <h3>Email</h3>
  <p>{{this.userdata?.email_id}}</p>
  <h3>Role</h3>
  <p style="color: #3f51b5;
  font-weight: 500;  " >{{this.userdata?.role_name}}</p>
  <!-- <p style="color: #3f51b5;
  font-weight: 500; cursor: pointer; " (click)="permission.toggle()">{{this.userdata?.role_name}}</p> -->
  <h3>Last login</h3>
  <p>{{this.userdata?.lastlogin|date:"medium"}}</p>
  <h3>Registration date</h3>
  <p>{{this.userdata?.reg_date|date:"medium"}}</p>
  <!-- <h3>Time Zone</h3>
  <p>{{this.userdata?.org_timezone}}</p> -->
</div>
<p class="sponser">Powered By <a href="https://www.niraltek.com/">Niraltek Solutions</a></p>
<mat-sidenav position="end" style="width:50%" #permission>
  <div>
    <mat-icon (click)="permission.close()">arrow_back</mat-icon>
    <h1>Role And permission</h1>
  </div>


</mat-sidenav>
<!-- account-page-content-body-end  -->
<!-- account-page-content-end  -->
