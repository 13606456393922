<div class="option">
  <mat-icon routerLink="/app/vertical" class="close-icon">arrow_back</mat-icon>

  <div class="option-head">
    <h1>{{this.verticalname}}</h1>
    <div class="btn-div">
      <button class="btn btn-danger" (click)="verticaldelete(this.vertical_Id)">
        Delete
      </button>
      <button class="btn btn-success" (click)="open()">Edit</button>
    </div>
  </div>
  <div class="mat-tab-title">
    <mat-tab-group>
      <mat-tab label="Info">
        <div class="option-body">
          <div>
            <div class="option-content">
              <h3>Vertical Type</h3>
              <p>{{this.vertical_type}}</p>
            </div>
            <div class="option-content">
              <h3>Contact Person</h3>
              <p>{{this.person}}</p>
            </div>
            <div class="option-content">
              <h3>Phone Number</h3>
              <p>{{this.phone}}</p>
            </div>
            <div class="option-content">
              <h3>Registration Date</h3>
              <p>{{this.regdate|date:'medium'}}</p>
            </div>
            <div class="option-content">
              <h3>Location Name</h3>
              <p>{{this.location_name}}</p>
            </div>
            <div class="option-content">
              <h3>Address</h3>
              <p>{{this.Address}}</p>
            </div>
            <div class="option-content">
              <h3>Organization Name</h3>
              <p>{{this.org_name}}</p>
            </div>

            <div class="option-content">
              <h3>description</h3>
              <p>{{this.description}}</p>
            </div>
          </div>
          <div class="right-side-image">
            <!-- <div class="input-div" *ngIf="this.vertical_logo?.length>=1">
              <img
                src="../../assets/vertical/{{this.vertical_logo}}"
                alt=""
                style="width: 100%; padding: 25px; max-height: 200px"
              />
            </div> -->
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<mat-sidenav position="end" style="width: 100%" [opened]="this.verticalinfo" #verticaledit>
  <div class="option">
    <div (click)="exit()">
      <mat-icon class="close-icon">arrow_back</mat-icon>
    </div>
    <div class="option-head">
      <div>
        <h1>{{this.verticalname}}</h1>
      </div>
      <div class="btn-div">
        <button class="btn btn-danger" (click)="exit()">Cancel</button>
        <button class="btn btn-success" [disabled]="this.verticalform.invalid" (click)="update()">
          <!--  -->
          Save And Apply
        </button>
      </div>
    </div>
    <div class="mat-tab-title">
      <mat-tab-group [selectedIndex]="0">
        <mat-tab label="Info">
          <div class="editvertical-main">
            <div class="left-side">
              <form [formGroup]="verticalform">
                <div class="form-field">
                  <label for="" class="form-label">Vertical Name</label>
                  <input type="text" class="form-control" formControlName="name" />
                  <div *ngIf="verticalform.controls['name'].dirty">
                    <span *ngIf="verticalform.controls['name']?.errors?.['required']" style="color: red">
                      *Vertical name is required.
                    </span>
                    <span
                      *ngIf="verticalform.controls['name']?.errors?.['minlength']&&!verticalform.controls['name']?.errors?.['pattern']"
                      style="color: red">
                      *Minimum 2 characters
                    </span>
                    <span *ngIf="verticalform.controls['name']?.errors?.['maxlength']" style="color: red">
                      *Maximum 20 characters
                    </span>
                    <span *ngIf="verticalform.controls['name']?.errors?.['pattern']" style="color: red">
                      *Name is invalid
                    </span>
                  </div>
                </div>

                <div class="form-field" style="display: flex;
    flex-direction: column;">
                  <label class="form-label">Vertical type</label>
                  <!-- <select class="form-select" formControlName="vertical_type">
                    <option class="form-option" *ngFor="let type of verticaltype" value="{{type.type_id}}">
                      {{type.vertical_type}}
                    </option>
                  </select> -->
                  <mat-form-field appearance="outline">
                    <mat-select panelClass="myPanelClasss" formControlName="vertical_type" multiple
                      placeholder="Select Vertical type">
                      <mat-option *ngFor="let type of verticaltype" [value]="type.type_id">
                        {{ type.vertical_type }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="verticalform.get('vertical_type')?.hasError('required')">* Please Select Vertical
                      type
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="form-field">
                  <label for="" class="form-label">Contact Person</label>
                  <input type="text" class="form-control" formControlName="person" />
                  <div *ngIf="verticalform.controls['person'].dirty">
                    <span *ngIf="verticalform.controls['person']?.errors?.['required']" style="color: red">
                      *Name is required.
                    </span>
                    <span
                      *ngIf="verticalform.controls['person']?.errors?.['minlength']&&!verticalform.controls['person']?.errors?.['pattern']"
                      style="color: red">
                      *Minimum 2 characters
                    </span>
                    <span *ngIf="verticalform.controls['person']?.errors?.['maxlength']" style="color: red">
                      *Maximum 20 characters
                    </span>
                    <span *ngIf="verticalform.controls['person']?.errors?.['pattern']" style="color: red">
                      *Name is invalid
                    </span>
                  </div>
                </div>
                <div class="form-field">
                  <label for="" class="form-label">Phone Number</label>
                  <!-- <input type="number" class="form-control"
                                        formControlName="number"> -->
                  <input #telInput class="form-control blur" (countryChange)="onCountryChange($event)"
                    [ng2TelInputOptions]="{ initialCountry: this.countrycode}" formControlName="number" ng2TelInput />
                  <div *ngIf="verticalform.controls['number'].dirty">
                    <span *ngIf="verticalform.controls['number']?.errors?.['required']" style="color: red">
                      *Number is required.
                    </span>
                    <span *ngIf="verticalform.controls['number']?.errors?.['pattern']" style="color: red">
                      *Number is invalid.
                    </span>
                    <span
                      *ngIf="verticalform.controls['number']?.errors?.['minlength'] && verticalform.controls['number']?.errors?.['minlength'].actualLength !== 0 && !verticalform.controls['number']?.errors?.['pattern']"
                      style="color: red">
                      *Minimum 7 Digits.
                    </span>
                    <span *ngIf="verticalform.controls['number']?.errors?.['maxlength']" style="color: red">
                      *Maximum 15 Digits.
                    </span>
                  </div>
                </div>
                <div class="form-field">
                  <label for="" class="form-label">Description</label>
                  <!-- <input type="text" class="form-control" formControlName="description"> -->
                  <textarea name="" id="textarea" class="form-control" formControlName="description"
                    rows="4"></textarea>
                  <div *ngIf="
                      
                            verticalform.controls['description'].dirty 
                        
                    ">
                    <span *ngIf="verticalform.controls['description']?.errors?.['maxlength']" style="color: red">
                      *Maximum 75 characters only
                    </span>
                  </div>
                </div>
                <div class="form-field">
                  <label for="" class="form-label">Location Name</label>
                  <input type="text" class="form-control" formControlName="location_name" readonly />
                </div>
                <div class="form-field">
                  <label for="" class="form-label">Address</label>
                  <input type="text" class="form-control" formControlName="location" readonly />
                </div>
                <div class="form-field">
                  <label for="" class="form-label">Organization Name</label>
                  <input type="text" class="form-control" formControlName="manufacturer" readonly />
                </div>
              </form>
            </div>
            <!-- <div class="right-side">
                        <div class="logo-input" [formGroup]="fileUploadForm">
                            <label for="" class="form-label">Vertical Image(Optional)</label>
                            <div class="input-div" *ngIf="this.vertical_logo?.length<=0">
                                <div class="ver-image" *ngIf="url">
                                    <img class="verimage" [src]="url" alt="">
                                    <mat-icon class="delete" (click)="reset()">delete</mat-icon>
                                </div>
                                <div>
                                    <input type="file" class="form-control" id="file" #fileupload name="Image"
                                        (change)="onFileChanged($event)"
                                        *ngIf="this.vertical_logo?.length<=0&&!url"
                                        accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" />
                                </div>
                            </div>
                            <div class="input-div" *ngIf="this.vertical_logo?.length>=1">
                                <div class="ver-image"><img class="verimage"
                                        src="../../assets/vertical/{{this.vertical_logo}}" alt="">
                                    <mat-icon class="delete">delete
                                    </mat-icon>
                                </div>
                            </div>

                            <div *ngIf="this.vertical_logo?.length<=0&&!url" (click)="choosefile()">
                                <mat-icon class="upload-icon">cloud_upload</mat-icon>

                            </div>
                            <div *ngIf="this.vertical_logo?.length<=0&&!url" class="upload-file-text"
                                (click)="choosefile()">
                                <h3>Add Image (optional)</h3>
                                Upload from computer or drag-n-drop
                                .png, <br>.jpg or .jpeg, minimum width 500px
                            </div>
                        </div>
                    </div> -->
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</mat-sidenav>