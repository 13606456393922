import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { OverlayModule } from '@angular/cdk/overlay';
// component 
import { AppComponent } from './app.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { Dataservice } from './services/dataservice';
import {
  HttpClient, HttpClientModule,
  HTTP_INTERCEPTORS

} from '@angular/common/http';
import { SigninComponent } from './signin/signin.component';
import { newdevice } from './mydevices/mydevices.component';
import { alldevices } from './mydevices/mydevices.component';
import { fromproduct } from './mydevices/mydevices.component';
import { manual } from './mydevices/mydevices.component';
import { qrcode } from './mydevices/mydevices.component';
import { rename } from './mydevices/mydevices.component';
import { deletepopup } from './mydevices/mydevices.component';
import { GeneralComponent } from './general/general.component';
import { firmwareinfo } from './over-the-air/over-the-air.component';
import { deviceinfo } from './mydevices/mydevices.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Angular material
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthGuardService } from './Authentication/auth-guard.service';
import { MatDialogModule } from '@angular/material/dialog';
import { HomeComponent } from './home/home.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MenuComponent } from './menu/menu.component';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MydevicesComponent } from './mydevices/mydevices.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { Clipboard } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { AgmCoreModule } from '@agm/core';
import { MatBadgeModule } from '@angular/material/badge';
import { GridsterComponent, GridsterItemComponent } from 'angular-gridster2';
import { NgForOf } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';

// ngx liabrary
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SocketService } from './socket.io/socket.service';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ColorPickerModule } from 'ngx-color-picker';
import { IconPickerModule } from 'ngx-icon-picker';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GooglePlaceModule } from "@yuvarajv/ngx-google-places-autocomplete";

import { cloudsComponent } from './clouds/clouds.component';
import { popup } from './clouds/clouds.component';
import { AccountComponent } from './account/account.component';
import { changepassword } from './account/account.component';
import { VerticalComponent } from './vertical/vertical.component';
import { newvertical } from './vertical/vertical.component';
import { VerticalmembersComponent } from './verticalmembers/verticalmembers.component';
import { allmembers } from './verticalmembers/verticalmembers.component';
import { UserComponent } from './user/user.component';
import { createuser } from './user/user.component';
import { useredit } from './user/user.component';
import { AllUser } from './user/user.component'
import { ProductComponent } from './product/product.component';
import { newproduct } from './product/product.component';
import { OverTheAirComponent } from './over-the-air/over-the-air.component';
import { OTAmatdialog } from './over-the-air/over-the-air.component';
import { datastreampopup } from './product/product.component';
import { widgetdatastream } from './product/product.component';

import { logout } from './menu/menu.component';
import { productinfo } from './product/product.component'
import { verticalInfo } from './vertical/vertical.component';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { environment } from 'src/environments/environment';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider
} from '@abacritt/angularx-social-login';
// primeng liabrary
import { KnobModule } from 'primeng/knob';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WebZoneComponent, webzoneEdit } from './web-zone/web-zone.component';
import { webzoneCreate } from './web-zone/web-zone.component';
import { webzoneInfo } from './web-zone/web-zone.component';
import { HttpErrorInterceptor } from './services/HttpErrorInterceptor';
import { webzonepopup } from './product/product.component';
import { SplashScreeenComponent } from './splash-screeen/splash-screeen.component';
import { SignupComponent, Validation } from './signup/signup.component';
import { googlesignup } from './signup/signup.component';
import { emailverification } from './signup/signup.component';
import { userinfo } from './user/user.component';
import { RolesPermissionComponent } from './roles-permission/roles-permission.component';
import { createRole } from './roles-permission/roles-permission.component';
import { rolesinfo } from './roles-permission/roles-permission.component';
import { GridsterModule } from 'angular-gridster2';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { confirmation } from './home/home.component';
import { tutorial } from './home/home.component';
import { MatStepperModule } from '@angular/material/stepper';
import { DownloadsComponent } from './downloads/downloads.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { newusersignin } from './signin/signin.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { forgotpassword } from './signup/signup.component';
import { resetpassword } from './signup/signup.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RoutesComponent } from './routes/routes.component';
import { ChartModule } from 'primeng/chart';
import { MatRadioModule } from '@angular/material/radio';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { Ng2TelInputModule } from 'ng2-tel-input';
// import { UiSwitchModule } from 'ngx-toggle-switch';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { analyticspopup } from './analytics/analytics.component';
import { dashboard } from './mydevices/mydevices.component';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { deviceanalytics } from './analytics/analytics.component';
import {clusterdashboard } from './product/product.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { CronjobsComponent } from './cronjobs/cronjobs.component';
import { AngularWeatherWidgetModule } from 'angular2-weather-widget';
import { APP_DATE_FORMATS, AppDateAdapter } from './date.adapter';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { schedule } from './over-the-air/over-the-air.component';
import { GroupComponent, creategroup, groupdb } from './group/group.component';
import { GaugeChartModule } from 'angular-gauge-chart'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
 let env = environment;

let googleClientId: string;
let googleClientSecret: string;
let googleMapApi: string;
googleClientId = env.googleClientId;
googleClientSecret = env.googleClientSecret;
googleMapApi = env.googleMapApi;

/**
 * Root module of the application.
 */
@NgModule({
  /**
   * Import required modules.
   */
  imports: [
    MatToolbarModule,
    GaugeChartModule,
    MatIconModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    BrowserModule,
    NgxSliderModule,
    MatBadgeModule,
    FormsModule,
    AppRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatSelectModule,
    HttpClientModule,
    GridsterModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule, NgxGaugeModule,
    MatDialogModule,
    MatToolbarModule, NgForOf, GridsterComponent, GridsterItemComponent,
    MatSidenavModule, OverlayModule,
    FontAwesomeModule,
    ChartModule,
    MatDatepickerModule,
    MatRadioModule,
    AgmCoreModule.forRoot({
      apiKey: googleMapApi,
    }),
    ToastrModule.forRoot({
      timeOut: 4000,
      progressBar: true,
      preventDuplicates: true
    }),
    NgbModule, MatStepperModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatChipsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MomentTimezonePickerModule,
    MatButtonToggleModule,
    MatTabsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    MatSlideToggleModule,
    MatSliderModule,
    ZXingScannerModule,
    DragDropModule,
    KnobModule,
    MatButtonModule,
    ColorPickerModule,
    IconPickerModule,
    OverlayModule,
    NgxChartsModule,
    MatNativeDateModule,
    GooglePlaceModule, SocialLoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatChipsModule,
    MatAutocompleteModule,
    NgxIntlTelInputModule,
    Ng2TelInputModule,
    UiSwitchModule.forRoot({

    }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: !isDevMode(),
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    AngularWeatherWidgetModule,
   
   
    // UiSwitchModule.forRoot({
    //   size: 'small',
    //   color: 'rgb(0, 189, 99)',
    //   switchColor: '#80FFA2',
    //   defaultBgColor: '#00ACFF',
    //   defaultBoColor : '#476EFF',
    //   checkedLabel: 'on',
    //   uncheckedLabel: 'off'
    // })

  ],
 
  /**
   * Declare your components.
   */
  declarations: [
    AppComponent, webzoneInfo,
    PagenotfoundComponent,
    SigninComponent,
    HomeComponent,
    clusterdashboard,
    MenuComponent,
    MydevicesComponent,
    alldevices,
    newdevice,
    fromproduct,
    manual,
    qrcode,
    rename,
    deletepopup,
    GeneralComponent,
    cloudsComponent,
    popup,
    AccountComponent,
    changepassword,
    VerticalComponent,
    newvertical,
    VerticalmembersComponent,
    allmembers,
    UserComponent,
    createuser,
    useredit,
    ProductComponent,
    newproduct,
    OverTheAirComponent,
    OTAmatdialog,
    newusersignin,
    datastreampopup,
    widgetdatastream,
    logout,
    verticalInfo,
    productinfo,
    deviceinfo,
    AllUser,
    DashboardComponent,
    WebZoneComponent,
    webzoneCreate,
    webzoneEdit,
    webzonepopup,
    SignupComponent,
    SplashScreeenComponent,
    googlesignup,
    emailverification,
    userinfo,
    RolesPermissionComponent,
    createRole,
    rolesinfo,
    confirmation,
    tutorial,
    DownloadsComponent,
    AnalyticsComponent,
    forgotpassword,
    resetpassword,
    RoutesComponent,
    Validation,
    analyticspopup,
    dashboard,
    deviceanalytics,
    CronjobsComponent,
    firmwareinfo,
    schedule,
    GroupComponent,
    groupdb,creategroup
  
  ],

  /**
 * Provide services and interceptors.
 */
  providers: [Dataservice, AuthGuardService, Clipboard, SocketService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: DateAdapter, useClass: AppDateAdapter
  },
  {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
  },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              googleClientId
            )
          },

        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  /**
  * Set the bootstrap component.
  */
  bootstrap: [AppComponent],
  /**
   * Apply the custom element schema.
   */
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  
})

export class AppModule { } export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

















