<mat-spinner
  role="progressbar"
  diameter="50"
  aria-hidden="true"
  *ngIf="loading"
  id="spinner"
>
</mat-spinner>
<div class="content" *ngIf="this.cronJobs?.length <= 0 && !loading">
  <div>
    <mat-icon>insert_drive_file</mat-icon>
    <h1 style="font-weight: 500">No Jobs</h1>
  </div>
</div>

<div class="main" *ngIf="this.cronJobs?.length >= 1 && !loading">
  <div class="main-head">
    <div class="main-head1">
      <h1>Jobs</h1>
      <h4>No Of Jobs : {{ this.cronJobs?.length }}</h4>
    </div>

      <div class="search-div">
        <input
          type="search"
          #search
          class="form-control"
          placeholder="Search Job Reference"
          [(ngModel)]="filterTerm"
          (ngModelChange)="onFilterTermChange()"
        />
        <mat-icon class="search-icon" (click)="search.focus()">search</mat-icon>
      
    </div>
     
      <!-- <mat-form-field appearance="fill" class="fld">
        <input matInput placeholder="myPlaceholder" class="inp" />
        <mat-icon matPrefix class="my-icon">search</mat-icon>
      </mat-form-field> -->
  </div>

  <div class="main-body">
    <table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Job Reference</th>
          <th>Job Type</th>
          <th>Schedule on</th>
          <th>Last Updated</th>
          <th>Job Duration</th>
          <th>Status</th>
          <th>Message</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let item of cronJobs| filter : filterTerm | paginate : cronjobtableconfig;
            let i = index
          "
        >
          <td>
            {{ (this.cronjobtableconfig.currentPage - 1) * 6 + i + 1 }}
          </td>
          <td>
            {{ item.Job_reference }}
          </td>
          <td>
            <div *ngIf="item.Job_type == 0">
              <div>Day</div>
            </div>
            <div *ngIf="item.Job_type == 1">
              <div>Week</div>
            </div>
            <div *ngIf="item.Job_type == 2">
              <div>Month</div>
            </div>
          </td>
          <td>
            {{ item.Created_on | date : "medium" }}
          </td>
          <td>
            {{ item.Last_updated | date : "medium" }}
          </td>
          <td>
            {{ item.Job_duration }}
          </td>
          <td>
            <div *ngIf="item.Actions == 0" class="status">
              <!-- <div class="userActive"></div>Active -->
              <div class="Pending">Pending...</div>
            </div>
            <div *ngIf="item.Actions == 1" class="status">
              <!-- <div class="userInActive"></div>Suspended -->
              <div class="Success">Success</div>
            </div>
            <div *ngIf="item.Actions == 2" class="status">
              <!-- <div class="userInActive"></div>Suspended -->
              <div class="Failed">Failed</div>
            </div>
          </td>
          <td [matTooltip]="item.Job_messages" matTooltipPosition="left">
           
            <span class="message">
              {{ item.Job_messages }}
            </span>
          </td>
          <td>
            <mat-icon
              *ngIf="item.Actions == 2"
              (click)="
                Reruncron(item.Created_on, item.Job_reference, item.Job_type,item.Job_token)
              "
              >autorenew</mat-icon
            >
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls
      id="job-table"
      class="pagination"
      (pageChange)="crontable($event)"
    ></pagination-controls>
  </div>
</div>
