<div class="main">

    <div class="main-head">
        <div>
            <h1>Create Roles and Permissions</h1>
            <p>Create Roles and permissions for the users by filling the form below</p>
            
        </div>
        <div >
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="main-body map-view">
        <form [formGroup]="Roles"> 
            <div class="form-field" style="width: 50%;
            margin-left: 23px;
            position: relative;
            font-weight: 500;
           margin-bottom: 20px;" >
                <label class="form-label">Roles Name<span style="color: red;position: absolute;">*</span></label>
                <input formControlName="rolename"  type="text" class="form-control" placeholder="Enter Role Name"
                    >
                    <div *ngIf="Roles.controls['rolename'].dirty" style="position: absolute;">
                        <span
                          *ngIf="Roles.controls['rolename']?.errors?.['required']"
                          style="color: red"
                        >
                          *Role name is required.
                        </span>
                        <span
                          *ngIf="Roles.controls['rolename']?.errors?.['minlength']&&!Roles.controls['rolename']?.errors?.['pattern']"
                          style="color: red"
                        >
                          *Minimum 2 characters
                        </span>
                        <span
                          *ngIf="Roles.controls['rolename']?.errors?.['maxlength']"
                          style="color: red"
                        >
                          *Maximum 20 characters
                        </span>
                        <span
                          *ngIf="Roles.controls['rolename']?.errors?.['pattern']"
                          style="color: red"
                        >
                          *Name is invalid
                        </span>
                      </div>
                    </div>

                      <div class="main-body1">
                        <table>
                          <thead>
                            <tr>
                              <th>Components</th>
                              <th>Create / Update</th>
                              <th>View</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of menuNames">
                              <td>{{item.menu_name}}<p>{{item.description}}</p></td>
                              <td>
                                <mat-checkbox class="custom-frame" color="primary" [checked]="item.create==1" [disabled]="item.read==0"  (change)="onChange(item.menu_Id, 'createOrUpdate', $event.checked)"></mat-checkbox>
                              </td>
                              <td>
                                <mat-checkbox class="custom-frame"  color="primary" [checked]="item.read==1" [disabled]="item.create==1" (change)="onChange(item.menu_Id, 'read', $event.checked)"></mat-checkbox>
                              </td>
                              <td>
                                <mat-checkbox class="custom-frame"  color="primary" [checked]="item.delete==1" [disabled]="item.read==0" (change)="onChange(item.menu_Id, 'delete', $event.checked)"></mat-checkbox>
                              </td>
                            </tr>
                          </tbody>
                        </table>
            </div>
        </form>
    
    </div>

</div>

<div class="btn-div">
  <button class="btn btn-danger" mat-dialog-close>Cancel</button>
  <button class="btn btn-primary" (click)="onSubmit()"[disabled]="this.Roles.invalid" >Create</button>
</div>
