import { Injectable } from '@angular/core';
import { Observable, window } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

/**
 * Service for managing socket communication.
 */
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: any;
  private readonly api: string = environment.socketApi;


  /**
   * Initialize the SocketService with the socket connection.
   */
  constructor() {
    // this.socket = io(this.api,{path:"/niraltek/socket.io/"});
    this.socket = io(this.api);
  }

  /**
   * Listen for a specific event from the socket.
   * @param eventName - The name of the event to listen for.
   * @returns An Observable that emits the data received from the socket.
   */
  listen(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data: any) => {
        // console.log(data);
        subscriber.next(data);
      });
    });
  }

  /**
   * Emit data to the socket for a specific event.
   * @param eventName - The name of the event to emit data for.
   * @param data - The data to emit to the socket.
   */
  emit(eventName: string, data: any): void {
    this.socket.emit(eventName, data);
  }
  
}
