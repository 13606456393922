<mat-toolbar [ngStyle]="{'display': removescreen ? 'none' : ''}" color="primary">
  <div class="logo-nav">
    <div style="width: 45px" (click)="onToolbarMenuToogle()">
      <!-- <img
        class="side-open"
        src="../../assets/icons/menu (1).png"
        *ngIf="isMenuOpen == false"
        alt=""
      /> -->
      <iconify-icon *ngIf="isMenuOpen == false" class="side-close"
        icon="solar:round-double-alt-arrow-right-broken"></iconify-icon>
      <iconify-icon *ngIf="isMenuOpen == true" class="side-close"
        icon="solar:round-double-alt-arrow-left-broken"></iconify-icon>
      <!-- 
      <iconify-icon    *ngIf="isMenuOpen == false"   class="side-close" icon="ri:arrow-right-double-line"></iconify-icon>
      <iconify-icon    *ngIf="isMenuOpen == true"   class="side-close" icon="ri:arrow-left-double-line"></iconify-icon> -->
      <!-- <img
        class="side-close"
        src="../../assets/icons/fast-forward (1).png"
        *ngIf="isMenuOpen == true"
        alt=""
      /> -->
    </div>

    <img class="logo" src="../../assets/organization/devsbot-nobeta-03.png" alt="" />
  </div>
  <div class="org-logo-user-logo">
    <div class="content-head">

      <mat-select panelClass="myPanelClass" [(value)]="this.vertical_id" *ngIf="this.superadmin"
        (selectionChange)="GetVertical($event)" placeholder="Select Verticals" disableOptionCentering>
        <mat-option [value]="0">
          All
        </mat-option>
        <mat-option *ngFor="let item of verticalvalue" [value]="item.vertical_id">
          {{item.vertical_name}}
        </mat-option>
      </mat-select>

      <i *ngIf="isread" matTooltip="Settings" routerLink="/app/general" (click)="Confirm('/app/general')"
        routerLinkActive="active" class="fa-sharp fa-solid fa-gear"></i>
      <!-- <a
        style="color: #fff"
        (click)="Confirm('https://docs.devsbot.com/devsbot/')"
        
      >
        <i matTooltip="Help" class="fa-sharp fa-solid fa-circle-info"></i
      ></a> -->

      <div class="dropdown">
        <a class="dropdown-toggle" href="#" role="button" id="notificationDropdown" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i matBadge="{{  this.messagecount >= 1 ?  this.messagecount : '' }}" matTooltip="Notification"
            class="fa-regular fa-bell"></i>
        </a>

        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notificationDropdown">
          <h6 class="dropdown-header">Notifications <span (click)="deleteallnotification()"
              class="btn btn-primary">Clear All</span></h6>
          <div class="tab-content" id="notificationTabsContent">
            <div class="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
              <div *ngIf="tendata.length == 0" class="nodevice">
                <i class="fas fa-exclamation-circle"></i>
                <h3>No Notificatons</h3>
              </div>
              <a class="dropdown-item" style="cursor: pointer" *ngFor="let item of tendata; let i = index">
                <div class="message">
                  <div class="noti_message">
                    <p (click)="
              deviceinfo(
                item.device_Id,
                item.cluster_Id,
                i,
                item.Notification_id,
                item.type_id
              )
            " class="noti" [ngClass]="{ 'new-message': !item.read_user }">
                      <span [ngClass]="{
                  blue: item.Event_type === 'Info',
                  orange: item.Event_type === 'Warning',
                  red: item.Event_type === 'Critical'
                }" [style.color]="getColorForEventType(item.Event_type)"><i class="fas fa-info-circle mr-2"></i>
                        {{ item.Event_type }} : </span>{{ item.message }}
                    </p>
                    <iconify-icon (click)="deletenotification(item.Notification_id)"
                      icon="jam:close-rectangle"></iconify-icon>
                  </div>
                  <div class="device">
                    <p class="d-flex">
                      {{ item.device_name }}
                      <span [ngClass]="{ 'new': !item.read_user }" *ngIf="!item.read_user">New</span>
                    </p>
                    <span class="notification-time"><i class="far fa-clock"></i>{{ item.timeDifference }}</span>
                  </div>
                </div>
              </a>
              <div *ngIf="
                  this.events.length >= 10 &&
                  this.events.length !== this.tendata.length
                " class="view-all-div">
                <button mat-fab extended class="down-arrow" (click)="Load()">
                  <mat-icon style="font-size: 20px; vertical-align: 0%">arrow_downward</mat-icon>
                </button>
                <!-- <iconify-icon  
                class="down-arrow"
                  (click)="Load()"
                  icon="line-md:arrow-down"
                ></iconify-icon> -->
                <!-- <button 
                  class="btn btn-primary view-all"
                 
                  (click)="Load()"
                >
                  View All
                </button> -->
              </div>
            </div>

          </div>
        </div>
      </div>

      <div (click)="Confirm('/app/account')" routerLink="/app/account" routerLinkActive="active">
        <h1 class="user-logo-name">
          Welcome! <Span class="user-name">{{ this.username }}</Span>
        </h1>
      </div>
    </div>

    <div *ngIf="this.userdata?.org_logo?.length >= 1" class="org-logo">
      <img src="../../assets/organization/{{ this.userdata?.org_logo }}" class="org-img" alt="" />
    </div>
    <div class="profilelogo" *ngIf="this.userdata?.org_logo?.length <= 0">
      {{ this.userdata?.firstname.slice(0, 1) | uppercase }}
    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav [ngStyle]="{'display': removescreen  ? 'none' : ''}" #sidenav
    [ngStyle]="{ 'transition.s': transtion }" mode="side" #sidenav1 id="sidenav" opened disableClose="false"
    [class.menu-open]="isMenuOpen" [class.menu-close]="isMenuClose">
    <app-menu (sidenavToggle)="sidenav.close()" [inputFromParent]="collapse" [inputFromParent2]="email"></app-menu>
  </mat-sidenav>

  <mat-sidenav-content class="mat-sidenav-content" [class]="opendashboard" [ngStyle]="{ 'transition.s': transtion }"
    [ngStyle]="{ 'margin-left.px': contentMargin }">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<div class="full-window" *ngIf="this.loop && showIcon">
  <div (mousemove)="onMouseMove()">
    <iconify-icon style="font-size: 40px; color: white; cursor: pointer" icon="carbon:close-outline"
      (click)="exit()"></iconify-icon>
  </div>
</div>