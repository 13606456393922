import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { SigninComponent } from './signin/signin.component';
import { AuthGuardService } from './Authentication/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { alldevices, dashboard, deviceinfo, MydevicesComponent, } from './mydevices/mydevices.component';
import { GeneralComponent } from './general/general.component';
import { cloudsComponent } from './clouds/clouds.component';
import { AccountComponent } from './account/account.component';
import { VerticalComponent, verticalInfo } from './vertical/vertical.component';
import { allmembers, VerticalmembersComponent } from './verticalmembers/verticalmembers.component';
import { AllUser, UserComponent, userinfo } from './user/user.component';
import { ProductComponent, clusterdashboard, productinfo } from './product/product.component';
import { OverTheAirComponent, firmwareinfo } from './over-the-air/over-the-air.component';
import { WebZoneComponent, webzoneInfo } from './web-zone/web-zone.component';
import { forgotpassword, googlesignup, resetpassword, SignupComponent, Validation } from './signup/signup.component';
import { RolesPermissionComponent, createRole, rolesinfo } from './roles-permission/roles-permission.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { newusersignin } from './signin/signin.component';
import { RoutesComponent } from './routes/routes.component';
import { CronjobsComponent } from './cronjobs/cronjobs.component';
import { GroupComponent, groupdb } from './group/group.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdsComponent } from './ads/ads.component';
import { VideoPlayerComponent } from './video-player/video-player.component';

// import { DashboardComponent } from './dashboard/dashboard.component';


/**
 * Routing configuration for the application.
 */
const routes: any = [
  /**
 * Redirect to the signin page by default.
 */
  {
    path: '',
    redirectTo: 'app/signin',
    pathMatch: 'full',
  },
  { path: 'RefreshComponent', redirectTo: '', pathMatch: 'full' }, // Define a dummy route and redirect it to HomeComponent
  /**
   * Route for the signin page.
   */
  {
    path: 'app/signin',
    component: SigninComponent,
  },
  /**
 * Route for the signin page with dynamic ID.
 */

  { path: 'app/signin/:id', component: SigninComponent },

  { path: 'app/forgotpassword', component: forgotpassword },

  /**
   * Route for the create password page.
   */
  { path: 'app/createpassword', component: resetpassword },
  /**
   * Route for the create password page with dynamic ID.
   */
  { path: 'app/createpassword/:id', component: resetpassword },
  /**
   * Route for the validation page.
   */
  { path: 'app/validation', component: Validation },

  {
    path: 'app',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'mydevices', component: MydevicesComponent },
      { path: 'devices', component: alldevices },
      { path: 'devicesall', component: alldevices },
      { path: 'general', component: GeneralComponent },
      { path: 'clouds', component: cloudsComponent },
      { path: 'account', component: AccountComponent },
      { path: 'vertical', component: VerticalComponent },
      { path: 'ota', component: OverTheAirComponent },
      { path: 'myverticalmembers', component: VerticalmembersComponent },
      { path: 'AllUser', component: AllUser },
      { path: 'allverticalmembers', component: allmembers },
      { path: 'cluster', component: ProductComponent },
      { path: 'clusterinfo/:id', component: productinfo },
      { path: 'clusterdashboard/:id', component: clusterdashboard },
      { path: 'verticalinfo/:id', component: verticalInfo },
      { path: 'deviceinfo/:id1/:id2', component: deviceinfo },
      { path: 'dashboard/:id1/:id2', component: dashboard },
      { path: 'firmwareinfo/:id1/:id2', component: firmwareinfo },
      { path: 'userinfo/:id', component: userinfo },
      { path: 'web-zone', component: WebZoneComponent },
      { path: 'webzoneinfo/:id', component: webzoneInfo },
      { path: 'roles-permissions', component: RolesPermissionComponent },
      { path: 'create-roles', component: createRole },
      { path: 'downloads', component: DownloadsComponent },
      { path: 'analytics', component: AnalyticsComponent },
      { path: 'analytics/:id1', component: AnalyticsComponent },
      { path: 'routes', component: RoutesComponent },
      { path: 'rolesinfo/:id', component: rolesinfo },
      { path: 'deviceinfo/:id1/:id2/:id3/:id4', component: deviceinfo },
      { path: 'cronjobs', component: CronjobsComponent },
      { path: 'ads', component: AdsComponent },
      { path: 'video', component: VideoPlayerComponent },
      { path: 'group', component: GroupComponent },
      { path: 'groupdb/:id', component: groupdb },
      {path:'Verticaldash',component:DashboardComponent},
      //  {path:'dashboard',component:DashboardComponent},

      /**
       * Catch-all route for unknown child routes.
       */

      {
        path: '**', pathMatch: 'full',
        component: PagenotfoundComponent
      },
    ],
  },
];

/**
 * Root module for routing.
 */
@NgModule({
  /**
   * Import the routes and configure preloading strategy.
   */
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],

  /**
   * Export the configured router module.
   */
  exports: [RouterModule]
})
export class AppRoutingModule { }