<div class="main">
  <div class="main-head">
    <div>
      <h1>Create Web zone</h1>
    </div>
    <div>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <div class="main-body map-view">
    <form [formGroup]="webzonedata">
      <div class="form-field">
        <label class="form-label"
          >Web zone Name <span style="color: red">*</span></label
        >
        <input
      
          type="text"
          class="form-control"
          formControlName="name"
          placeholder="Enter the Web Zone Name"
          (input)="Show()"
        />
        <div style="position: absolute;">
          <span *ngIf="webzonedata.controls['name']?.errors?.['required']" style="color: red;">
            *Field is Required.
          </span>
          <span *ngIf="webzonedata.controls['name']?.errors?.['minlength']&&!webzonedata.controls['name']?.errors?.['pattern']" style="color: red;">
              *Minimum 2 characters 
          </span>
          <span *ngIf="webzonedata.controls['name']?.errors?.['maxlength']" style="color: red;">
              *Maximum 20 characters
          </span>
          <span *ngIf="webzonedata.controls['name']?.errors?.['pattern']" style="color: red;">
              Special character not allowed
          </span>
          <span *ngIf="webzonedata.controls['name']?.errors?.['noSpaceInFirstTwoCharacters']" style="color: red">
              *Spaces are not allowed
            </span>
      </div>

      </div>
      <div class="form-field">
        <label class="form-label">SSID <span style="color: red">*</span></label>
        <input
          formControlName="ssid"
          type="text"
          id="search"
          placeholder="Enter the SSID"
          class="form-control"
        />
        <div style="position: absolute;">
          <div *ngIf="webzonedata.controls['ssid'].dirty">
            <span
              *ngIf="webzonedata.controls['ssid']?.errors?.['required']"
              style="color: red"
            >
              *Field is Required.
            </span>
          </div>
          <span
          *ngIf="webzonedata.controls['ssid']?.errors?.['minlength']"
          style="color: red"
        >
          *Minimum 2 characters
        </span>
        <span
          *ngIf="webzonedata.controls['ssid']?.errors?.['pattern']"
          style="color: red"
        >
        *Special character not allowed
        </span>
        <span *ngIf="webzonedata.controls['ssid']?.errors?.['noSpaceInFirstTwoCharacters']" style="color: red">
          *Spaces are not allowed
        </span>
        </div>
      </div>
      <div class="form-field">
        <label class="form-label"
          >Password <span style="color: red">*</span></label
        >
        <input
          formControlName="password"
          type="text"
          id="search"
          placeholder="Enter the password"
         
          class="form-control"
        />
        <div style="position: absolute;">
          <div *ngIf="webzonedata.controls['password'].dirty">
            <span
              *ngIf="webzonedata.controls['password']?.errors?.['required']"
              style="color: red"
            >
              *Field is Required.
            </span>
          </div>
          <span
          *ngIf="webzonedata.controls['password']?.errors?.['minlength']"
          style="color: red"
        >
          *Minimum 6 characters
        </span>
        <span
          *ngIf="webzonedata.controls['password']?.errors?.['maxlength']"
          style="color: red"
        >
          *Maximum 15 characters
        </span>
        <span
          *ngIf="webzonedata.controls['password']?.errors?.['pattern']"
          style="color: red"
        >
        *Special character not allowed
        </span>
        <span *ngIf="webzonedata.controls['password']?.errors?.['noSpaceInFirstTwoCharacters']" style="color: red">
          *Spaces are not allowed
        </span>
        </div>
      </div>
      <div class="form-field">
        <label class="form-label"
          >Location <span style="color: red">*</span></label
        >
        <input
          formControlName="location"
          type="text"
          id="search"
          placeholder="Search Location"
          ngx-google-places-autocomplete
          (onAddressChange)="handleAddressChange($event)"
          class="form-control"
          (input)="open()"
          #placesRef="ngx-places"
          [options]="options"
        />
        <div style="position: absolute;">
          <div *ngIf="webzonedata.controls['location'].dirty">
            <span
              *ngIf="webzonedata.controls['location']?.errors?.['required']"
              style="color: red"
            >
              *Location is Required.
            </span>
            <span
              *ngIf="webzonedata.controls['location']?.errors?.['minlength']"
              style="color: red"
            >
              *Minimum 6 characters.
            </span>
          </div>
      </div>
    </div>
      <div *ngIf="subAddress" class="form-field">
        <label class="form-label">Time Zone</label>
        <input
          formControlName="time"
          type="text"
          id="search"
          placeholder="Time Zone"
          class="form-control"
          (input)="open()"
          [value]="loading ? 'Loading...' : (timeZone || '')"
          
        />
     
      </div>
   
    </form>
    <div *ngIf="subAddress" class="map-div">
      <agm-map
        [latitude]="latitude"
        [longitude]="longitude"
        [zoom]="zoom"
        mapTypeId="hybrid"
      >
        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
      </agm-map>
    </div>
  </div>
</div>

<div class="btn-div">
  <button class="btn btn-danger" mat-dialog-close>Cancel</button>
  <button
    class="btn btn-primary"
    (click)="onsubmit()"
    [disabled]="this.webzonedata.invalid"
    mat-dialog-close
  >
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
      *ngIf="loading"
      

    ></span>
    Create
  </button>
</div>
