import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Dataservice } from '../services/dataservice';
import { HomeComponent } from '../home/home.component';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.css']
})
export class AdsComponent implements OnInit {

  private routes = [
    '/app/dashboard/1050/393',
    '/app/dashboard/1049/392',
    '/app/dashboard/1048/388',
    '/app/video'
  ];
  // private routes = [
  //   '/app/dashboard/1050/393',
  //   '/app/video'
  // ];
  refresh: any[] = []
  private currentIndex = 0;

  private currentInterval:any;
  private intervalId: any;
  loop:any;
  constructor(private router: Router,private dataservice:Dataservice,private home:HomeComponent) { }

  ngOnInit() {
    this.dataservice.setadsloop(true)
    this.home.loopstate(true)
    this.cycleRoutes();
   
  }
  

  cycleRoutes() {

    const navigateAndSetInterval = () => {
      this.router.navigate([this.routes[this.currentIndex]]);
      let isVideoRoute = this.routes[this.currentIndex] == '/app/video';
  
      // Clear existing interval if any
      if (this.currentInterval) {
        clearInterval(this.currentInterval);
      }
  
      // Set new interval based on the route type
      this.currentInterval = setInterval(() => {
        this.loop=this.dataservice.getadsloop()

        if(!this.loop){
          clearInterval(this.currentInterval);
        }
        this.currentIndex = (this.currentIndex + 1) % this.routes.length;
        if(this.loop){
          navigateAndSetInterval(); // Recursive call to change route and reset interval
        }
      }, isVideoRoute ? 70000 : 10000);
    };
  
    // Initialize the first navigation and interval
    this.currentIndex = 0;
    navigateAndSetInterval();
  }

  // cycleRoutes() {
  //   this.router.navigate([this.routes[this.currentIndex]]);
  //   let isVideoRoute;
  //   this.refresh.push(setInterval(() => {
  //     this.currentIndex = (this.currentIndex + 1) % this.routes.length;
  //     this.router.navigate([this.routes[this.currentIndex]])
  //     isVideoRoute = this.routes[this.currentIndex] === '/app/video';
  //     console.log(isVideoRoute)
  //     // If the current route is the video route, wait 15 seconds, otherwise wait 10 seconds

  //   }, isVideoRoute ? 20000 : 8000)) // Change route every 5 seconds
  // }


  // navigateToRoute() {
  //   this.router.navigate([this.routes[this.currentIndex]]);
  //   const isVideoRoute = this.routes[this.currentIndex] === 'video';
  //   console.log(isVideoRoute)
  //   clearInterval(this.intervalId);
  //   this.intervalId = setInterval(() => {
  //     this.currentIndex = (this.currentIndex + 1) % this.routes.length;
  //     this.router.navigate([this.routes[this.currentIndex]]);

  //     this.navigateToRoute();
  //   }, isVideoRoute ? 20000 : 8000); // 20 seconds for video, 5 seconds for others
  // }

  // ngOnDestroy() {
  //   for (const intervalId of this.refresh) {
  //     clearInterval(intervalId);
  //   }
  // }



}
