<mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="loading" id="spinner">
</mat-spinner>

<!-- <div class="content" *ngIf="verticalvalue?.length==0&&devicevalue?.length==0">
  <div>
    <h1 style="font-weight: 500">vertical</h1>
    <p style="font-weight: 500">
      Create the tree-like structure of Vertical and invite to them users who
      will use the same sets of Clusters and Devices
    </p>
  </div>
  <div>
    <button class="btn btn-primary" (click)="newvertical()">
      + Create vertical
    </button>
  </div>
</div> -->
<div class="content" *ngIf="isread == false">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p style="font-weight: 500">
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>

<div class="content" *ngIf="productvalue?.length==0&& isread == true">
  <div>
    <h1 style="font-weight: 500">Start by creating your first Cluster</h1>
    <p style="font-weight: 500">
      Cluster is a digital model of a physical object. It is used in Devsbot
      platform as a Cluster to be assigned to devices.
    </p>
  </div>
  <div>
    <button class="btn btn-primary" (click)="newproduct()">
      + New Cluster
    </button>
  </div>
</div>
<div class="content" *ngIf="devicevalue?.length==0&&productvalue?.length>=1&& isread == true">
  <div>
    <h1 style="font-weight: 500">All of your devices will be here.</h1>
    <p style="font-weight: 500">
      You can activate new devices by using your app.
    </p>
  </div>

  <div class="newdev-alldev">
    <button class="btn btn-primary" (click)="newdevice()">+ New Device</button>

    <button class="btn btn-success" *ngIf="this.Vertical_Id==null" routerLink="/app/mydevices">
      My Devices
    </button>
  </div>
</div>

<div *ngIf="
devicevalue?.length >= 1 && productvalue?.length >= 1 && isread == true
">
  <div class="col-md-12 d-flex">
    <div class="col-md-6">
      <div class="main-head">
        <div class="d-flex" style="align-items: center; gap: 5px">
          <h1>All Devices</h1>
          <!-- <mat-icon
            class="help"
            (click)="sidenav.toggle()"
            matTooltipClass="my-tooltip"
            matTooltip="Help"
            matTooltipPosition="right"
            >info
          </mat-icon> -->
        </div>
      </div>
      <div class="count-device">
        <div>
          <span class="count-device-font" *ngIf="this.deletedevice_id.length<=0">
            <h4>
              No Of Devices : {{filterdevicevalue?.length}} &nbsp;
              <i (click)="alldevicedatacsv()"  matTooltipPosition="below" matTooltip="Export
                devices(CSV)" id="more-vert1" class="fa-solid fa-file-export"></i>
            </h4>
          </span>
          <span class="count-device-font" *ngIf="this.deletedevice_id.length>=1">
            <h4>
              No Of Devices selected : {{this.deletedevice_id.length}} &nbsp;
              <i class="fa-solid fa-ellipsis" [matMenuTriggerFor]="devicedelete" id="more-vert1"></i>
            </h4>
          </span>
        </div>
        <div>
          <!-- <mat-icon [matMenuTriggerFor]="list" class="more-vert">more_vert</mat-icon> -->
          <!-- <mat-menu #list="matMenu">
              <button
                mat-menu-item
                class="mat-menu-button"
                (click)="alldevicedatacsv()"
              >
                <mat-icon class="menu-btn-icon">sim_card</mat-icon>Export
                devices(CSV)
              </button>
              <button
                mat-menu-item
                class="mat-menu-button"
                routerLink="/app/qrcode/manage"
              >
                <mat-icon class="menu-btn-icon">qr_code</mat-icon>Manage Qrcode
              </button>
            </mat-menu> -->
          <mat-menu #devicedelete="matMenu">
            <button mat-menu-item class="mat-menu-button" (click)="multipledevicesdelete()">
              <mat-icon class="menu-btn-icon">delete</mat-icon>Delete
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <div class="col-md-6 d-flex new-dev-sec">
      <div class="buttons" *ngIf="this.iscreate">
        <button class="btn btn-primary new-dev" (click)="newdevice()">
          New Device <i class="fa-solid fa-plus"></i></button><button class="btn btn-success all-dev"
          *ngIf="this.Vertical_Id==null" routerLink="/app/mydevices">
          My Devices
        </button>
      </div>
      <div class="search-div">
        <input type="search" #search class="form-control" placeholder="Search Devices" [(ngModel)]="filterTerm"
          (ngModelChange)="onFilterTermChange()" />
        <mat-icon class="search-icon" (click)="search.focus()">search</mat-icon>
      </div>
    </div>
  </div>

  <div class="main-body" [ngClass]="{'tableextract':this.devicetableconfig.itemsPerPage>6}">
    <table>
      <thead>
        <tr>
          <th id="checkbox">
            <mat-checkbox (click)="validatepermission($event)" color="primary" (change)="allCheckboxChange($event)"
              [indeterminate]="
                this.deletedevice_id.length >= 1 && this.allselect == false
              " [checked]="this.allselect == true">
            </mat-checkbox>
          </th>
          <th>Device Name</th>
          <th>
            Gateway Name<button [matMenuTriggerFor]="menu1">
    <iconify-icon *ngIf="this.productvalues.length!=this.selectedCategoriescluster.size"  class="search_icon" icon="material-symbols:filter-alt"></iconify-icon>
              <!-- <div [ngClass]="{'activefilters':this.productvalues.length!=this.selectedCategoriescluster.size}" ></div> -->
              <iconify-icon *ngIf="this.productvalues.length==this.selectedCategoriescluster.size" class="search_icon"  icon="ion:filter-outline"></iconify-icon>
            </button>
            <mat-menu fittingWidth="fit" class="productfilter" #menu1="matMenu" xPosition="after" yPosition="below">
              <!-- <button  mat-menu-item></button> -->
              <!-- <mat-radio-button value="1">{{ item.device_name }}</mat-radio-button> -->
              <div class="drop-value">
                <section style="height: 50px" class="example-section" *ngFor="let value of productvalues">
                  <mat-checkbox color="primary" [checked]="value.ischecked" class="checkbox-ellipse"
                    (click)="$event.stopPropagation()" (change)="filterClusterName(value.cluster_name, $event)"
                    matTooltip="{{ value.cluster_name }}" matTooltipPosition="left"><span class="ellipse">{{
                      value.cluster_name }}</span></mat-checkbox>

                  <!-- <mat-radio-button value="2">Option 2</mat-radio-button> -->
                </section>
              </div>
              <div class="drop-btn d-flex">
                <button class="btn btn-primary" (click)="$event.stopPropagation()"  (click)="SelectallFilters(true,'Cluster')">
                  Select All
                </button>
                <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="SelectallFilters(false,'Cluster')">
                  clear
                </button>
              </div>
            </mat-menu>
          </th>

          <th>
            Status<button [matMenuTriggerFor]="menu2">
              <iconify-icon *ngIf="!this.online||!this.offline||!this.Inactive"  class="search_icon" icon="material-symbols:filter-alt"></iconify-icon>
              <!-- <div *ngIf=""[ngClass]="{'activefilters':!this.online||!this.offline||!this.Inactive}" ></div> -->
              <iconify-icon *ngIf="this.online&&this.offline&&this.Inactive"class="search_icon"  icon="ion:filter-outline"></iconify-icon>
            </button>
            <mat-menu #menu2="matMenu" fittingWidth="fit" xPosition="after" yPosition="below">
              <!-- <button  mat-menu-item></button> -->
              <!-- <mat-radio-button value="1">{{ item.device_name }}</mat-radio-button> -->
              <div class="drop-value" [formGroup]="statusform">
                <section class="example-section">
                  <mat-checkbox class="status-filter" formControlName="online" color="primary"
                    (click)="$event.stopPropagation()" (change)="deviceonlinefilter($event)">
                    Online
                  </mat-checkbox>
                  <mat-checkbox color="primary" class="status-filter" formControlName="offline"
                    (click)="$event.stopPropagation()" (change)="deviceofflinefilter($event)">
                    Offline</mat-checkbox>
                  <mat-checkbox color="primary" class="status-filter" formControlName="Inactive"
                    (click)="$event.stopPropagation()" (change)="deviceInactivefilter($event)">
                    Inactive</mat-checkbox>
                  <!-- <mat-radio-button value="2">Option 2</mat-radio-button> -->
                </section>
              </div>
              <div class="drop-btn d-flex">
                <button class="btn btn-primary" (click)="$event.stopPropagation()"  (click)="SelectallFilters(true,'Status')">
                  Select All
                </button>
                <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="SelectallFilters(false,'Status')">
                  clear
                </button>
              </div>
            </mat-menu>
          </th>
          <th>
            Wifi Status
            <button  [matMenuTriggerFor]="menu9">
              <iconify-icon *ngIf="this.selectedwifi.size!=this.wifistatus.length"   class="search_icon" icon="material-symbols:filter-alt"></iconify-icon>
              <!-- <div [ngClass]="{'activefilters':this.selectedwifi.size!=this.wifistatus.length}" ></div> -->
              <iconify-icon *ngIf="this.selectedwifi.size==this.wifistatus.length" class="search_icon" icon="ion:filter-outline"></iconify-icon>
            </button>
            <mat-menu class="filter-menus" fittingWidth="fit" class="productfilter" #menu9="matMenu" xPosition="after"
              yPosition="below">
              <div class="drop-value">
                <section style="height: 50px" class="example-section wifii" *ngFor="let value of wifistatus">
                  <mat-checkbox color="primary" [checked]="value.ischecked" class="checkbox-ellipse"
                    (click)="$event.stopPropagation()" (change)="filterWifiName(value.name, $event)"
                    matTooltip="{{value.name}}" matTooltipPosition="left"><span
                      class="ellipse">{{value.name}}</span></mat-checkbox>
                  <!-- <mat-radio-button value="2">Option 2</mat-radio-button> -->
                </section>
              </div>
              <div class="drop-btn d-flex">
                <button class="btn btn-primary" (click)="$event.stopPropagation()"  (click)="SelectallFilters(true,'Wifistatus')">
                  Select All
                </button>
                <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="SelectallFilters(false,'Wifistatus')">
                  clear
                </button>
              </div>
            </mat-menu>
          </th>
          <th>Slave Id</th>
        
          <th>
            Vertical

            <button  [matMenuTriggerFor]="menu8">
              <iconify-icon  *ngIf="this.selectedCategoriesvertical.size!=this.verticalvalues.length"  class="search_icon" icon="material-symbols:filter-alt"></iconify-icon>
              <!-- <div *ngIf="this.selectedCategoriesvertical.size!=this.verticalvalues.length"[ngClass]="{'activefilters':this.selectedCategoriesvertical.size!=this.verticalvalues.length}" ></div> -->
              <iconify-icon  *ngIf="this.selectedCategoriesvertical.size==this.verticalvalues.length" class="search_icon" icon="ion:filter-outline"></iconify-icon>
            </button>
            <mat-menu class="filter-menus" fittingWidth="fit" class="productfilter" #menu8="matMenu" xPosition="after"
              yPosition="below">
              <div class="drop-value">
                <section style="height: 50px" class="example-section" *ngFor="let value of verticalvalues">
                  <mat-checkbox color="primary" [checked]="value.ischecked" class="checkbox-ellipse"
                    (click)="$event.stopPropagation()" (change)="filterVerticalName(value.vertical_name, $event)"
                    matTooltip="{{ value.vertical_name }}" matTooltipPosition="left"><span class="ellipse">{{
                      value.vertical_name }}</span></mat-checkbox>

                  <!-- <mat-radio-button value="2">Option 2</mat-radio-button> -->
                </section>
              </div>
              <div class="drop-btn d-flex">
                <button class="btn btn-primary" (click)="$event.stopPropagation()"  (click)="SelectallFilters(true,'Vertical')">
                  Select All
                </button>
                <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="SelectallFilters(false,'Vertical')">
                  clear
                </button>
              </div>
            </mat-menu>
          </th>
          <th>Version</th>
          <!-- <th>
            Device Owner
            <button  [matMenuTriggerFor]="menu">
              <iconify-icon  *ngIf="this.selectedCategoriesuser.size!=this.uniqueValues.length" class="search_icon" icon="material-symbols:filter-alt"></iconify-icon>
          
              <iconify-icon *ngIf="this.selectedCategoriesuser.size==this.uniqueValues.length" class="search_icon" icon="ion:filter-outline"></iconify-icon>

            </button>
            <div style="width: 100%">

              <mat-menu #menu="matMenu" xPosition="after" yPosition="below">
                <div class="drop-value">
                  <section class="example-section" *ngFor="let value of uniqueValues">
                    <mat-checkbox color="primary" [checked]="value.ischecked" (click)="$event.stopPropagation();"
                      (change)="filterDeviceOwner(value.firstname,$event)">{{ value.firstname }}</mat-checkbox>

               
                  </section>
                </div>
                <div class="drop-btn d-flex">
                  <button class="btn btn-primary" (click)="$event.stopPropagation()"  (click)="SelectallFilters(true,'User')">
                    Select All
                  </button>
                  <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="SelectallFilters(false,'User')">
                    clear
                  </button>
                </div>
              </mat-menu>
            </div>
          </th> -->

          <!-- <th>Device Model</th> -->
          <!-- <th>Last Updated</th> -->
          <!-- <th>Zone Name</th> -->
          <!-- <th>Qr code</th> -->
          <!-- <th>Organization</th> -->
          <th>Activation Date</th>
          <!-- <th>Mac Id</th> -->
          <th>Actions</th>
        </tr>
      </thead>
      <div *ngIf="filterdevicevalue?.length==0" class="no-data">
        <mat-icon> insert_drive_file</mat-icon>
        <h5>No Data</h5>
      </div>
      <tbody>
        <tr *ngIf="filterdevicevalue?.length==0">
          <td style="padding: 40px !important; display: flex" colspan="8"></td>
        </tr>
        <tr *ngFor="let item of filterdevicevalue|filter:filterTerm|paginate: devicetableconfig">
          <td id="checkbox">
            <div *ngIf="item.cluster_logo?.length <= 0">
              <svg xmlns="http://www.w3.org/2000/svg" id="device-icon" style="width: 30px" class="h-6 w-6" fill="none"
                viewBox="0 0 24 24" stroke="#3f51b5" stroke-width="2" [ngClass]="{
                    'svg-hide': item.isSelected == true || this.checked == true,
                    svg: item.isSelected == false
                  }">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
              </svg>
            </div>
            <!-- <div *ngIf="item.cluster_logo?.length >= 1">
                <img
                  src="../../assets/product/{{ item.cluster_logo }}"
                  alt=""
                  style="width: 30px; min-height: 4vh"
                  [ngClass]="{
                    'svg-hide':this.checked==true||this.deletedevice_id.length>=1,
                    'svg':this.checked==false&&this.deletedevice_id.length<=0}
                   "
                />
              </div> -->
            <mat-checkbox class="example-margin" (click)="validatepermission($event)"
              (change)="onCheckboxChange($event, item.device_id)" [checked]="item.isSelected" color="primary" [ngClass]="{
                'example-margin': this.deletedevice_id.length <= 0
              }"></mat-checkbox>
          </td>

          <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <span class="data-info">{{ item.device_name }}</span>
          </td>
          <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <span class="data-info">{{ item.cluster_name }}</span>
          </td>

          <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <div>
              <!-- <mat-spinner
                  role="progressbar"
                  diameter="25"
                  aria-hidden="true"
                  *ngIf="statusloading"
                >
                </mat-spinner> -->
              <iconify-icon *ngIf="statusloading" icon="svg-spinners:bars-fade"></iconify-icon>
            </div>
            <div class="offline" *ngIf="
              +this.currenttime - +item.last_online > 100 * item.heartbeat &&
              (item.status_Id == 1 || item.status_Id==2) &&
              !statusloading
            " matTooltip="Last Online Since {{
                  item.last_online | date : 'short'
                }}" matTooltipPosition="left">
              Offline
            </div>
            <div class="online" *ngIf="
              +this.currenttime - +item.last_online < 100 * item.heartbeat &&
              (item.status_Id == 1 || item.status_Id==2)&&
              !statusloading
            ">
              Online
              <!-- <span *ngIf="!statusloading">{{ item.wifi_status }}</span> -->
              <iconify-icon class="highlighted" *ngIf="item.status_Id == 2" style="font-size: 18px; color: red"
                icon="fluent:plug-disconnected-28-regular"></iconify-icon>
            </div>

            <div class="Inactive" *ngIf="item.status_Id == 0 &&!statusloading">
              Inactive
              <!-- <span *ngIf="!statusloading">{{ item.wifi_status }}</span> -->
            </div>
            <!-- <div class="online"
            *ngIf="
              +this.currenttime - +item.last_online < 1000 * item.heartbeat &&
              item.status_Id == 1 &&
              !statusloading
            "
            [ngStyle]="{'color': getColorForWifiStatus(item.wifi_status),'border-color':getColorForWifiStatus(item.wifi_status)}"
       >
         Online
         <span *ngIf="!statusloading">{{ item.wifi_status }}</span>
       </div> -->
          </td>
          <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <div>
              <!-- <mat-spinner
                role="progressbar"
                diameter="25"
                aria-hidden="true"
                *ngIf="statusloading"
              >
              </mat-spinner> -->
              <iconify-icon *ngIf="statusloading" icon="svg-spinners:bars-fade"></iconify-icon>
            </div>
            <div matTooltip="{{item.wifi_status + ' dBm'}}" matTooltipPosition="left" class="status_online"
              *ngIf="+this.currenttime - +item.last_online < 100 * item.heartbeat &&(item.status_Id == 1 ||item.status_Id==2) &&!statusloading">
              <span *ngIf="!statusloading && item.wifi_status >= -50">
                Excellent
                <iconify-icon style="color: #027402; font-size: 23px"
                  icon="ic:baseline-signal-wifi-4-bar"></iconify-icon>
              </span>
              <span *ngIf="!statusloading && item.wifi_status < -50 && item.wifi_status >= -60">
                Good
                <iconify-icon style="color: #027402; font-size: 23px"
                  icon="ic:baseline-signal-wifi-3-bar"></iconify-icon>
              </span>
              <span *ngIf="!statusloading && item.wifi_status < -60 && item.wifi_status > -70">
                Average
                <iconify-icon style="color: #df8f0f; font-size: 23px"
                  icon="ic:baseline-signal-wifi-2-bar"></iconify-icon>
              </span>
              <span *ngIf="!statusloading && item.wifi_status <= -70">
                Bad
                <iconify-icon style="color: #c80609; font-size: 23px"
                  icon="ic:baseline-signal-wifi-1-bar"></iconify-icon>
              </span>
            </div>
            <div class="status_offline" *ngIf="
              +this.currenttime - +item.last_online > 100 * item.heartbeat &&
              (  item.status_Id == 2 || item.status_Id==1) &&
              !statusloading ||(item.status_Id == 0 && !statusloading)
            ">
              Disconnected
            </div>
          </td>
          <td>
            <span> {{item.slave_id}} </span>
          </td>
 

          <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <span>{{ item.vertical_name === null ? "Super Admin":item.vertical_name}}</span>
          </td>

          <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <div>
              <!-- <mat-spinner
                  role="progressbar"
                  diameter="25"
                  aria-hidden="true"
                  *ngIf="statusloading"
                >
                </mat-spinner> -->
              <iconify-icon *ngIf="statusloading" icon="svg-spinners:bars-fade"></iconify-icon>
            </div>
            <span *ngIf="!statusloading">{{ item.device_version }}</span>
          </td>
          <!-- <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <span class="data-info">{{ item.firstname }}</span>
          </td> -->
          <!-- <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <span>{{ item.hardware_name }}</span>
          </td> -->
          <!-- <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <span>{{ item.last_online | date : "medium" }}</span>
          </td>
          <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            {{ item.zonename }}
          </td>
          <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            {{ item.qrcode }}
          </td>
          <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <span>{{ item.org_Name }}</span>
          </td> -->
          <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <span class="data-content">{{ item.device_activation | date : "medium" }}</span>
          </td>

          <!-- <td (click)="deviceinfo(item.device_id, item.cluster_id)">
            <span>{{ item.device_mac_Id }}</span>
          </td> -->
          <td class="action">
            <!-- <mat-icon [matMenuTriggerFor]="menu" (click)="info(item.device_id)" class="action-icon">more_vert
              </mat-icon> -->
            <i [ngClass]="{ disabled: deletedevice_id.length === 6 ||this.checked == true
              }" class="fa-solid fa-ellipsis action-icon" [matMenuTriggerFor]="menu" id="more-vert"></i>
            <mat-menu #menu="matMenu">
              <button mat-menu-item class="mat-menu-button"
                (click)="rename(item.device_id,item.device_name,item.slave_id,item.device_auth_token)">
                <mat-icon class="menu-btn-icon">keyboard</mat-icon>Edit
              </button>
              <button mat-menu-item class="mat-menu-button" (click)="devicereport(item.device_id,item.device_name)">
                <mat-icon class="menu-btn-icon">sim_card</mat-icon>Download
                Report
              </button>

              <button  *ngIf="item.slave_id === 1" mat-menu-item class="mat-menu-button" (click)="devicereboot( item.cluster_api_Id,
                item.last_online,item.heartbeat)">
                <mat-icon class="menu-btn-icon">autorenew</mat-icon>Reboot
              </button>

              <button mat-menu-item class="mat-menu-button" *ngIf="this.iscreate"
                (click)="erasedevicedata(item.device_id,item.device_name)">
                <mat-icon class="menu-btn-icon">highlight_off</mat-icon>Erase
                Data
              </button>
              <button *ngIf="this.iscreate" mat-menu-item class="mat-menu-button"
                (click)="deletedevice(item.device_id)">
                <mat-icon class="menu-btn-icon">delete</mat-icon>Delete
              </button>
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>

    <pagination-controls
      [ngClass]="{ disabled: deletedevice_id.length === 6 || this.checked,paginationoffall:this.devicetableconfig.itemsPerPage>6 }"
      id="device-table" class="pagination" (pageChange)="devicetable($event)"></pagination-controls>

    <span [ngClass]="{ disabled: deletedevice_id.length === 6 || this.checked}"
      *ngIf="this.devicetableconfig.itemsPerPage<=6" (click)="showall()" class="pagination showall">
      Show All
    </span>
    <span
      [ngClass]="{ disabled: deletedevice_id.length === 6 || this.checked,collapsetableall:this.devicetableconfig.itemsPerPage>6}"
      *ngIf="this.devicetableconfig.itemsPerPage>6" (click)="collapse()" class="pagination showall">
      Collapse
    </span>
  </div>
</div>