
<mat-spinner
role="progressbar"
diameter="50"
aria-hidden="true"
*ngIf="loading"
id="spinner"
>
</mat-spinner>

<!-- <div class="content" >
<div>
  <h1 style="font-weight: 500">
    Use Roles and Permission to organize your application better
  </h1>
  <p style="font-weight: 500">
    Start by creating Roles and Permission. Once created,you can assign Roles and permission to your users
  </p>
</div>
<div class="newloc-allloc new-dev">
  <button class="btn btn-primary" (click)="open()">
    Create Roles and Permission <i class="fa-solid fa-plus"></i>
  </button>
</div>
</div> -->
<div class="content" *ngIf="this.isread == false">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p style="font-weight: 500;text-transform: capitalize;margin: 0 !important;">
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>

<!-- role START VIEW PAGE START -->

<div style="padding: 10px" *ngIf=" this.isread == true && !loading">
  <div class="col-md-12 d-flex">
    <div class="col-md-6">
      <div class="main-head1">
        <div class="d-flex" style="align-items: center; gap:5px">
          <h1>Roles And Permissions</h1>
          <!-- <h1>Roles And Permissions <mat-icon
            class="help" (click)="sidenav.toggle()"
            matTooltipClass="my-tooltip"
            matTooltip="Help"
            matTooltipPosition="right"
            >info
          </mat-icon></h1> -->
        </div>    
      </div>
      <div class="count-role">
        <div>
          <span class="count-role-font">
            <h4>No Of Roles : {{ this.Roles.length }}</h4>
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-flex new-dev-sec">
      <div class="buttons">
        <button class="btn btn-primary new-dev" (click)="open()">
          New Roles <i class="fa-solid fa-plus"></i>
        </button>
      </div>
      <div class="search-div" *ngIf="Roles.length >= 1">
        <input
          type="search"
          #search
          class="form-control"
          placeholder="Search Roles"
          [(ngModel)]="filterTerm"
        />
        <mat-icon class="search-icon" (click)="search.focus()">search</mat-icon>
      </div>
    </div>
  </div>
</div>
<div class="roles-permission" *ngIf=" this.isread == true  && !loading">
  <div class="role-body" *ngIf=" this.isread == true">
    <div
      class="role"
      (click)="edit(item.group_Id)"
      *ngFor="let item of Roles | filter : filterTerm"
    >
      <div class="role-info">
        <h6>{{ item.regdate | date : "medium" }}</h6>
        <h6>{{item.created_date|date:"medium"}}</h6>

        <div class="rolelogo">
          <!-- profilelogo -->
          {{item.group_name.slice(0,1)|uppercase}}
        </div>
      </div>
      <div class="role-preview">
        <h6>&nbsp;</h6>
        <h2>{{ item.group_name }}</h2>
        <!-- <div >{{item.usercount
      }}</div> -->
        <div *ngIf="item.usercount">No Of Users : {{ item.usercount }}</div>
        <div *ngIf="!item.usercount">No Of Users : No Users</div>
        </div>
    </div>
  </div>
</div>
<!-- role START VIEW  PAGE END -->

<!-- <ng-container >
  <app-createRole *ngIf="showcreateRole"></app-createRole>
</ng-container> -->

  
 