<div class="content">
    <div class="content-head">
        <div>
            <h1 class="heading">Create new Clouds</h1>
        </div>
        <div>
            <mat-icon mat-dialog-close class="close-icon">close</mat-icon>
        </div>
    </div>
    <div class="content-body">
        <form action="">
            <div class="form-field">
                <label class="form-label">Choose Cluster<span class="imp">*</span></label>
                <input type="text" formControlName="name" class="form-control" placeholder="Clouds name">
                <!-- <div *ngIf="verticalform.controls['name'].dirty">
                    <span *ngIf="verticalform.controls['name']?.errors?.['required']" style="color: red;">
                        *Name is required.
                    </span> -->
            </div>
            <div class="form-field">
                <label class="form-label">Choose Device<span class="imp">*</span></label>
                <input type="text" formControlName="name" class="form-control" placeholder="Clouds name">
                <!-- <div *ngIf="verticalform.controls['name'].dirty">
                    <span *ngIf="verticalform.controls['name']?.errors?.['required']" style="color: red;">
                        *Name is required.
                    </span> -->
            </div>
            <div class="form-field">
                <label class="form-label">Choose Data feeds<span class="imp">*</span></label>
                <input type="text" formControlName="name" class="form-control" placeholder="Clouds name">
                <!-- <div *ngIf="verticalform.controls['name'].dirty">
                    <span *ngIf="verticalform.controls['name']?.errors?.['required']" style="color: red;">
                        *Name is required.
                    </span> -->
            </div>
            <!-- <div class="form-field form-field-one"><label class="form-label">webhook triger event <span class="imp">*</span></label>
                <select class="form-select">
                    <option (click)="sendfun()">Device sends data to datastream</option>
                </select>
            </div> -->
            <div *ngIf="isSend">
                <div class="form-field form-field-two">
                    <label class="form-label">Device</label>
                    <input type="text" class="form-control" >
                </div>
                <div class="form-field">
                    <label class="form-label">Datastream</label>
                    <input type="text" class="form-control">
                </div>
            </div>
            <div class="form-field"><label class="form-label">webhook url <span class="imp">*</span></label>
                <input class="form-control" placeholder="URl" formControlName="url">
            </div>
            <div class="form-field">
                <label class="form-label">request type <span class="imp">*</span></label>
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="btn-group-toggle">
                    <mat-button-toggle (click)="getfun()">GET</mat-button-toggle>
                    <mat-button-toggle>POST</mat-button-toggle>
                    <mat-button-toggle>PUT</mat-button-toggle>
                    <mat-button-toggle>DELETE</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="form-field"><label class="form-label">content type <span class="imp">*</span></label>
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="btn-group-toggle">
                    <mat-button-toggle (click)="webformfun()">Web Form</mat-button-toggle>
                    <mat-button-toggle (click)="plainfun()">Plain Text</mat-button-toggle>
                </mat-button-toggle-group>
                <div *ngIf="webform" class="form-field">
                    <label class="form-label">form field construter</label>
                    <div class="dbl-input">
                        <div class="input-field">
                            <input type="text" class="form-control" placeholder="key">
                        </div>
                        <div class="input-field"><input type="text" class="form-control" placeholder="value"></div>
                    </div>
                </div>

                <div *ngIf="plaintext" class="form-field2">
                    <div class="input-field">
                        <!-- <input type="text" class="form-control" id="text-input"> -->
                        <textarea class="form-control rounded-0" id="exampleFormControlTextarea2" rows="6"></textarea>


                    </div>
                    <div class="input-field"> <label class="form-label"><span>dynamic data</span></label>
                        <input type="search" class="form-control" id="search-input">
                        <mat-icon class="search-icon">search</mat-icon>
                    </div>

                </div>
                <div class="form-field1">
                    <label class="form-label">query parameters(optional)</label>
                    <div class="dbl-input">
                        <div class="input-field"><input type="text" class="form-control" placeholder="Key"></div>
                        <div class="input-field"><input type="text" class="form-control " placeholder="Value"></div>
                    </div>
                </div>

            </div>
            <div class="form-field">
                <label class="form-label">Authorization Method (optional)</label>
                <label class="form-label sub-menu">authentication type</label>

                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="btn-group-toggle">
                    <mat-button-toggle (click)="basicfun()">Basic Auth</mat-button-toggle>
                    <mat-button-toggle (click)="authfun()">OAuth2.0</mat-button-toggle>
                    <mat-button-toggle (click)="nonefun()">None</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div *ngIf="basic" class="form-field1">
                <div class="dbl-input">
                    <div class="input-field">
                        <label class="form-label">username</label>
                        <input type="text" class="form-control" placeholder="Name">
                    </div>
                    <div class="input-field"><label class="form-label">password</label>
                        <input type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div *ngIf="auth" class="form-field1">
                <div class="dbl-input">
                    <div class="input-field">
                        <label class="form-label">client id</label>
                        <input type="text" class="form-control" placeholder="Client Id">
                    </div>
                    <div class="input-field">
                        <label class="form-label">client secret</label>
                        <input type="text" class="form-control" placeholder="Client Secret">
                    </div>
                </div>
                <div class="form-field">
                    <label for="" class="form-label">token url</label>
                    <input type="text" class="form-control" placeholder="Token URl">
                </div>

            </div>
            <div class="form-field">
                <label class="form-label">HTTP Headers(optional)</label>
         
            <div class="form-field1">

                <div class="dbl-input">
                    <div class="input-field"> <input type="text" class="form-control" placeholder="Key"></div>
                    <div class="input-field"> <input type="text" class="form-control" placeholder="value"></div>
                </div>
            </div>
            </div>
            <div class="form-field">
                <label class="form-label">failure receivers(optional)</label>
                <input type="text" class="form-control">
            </div>
            <div class="form-field">
                <label for="" class="form-label">Send failure email after 10 failure/s  <span class="imp">*</span></label>
                <input type="number" class="form-control">
            </div>



        </form>
    </div>
    <div class="btn-div">
        <div><button class="btn btn-primary">
                Test webhook <mat-icon class="sett">build</mat-icon>
            </button>
        </div>
        <div class="right-side-btn">
            <button class="btn btn-danger" id="cancel" mat-dialog-close>cancel</button>
            <button class="btn btn-success">create webhook</button>
        </div>
    </div>
</div>