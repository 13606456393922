<div class="main">
  <div class="main-head">
    <div>
      <h1>Edit Web Zone</h1>
      <p class="text-content">Edit new Web Zone by filling in the form below.</p>
    </div>
    <div>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <div class="main-body map-view">
    <form [formGroup]="form" #webzoneForm="ngForm">
      <div class="form-field">
        <label class="form-label"
          >Web Zone Name <span style="color: red">*</span></label
        >
        <input
          formControlName="webzone_name"
          type="text"
          class="form-control"
          placeholder="Enter  Web Zone Name"
        />
        <div  style="position: absolute;font-size: 12px; color: red; margin-left: 10px">
          <span *ngIf="form.controls['webzone_name']?.errors?.['required']" style="color: red;">
            *Field is Required.
          </span>
          <span *ngIf="form.controls['webzone_name']?.errors?.['minlength']&&!form.controls['webzone_name']?.errors?.['pattern']" style="color: red;">
              *Minimum 2 characters 
          </span>
          <span *ngIf="form.controls['webzone_name']?.errors?.['maxlength']" style="color: red;">
              *Maximum 20 characters
          </span>
          <span *ngIf="form.controls['webzone_name']?.errors?.['pattern']" style="color: red;">
              Special character not allowed
          </span>
          <span *ngIf="form.controls['webzone_name']?.errors?.['noSpaceInFirstTwoCharacters']" style="color: red">
              *Spaces are not allowed
            </span>
      </div>
       
      </div>
      <div class="form-field">
        <label class="form-label">SSID <span style="color: red">*</span></label>
        <input
          formControlName="SSID"
          type="text"
          id="search"
          placeholder="Enter the SSID"
          class="form-control"
        />
        <div style="position: absolute; font-size: 12px; color: red; margin-left: 10px">
          <div *ngIf="form.controls['SSID'].dirty">
            <span
              *ngIf="form.controls['SSID']?.errors?.['required']"
              style="color: red"
            >
              *Field is Required.
            </span>
          </div>
          <span
          *ngIf="form.controls['SSID']?.errors?.['minlength']"
          style="color: red"
        >
          *Minimum 2 characters
        </span>
        <span
          *ngIf="form.controls['SSID']?.errors?.['pattern']"
          style="color: red"
        >
        *Special character not allowed
        </span>
        <span *ngIf="form.controls['SSID']?.errors?.['noSpaceInFirstTwoCharacters']" style="color: red">
          *Spaces are not allowed
        </span>
        </div>
        
      </div>
      <div class="form-field">
        <label class="form-label"
          >Password <span style="color: red">*</span></label
        >
        <input
          formControlName="password"
          type="text"
          id="search"
          placeholder="Enter the password"
          class="form-control"
        />
        
        <div style="position: absolute;font-size: 12px; color: red; margin-left: 10px">
          <div *ngIf="form.controls['password'].dirty">
            <span
              *ngIf="form.controls['password']?.errors?.['required']"
              style="color: red"
            >
              *Field is Required.
            </span>
          </div>
          <span
          *ngIf="form.controls['password']?.errors?.['minlength']"
          style="color: red"
        >
          *Minimum 6 characters
        </span>
        <span
          *ngIf="form.controls['password']?.errors?.['maxlength']"
          style="color: red"
        >
          *Maximum 15 characters
        </span>
        <span
          *ngIf="form.controls['password']?.errors?.['pattern']"
          style="color: red"
        >
        *Special character not allowed
        </span>
        <span *ngIf="form.controls['password']?.errors?.['noSpaceInFirstTwoCharacters']" style="color: red">
          *Spaces are not allowed
        </span>
        </div>
      </div>
      <div class="form-field">
        <label class="form-label"
          >Location <span style="color: red">*</span></label
        >
        <input
          formControlName="address"
          type="text"
          id="search"
          placeholder="Search Location"
          ngx-google-places-autocomplete
          (onAddressChange)="handleAddressChange($event)"
          class="form-control"
          #placesRef="ngx-places"
          [options]="options"
        />
        <div
        style="position: absolute"
        *ngIf="form.controls.address.invalid && (form.controls.address.dirty || form.controls.address.touched)"
      >
        <div
          style="font-size: 12px; color: red; margin-left: 10px"
          *ngIf="form.controls.address.errors!['required']"
        >
          Location is required
        </div>

        <div
          style="font-size: 12px; color: red; margin-left: 10px"
          *ngIf="form.controls.address.errors!['minlength']"
        >
          *Minimum 6 characters
        </div>
  
      </div>
      </div>

      <div class="form-field">
        <label class="form-label">Time Zone</label>
        <input
          formControlName="timeZone"
          type="text"
          id="search"
          placeholder="Time Zone"
          class="form-control"
          [value]="loading ? 'Loading...' : (timeZone || '')"
          readonly
        />
      </div>
    </form>
    <div class="map-div">
      <agm-map
        style="height: 315px"
        [latitude]="latitude"
        [longitude]="longitude"
        [zoom]="zoom"
        mapTypeId="hybrid"
      >
        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
      </agm-map>
    </div>
  </div>
</div>

<div class="btn-div">
  <button class="btn btn-danger" mat-dialog-close>Cancel</button>
  <button [disabled]="this.form.invalid"
    class="btn btn-primary"
    mat-dialog-close
    (click)="onsubmit()"
   >

    Update
  </button>
</div>
