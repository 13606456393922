<div class="email">
  <img
    style="width: 75px"
    src="../../assets/organization/devsbot-favicon-13.png"
    alt=""
  />
  <div>
    <!-- <h3>Devsbot Info</h3> -->
    <h3>Reset Password Link Expired</h3>
  </div>
</div>
