<mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="loading" id="spinner"></mat-spinner>

<div *ngIf="!loading && isread == true" style="height: 100%;" class="main-cluster">
  <div class="content" *ngIf="clusterdata?.length == 0">
    <div class="newcluster">
      <h1 style="font-weight: 500">Clusters</h1>
      <p style="font-weight: 500">
        No Data Found
      </p>
      <div class="buttons">
        <button class="btn btn-primary new-dev" (click)="CreateCluster()">
          New Cluster <i class="fa-solid fa-plus"></i>
        </button>
      </div>
    </div>
    <div>
    </div>
  </div>
  <div style="padding: 10px" *ngIf="clusterdata.length >= 1">
    <div class="col-md-12 d-flex">
      <div class="col-md-6">
        <div class="main-head">
          <div class="d-flex" style="align-items: center; gap:5px">

            <h1> <mat-icon *ngIf="superadmin" class="close-icon" (click)="back()">arrow_back</mat-icon> Clusters</h1>
          </div>
        </div>
        <div class="count-vertical">
          <div>
            <span class="count-vertical-font">
              <h4>No Of Clusters : {{ clusterdata.length }}</h4>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex new-dev-sec">
        <div class="buttons">
          <button class="btn btn-primary new-dev" (click)="CreateCluster()">
            New Cluster <i class="fa-solid fa-plus"></i>
          </button>
        </div>
        <div class="search-div">
          <input type="search" #search class="form-control autocomplete" placeholder="Search Cluster"
            [(ngModel)]="filterTerm" />
          <mat-icon class="search-icon" (click)="search.focus()">search</mat-icon>
        </div>
      </div>
    </div>

    <div class="vertical-body">
      <div class="vertical" *ngFor="let cluster of clusterdata | filter : filterTerm"
        (click)="Clusterdata(cluster.group_id)">
        <div class="vertical-info">
          <h6>{{ cluster.created_on | date : "medium" }}</h6>

          <div class="verticallogo">
            {{ cluster.group_name.slice(0, 1) | uppercase }}
          </div>
        </div>
        <div class="vertical-preview">
          <p>{{ '('+cluster.vertical_name+ ')'}}</p>
          <h2>{{ cluster.group_name }}</h2>
          <div *ngIf="cluster.device_count == 0">No Devices</div>
          <div *ngIf="cluster.device_count >= 1">
            {{ cluster.device_count }} Devices
          </div>
        </div>
      </div>
    </div>
  </div>

</div>