import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Dataservice } from '../services/dataservice';
import { ToastrService } from 'ngx-toastr';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ActivatedRoute, Router } from '@angular/router';
import { deletepopup } from '../mydevices/mydevices.component';
import { AuthenticationService } from '../Authentication/authentication.service';
@Component({
  selector: 'app-web-zone',
  templateUrl: './web-zone.component.html',
  styleUrls: ['./web-zone.component.css']
})
/**
 * Represents the component responsible for managing webzone data and user roles.
 */
export class WebZoneComponent {
  loading: boolean;
  id: any;
  user_Id: any;
  data: any;
  webzonedata: any;
  org_Id: any;
  config: any;
  filterTerm!: string;
  item: any;
  webzoneinfo: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  /**
    * Initializes an instance of the WebzoneComponent.
    *
    * @param {MatDialog} matdialog - Angular Material dialog service.
    * @param {Dataservice} dataservice - Service for managing data.
    * @param {ToastrService} toastr - Service for showing toast messages.
    * @param {Router} router - Angular router service.
    * @param {AuthenticationService} authentication - Service for user authentication.
    */
  constructor(public matdialog: MatDialog,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private router: Router, private authentication: AuthenticationService) {
    this.loading = false;

    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
    };
    // Get user roles and initialize permission flags
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[6];
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })


  }
  /**
  * Angular lifecycle hook - called after initialization.
  */
  ngOnInit(): void {
    this.dataservice.setPaginationState(null, 'all');
    this.loading = true;
    // This can be used to control API calls or data updates when switching components.
    this.dataservice.MyDevicestatus.next()
    this.dataservice.AllDevicestatus.next()
    this.dataservice.singleDevicestatus.next()
    // Get user data
    const data = this.authentication.getUserData()
    this.getwebzonedata(data);
    // Subscribe to data refresh events
    this.dataservice.Webzonerefresh.subscribe((response: any) => {
      this.getwebzonedata(data)
    })
  }
  /**
   * Fetches webzone data from the server based on the provided user data.
   *
   * @param {any} data - User data used to fetch webzone data.
   */
  getwebzonedata(data: any) {
    this.dataservice.getwebzone(data).subscribe((res: any) => {
      console.log(res);

      this.loading = false;
      if (res.status == "200") {
        this.data = res.data;
        this.webzonedata = this.data;
      } else {
        this.toastr.error("Error occurred");
      }
    })
  }
  /**
   * Opens the webzone creation dialog if the user has create permission.
   */
  open() {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(webzoneCreate, { disableClose: true });
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }
  /**
   * Called when the filter term changes. Updates the current page to 1.
   */
  onFilterTermChange() {
    // Update previousFilterTerm whenever filterTerm changes
    this.config.currentPage = 1;
  }


  /**
   * Retrieves detailed information about a specific webzone based on its ID.
   *
   * @param {any} id - The ID of the webzone to retrieve information for.
   */
  info(id: any) {
    // find the selected item by webzone_Id
    const selectedItem = this.webzonedata.find((item: { webzone_Id: any; }) => item.webzone_Id === id);
    // check if a selected item is found
    if (selectedItem) {

      this.item = selectedItem;

    }
  }
  /**
 * Handles pagination page change events.
 *
 * @param {any} event - The page change event.
 */
  pageChanged(event: any) {
    this.config.currentPage = event;
  }

  /**
   * Deletes a webzone based on its ID if the user has delete permission.
   *
   * @param {any} Id - The ID of the webzone to be deleted.
   */
  deletewebzone(Id: any) {
    if (this.isdelete == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "delete"
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          const webzone_Id = { Id }
          this.dataservice.deletewebzone(webzone_Id).subscribe((res: any) => {

            if (res.status == "201") {
              this.toastr.success("Web Zone Deleted Successfully");
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1500);
            } else {
              this.toastr.error("error occurred")
            }

          })
        }
      });
    }
    else if (this.isdelete == false) {
      this.toastr.info("User not permitted")
    }
  }
  /**
 * Opens the webzone edit dialog if the user has create permission.
 *
 * @param {any} item - The item to be edited.
 */
  editwebzone(item: any) {
    if (this.iscreate == true) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.data = { webzoneData: this.webzonedata, selectedItem: item };
      const dialogRef = this.matdialog.open(webzoneEdit, dialogConfig);
    }
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted")
    }
  }
}


@Component({
  selector: 'webzoneCreate',
  templateUrl: 'webzone-create.html',
  styleUrls: ['web-zone.component.css']
})
/**
 * Component responsible for creating a new webzone.
 */
export class webzoneCreate implements OnInit {



  street_number: any;
  latitude!: any
  longitude!: any
  zoom: number;
  data: any;
  id: any;
  user_Id: any;
  isAddress = false;
  subAddress = false;
  loading: boolean;
  Address: any;

  noSpaceInFirstTwoCharactersValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
    const value: string = control.value;


    if (value && value.length >= 2) {
      // Check if the second character is a space
      if (value.charAt(1) === ' ') {

        // Check if there are two or more spaces after the first character
        if (value.substring(2).includes(' ')) {
          return { 'noSpaceInFirstTwoCharacters': true };
        }
      }
    }


    return null; // Validation passed
  };
  // FormGroup to manage form controls
  webzonedata = this.formbuilder.group({
    name: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15), Validators.pattern('^[A-Za-z_][A-Za-z0-9_ .\\-]*$'), this.noSpaceInFirstTwoCharactersValidator],
    ],
    ssid: [
      '',
      [Validators.required, Validators.minLength(2),this.noSpaceInFirstTwoCharactersValidator]],
    password: [
      '',
      [Validators.required, Validators.minLength(6),this.noSpaceInFirstTwoCharactersValidator]],
    location: ['', [Validators.required, Validators.minLength(6)]],
    latitude: [''],
    longitude: [''],
    address: [''],
    timeZone: [''],
  })




  // Options for Google Places Autocomplete
  options = {
    types: ["establishment"],
    componentRestrictions: { country: ['IND', 'US', 'AUS', 'UK', 'RU'] },
    mapTypeId: 'hybrid',
  } as unknown as Options;
  @ViewChild("placesRef")
  placesRef!: GooglePlaceDirective;
  timeZone: any;




  /**
  * Creates an instance of the webzoneCreate component.
  *
  * @param {Dataservice} dataservice - Service for managing data related to webzones.
  * @param {FormBuilder} formbuilder - Service for building and managing forms.
  * @param {ToastrService} toastr - Service for displaying toast messages.
  * @param {AuthenticationService} authentication - Service for user authentication.
  */



  constructor(private dataservice: Dataservice,
    private formbuilder: FormBuilder,
    private toastr: ToastrService, private authentication: AuthenticationService) {
    this.loading = false;
    this.zoom = 15;
  }


  /**
   * Handles the change of selected address.
   *
   * @param {Address} address - The selected address object.
   */
  public handleAddressChange(address: Address) {
    this.Address = address;
    // Get latitude and longitude from the selected address
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();


    this.webzonedata.controls['location'].setValue(this.Address.formatted_address);

    // Call the getTimeZone function from the service to get the timezone for the selected location
    this.dataservice.getTimeZone(this.latitude, this.longitude).subscribe(
      (response: any) => {
        console.log(response)
        const timezoneId = response.timeZoneId;
        const offset = response.rawOffset + response.dstOffset;
        // format the offset into a string like "+5:30"
        const offsetHours = Math.floor(offset / 3600);
        const offsetMinutes = Math.floor((offset % 3600) / 60);
        const offsetString = `${offsetHours >= 0 ? '+' : '-'}${Math.abs(offsetHours)}:${offsetMinutes < 10 ? '0' : ''}${offsetMinutes}`;
        // set the timezone value in your component


        this.timeZone = `${timezoneId} (${offsetString})`;

      },
      (error: any) => {
        this.toastr.error(error);
        // handle errors as needed
      }
    );

    /**
     * Handles the address components to extract latitude, longitude, and address details.
     *
     * @param {any} address - The selected address object.
     */
    address.address_components.forEach((add: any) => {
      add.types.forEach((addType: any) => {
        if (addType == "route")
          this.isAddress = add.long_name;


        if (addType == 'latitude')
          this.latitude = add.long_name;
        if (addType == 'longitude')
          this.longitude = add.long_name;
      });
    });
  }
  /**
 * Handles the event when the timezone is selected.
 *
 * @param {any} event - The selected timezone event.
 */
  selectedTimeZone: string = '';
  getTimezone(event: any) {
    console.log(event);

  }
  /**
 * Handles the form submission for creating a web zone.
 */
  onsubmit() {
    this.loading = true;
    // Get user data from the authentication service
    const user = this.authentication.getUserData();
    this.user_Id = user.user_Id;
    const org_Id = user.org_Id;
    const user_Id = this.user_Id;
    const addressValue = this.placesRef?.place?.vicinity;
    const timeZoneValue = this.timeZone;
    const longitudeValue = this.longitude;
    const latitudeValue = this.latitude;

    // Set the values in the form controls
    this.webzonedata.controls['address'].setValue(addressValue);
    this.webzonedata.controls['timeZone'].setValue(timeZoneValue);
    this.webzonedata.controls['longitude'].setValue(longitudeValue);
    this.webzonedata.controls['latitude'].setValue(latitudeValue);
    if (!addressValue) {
      this.webzonedata.controls['address'].setValue(String(this.webzonedata.value.location));
    } else {
      this.webzonedata.controls['address'].setValue(addressValue);
    }
    const webzonedata = this.webzonedata.value



    const data = { webzonedata, user_Id, org_Id };

    console.log(data);



    this.dataservice.createWebZone(data).subscribe((res: any) => {


      this.loading = false;
      if (res.status == '201') {
        this.toastr.success("Web Zone Created");

      } else {
        this.toastr.error("Web Zone Creation Failed!");
      }
    })
  }
  /**
 * Displays the address details.
 */
  Show() {
    this.isAddress = true;
  }
  /**
 * Opens the address input section.
 */
  open() {
    this.subAddress = true;
  }

  ngOnInit(): void {

  }
}
@Component({
  selector: 'webzoneEdit',
  templateUrl: 'webzone-edit.html',
  styleUrls: ['web-zone.component.css']
})

export class webzoneEdit implements OnInit {
  webzoneData: any;
  selectedItem: any;
  webzoneinfo: any;
  loading!: boolean;
  webzoneForm: any;
  zoom!: number;
  // Options for Google Places Autocomplete
  options = {
    types: ["establishment"],
    componentRestrictions: { country: ['IND', 'US', 'AUS', 'UK', 'RU'] },
    mapTypeId: 'hybrid',
  } as unknown as Options;
  // Declare the form group and its controls
  form!: FormGroup<{
    webzone_name: FormControl<any>;
    address: FormControl<any>;
    password: FormControl<any>;
    SSID: FormControl<any>;
    latitude: FormControl<any>;
    longitude: FormControl<any>;
    webzone_Id: FormControl<any>;
    timeZone: FormControl<any>
  }>;
  // Declare properties for storing timezone, latitude, and longitude
  timeZone!: string;
  longitude!: number;
  Address!: Address;
  isAddress: any;
  latitude!: number;
  // Reference to Google Places directive
  @ViewChild("placesRef")
  placesRef!: GooglePlaceDirective;
  subAddress = false;

  id: any;
  /**
  * Initializes the `webzoneEdit` component.
  *
  * @param formBuilder - The FormBuilder service for creating forms.
  * @param dataservice - The Dataservice for handling data operations.
  * @param authentication - The AuthenticationService for user authentication.
  * @param toastr - The ToastrService for displaying notifications.
  * @param dialogRef - The MatDialogRef for the current dialog.
  * @param data - The injected data for the dialog.
  */
  constructor(private formBuilder: FormBuilder, private dataservice: Dataservice, private authentication: AuthenticationService, private toastr: ToastrService,
    public dialogRef: MatDialogRef<webzoneEdit>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    // Initialize properties based on dialog data
    this.webzoneData = data.webzoneData;
    this.selectedItem = data.selectedItem;
    this.loading = false;
    this.zoom = 15;



  }

  noSpaceInFirstTwoCharactersValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
    const value: string = control.value;


    if (value && value.length >= 2) {
      // Check if the second character is a space
      if (value.charAt(1) === ' ') {

        // Check if there are two or more spaces after the first character
        if (value.substring(2).includes(' ')) {
          return { 'noSpaceInFirstTwoCharacters': true };
        }
      }
    }


    return null; // Validation passed
  };
  /**
   * Lifecycle hook called when the component is initialized.
   */
  ngOnInit() {
    // Create a FormGroup with form controls for editing web zone data
    this.form = this.formBuilder.group({
      webzone_name: [null,  [Validators.required, Validators.minLength(2), Validators.maxLength(15), Validators.pattern('^[A-Za-z_][A-Za-z0-9_ .\\-]*$'), this.noSpaceInFirstTwoCharactersValidator]],
      address: [null, [Validators.required, Validators.minLength(6)]],
      password: [null, [Validators.required, Validators.minLength(6),this.noSpaceInFirstTwoCharactersValidator]],
      SSID: [null, [Validators.required, Validators.minLength(2),this.noSpaceInFirstTwoCharactersValidator]],
      latitude: [null,],
      longitude: [null,],
      timeZone: [null,],
      webzone_Id: [null,],
    });


    // Patch the selected item's data into the form

    this.form.patchValue(this.selectedItem);


    // / Set the initial latitude and longitude based on the form's initial value
    this.latitude = this.form.value.latitude;
    this.longitude = this.form.value.longitude;
    this.timeZone = this.form.value.timeZone;



    console.log(this.form);
  }

  /**
  * Handles the form submission for updating the web zone.
  */


  onsubmit() {
    // Get token from local storage
    this.id = localStorage.getItem('token');
    const user = this.authentication.getUserData()
    const user_Id = user.user_Id;
    // Update webzone_name value by removing multiple consecutive whitespaces
    this.form.controls.webzone_name.setValue(this.form.value.webzone_name.replace(/[^\S\r\n]{2,}/g, ' '))
    this.form.value.timeZone = this.timeZone;
    this.form.value.latitude = this.latitude
    this.form.value.longitude = this.longitude


    // Get web zone data from the form
    const webzonedata = this.form.value;
    // Prepare data for update
    const data = { user_Id, webzonedata }
    // Call the dataservice to update the web zone
    this.dataservice.updateWebZone(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == '201') {
        this.toastr.success("Web Zone Updated Successfully");
      } else {
        this.toastr.error("Web Zone Update Failed!");
      }
    })
  }

  /**
     * Handles the change of address when the user selects a new address.
     * @param address - The selected address information.
     */
  public handleAddressChange(address: Address) {
    this.Address = address;
    console.log(this.Address);

    // Set the value of the 'address' form control to the selected address
    this.form.controls['address'].setValue(address.formatted_address);


    // Set the form dirty flag to true
    this.form.markAsDirty();

    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();



    // call the getTimeZone function from your service to get the timezone for the selected location
    this.dataservice.getTimeZone(this.latitude, this.longitude).subscribe(
      (response: any) => {

        const timezoneId = response.timeZoneId;
        const offset = response.rawOffset + response.dstOffset;

        // format the offset into a string like "+5:30"
        const offsetHours = Math.floor(offset / 3600);
        const offsetMinutes = Math.floor((offset % 3600) / 60);
        const offsetString = `${offsetHours >= 0 ? '+' : '-'}${Math.abs(offsetHours)}:${offsetMinutes < 10 ? '0' : ''}${offsetMinutes}`;

        // set the timezone value in your component
        this.timeZone = `${timezoneId} (${offsetString})`;

      },
      (error: any) => {
        console.log(error);
      }
    );
  }






}

@Component({
  selector: 'webzoneInfo',
  templateUrl: 'webzone-info.html',
  styleUrls: ['web-zone.component.css']
})
export class webzoneInfo implements OnInit {
  /**
  * Represents the loading state of the component.
  */
  loading: boolean;
  id: any;
  user_Id: any;
  data: any;
  webzonedata: any;
  org_Id: any;
  config: any;
  /**
  * Represents the search filter term for web zones.
  */
  filterTerm!: string;
  item: any;
  webzoneinfo: any;
  /**
   * Initializes the `webzoneInfo` component.
   *
   * @param matdialog - The MatDialog service for opening dialogs.
   * @param dataservice - The Dataservice for handling data operations.
   * @param toastr - The ToastrService for displaying notifications.
   * @param router - The Router for navigating between views.
   * @param route - The ActivatedRoute for accessing route parameters.
   */
  constructor(public matdialog: MatDialog, private dataservice: Dataservice, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) {
    this.loading = false;

    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
    };


  }
  ngOnInit(): void {



  }
  /**
   * Opens a dialog for creating a new web zone.
   */
  open() {
    const MatDialogConfig = this.matdialog.open(webzoneCreate, { disableClose: true });
  }

  /**
    * Displays detailed information about a specific web zone.
    *
    * @param id - The ID of the selected web zone.
    */
  info(id: any) {
    // find the selected item by webzone_Id
    const selectedItem = this.webzonedata.find((item: { webzone_Id: any; }) => item.webzone_Id === id);

    // check if a selected item is found
    if (selectedItem) {

      this.item = selectedItem;
      console.log(this.item);

      // open the side-nav to display the details of the selected item


    }
  }



}


