import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Dataservice } from '../services/dataservice';
import { logout } from '../menu/menu.component';
import { AuthenticationService } from '../Authentication/authentication.service';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  loading: boolean;
  data: any;
  id: any;
  user_id: any;
  userdata: any;
  user: any;
  roles: any;

  /**
 * Constructs an instance of the component.
 * @param {Dataservice} dataservice - The service used for data operations.
 * @param {Router} router - The router service for navigating between routes.
 * @param {MatDialog} matdialog - The dialog service for displaying dialogs.
 * @param {ToastrService} toastr - The service for displaying toast notifications.
 * @param {AuthenticationService} authentication - The service for user authentication operations.
 * @returns {void}
 */
constructor(
  private dataservice: Dataservice,
  private router: Router,
  private matdialog: MatDialog,
  private toastr: ToastrService,
  private authentication: AuthenticationService
) {
  /**
   * Initialize the loading state to false.
   */
  this.loading = false;
}


 /**
 * Angular lifecycle hook - called after initialization.
 * Initializes component properties and subscribes to data service for updates.
 * @returns {void}
 */
ngOnInit(): void {
  /**
   * Initialize device status flags using the data service.
   */
  this.dataservice.MyDevicestatus.next();
  this.dataservice.AllDevicestatus.next();
  this.dataservice.singleDevicestatus.next();
  this.dataservice.setPaginationState(null,'all');
  /**
   * Get user data from authentication service.
   */
  const data = this.authentication.getUserData();

  /**
   * Call the function to retrieve user-related data.
   */
  this.getuserdata(data);

  /**
   * Subscribe to the Userrefresh event from the data service to handle updates.
   */
  this.dataservice.Userrefresh.subscribe((response: any) => {
    /**
     * Call the function to retrieve user-related data again after a refresh is required.
     */
    this.getuserdata(data);
  });
}

  /**
 * Retrieves user data using the data service and subscribes to the response.
 * @param {any} data - User data required for the request.
 * @returns {void}
 */
getuserdata(data: any): void {
  /**
   * Call the data service to get user-related data.
   */
  this.dataservice.getuserdata(data).subscribe((res: any) => {
    if (res.status == "200") {
      /**
       * Update the component's `userdata` property with the retrieved data.
       */
      this.userdata = res.data;
    } else {
      /**
       * Display an error notification if an error occurs during data retrieval.
       */
      this.toastr.error("Error occurred");
    }
  });
}

 /**
 * Logs out the user by displaying a confirmation dialog and clearing local storage upon confirmation.
 * After logging out, navigates the user to the sign-in page.
 * @returns {void}
 */
logout(): void {
  /**
   * Configure the dialog for logging out and disable closing the dialog.
   */
  const MatDialogConfig = this.matdialog.open(logout, { disableClose: true });

  /**
   * Subscribe to the dialog's 'afterClosed' event to handle the user's confirmation choice.
   */
  MatDialogConfig.afterClosed().subscribe((result: any) => {
    if (result.event == "confirm") {
      /**
       * Clear the local storage to log the user out.
       */
      localStorage.clear();
      /**
       * Display a success notification for successful logout.
       */
      this.toastr.success("Successfully Logged Out");
      /**
       * Navigate the user to the sign-in page.
       */
      this.router.navigate(['/app/signin']);
    }
  });
}

 /**
 * Opens a dialog for the user to change their password.
 * @returns {void}
 */
changepassword(): void {
  /**
   * Configure the dialog for changing the password and disable closing the dialog.
   */
  const MatDialogConfig = this.matdialog.open(changepassword, { disableClose: true });
}

}


@Component({
  selector: 'changepassword',
  templateUrl: 'changepassword.html',
  styleUrls: ['./account.component.css']
})
export class changepassword implements OnInit {

  loading: boolean;
  hide = true;
  show = true;
  id: any;
  user_id: any;
  data: any;

 /**
 * Constructs an instance of the component.
 * @param {Dataservice} dataservice - The service used for data operations.
 * @param {FormBuilder} formbuilder - The form builder service for creating and managing forms.
 * @param {MatDialog} matdialog - The dialog service for displaying dialogs.
 * @param {ToastrService} toastr - The service for displaying toast notifications.
 * @param {AuthenticationService} authentication - The service for user authentication operations.
 * @returns {void}
 */
constructor(
  private dataservice: Dataservice,
  private formbuilder: FormBuilder,
  private matdialog: MatDialog,
  private toastr: ToastrService,
  private router: Router,
  private authentication: AuthenticationService
) {
  /**
   * Initialize the loading state to false.
   */
  this.loading = false;

}

 /**
 * Represents the form group for changing the user's password.
 * @type {FormGroup}
 */
 noSpaceValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
  if (control.value && /\s/.test(control.value)) {
    return { 'noSpace': true }; // Validation failed if there are spaces
  }
  return null; // Validation passed
};
passwordform = this.formbuilder.group({
  /**
   * Represents the input field for the user's old password.
   * @type {FormControl}
   */
  oldpassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20),this.noSpaceValidator]],
  /**
   * Represents the input field for the user's new password.
   * @type {FormControl}
   */
  newpassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20),this.noSpaceValidator]]
});
/**
 * Angular lifecycle hook - called after initialization.
 * Retrieves and sets user-related data during component initialization.
 * @returns {void}
 */  
ngOnInit(): void {
  
  /**
   * Get the user data from the authentication service.
   */
  this.data = this.authentication.getUserData();
  /**
   * Set the user ID based on the retrieved user data.
   */
  this.user_id = this.data.user_Id;
}

  /**
 * Handles the process of changing the user's password.
 * Compares old and new passwords, initiates the change if they are different.
 * @returns {void}
 */
changepassword(): void {
  /**
   * Compare if the old password and new password are the same.
   */
  if (this.passwordform.value.oldpassword === this.passwordform.value.newpassword) {
    /**
     * Display an info notification if old and new passwords are the same.
     */
    this.toastr.info("Old password and new password are the same");
  } else {
    /**
     * Set the loading state to true.
     */
    this.loading = true;
    /**
     * Get the password form value.
     */
    const passwordform = this.passwordform.value;
    /**
     * Get the user ID for the password change operation.
     */
    const user_Id = this.user_id;
    /**
     * Prepare the data for the password change operation.
     */
    const data = { passwordform, user_Id };
    /**
     * Subscribe to the dataservice's changepassword method.
     */
    this.dataservice.changepassword(data).subscribe((res: any) => {
      /**
       * Set the loading state to false.
       */
      this.loading = false;
      /**
       * Handle the response status for different scenarios.
       */
      if (res.status == '201') {
        /**
         * Display a success notification for a successful password change.
         */
        this.toastr.success("Password Successfully Changed");
        /**
         * Close all open dialog boxes.
         */
        this.matdialog.closeAll();
        localStorage.clear();
        this.router.navigate(['/app/signin']);
      } else if (res.status == "100") {
        /**
         * Display an error notification for an incorrect old password.
         */
        this.toastr.error("Old Password is Incorrect");
      } else {
        /**
         * Display an error notification for other errors.
         */
        this.toastr.error("Error occurred");
      }
    });
  }
}


}