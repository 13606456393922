import { Component, OnInit } from '@angular/core';
import { Dataservice } from '../services/dataservice';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../Authentication/authentication.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  constructor(public matdialog: MatDialog,private dataservice :Dataservice, private authentication: AuthenticationService) {

  }
  ngOnInit(): void {
    this.dataservice.setPaginationState(null,'all');
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[11];
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })
    this.dataservice.MyDevicestatus.next()
    this.dataservice.AllDevicestatus.next()
    this.dataservice.singleDevicestatus.next()
    
  }

}
